.input-container {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  font-family: Jost-400;
  font-size: 1.6rem;
}

.input-container:focus-within label {
  transform: translate(0, 35%) !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: #7458d3 !important;
}

.input-container:focus-within:has(.filled) label {
  transform: translate(0, 0.5rem) !important;
}

.input-container .filled {
  transform: translate(0, 0.5rem) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.input-container input:-webkit-autofill ~ label {
  transform: translate(0, 0.5rem) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.input-container input:-webkit-autofill:hover ~ label {
  color: rgba(116, 88, 211, 0.6);
}

.input-container input:-webkit-autofill {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background-color: rgba(244, 244, 244, 0.4) !important;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: rgba(88, 88, 88, 0.6);
  -webkit-text-fill-color: #585857;
  -webkit-text-size-adjust: 20px;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}

.input-container input:-webkit-autofill::before {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background-color: rgba(244, 244, 244, 0.4) !important;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: rgba(88, 88, 88, 0.6);
  -webkit-text-fill-color: #585857;
  -webkit-text-size-adjust: 20px;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}

input:-autofill {
  font-size: 1.6rem;
}

.input-container label {
  position: absolute !important;
  pointer-events: none !important;
  transform: translate(0, 1.3rem) scale(1) !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 1.6rem !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.input-container:hover label {
  color: rgba(116, 88, 211, 0.6);
}

/* --------------------------------------------------------------------------------- */
.input-container input {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background: rgba(244, 244, 244, 0.4);
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: #585857;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}

.input-container input::before {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background: rgba(244, 244, 244, 0.4);
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: #585857;
}

.input-container input:hover {
  border: 1px solid rgba(116, 88, 211, 0.2);
  border-radius: 10px;
}

.input-container input:hover::before {
  border: 2px solid rgba(116, 88, 211, 0.2);
  border-radius: 10px;
}

.input-container input:focus {
  background: #ffffff;
  border: 1px solid #7458d3 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}

.input-container input:focus::before {
  background: #ffffff;
  border: 2px solid #7458d3 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}

.input-container .filledInput {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  color: #393939;
}
.input-container .filledInput::before {
  background: #ffffff;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  color: #393939;
}

.input-container .filledInput:hover {
  background: #ffffff;
  border: 1px solid rgba(116, 88, 211, 0.2);
  border-radius: 10px;
  background-image: url('../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  transition: 0ms !important;
}
.input-container .filledInput:hover::before {
  background: #ffffff;
  border: 2px solid rgba(116, 88, 211, 0.2);
  border-radius: 10px;
  background-image: url('../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  transition: 0ms !important;
}

.input-container .filled {
  transform: translate(0, 0.5rem) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}
/* --------------------------------------------------------------------------------- */

.input-container input[type='password'] {
  font-weight: bold;
  font-size: 2.2rem;
  -webkit-text-stroke-width: 0.15rem;
  letter-spacing: 0.09rem;
}

.input-container input:disabled {
  background: rgba(244, 244, 244, 0.8) !important;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  color: rgba(88, 88, 88, 0.4);
}
.input-container input:disabled::before {
  background: rgba(244, 244, 244, 0.8) !important;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  color: rgba(88, 88, 88, 0.4);
}

.input-container input:disabled ~ label {
  color: rgba(88, 88, 88, 0.2);
}

.input-container .errorInput {
  background: #ffffff;
  border: 1px solid rgba(222, 71, 71, 0.8) !important;
  border-radius: 10px;
  transition: 0ms !important;
}

.input-container .errorInput::before {
  background: #ffffff;
  border: 2px solid rgba(222, 71, 71, 0.8) !important;
  border-radius: 10px;
  transition: 0ms !important;
}

.input-container .errorInput:hover {
  background: #ffffff;
  background-image: url('../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  border: 1px solid rgba(222, 71, 71, 0.8) !important;
  border-radius: 10px;
  transition: 0ms !important;
}

.input-container .errorInput:hover::before {
  background: #ffffff;
  background-image: url('../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  border: 2px solid rgba(222, 71, 71, 0.8) !important;
  border-radius: 10px;
  transition: 0ms !important;
}

.input-container .errorInput:hover ~ label {
  color: rgba(222, 71, 71, 0.8) !important;
}

.input-container .errorInput ~ label {
  color: rgba(222, 71, 71, 0.8) !important;
}

.input-container .errorInput:focus ~ label {
  color: rgba(222, 71, 71, 0.8) !important;
}

.input-container .errorInput:focus {
  background: #ffffff;
  border: 1px solid rgba(222, 71, 71, 0.8) !important;
  border-radius: 10px;
  transition: 0ms;
}
.input-container .errorInput:focus::before {
  background: #ffffff;
  border: 2px solid rgba(222, 71, 71, 0.8) !important;
  border-radius: 10px;
  transition: 0ms;
}

.input-container .validInput:focus ~ label {
  color: rgba(74, 195, 137, 0.8) !important;
}

.input-container .validInput {
  background: #ffffff;
  background-image: url('../../images/valid.svg') !important;
  background-repeat: no-repeat;
  background-position: right 14px center;
}

.input-container .validInput:hover {
  background: #ffffff;
  background-image: url('../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  border: 1px solid rgba(74, 195, 137, 0.2) !important;
}

.input-container .validInput:hover::before {
  background: #ffffff;
  background-image: url('../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  border: 2px solid rgba(74, 195, 137, 0.2) !important;
}

.input-container .validInput:hover ~ label {
  color: rgba(74, 195, 137, 0.6) !important;
}

.input-container .validInput:focus ~ label {
  color: rgba(74, 195, 137, 0.8) !important;
}

.input-container .validInput:focus {
  background: #ffffff !important;
  border: 1px solid rgba(74, 195, 137, 0.8) !important;
  background-image: url('../../images/valid.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right 14px center !important;
  border-radius: 10px;
  transition: 0ms;
}
.input-container .validInput:focus::before {
  background: #ffffff !important;
  border: 2px solid rgba(74, 195, 137, 0.8) !important;
  background-image: url('../../images/valid.svg') !important;
  background-repeat: no-repeat !important;
  background-position: right 14px center !important;
  border-radius: 10px;
  transition: 0ms;
}

.errorContainer label {
  position: absolute !important;
  pointer-events: none !important;
  transform: translate(0, 1.3rem) scale(1) !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 1.6rem !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #de4747;
}
.errorContainer:hover label {
  color: #de4747;
}
.errorContainer input {
  background: #ffffff;
  border: 1px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.errorContainer input::before {
  background: #ffffff;
  border: 2px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.errorContainer:focus-within label {
  transform: translate(0, 0.5rem) !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: #de4747 !important;
}
.errorContainer input:focus {
  background: #ffffff;
  border: 1px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}

.errorContainer input:focus::before {
  background: #ffffff;
  border: 2px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.errorContainer input:focus label {
  background: #ffffff;
  border: 1px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.errorContainer input:focus label::before {
  background: #ffffff;
  border: 2px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}

.textInputErrorMessageWrapper {
  font-family: 'Jost-400';
  flex-wrap: wrap;
  font-size: max(1.2rem, 12px);
  line-height: 1.7rem;
  color: #de4747;
  margin-left: 0.5rem;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
}

.bodyWrapper {
  padding: 20px;
  position: relative;
  font-family: 'Jost-300';
  width: 100%;
  height: fit-content;
}

.infoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding-top: 19px;
  border-top: 1px solid rgba(88, 88, 88, 0.1);
}

.innerBody {
  background: rgba(244, 244, 244, 0.6);
  border-radius: 8px;
  padding-left: 20px;
  padding-top: 14px;
  padding-right: 20px;
  padding-bottom: 14px;
  margin-bottom: 16px;
  margin-top: 26px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-top: 18px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: #393939;
}

.time {
  display: flex;
  flex-direction: row;
  padding: 2.5px 12px 2.5px 8px;
  gap: 6px;
  height: 22px;
  background: #f4f4f4;
  border: 0.766197px solid #f4f4f4;
  border-radius: 7px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  margin-right: 4px;
}

.difficulty {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 8px;
}

.difficulty::before {
  content: '-';
  margin-right: 8px;
}

.category {
  background: #ffffff;
  border: 0.766197px solid #585858;
  border-radius: 7px;
  padding: 1px 12px 1px 12px;
  font-family: 'Jost-400';
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #393939;
  margin-left: 4px;
  margin-right: 4px;
}

.codingQuestionText {
  /* font-family: 'News Cycle'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #585858;
}

.codingWrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  padding: 28px;
  padding-top: 0px;
  overflow-y: scroll;
}

.codingQuestionText p {
  font-weight: 700 !important;
}


.codingEditorWrapper {
  border-radius: 8px;
  max-height: 400px;
  width: 49.5%;
}

.freeTextQuestionText p {
  font-weight: 700 !important;
}

.multipleChoiceWrapper {
  padding-left: 28px;
  padding-top: 2px;
  padding-bottom: 20px;
  padding-right: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.freeTextQuestionText {
  font-style: normal;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.02em;

  color: #585858;
}

.multipleChoiceQuestionText {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #585858;
}

.optionText {
  margin-left: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  letter-spacing: -0.02em;
  flex-wrap: wrap;
  color: rgba(88, 88, 88, 1);
  overflow-x: auto;
}

.optionText p {
  margin-bottom: 0px;
}

.optionWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow-x: auto;
}

.Option {
  /* height: 100% !important; */
  /*background: #F4F4F4;*/
  /*margin: 10px 5px;*/
  /*padding: 15px 20px;*/
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.StringOptionWrapper {
  width: 100%;
}

.HtmlOptionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  align-items: flex-start;
}

.HtmlOptionWrapper p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.HtmlOptionWrapper img {
  /* max-width: 100%;
    max-height: 100%; */
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center;
  justify-content: center; */
  max-height: 500px;
  max-width: 300px;
}

.optionCheckbox {
  width: 20px;
  min-width: 20px !important;
  height: 20px;
  background: #f4f4f4;
  border: 1px solid #393939;
  border-radius: 10px;
  margin-top: 5px;
}

.multipleChoiceQuestionText p {
  font-weight: 700 !important;
}

.freeTextWrapper {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  width: 100%;
  display: inline-block;
  padding: 28px;
  padding-top: 0px;
}

.QuestionMainWrapper {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}


.QuestionMainWrapper pre {
  background-color: #e5e5e5;
  padding: 1rem;
}

@media screen and (max-width: 576px) {
  html:has(.QuestionMainWrapper) {
      font-size: 8px;
  }
}
.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
  padding: 0px !important;
}

.modal-content {
  width: 694px;
  max-width: 100vw;
}
.notAvailable {
  font-family: Jost-300;
  font-size: 1.6rem;
  text-align: center;
  width: 630px;
  margin-top: 75px;
  color: #393939;
  opacity: 0.5;
}
.modalContent {
  border: none;
  padding: 20px 30px !important ;
  width: 694px !important;
  max-width: 694px;
  /* min-height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Header {
  font-family: Jost-400;
  font-size: 24px;
  line-height: 35px;

  display: flex;
  align-items: center;
  color: #393939;

  margin-bottom: 8px;
}

.Description {
  font-family: Jost-300;
  font-size: 16px;
  line-height: 23px;
  color: #393939;

  margin-bottom: 16px;
}

.ButtonWrapper {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 0px auto;
  z-index: 1;
}

.InformationText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
}

.InformationWrapper {
  margin: 8px 0px 8px 0px;
  min-height: 72px;
  /* height: fit-content; */
  max-height: 300px;
  opacity: 1;
  transition: opacity 0.6s, margin 0.6s, min-height 0.6s, max-height 2s;
  /* transition: 1s; */
}

.Hidden {
  min-height: 0px;
  max-height: 0px;
  margin: 0px !important;
  height: 0 !important;
  opacity: 0 !important;
  transition: 0.6s !important;
}

.InformationBox {
  margin-top: 6px;
  width: 634px;
  height: fit-content;
  min-height: 43px;
  background: rgba(116, 88, 211, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  flex-wrap: wrap;

  font-family: 'Jost-300';
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
}

.BodyWrapper {
  display: flex;
  flex-direction: column;
}

.ListWrapper {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.LangAndCategoryWrapper {
  /* margin-bottom: 10px; */
}

.Disabled {
  pointer-events: none;
  opacity: 0.4;
}

.Category {
  /* font-family: 'Inter-400'; */
  font-size: 12px;
  line-height: 15px;
  color: rgba(88, 88, 88, 0.6);
  margin: 10px 0px 0px;
}

.LanguagesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
}

.Language {
  font-family: 'Jost-300';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #585858;
  width: 200px;

  margin-bottom: 8px;
}
.difficultyWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 18px 0px 8px;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.headerWrapper {
  display: flex;
  align-items: center;
}

.headerText {
  font-family: 'Jost-400';
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #393939;
}

.companyInputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.dropzoneWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.logoEmptyContainer {
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='101%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(116, 88, 211, 0.2)' stroke-width='2.5' stroke-dasharray='12%2c 12' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 2px;
  max-height: 156px;
  min-height: 156px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 12px;
}
.logoPresent {
  border: 1px solid rgba(57, 57, 57, 0.2);
  background-image: none;
}

.existingLogo {
  max-width: 80%;
  max-height: 95px;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
}

.emptyLogoTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #393939;
}

.emptyLogoTextWrapper .title {
  font-family: 'Jost-500';
}

.emptyLogoTextWrapper .subtitle {
  font-family: 'Jost-300';
}

.dropzoneButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerText {
  font-family: 'Jost-400';
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(116, 88, 211, 0.6);
}

.bottomButtonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.descriptionBox {
  background: rgba(250, 250, 250, 0.5);
  border: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 10px;
  height: 255px;
  padding: 15px;
  cursor: text;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.descriptionBox textarea {
  text-align: start;
  text-anchor: start;
  align-content: flex-start;
  background: transparent;
  border: none;
  outline: none;
  height: 80%;
  width: 95%;
  max-height: 190px;
  max-width: 486px;
  display: inline-block;
  align-items: flex-start;
  align-content: flex-start;
  align-self: flex-start;
  text-align: left;
  vertical-align: text-top;
  justify-content: flex-start;
  font-family: 'Jost-400';
  font-size: 16px;
  color: rgba(88, 88, 88, 1);
}

.descriptionBox:hover {
  background: #ffffff !important;
  border: 1px solid #7458d3 !important;
}

.descInput::placeholder {
  font-family: 'Jost-400';
  font-size: 16px;
  display: flex;
  color: rgba(88, 88, 88, 0.6);
  text-align: start;
  justify-content: baseline;
  position: absolute;
}

.descInput {
  font-family: 'Jost-400';
  font-size: 16px;
  display: flex;
  color: black;
  text-align: start;
  justify-content: baseline;
  position: absolute;
  resize: none;
}

.wordCountText {
  position: absolute;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
  align-self: flex-end;
  margin-left: 5px;
  margin-bottom: -5px;
}

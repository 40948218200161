.text {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #4ac389;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.close {
  flex-shrink: 0;
  cursor: pointer;
  margin-top: 6px;
  margin-left: 8px;
}

.icon {
  width: 16px;
  flex-shrink: 0;
  margin-left: 12px;
  margin-right: 12px;
  height: 16px;
}

.closeContainer {
  width: 39px;
  height: 39px;
  background: white;
  border-radius: 14px;
  position: absolute;
  z-index: 10005;
  top: -39px;
  text-overflow: clip;
  overflow: clip;
  align-items: center;
  display: flex;
  flex-direction: row;
  transition: top 0.4s cubic-bezier(0.75, 1.14, 0.48, 1.41), width 0.2s linear 0.4s;
}

.closeContainerMobile {
  max-width: 85vw;
  background: white;
  padding-right: 16px;
  height: 43px;
  border-radius: 14px;
  position: absolute;
  z-index: 10005;
  bottom: -200px;
  align-items: center;
  display: flex;
  flex-direction: row;
  transition: bottom 0.7s cubic-bezier(0.75, 1.14, 0.48, 1.41);

  left: 45vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App {
  font-family: sans-serif;
  text-align: center;
}


.wheel {
  margin: 10px auto;
  width: 100%;
}

.circle-bg {
  fill: none;
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 3;
}

.circle {
  fill: none;
  stroke-width: 4;
  stroke-linecap: round;
  stroke: #4AC389;
}

.percentage {
  font-size: 0.5em;
  text-anchor: middle;
}

.myh2 {
  margin-bottom: 0;
}

.CodingQuestionBodyWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 2.4rem 4.2rem;
  color: #393939;
  overflow: hidden;
}

.CodingQuestionBodyWrapperMobile {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 2.4rem 2.4rem;
  color: #393939;
  overflow-y: scroll;
}

.CodingQuestionDescriptionWrapperMobile {
  font-family: Jost-500;
  font-size: 1.8rem;
  line-height: 2.6rem;
  min-width: unset !important;
  height: fit-content;
  padding: 0rem 0rem 4rem 0rem;
}

.CodingQuestionDescriptionWrapperMobile img{
  max-width: 100%;
  height: unset !important;
}

.CodingQuestionDescriptionWrapper {
  font-family: Jost-500;
  font-size: 1.8rem;
  line-height: 2.6rem;
  padding-right: 1.8rem;
  padding-bottom: 8rem;
  width: 50%;
  overflow: scroll;
}

.CodingQuestionDescriptionWrapper img{
  max-width: 100%;
}

.CodingQuestionCompilerWrapper {
  padding-left: 1.8rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.CodingQuestionCompilerWrapperMobile {
  min-width: unset !important;
  height: 600px;
  padding-bottom: 30rem;
}

/* .CodingQuestionCompilerWrapper :global(.monaco-editor) {
    background-color: red;
}

.CodingQuestionCompilerWrapper :global(.monaco-editor-background) {
    background-color: red;
}

.CodingQuestionCompilerWrapper :global(.monaco-editor .margin) {
    background-color: red;
} */

.CompilerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.8rem;
  background: rgba(57, 57, 57, 0.8);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.LanguageDropdown {
  font-family: Jost-400;
  font-size: 1.4rem;
  line-height: 2rem;

  color: #585858;
}

.LanguageDropdown :global(.Dropdown-control) {
  min-width: 144px;
  border-radius: 10px !important;
  padding-left: 1.6rem;
  cursor: pointer;
}

.LanguageDropdown :global(.Dropdown-menu) {
  margin-top: 0.2rem;
  border-radius: 10px !important;
  padding-left: 0px;
  padding-right: 0px;
}

.LanguageDropdown :global(.Dropdown-option) {
  padding-left: 1.6rem;
}

.ResetButton {
  font-family: Jost-400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.CompilerBodyWrapper {
  height: 100%;
  -webkit-user-select: text;
}

.CompilerBodyWrapper :global(.monaco-editor.no-user-select .view-line) {
  -webkit-user-select: text;
  /* -webkit-touch-callout: initial;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; */
}

.CompilerFooter {
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.CompilerFooterUpper {
  padding: 0.8rem 1.8rem;
  background: rgba(57, 57, 57, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: Jost-400;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #ffffff;
}

.CompilerFooterBottom {
  padding: 0.8rem 1.8rem;
  background: #393939;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-family: Jost-400;
  font-size: max(1.6rem, 14px);
  line-height: 2.3rem;
  /* min-height: 90px; */
  height: 120px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.RunButton {
  background: #ffffff;
  border-radius: 10px;
  font-family: Jost-400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #585858;
  padding: 0.6rem 2rem;
  cursor: pointer;
}

.DefaultColor {
  color: rgba(255, 255, 255, 0.6);
}

.MultiSelectDropdown {
  background: #ffffff;
  /* border: 1px solid rgba(88, 88, 88, 0.2); */
  border-radius: 10px;
  min-height: 4.8rem;
  font-size: 1.6rem;
  font-family: 'Jost-400';
}
.MultiSelectDropdown::before {
  background: #ffffff;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  min-height: 4.8rem;
  font-size: 1.6rem;
  font-family: 'Jost-400';
}

.MultiSelectDropdown :global(.searchWrapper) {
  height: 100%;
  border-radius: 10px;
  display: flex;
  /* border: none; */
  flex-wrap: wrap;
  padding: 0.8rem;
  padding-left: 1.6rem;
  min-height: 4.8rem;
  height: 100%;
}

.MultiSelectDropdown :global(.searchWrapper:focus-within) {
  border: 1px solid #7458d3 !important;
}
.MultiSelectDropdown :global(.searchWrapper:focus-within)::before {
  border: 2px solid #7458d3 !important;
}

.MultiSelectDropdown :global(.searchWrapper .chip) {
  margin: 0.2rem 0.4rem 0.2rem 0rem;
  font-size: 1.2rem;
  line-height: 1.9rem;
  background: #7458d3;
  border-radius: 8px;
  padding: 0.4rem 1rem;
  max-width: 98%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MultiSelectDropdown :global(.searchWrapper .searchBox) {
  margin: 0rem;
  color: #585858;
}

.MultiSelectDropdown :global(.searchWrapper .searchBox::placeholder) {
  color: rgba(57, 57, 57, 0.5);
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer) {
  border-radius: 10px;
  border: 1px solid #7458d3 !important;
  max-height: 15.6rem;
}

.MultiSelectDropdown :global(.optionListContainer) {
  margin-top: 10px;
  font-size: 1.6rem;
  color: #585858;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .option:hover) {
  background: rgba(244, 244, 244, 0.8);
  color: #585858;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .option) {
  height: 5.2rem;
  padding-left: 1.6rem;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .notFound) {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5.2rem;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .option input[type='checkbox']) {
  -webkit-appearance: none;
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #585858;
  border-radius: 6px;
  height: 19.2px;
  width: 19.2px;
  max-width: 19.2px;
  max-height: 19.2px;
  min-height: 19.2px;
  min-width: 19.2px;
  margin-top: 0px;
  cursor: pointer;
  vertical-align: sub;
}

.MultiSelectDropdown
  :global(.optionListContainer .optionContainer .option input[type='checkbox']:active) {
  border: 1px solid #7458d3;
}

.MultiSelectDropdown
  :global(.optionListContainer .optionContainer .option input[type='checkbox']:checked) {
  background-color: #7458d3;
  border: 1px solid #7458d3;
  background-image: url('../../RawCheckbox/tick.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

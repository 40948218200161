.wrapper {
  display: flex;
  align-items: center;
}
.text {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #393939;
  margin-left: 0.8rem;
  margin-right: 0.4rem;
  /* margin-top: 3px; */
}
.NanWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}
.smallText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
  color: #585858;
  margin-right: 5px;
  white-space: nowrap;
}

.progressBarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.7rem;
}
.arrow {
  /* margin-top: 0.7rem; */
  width: 1.2rem;
}
.candidateArrow {
  width: 1rem;
  height: 1rem;
}
.info {
  width: 1.2rem;
  height: 1.22rem;
  margin-top: 4px;
  margin-left: 4px;
}
.measure {
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  /* margin-top: 5px; */
  align-items: center;
  justify-content: center;
}
.candidateMeasure {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
.progressBar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  width: 20rem;
  height: 0.8rem;
  border-radius: 16px;
}
.candidateProgressBar {
  width: 16rem;
}

.progress {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background: linear-gradient(270deg, #4ac389 0%, rgba(74, 195, 137, 0.6) 100%);
  height: 8px;
}

.empty {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: #f4f4f4;
  height: 8px;
}

@media screen and (max-width: 576px) {
  .wrapper:hover {
    cursor: pointer;
  }
  .wrapper:active {
    cursor: pointer;
  }
}

@media screen and (max-width: 960px) {
  .progressBar {
    width: 170px;
  }
  .candidateProgressBar {
    width: 12rem;
  }

  .text {
    font-size: 16px;
    line-height: 23px;
  }
  .candidateMeasure {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1440px) {
  .progressBar {
    width: 20rem;
  }
  .candidateProgressBar {
    width: 14rem;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .progressBar {
    width: 24rem;
  }
  .candidateProgressBar {
    width: 16rem;
  }
}
@media screen and (min-width: 1920px) {
  .progressBar {
    width: 28rem;
  }
  .candidateProgressBar {
    width: 23rem;
  }
}

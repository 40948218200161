  .setting {
    font-family: 'Jost-400';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.7rem;
    text-transform: uppercase;
    color: rgba(57, 57, 57, 0.49);
    margin-top: 1.6rem;
  }


.settingBlock {
    margin-top: 1.6rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.6rem;
    margin-top: 1.2rem;
  }


  .settingRow {
    display: flex;
    /* margin-bottom: 2.1rem; */
    align-items: center;
    flex-direction: row;
  }

  .icon {
    width: 13.5px;
    height: 13.5px;
  }

  .settingText {
    font-family: 'Jost-400';
    font-style: normal;
    margin-left: 1.2rem;
    font-weight: 400;
    font-size: 1.6rem;
    cursor: pointer;
    line-height: 2.3rem;
    color: #393939;
  }


.borderBottom {
    padding-bottom: 1.6rem;
    border-bottom: 1px solid #d9d9d9;
  }

  .logoutRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.2rem;
  }
  
  .logoutText {
    font-family: 'Jost-400';
    font-style: normal;
    font-weight: 400;
    margin-left: 1.1rem;
    font-size: 1.6rem;
    cursor: pointer;
    line-height: 2.3rem;
    color: #de4747;
  }
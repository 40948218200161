.wrapper {
  font-family: Jost-400;
  /* height: 50px; */
}

.list {
  z-index: 50;
  /* position: absolute; */
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 0.8rem;
  border: 1px solid #7458d3;
  border-radius: 10px;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  z-index: 5;
  position: sticky;
  overflow-y: auto;
}

.listItem {
  /* display: inline-block; */
  overflow: hidden;
  width: 100%;
  border: none;
  background: transparent;
  white-space: nowrap;
  padding: 1rem 0rem;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.4rem;
  /* height: 5rem; */
  text-align: left;
  padding-left: 1.6rem;
}

.firstItem {
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
}
.firstItem:hover {
  background: rgba(244, 244, 244, 0.8);
}

.lastItem {
  border-radius: 0 0 10px 10px;
}
.lastItem:hover {
  background: rgba(244, 244, 244, 0.8);
}

.selectedItem {
  background: rgba(244, 244, 244, 0.8);
}
.selectedItem:hover {
  color: #7458d3;
}
.listItem:hover {
  background: rgba(244, 244, 244, 0.8);
}

.arrowUp {
  background-image: url('./Images/arrowUpPurple.svg');
  background-repeat: no-repeat;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  margin-right: 5px;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.3s ease-out;
  justify-content: center;
  align-items: center;
}
.arrowDown {
  background-image: url('./Images/arrowDownGray.svg');
  background-repeat: no-repeat;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  /* margin-top: auto; */
  /* margin-bottom: auto; */
  transition: 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.closeNoSelect:hover .arrowDown {
  background-image: url('./Images/arrowDownPurple.svg');
  transition: 0.3s ease-out;
}

.closeSelect:hover .arrowDown {
  background-image: url('./Images/arrowDownPurple.svg');
  transition: 0.3s ease-out;
}

/* FRESH START */

.dd-container {
  position: relative;
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.3rem;
  /* min-height: 50px; */
  border-radius: 10px;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  justify-content: space-between;
}

.closeNoSelect {
  /* background: rgba(244, 244, 244, 0.4); */
  border: 1px solid rgba(88, 88, 88, 0.2);
}
.closeNoSelect::before {
  /* background: rgba(244, 244, 244, 0.4); */
  border: 2px solid rgba(88, 88, 88, 0.2);
}


.closeSelect {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
}
.closeSelect::before {
  background: #ffffff;
  border: 2px solid rgba(88, 88, 88, 0.2);
}

.openNoSelect {
  background: #ffffff;
  border: 1px solid #7458d3;
}
.openNoSelect::before {
  background: #ffffff;
  border: 2px solid #7458d3;
}


.openSelect {
  background: #ffffff;
  border: 1px solid #7458d3;
}
.openSelect::before {
  background: #ffffff;
  border: 2px solid #7458d3;
}
/* .openNoSelect & .openSelect -> input alacak */

.placeholder {
  /* color: #585858; */
  color: #393939;
  /* min-height: 50px !important; */
  padding: 2rem 0.2rem 0.5rem 1.6rem !important;
  letter-spacing: -0.02em;
  font-size: 1.6rem;
  line-height: 2.3rem;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  white-space: pre;
}

.openNoSelect .placeholder {
  color: rgba(88, 88, 88, 0.2);
}

.closeNoSelect .title {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.6rem !important;
  margin-bottom: 0;
  color: rgba(88, 88, 88, 0.6);
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}

.openNoSelect .title {
  position: absolute;
  transform: translate(0, 0.4rem) scale(0.8) !important;
  transform-origin: top left !important;
  font-size: 12px !important;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  padding-left: 2rem !important;
  color: #7458d3;
}
.openSelect .title {
  position: absolute;
  transform: translate(0, 0.4rem) scale(0.8) !important;
  transform-origin: top left !important;
  font-size: 1.3rem !important;
  line-height: 1.6rem;
  letter-spacing: -0.02em;
  padding-left: 2rem !important;
}

.closeSelect .title {
  position: absolute;
  transform: translate(0,0.4rem) scale(0.8) !important;
  transform-origin: top left !important;
  font-size: 1.3rem !important;
  line-height: 1.6rem;
  letter-spacing: -0.02em;
  padding-left: 2rem !important;
  color: rgba(88, 88, 88, 0.6);
}

.closeSelect:hover .title {
  color: rgba(116, 88, 211, 1) !important;
}
.closeNoSelect:hover .title {
  color: rgba(116, 88, 211, 0.6) !important;
}

.mainWrapper{
  width: 90%;
  max-width: 1100px;
  min-width: 750px;
  z-index: 10;
  background-color: #FFFFFF;
  top: 0;
  height: 76px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  background-color: rgba(244, 244, 244, 0.6);
  border-radius: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assessmentName {
  font-style: normal;
  font-weight: 400;
  font-family: 'Jost-400';
  font-size: 20px;
  line-height: 28.9px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
}

.left{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 22px;
}
.dropdown{
  display: flex;
  flex-direction: row;
  min-width: fit-content;
  justify-content: flex-end;
}
.right{
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
  margin-left: auto;
  align-items: center;
  margin-right: 0px;
}

.stage{
  margin-right: 4px;
  display: flex;
  align-items: center;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  line-height: 29px;
  font-size: 18px;
}

.hiringState{
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #585858;
}

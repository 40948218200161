.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.modal-content {
  max-width: 100vw;
  min-width: 572px;
  height: fit-content !important;
  align-items: center;
  display: flex;
}

/* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .modal-content {
    max-width: 100vw;
    min-width: fit-content;
    height: fit-content !important;
    align-items: center;
    display: flex;
  }
}
/* Temporary mobile solution */

.textWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #585858;
  margin-bottom: 12px;
}
.subtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #585858;
  margin-bottom: 24px;
  text-align: center;
}

.subtitleBold {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #585858;
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 45px;

  justify-content: flex-end;
}
.modalContent {
  border: none;
  padding: 24px 24px 24px 32px !important ;
  max-width: 572px;
}
.rowWrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.successIcon {
  /* margin-top: 26px; */
  margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
  .subtitleMobile {
    font-size: 14px;
    margin-bottom: 0;
  }
  .titleMobile {
    font-size: 16px;
    color: #393939;
    margin-bottom: 0;
    font-family: Jost-400;
  }
  .titleWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 24px;
  }
  .mobileButtonWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}

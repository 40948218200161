.PositionsWrapper {
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
  padding: 0px 0px 10px 0px;
  height: 420px;
  width: 438px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.PositionsText {
  font-family: 'Jost-500';
  font-size: 18px;
  line-height: 26px;
  color: #393939;

  min-width: 100%;
  display: flex;
  background: #ffffff;
  position: sticky;
  top: 0px;
  padding-bottom: 18px;
  padding-top: 20px;
  padding-left: 24px;
  z-index: 2;
}

.PositionsWrapper:hover {
  overflow-y: auto;
  /* padding-right: calc(3.6rem - 12px); */
}

.CardsWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 36px;
}

.PositionCardWrapper {
  background: #ffffff;
  border: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 10px;
  margin: 12px 0px 0px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 390px;
}

@media screen and (min-width: 961px) {
  .PositionCardWrapper {
    margin: 0px 0px 12px;
  }

  .PositionsWrapper {
    padding: 0px 0px 12px 0px;
  }
}

.PositionNameRow {
  display: flex;
  align-items: center;
}

.PositionName {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.isSuspectOverlay {
  margin-left: 6px;
}

.CurrentPositionDiv {
  background: rgba(116, 88, 211, 0.15);
  border-radius: 4px;
  color: #7458d3;
  text-transform: uppercase;
  font-family: 'Jost-500';
  font-size: 11px;
  line-height: 16px;
  padding: 3px 8px;
  margin-left: 12px;

  white-space: nowrap;
}

.UpperPart {
  display: flex;
  flex-direction: column;
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  gap: 12px;
  margin-bottom: 16px;
}

.ScoreRow {
  display: flex;
  align-items: center;

  color: #393939;
}

.ScoreWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 12px;
  border-left: 2px solid black;
}

.ScoreCircle {
  width: 12px;
  height: 12px;
  border-radius: 6rem;
  margin-right: 5px;
}

.MiddlePart {
  display: flex;
  align-items: center;
  width: 100%;
}

.FunnelStatusText {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  color: rgba(57, 57, 57, 0.5);
}

.LowerPart {
  border-top: 1px solid rgba(57, 57, 57, 0.1);
  padding-top: 16px;
  margin-top: 16px;
  font-family: 'Jost-300';
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: rgba(57, 57, 57, 0.5);
}

.scrollWrapper {
  display: none;
}

.blurWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 960px) {
  .blurWrapper {
    display: flex;
    flex-direction: row;
    gap: 18px;
  }

  .scrollWrapper {
    display: initial;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
  }

  .scrollWrapper span img {
    max-height: 28px;
  }

  .PositionsWrapper {
    height: 303px;
    width: 100%;
    padding: 0px 10px 10px;
    position: relative;
  }

  .PositionsText {
    /* position: sticky;
    left: 20px; */
    padding-left: 10px;
    padding-bottom: 0;
  }

  .CardsWrapper {
    flex-direction: row;
    gap: 18px;
    overflow-x: hidden;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    scroll-behavior: smooth;
    overflow-y: hidden;
  }

  .CardsWrapper:hover {
    overflow-x: auto;
  }

  .PositionsWrapper:hover {
    overflow-y: hidden;
  }

  .PositionCardWrapper {
    min-width: 360px !important;
    max-width: 360px;
    height: 100%;
    overflow-y: hidden;
  }

  .FunnelStatusText {
    height: 42px;
    align-items: center;
  }
}

@media screen and (max-width: 850px) {
  .PositionCardWrapper {
    min-width: 315px !important;
    max-width: 315px;
    height: 100%;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 700px) {
  .PositionCardWrapper {
    min-width: 335px !important;
    max-width: 335px;
    height: 100%;
    overflow-y: hidden;
  }
}

@media screen and (max-width: 576px) {
  .PositionsWrapper {
    padding: 0px 20px 12px 10px;
  }
}

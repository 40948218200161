.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  color: #585858;
}

.imgWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100vh;
  width: 100vw;
}

.text {
  font-family: Jost-400;
  font-size: 2.4rem;
}
.header {
  font-family: Jost-500;
  font-size: 4.8rem;
}

.textWrapper {
  position: absolute;
  top: 20%;
  left: 8%;
  display: flex;
  flex-direction: column;
}

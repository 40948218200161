.RejectionMailTextEditorWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.RejectionMailTextEditorWrapper::before {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.Toolbar {
  background-color: rgba(88, 88, 88, 0.2) !important;
  margin: 0 !important;
  border: none !important;
}

.Wrapper {
  height: 34.6rem;
  display: flex;
  flex-direction: column;
}

.Editor {
  background-color: white;
  height: calc(100% - 8px) !important;
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0rem 1.2rem 1.4rem;
  padding: 0rem 1.4rem 0rem 0rem;
  overflow-y: auto;
}

@media screen and (max-width: 576px) {
  .Toolbar {
    display: none;
  }

  .Editor {
    margin: 10px 0px 10px 16px;
    padding: 0px 10px 0px 0px;
  }

  .Wrapper {
    height: 136px;
  }
}

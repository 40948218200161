.individual-comment {
  display: grid;
  grid-template-rows: auto auto;
  /* grid-template-columns: max-content auto; */
  grid-column-gap: 2%;
  width: 95%;
}
.addCommentImage {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: central;
  /* align-self: center; */
  font-size: 16px;
  line-height: 35px;
  color: #7458d3;
  background: #c3b6ef;
  border: 1px solid rgba(116, 88, 211, 0.2);
  margin-left: 0;
  margin-right: auto;
  margin-top: 30%;
  font-family: Belleza;
  font-style: normal;
  font-weight: normal;
}
.user-comment {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: central;
  font-size: 16px;
  line-height: 35px;
  font-family: Belleza;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
}

.comment-editState {
  border: 1px solid #7458d3;
  border: 1px solid #7458d3;
  box-sizing: border-box;
  filter: drop-shadow(1px 1px 6px rgba(116, 88, 211, 0.15));
  border-radius: 25px;
  height: fit-content;
  width: 100%;
  display: inline-block;
  padding: 15px;
}

.commentshowcircle-current {
  height: fit-content;
  width: 100%;
  display: inline-block;
  padding: 15px;
  /* margin-left: 10px; */
  margin-right: 5px;
  background: rgba(244, 244, 244, 0.4);
  border-radius: 18px;
}

.commentshowcircle-current:hover {
  height: fit-content;
  width: 100%;
  display: inline-block;
  padding: 15px;
  /* margin-left: 10px; */
  margin-right: 5px;
  background: rgba(244, 244, 244, 0.8);
  border-radius: 18px;
}

.commentshowcircle {
  height: fit-content;
  width: 100%;
  display: inline-block;
  padding: 15px;
  margin-left: 5px;
  background: rgba(244, 244, 244, 0.4);
  border-radius: 18px;
}
.currentUserEdit {
  display: grid;
  grid-template-columns: 8fr 1fr;
}

.comment-userName-text {
  font-family: Belleza;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
}

.comment-content-text {
  font-family: News Cycle;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
  background-color: transparent;
  border-color: transparent;
  width: 100%;
  height: 100%;
}

.comment-content-text:focus {
  outline: none;
}

.comment-date-text {
  font-family: News Cycle;
  font-style: normal;

  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.4);
}

.comment-button-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 5%;
  margin-left: 10px;
}
.comment-cancel-button {
  border: 1px solid rgba(88, 88, 88, 0.4);
  box-sizing: border-box;
  border-radius: 24px;
  width: 154px;
  height: 40px;
  background-color: transparent;
  color: rgba(88, 88, 88, 0.6);
  grid-column-start: 1;
  margin-right: 5%;
  margin-left: auto;
  cursor: pointer;
}

.comment-cancel-button:hover {
  color: rgba(88, 88, 88, 1);
}

.comment-save-button {
  background: rgba(116, 88, 211, 0.2);
  border: 1px solid rgba(116, 88, 211, 0.2);
  box-sizing: border-box;
  border-radius: 24px;
  width: 154px;
  height: 40px;
  color: #7458d3;
  grid-column-start: 2;
  margin-left: 5%;
  cursor: pointer;
}

.comment-save-button:hover {
  background: rgba(116, 88, 211, 0.4);
}

.comment-save-button:focus {
  outline: none;
  border: 1px solid #7458d3;
}

.comment-cancel-button:focus {
  outline: none;
  border: 1px solid #7458d3;
}

.editIconWrapper {
  width: 21px;
  height: 21px;
  grid-column-start: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 25px;
  cursor: pointer;
}

.editIconWrapper:hover {
  background-color: rgba(88, 88, 88, 0.1);
}

.deleteIconWrapper {
  width: 21px;
  height: 21px;
  grid-column-start: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 25px;
  cursor: pointer;
}

.deleteIconWrapper:hover {
  background-color: rgba(88, 88, 88, 0.1);
}

/* Icon Image  */
.img {
  /* display: inline-block; */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  text-transform: uppercase;
  border-radius: 50%;
}

.img1 {
  color: #7458d3;
  border: 1px solid rgba(116, 88, 211, 0.2);
  background: rgb(226, 223, 244);
}
.img2 {
  color: rgba(226, 119, 33, 1);
  background: rgba(226, 119, 33, 0.3);
  border: 1px solid rgba(226, 119, 33, 0.2);
}
.img3 {
  color: rgba(33, 226, 144, 1);
  background: rgba(33, 226, 144, 0.3);
  border: 1px solid rgba(33, 226, 144, 0.2);
}
.img4 {
  color: rgba(226, 119, 229, 1);
  background: rgba(226, 119, 229, 0.3);
  border: 1px solid rgba(226, 119, 229, 0.2);
}
.img5 {
  color: rgba(17, 54, 183, 1);
  background: rgba(17, 54, 183, 0.3);
  border: 1px solid rgba(17, 54, 183, 0.2);
}
.img6 {
  color: rgba(164, 188, 54, 1);
  background: rgba(164, 188, 54, 0.3);
  border: 1px solid rgba(164, 188, 54, 0.2);
}
.img7 {
  color: rgba(100, 37, 6, 1);
  background: rgba(100, 37, 6, 0.3);
  border: 1px solid rgba(100, 37, 6, 0.2);
}
.img8 {
  color: rgba(105, 36, 141, 1);
  background: rgba(105, 36, 141, 0.3);
  border: 1px solid rgba(105, 36, 141, 0.2);
}
.img9 {
  color: rgba(255, 0, 130, 1);
  background: rgba(255, 0, 130, 0.3);
  border: 1px solid rgba(255, 0, 130, 0.2);
}
.img10 {
  color: rgba(36, 151, 151, 1);
  background: rgba(36, 151, 151, 0.3);
  border: 1px solid rgba(36, 151, 151, 0.2);
}

.commentsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: fit-content;
  margin-bottom: 12px;
}

.withIconWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  /*min-height: 156px;*/
}

.userIconWrapper {
  width: min-content;
}

.commentWrapperRight {
  width: 75%;
  background: rgba(244, 244, 244, 0.4);
  border-radius: 18px;
  margin-right: 2%;
  margin-left: auto;
  padding: 12px 18px 10px;
  font-family: Jost-400;
  line-height: 26px;
  font-style: normal;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
}

.commentWrapperRight:hover {
  background: rgba(244, 244, 244, 0.8);
}

.commentWrapperLeft {
  width: 75%;
  background: rgba(244, 244, 244, 0.4);
  border-radius: 18px;
  margin-left: 2%;
  margin-right: auto;
  padding: 12px 18px 10px;
  font-family: Jost-400;
  line-height: 26px;
  font-style: normal;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
}

.nameText {
  font-size: 18px;
  line-height: 23px;
  color: #585858;
  padding-bottom: 10px;
  webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.commentText {
  font-size: 18px;
  line-height: 30px;
  color: #585858;
  font-family: 'Jost-300' !important;
  margin-bottom: 10px;
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  height: fit-content;
  height: fit-content;
  -webkit-line-clamp: 5;
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  white-space: pre-line;
  word-wrap: normal;
  word-break: break-all; /* Added this for stopping overflow */
  -webkit-box-orient: vertical;
}

.divAnimation {
  -webkit-transition: 1s ease;
  -o-transition: 1s ease;
  transition: 1s ease;
}

.seeAllText {
  text-decoration: underline;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 30px;
  cursor: pointer;
  color: #585858;
  font-family: 'Jost-300' !important;
  white-space: normal;
  word-wrap: normal;
}

.editTextArea {
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  /* height: fit-content; */
  /* max-height: 150px; */
  height: 100%;
  font-size: 18px;
  line-height: 30px;
  color: #585858;
  font-family: 'Jost-300';
  background-color: transparent;
  /* margin-bottom: 10px; */
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  overflow-y: scroll;
  transition: 0.7s;
  display: -webkit-box;
  /* max-width: 100%; */
  white-space: pre-line;
  word-wrap: normal;
  -webkit-box-orient: vertical;
}

.a {
  font-size: 18px;
  line-height: 30px;
  color: #585858;
  font-family: 'Jost-300' !important;
  margin-bottom: 10px;
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  text-overflow: ellipsis;
  -webkit-line-clamp: 100;
  overflow: hidden;
  transition: 0.7s;
  display: -webkit-box;
  max-width: 100%;
  white-space: pre-line;
  word-wrap: normal;
  word-break: break-all;
  -webkit-box-orient: vertical;
}
.dateText {
  font-size: 14px;
  line-height: 20px;
  color: rgba(88, 88, 88, 0.4);

  webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.commentGeneral {
  width: 46px;
  height: 46px;
  text-align: center;
  display: flex;
}

.noComments {
  font-family: 'Jost-300';
  font-style: normal;
  font-size: 18px;
  line-height: 29px;
  color: rgba(88, 88, 88, 0.6);
  margin-top: 80px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.nameAreaWrapper {
  display: grid;
  grid-template-columns: auto min-content;
}
.buttonsWrapper {
  display: grid;
  margin-right: 0;
  margin-left: auto;
  height: 32px;
  grid-template-columns: 32px 32px;
}

.editIconButton {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  margin-left: 4px;
  visibility: hidden;
  justify-content: center;
  display: flex;
  align-self: center;
}

.editWrapper {
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.deleteWrapper{
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.editWrapper[mydisabled="disabled"],.deleteWrapper[mydisabled="disabled"]{
  opacity: 0.4;
}
.editWrapper[mydisabled="enabled"]:hover {
  background-color: rgba(88, 88, 88, 0.1);
  cursor: pointer;
  border-radius: 50%;
}
.editWrapper[mydisabled="enabled"]:active {
  background-color: rgba(88, 88, 88, 0.2);
  border-radius: 50%;
  cursor: pointer;
}

.deleteWrapper[mydisabled="enabled"]:hover {
  background-color: rgba(88, 88, 88, 0.1);
  border-radius: 50%;
  cursor: pointer;
}
.deleteWrapper[mydisabled="enabled"]:active {
  background-color: rgba(88, 88, 88, 0.2);
  border-radius: 50%;
  cursor: pointer;
}
.deleteIconButton {
  display: flex;
  align-self: center;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-left: 4px;
  visibility: hidden;
  display: flex;
  align-self: center;
  justify-content: center;
}

.commentWrapperRight:hover .editIconButton {
  visibility: visible;
}
.commentWrapperRight:hover .deleteIconButton {
  visibility: visible;
}

.editingComment {
  background-color: transparent;
  border: 1px solid #585858;
  border-radius: 18px;
  /* height: 156px; */
  /* width: calc((100% - 54px) * 0.9); */
  width: 98%;
  padding-top: 14px;
  padding-left: 18px;
  padding-bottom: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  resize: none;
  display: grid;
  grid-template-rows: auto min-content;
  margin-right: 2%;
  margin-left: auto;
  transition: 0.5s;
  box-shadow: 1px 1px 24px rgba(116, 88, 211, 0.1);
  grid-row-gap: calc((100% - 36px) * 0.09);
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 0;
}

.cancel {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(88, 88, 88, 1);
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 12px;
  width: 72px;
  cursor: pointer;
}
.cancel:hover{
  background: #F4F4F4;
  border-radius: 10px;
}
.cancel:active{
  background: #F4F4F4;
  color: rgba(88, 88, 88, 0.6);
}

.send {
  background: rgba(116, 88, 211, 1);
  border-radius: 12px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  height: 36px;
  width: 72px;
  border: none;
}

.send:hover{
  background: rgba(116, 88, 211, 0.9);
}
.send:active{
  background: rgba(116, 88, 211, 0.9);
  color: rgba(255, 255, 255, 0.6);
}
.send:disabled{
  background: rgba(88, 88, 88, 0.3);
  color: rgba(255, 255, 255, 0.6);
}

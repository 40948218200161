.CardWrapper {
    /* width: 376px; */
    width: 90%;
    max-width: 460px;
    min-width: 335px;
    /* min-width: 376px; */
    height: 158px;
    margin: 10px 5px 10px 5px;
    border-radius: 18px;
    border: none;
    background-color: #ffffff;
    cursor: pointer;
    /* display: grid;
      grid-template-columns: 17fr 3fr; */
    flex-direction: row;
  }

  .CardBodyWrapper {
    height: 100%;
    border-radius: 18px;
    /* border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
    /* border-right: 0px; */
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 1fr 18fr;
  }

  .CardBodyWrapper:hover {
      background-color: rgba(116, 88, 211, 0.2);
      outline: 2px solid white;
  }

  .selected {
    background-color: rgba(116, 88, 211, 0.8);
  }

  .selected:hover {
    background-color: rgba(116, 88, 211, 0.8);
    outline: none;
  }

  .CardBody {
    grid-column-start: 2;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Jost-400;
    font-size: 20px;
    width: 100%;
    display: flex;
    flex: 1 1;
    padding-left: 10px;
    border: 1px solid white;
    border-radius: 18px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    /* justify-items: ; */
  }

  .CardBodyName {
    margin-top: 12px;
    font-family: 'Jost-400';
    font-size: 20px;
    line-height: 28.9px;
    color: #585858;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .CardBodyDate {
    margin-bottom: 13px;
    font-family: 'Jost-300';
    font-size: 12px;
    line-height: 17px;
    color: #585858;
  }

  .CardBodyScore {
    display: grid;
    grid-template-columns: 50px auto;
  }

  .CardBodyScoreTextArea {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    /* justify-content: center; */
  }

  .CardBodyScoreText {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-family: 'Jost-400';
    color: #585858;
    font-size: 15px;
    line-height: 29px;
  }

  .CardBodyScoreAverage {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-family: 'Jost-400';
    color: #585858;
    font-size: 16px;
    line-height: 23px;
  }

  .CardBodyDate {
    font-family: 'Jost-300';
    color: #585858;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
  }



  .shield {
    box-shadow: none !important;
    width: 27px;
    height: 27px;
  }

  .shield:hover {
    border-radius: 40px !important;
    background: rgba(88, 88, 88, 0.1) !important;
  }

  .isSuspectOverlay {
    margin-right: 16px;
    display: flex;
    /* width: 24px; */
    /* align-self: center; */
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start; */
    /* height: 24px; */
  }

  .ibeOverlay{
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 24px;
    width:24px;
    justify-content: center;
  }

  .ibeOverlay:hover{
    border-radius: 40px !important;
    background: rgba(88, 88, 88, 0.1) !important;
  }

  .ibe {
    box-shadow: none !important;
    width: 27px;
    height: 27px;
  }

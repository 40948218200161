.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Jost-400';
  font-size: 1.6rem;
  padding-left: 1.6rem;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  color: #393939;
}
.inputContainer::before {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Jost-400';
  font-size: 1.6rem;
  padding-left: 1.6rem;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  color: #393939;
}

.inputContainer:hover {
  border: 1px solid rgba(116, 88, 211, 0.2);
}

.inputContainer:hover::before {
  border: 2px solid rgba(116, 88, 211, 0.2);
}

.inputContainer label {
  position: absolute;
  top: 0.5rem;
  font-family: 'Jost-400';
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.inputContainer:hover label {
  color: rgba(116, 88, 211, 0.6);
}

.inputContainer:focus-within label {
  color: #7458d3;
}

.inputContainer input:-webkit-autofill ~ label {
  color: rgba(88, 88, 88, 0.6);
}

.inputContainer input:-webkit-autofill:hover ~ label {
  color: rgba(116, 88, 211, 0.6);
}

.inputContainer input {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 0.4rem;
  background: #ffffff;
  border: none;
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: #393939;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.inputContainer input::placeholder {
  color: rgba(57, 57, 57, 0.2);
  opacity: 1; /* Firefox */
}

.inputContainer:has(input:focus) {
  background: #ffffff;
  border: 1px solid #7458d3;
}

.inputContainer:has(input:focus)::before {
  background: #ffffff;
  border: 2px solid #7458d3;
}

.inputContainer:has(.filledInput) {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
}
.inputContainer:has(.filledInput)::before {
  background: #ffffff;
  border: 2px solid rgba(88, 88, 88, 0.2);
}

.inputContainer:has(input:disabled) {
  background: rgba(244, 244, 244, 0.8);
  border: 1px solid rgba(88, 88, 88, 0.2);
  color: rgba(88, 88, 88, 0.4);
}
.inputContainer:has(input:disabled)::before {
  background: rgba(244, 244, 244, 0.8);
  border: 2px solid rgba(88, 88, 88, 0.2);
  color: rgba(88, 88, 88, 0.4);
}

.inputContainer .filledInput:disabled {
  background: rgba(244, 244, 244, 0.8);
  color: rgba(88, 88, 88, 0.4);
}

.inputContainer:has(.filledInput:hover) {
  border: 1px solid rgba(116, 88, 211, 0.2);
}
.inputContainer:has(.filledInput:hover)::before {
  border: 2px solid rgba(116, 88, 211, 0.2);
}

input:-autofill {
  font-size: 1.6rem;
}

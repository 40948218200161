.wrapper {
  /* padding: 1.4rem 0.8rem 1.4rem 2rem; */
  background: #f4f4f4;
  margin-bottom: 1.6rem;
  margin-left: -1.1rem;
  margin-right: -1.6rem;
  margin-top: -0.4rem;
}

.container {
  background: #ffffff;
  padding: 16px 16px 16px 20px;
  transition: height 0.3s linear;
}

.header {
  font-family: 'Jost-500';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
  display: flex;
  align-items: center;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.headerTextWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.hideShowText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  color: #7458d3;
  cursor: default;
}

/* Request Candidate List Component */
.candWrapper {
  border: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 12px;
  /* padding: 1.2rem 1.8rem; */
  padding: 1.2rem 1.8rem 1.2rem 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}

.buttonWrapper {
  display: none;
}

.candTextWrapperNoHover {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.candText {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #393939;
}

.candDate {
  /* border-right: 2px solid rgba(57, 57, 57, 0.2); */
  /* padding-right: 16px; */
  font-family: 'Jost-400';
  font-size: 1.6rem;
  height: 100%;
  color: rgba(57, 57, 57, 0.8);
  display: flex;
  align-items: center;
}
.candScore {
  font-family: 'Jost-400';
  font-size: 1.5rem;
  line-height: 22px;
  color: #393939;
}

.circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  min-height: 10px;
  max-height: 10px;
  min-width: 10px;
  max-width: 10px;
}

.redCircle {
  background: #ca1515;
}
.yellowCircle {
  background: #ffb800;
}

.greenCircle {
  background: #4ac389;
}
.scoreWrapper {
  /* gap: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 40px;
}

.indAverageText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(88, 88, 88, 0.8);
  white-space: nowrap;
}

.rightPartHovered {
  display: none;
}

.rightPartWrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 100%;
  align-items: center;
}

.arrowIcon {
  transition: all 0.3s ease-in-out;
  margin-right: 0;
  margin-left: auto;
}

[ccShown='true'].arrowIcon {
  transform: rotateZ(180deg);
}

.lockPart {
  /* padding-right: 9px; */
  /* border-right: 1px solid rgba(57, 57, 57, 0.2); */
  height: 16px;
  justify-content: center;
  display: flex;
  /* margin-right: 8px; */
}

.border {
  width: 24px;
  height: 0;
  border: 1px solid rgba(88, 88, 88, 0.2);
  transform: rotate(90deg);
}

.tagWrapper {
  background: rgba(116, 88, 211, 0.15);
  border-radius: 4px;
  height: 24px;
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  color: #7458d3;
  font-family: 'Jost-500';
  font-size: 11px;
}
.noCandText {
  font-family: Jost-400;
  font-size: 14px;
  color: #393939;
  text-align: center;
  margin: 16px 0px;
}

@media screen and (hover: hover) and (min-width: 576px) {
  .tabContainer:hover {
    cursor: pointer;
  }
  .candWrapper:hover .rightPartHovered {
    display: flex;
    flex-direction: row;
    /* border-right: 2px solid rgba(57, 57, 57, 0.2);
    padding-right: 16px; */

    height: 100%;
  }
  .candWrapper:hover .candDate {
    display: none;
  }
  .hideShowText:hover {
    cursor: pointer;
  }
  .candWrapper:hover .buttonWrapper {
    display: flex;
  }
  .candWrapper:hover {
    cursor: pointer;
  }
  .candText:hover {
    cursor: pointer;
  }
}

/* Tab Styling */
.tabWrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  height: 37px;
}

.tabContainer {
  background: rgba(244, 244, 244, 0.8);
  border-radius: 10px;
  height: 100%;
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #393939;
  padding: 0px 18px;
  cursor: default;
}

.selectedTab {
  background: #7458d3;
  color: #ffffff;
}

@media screen and (max-width: 576px) {
  .candWrapper {
    padding: 10px 12px 10px 14px;
    height: 40px;
  }

  .candText {
    font-size: 14px;
    line-height: 20px;
  }
  .container {
    padding: 14px 7px 14px 14px;
  }

  .wrapper {
    margin-left: -2rem;
    margin-right: -0.8rem;
  }

  .header {
    font-size: 14px;
    line-height: 20px;
  }

  .tabWrapper {
    gap: 12px;
  }

  .tabContainer {
    font-size: 14px;
    line-height: 20px;
    justify-content: center;
    padding: 0px;
    width: 107px;
  }

  .tagWrapper {
    height: 20px;
  }

  .scoreWrapper {
    /* gap: 10px;
    min-width: 37px; */
  }

  .candScore {
    font-size: 14px;
    line-height: 20px;
  }

  .hideShowText {
    margin-right: 8px;
  }
}

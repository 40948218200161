.wrapper {
  height: 52px;
  /* align-items: center; */
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: center;
}

.tabWrapper {
  height: 36px;
  /* width: 95%; */
  width: 100%;
  /* margin-left: 35px; */
  margin-right: 24px;
  display: flex;
  flex-direction: row;
}

.activeTab {
  background-color: #7458d3;
  border-radius: 12px;
  /* height: 115%; */
  height: 40px;
  position: absolute;
  width: 70%;
  z-index: 4;
  transition: all 0.4s linear;
}

.animate {
  z-index: 5;
  left: 70px;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-content: center;
  color: white;
  transition: left 0.4s linear, opacity 0.3s linear;
}

.animateHm {
  z-index: 5;
  left: 38px;
  display: flex;
  position: absolute;
  flex-direction: row;
  align-content: center;
  color: white;
  transition: left 0.4s linear, opacity 0.3s linear;
}

.rightAnimate {
  left: calc(100% - 200px);
  color: #393939;
  /* transition: all 0.4s cubic-bezier(0.75, -1.12, 0.61, -1.06); */
  transition: left 0.4s linear, opacity 0s linear;
}

.rightAnimateHm {
  left: calc(100% - 230px);
  color: #393939;
  /* transition: all 0.4s cubic-bezier(0.75, -1.12, 0.61, -1.06); */
  transition: left 0.4s linear, opacity 0s linear;
}

.selectedTab {
  width: 70%;
  background: #7458d3;
  border-radius: 12px;
  height: 115%;
  z-index: 5;
  display: flex;
  align-items: center;
}

.displayNone {
  display: none;
}

.whiteTab {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 4;
  flex-direction: row;
  display: flex;
  align-items: center;
  /* width: calc(100% - 12px); */
  margin-right: 0;
  margin-left: auto;
}

.unSelectedWrapper {
  width: 30%;
  height: 100%;
  background-color: transparent;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.unSelectedText {
  font-family: Jost-400;
  color: #393939;
  font-size: 16px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  white-space: nowrap;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.selectedText {
  font-family: Jost-400;
  font-size: 18px;
  z-index: 5;
  line-height: 26px;
  white-space: nowrap;
  text-align: center;
  /* margin-left: 70px; */
  /* color: #ffffff; */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.selectedBaseText {
  font-family: Jost-300;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  /* color: #ffffff; */
  z-index: 5;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: left 0.4s linear, opacity 0.3s linear;
}

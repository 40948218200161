.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
  padding: 0px !important;
}

.modal-content {
  max-width: 100vw;
  height: 350px;
  width: 572px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 24px;
  justify-content: flex-end;
}
.modalContent {
  border: none;
  padding: 27px 40px 0px 40px !important ;
  max-width: 572px;
}
.icon {
  width: 36px;
  margin-bottom: 25px;
  margin-top: 12px;
  height: 30px;
}

.modalContent {
  width: 914px !important;
  height: 486px !important;
  padding: 28px !important;
  border: none !important;
  border-radius: 16px !important;
}

.innerContainer {
  display: flex !important;
  flex-direction: row !important;
  height: 366px;
}

.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
  padding: 0px !important;
}

.icon {
  width: 23px;
  margin-right: 10px;
  height: 16px;
}

.questionDifficulty {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 8px;
}

.questionDifficulty::before {
  content: "-";
  margin-right: 8px;
}

.assessmentName {
  font-family: 'Jost-400';
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(88, 88, 88, 0.6);
  text-transform: uppercase;
}

.buttonContainer {
  display: flex;
  margin-top: 28px;
  flex-direction: row;
  justify-content: flex-end;
}

.questionInfo {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.questions {
  font-family: 'Jost-400';
  font-style: normal;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(88, 88, 88, 0.6);
}

.tick {
  margin-right: 12px;
  cursor: pointer;
}

.questionNumber {
  font-family: 'Jost-500';
  font-style: normal;
  margin-left: 3px;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.leftPane {
  border-right: 1px solid rgba(88, 88, 88, 0.2);
  height: 100%;
  width: 395px;
}

.NumberContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.newTabIcon {
  cursor: pointer;
  width: 12px;
  height: 12px;
}


.imgWrapper {
  display: flex;
  margin-right: 2px;
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.imgWrapper:hover {
  background: rgba(88, 88, 88, 0.1);
}

.questionsContainer {
  overflow-y: scroll;
  overflow-x: clip;
  height: 337px;
}

.createButtonContainer {
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  display: flex;
  border: none;
  padding: 9px 24px 9px 24px;
}

.purpleCreate {
  background: #7458d3;
  color: #ffffff;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 44px;
  margin-left: 15px;
}

.whiteCreate {
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid #7458d3;
  color: #7a60d5;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

@media screen and (hover: hover) {
  .questionName:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}


.questionItem {
  width: 412px;
  height: 48px;
  font-family: 'Jost-400';
  border-bottom: 1px solid #f4f4f4;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.skillsWrapper {
  overflow: scroll !important;
  height: 44%;
}

.rightPane {
  margin-left: 24px;
  height: 366px;
  width: 438px;
}

.Skills {
  font-family: 'Jost-400';
  font-style: normal;
  margin-bottom: 14px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(88, 88, 88, 0.6);
  text-transform: uppercase;
}

.skillItem {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.skillItemContainer {
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
}

.assessmentTitle {
  font-family: 'Jost-400';
  font-style: normal;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #393939;
}

.detailsColumn {
  display: flex;
  flex-direction: row;
}

.index {
  margin-right: 4px;
}

.DetailsRow {
  height: 42px;
  border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 24px 24px 0px;
}

.DetailBox {
  width: 100%;
  height: 22px;
  font-size: 14px;
  line-height: 20px;
  color: #393939;
  display: flex;
  align-items: center;
}

.JustifyCenter {
  justify-content: center;
}

.RightBorder {
  border-right: 1px solid rgba(57, 57, 57, 0.15);
}

.icons {
  margin-right: 10px;
}

.assessmentDetails {
  font-family: 'Jost-400';
  margin-bottom: 12px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(88, 88, 88, 0.6);
}



@media screen and (min-width: 576px) and (max-width: 914px) {
  .WebSmallScrollableContent {
    overflow: auto;
  }
  
  .WebSmallUpperContent {
    width: 100%;
  }
  
  .WebSmallLowerContent {
    margin-top: 16px;
    width: 100%;
    overflow-y: hidden;
  }
  
  .WebSmallQuestionsContainer {
    overflow-x: clip;
    padding-right: 10px;
  }

  .assessmentTitle {
    margin-bottom: 16px;
  }

  .DetailsRow {
    margin-right: 0px;
  }

  .questionItem {
    width: 100%;
  }

  .skillsWrapper {
    overflow: hidden !important;
  }
}

@media screen and (max-width: 576px) {
  .MobileButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    box-shadow: 0px -1px 12px rgba(134, 134, 134, 0.15);
  }

  .MobileContentWrapper {
    padding-right: 20px;
  }

  .BackToWrapper {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: rgba(116, 88, 211, 0.6);
    gap: 7px;
    margin-bottom: 18px;
  }

  .assessmentTitle {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 18px;
  }

  .DetailsRow {
    margin-right: 0px;
    margin-bottom: 18px;
  }

  .MobileUpperContent {
    width: 100%;
    border-bottom: 1px solid rgba(88, 88, 88, 0.1);
  }

  .skillsWrapper {
    overflow: hidden !important;
  }

  .MobileLowerContent {
    margin-top: 16px;
    width: 100%;
    overflow-y: hidden;
  }

  .MobileQuestionsContainer {
    overflow: hidden;
  }

  .questionItem {
    width: 100%;
  }

  .assessmentDetails {
    margin-bottom: 9px;
  }

  .Skills {
    margin-bottom: 12px;
  }

  .questions {
    margin-bottom: 6px;
  }
}
.MainWrapper {
  display: flex;
  flex-direction: column;
  width: 411px;
  min-width: 411px;
  height: 300px;
  padding: 20px 20px 24px 20px;
  font-family: Jost-400;
  gap: 16px;

  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 12px;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.1);
  cursor: pointer;
}

.Title {
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #393939;
}

.kebabMenu:hover {
  border-radius: 20px;
  outline: 1px solid rgba(57, 57, 57, 0.1);
  background-color: rgba(57, 57, 57, 0.1);
}

.kebabMenuDropdown {
  position: fixed;
  opacity: 1;
  z-index: 1;
  background-color: #fff;
  width: 122px;
  height: 86px;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.2);
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kebabMenuElement {
  font-size: 16px;
  line-height: 23px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.disabledMenuElement {
  cursor: inherit;
  opacity: 0.4;
}

.SkillsRow {
  display: flex;
  flex-direction: row;
  gap: 13px;
  height: 32px;
}

.SkillBox {
  display: flex;
  align-items: flex-start;
  padding: 6px 12px;

  background: #f4f4f4;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #393939;
}

.SkillBox span {
  white-space: nowrap;
}

.DetailsRow {
  height: 42px;
  border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DetailBox {
  width: 100%;
  height: 22px;
  font-size: 14px;
  line-height: 20px;
  color: #393939;
  display: flex;
  align-items: center;
}

.JustifyCenter {
  justify-content: center;
}

.RightBorder {
  border-right: 1px solid rgba(57, 57, 57, 0.15);
}

.icons {
  margin-right: 10px;
}

.PreparedByWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.PreparedByHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.PreparedByText {
  font-size: 12px;
  line-height: 17px;
  /* display: flex;
  align-items: center;
  letter-spacing: 0.005em; */
  white-space: nowrap;

  color: rgba(88, 88, 88, 0.5);
}

.PreparedByLine {
  width: 100%;
  height: 0px;
  border: 1px solid #f4f4f4;
}

.PreparedByContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 41px 14px 20px;
  gap: 10px;
  border: 1px solid #f4f4f4;
  border-radius: 12px;
}

.PreparedByContentHeader {
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #393939;
}

.PreparedByContentSubtext {
  font-family: Jost-300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #393939;
}

/* Mobile Design */
@media screen and (max-width: 576px) {
  .MainWrapper {
    min-width: 335px !important;
    max-width: 335px !important;
    height: 231px;
    gap: 12px;
    padding: 11px 16px 16px 16px;
  }
  .PreparedByContent {
    padding: 11px 16px;
    gap: 0px;
    grid-gap: 0px;
  }
  .Title {
    font-size: 15px;
    line-height: 22px;
  }

  .SkillsRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 26px;
  }

  .SkillBox {
    padding: 5px 9px;
    font-size: 11px;
    line-height: 16px;
  }

  .DetailBox {
    font-size: 11px;
    line-height: 16px;
  }

  .DetailsRow {
    min-height: 32px;
    width: 100%;
  }

  .PreparedByContentSubtext {
    font-size: 11px;
  }
  .PreparedByContentHeader {
    font-size: 13px;
  }
  .PreparedByWrapper {
    gap: 6px;
    grid-gap: 6px;
  }
}
/* Mobile Design */

.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.outerCard {
  max-width: 1000px;
  min-width: 576px;
  background: #ffffff;
  display: flex;
  padding: 6.4rem 12.8rem;
  justify-content: center;
  width: 47%;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}
.innerCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo {
  margin-bottom: 3rem;
}

.cardTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
  margin-bottom: 3.2rem;
}
.text {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  margin-bottom: 1.6rem;
}
.errorWrapper {
  position: absolute;
  display: inline-block;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 1rem;
  line-height: 1.7rem;
  color: #de4747;
  /* margin-top: 4.8rem; */
  margin-left: 0.5rem;
}
.error {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #de4747;
}
/* old version
@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }

  .outerCard {
    width: 100%;
    flex-direction: column;
    max-width: none;
    min-width: 0;
    box-shadow: none;
    justify-content: flex-start;
  }
  .innerCard {
    width: 100%;
    height: 100%;
  }
}
*/
@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }
  .outerCard {
    width: 60%;
    min-width: 375px;
    padding: 3rem;
    box-shadow: none;
  }
  .cardTitle {
    font-size: 20px;
    line-height: 29px;
  }
  .text {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 24px;
  }
}

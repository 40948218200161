.FreeTextQuestionBodyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}

.FreeTextOverflowWrapper {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  color: #393939;
}

.FreeTextQuestionDetailsWrapper {
  align-self: flex-start;
  max-width: 700px;
  min-width: 480px;
  margin: 1.4rem 2rem;
  width: 100%;
}

.FreeTextQuestionDetailsWrapperMobile {
  align-self: flex-start;
  margin: 1rem 2rem;
  width: 100%;
}

.FreeTextQuestionDescription {
  font-family: Jost-500;
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.FreeTextQuestionDescription img{
  max-width: 100%;
  height: unset !important;
}

.FreeTextAnswerFieldWrapper {
  margin: 2.4rem 0rem 0.8rem 0rem;
  width: 100%;
}

.TextArea {
  width: 100%;
  height: fit-content;
  min-height: 290px;
  background: rgba(250, 250, 250, 0.5);
  border: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 10px;
  resize: none;

  font-family: Jost-400;
  font-size: 1.8rem;
  line-height: 2.6rem;

  display: flex;
  align-items: center;
  padding: 1.4rem 1.8rem;
}

.FreeTextInfo {
  margin: 0rem 0rem 0.8rem 0rem;
  font-family: Jost-300;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;

  color: rgba(116, 88, 211, 1);
}

.QuestionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fafafa;
    padding: 1rem 3.2rem;
    align-items: center;
  
    font-family: Jost-400;
    font-size: 1.8rem;
    line-height: 2.6rem;
    color: #393939;
  }

  .UpperLogo {
    max-width: 13.7rem;
    max-height: 3.2rem;
  }
.successContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  /* padding-left: 16px !important; */
}
.modal-content {
  max-width: 100vw;
  min-width: 572px !important;
  height: 230px;
  /* padding: 0 !important; */
  padding: 24px 24px 28px 32px !important;

}

.successFootNote {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: rgba(88, 88, 88, 0.6);
  margin-top: 27px;
}

.successTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  /* margin-top: 16px; */
  text-align: left;
  color: #585858;
}

.descText {
  font-family: 'Jost-300';
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: #585858;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 42px;
}

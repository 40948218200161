.CloseButtonContainer {
  float: right;
  margin-left: auto;
  border: none;
  background: transparent;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.CloseButtonImage {
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (hover: hover) {
  .CloseButtonImage:hover {
    background-color: rgba(88, 88, 88, 0.1);
  }
}

.CloseButtonImage:active {
  background-color: rgba(88, 88, 88, 0.2);
}

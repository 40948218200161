.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 2.8rem;
  background: #ffffff;
}

.text {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #32cbe0;
  margin-left: 16px;
}

.funnelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.funnelName {
  text-transform: uppercase;
  font-family: 'Jost-500';
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.4);
  margin-right: 6px;
  white-space: nowrap;
}
.funnelIcon {
  margin-top: -4px;
  cursor: pointer;
}

/* Funnel texts */

[myId='headerFunnel-Scored'] {
  color: #32cbe0;
}

[myId='headerFunnel-Not Scored'] {
  color: #32cbe0;
}

[myId='headerFunnel-All Candidates'] {
  color: #585858;
}

[myId='headerFunnel-Hired'] {
  color: #4ac389;
}

[myId='headerFunnel-Rejected'] {
  color: #de4747;
}
[myCategory='30'] {
  color: #7458d3;
}

/* progress bar modal related CSS */
.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem 2.4rem 2rem;
  height: 100%;
  width: 100%;
}
.upperRow {
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-between;
}
.scoreWrapper {
  display: flex;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #585858;
  padding-top: 2rem;
}

.modalBorder {
  border: 1px solid #f4f4f4;
  width: 100%;
}
.lowerRow {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

/* end of progress bar modal related CSS */

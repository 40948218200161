.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
  padding: 0px !important;
}

.modal-content {
  max-width: 100vw;
  width: 572px;
  height: 234px;
}
.modalContent {
  border: none;
  padding: 24px 24px 24px 32px !important ;
  max-width: 572px;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
}
.deleteIcon {
  fill: #de4747 !important;
  color: #de4747;
  width: 16px;
  height: 16px;
}
.delete:enabled:hover {
  text-decoration: underline;
}
.delete {
  /* margin-right: 292px; */
  background-color: white;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  /* NRed/NRed100 */

  color: #de4747;
}
.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  /* margin-bottom: 24px; */
  line-height: 35px;
  color: #585858;
}

.subtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #585858;
}

.wrapper {
  background: #f4f4f4;
  flex-grow: 8;
  height: 100%;
  overflow-y: auto;
  margin-top: -3px;
}

.questionWrapper {
  min-height: 110px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.1);
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}
.languageLabel {
  margin-top: 3px;
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.questionLanguage {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 3px;
}
.worldIcon {
  width: 15px;
  height: 15px;
}

.questionDifficulty {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 8px;
  margin-top: 1px;
}

.questionDifficulty::before {
  content: '-';
  margin-right: 8px;
}

.qTitleRow {
  display: flex;
  margin-bottom: 10px;
}

.header {
  margin-left: 16px;
  font-family: 'Jost-500';
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  /* align-items: center; */
  align-self: center;
  color: #585858;
}

.addButton {
  background: #7458d3;
  border-radius: 8px;
  height: 30px;
  width: 70px;
  color: #ffffff;
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: none;
  text-align: center;
  justify-content: center;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
}

.addButton:hover {
  background: rgba(116, 88, 211, 0.9);
}

.addButton:active {
  background: rgba(116, 88, 211, 0.9);
  color: rgba(255, 255, 255, 0.6);
}

.count {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #393939;
  margin-right: 20px;
  min-width: 10px;
}

.qLine {
  display: flex;
  height: 50px;
  align-items: center;
  /* border-left: 1px solid rgba(50, 203, 224, 0.8); */
}

.skills {
  display: flex;
  flex-direction: row;
  flex-grow: 9;
  flex-basis: 90%;
  height: 100%;
}
.skillList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.skillElement {
  padding: 4px;
  display: flex;
  align-items: center;
}

.skillElement::before {
  content: '';
  display: inline-block;
  width: 2px;
  margin-right: 8px;
  height: 2px;
  border-radius: 50%;
  background: #585858;
}
.more {
  cursor: pointer;
}

.skillElement span {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #585858;
}

.noQText {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #393939;
  align-items: center;
  display: flex;
  margin-right: 8px;
}

.noQWrapper {
  display: flex;
  flex-direction: row;
}

.noQAddText {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #7458d3;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.rightColumn {
  display: flex;
  flex-direction: row;
  /* flex-basis: 10%; */
  min-width: 100px;
}

.buttonsWrapper {
  margin-right: 8px;
  margin-left: 8px;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.lineWrapper {
  width: 52px;
}

.skillText {
  font-family: 'Jost-300';
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #393939;
  align-items: center;
  display: flex;
}

.verticalLine {
  width: 70%;
  margin-right: 0;
  margin-left: auto;
}

.codingQ {
  border-left: 1px solid rgba(50, 203, 224, 0.8);
}

.multiselectQ {
  border-left: 1px solid rgba(255, 214, 0, 0.8) !important;
}

.freetextQ {
  border-left: 1px solid rgba(255, 126, 98, 0.8) !important;
}

.horizontalLine {
  width: 70%;
}

.codingQH {
  border-bottom: 1px solid rgba(50, 203, 224, 0.8);
}

.multiselectQH {
  border-bottom: 1px solid rgba(255, 214, 0, 0.8) !important;
}

.freetextQH {
  border-bottom: 1px solid rgba(255, 126, 98, 0.8);
}

.countSingle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #393939;
  margin-right: 20px;
  min-width: 10px;
}

.minusWrapper .loading {
  width: 100%;
  height: 100%;
}

.minusWrapper:hover {
  background: rgba(116, 88, 211, 0.1);
  border-radius: 6px;
}

.minusWrapper:active {
  background: rgba(116, 88, 211, 0.2);
  border-radius: 6px;
}

.plusWrapper:hover {
  background: rgba(116, 88, 211, 0.1);
  border-radius: 6px;
}

.plusWrapper:active {
  background: rgba(116, 88, 211, 0.2);
  border-radius: 6px;
}

.pageContainer {
  width: 100%;
  /* height: 100dvh; */
  overflow: hidden;
}

.layoutContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: hidden;
}

.barContainer {
  display: flex;
  flex-basis: 320px;
  min-width: 160px;
  flex-shrink: 1;
  flex-grow: 0;
  position: relative;
}
.tabContainer {
  width: 100%;
  overflow-y: auto;
  background-color: #fafafa;
  padding: 24px;
}

@media screen and (max-width: 576px) {
  .barContainer {
    flex-basis: unset;
    padding: 16px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .layoutContainer {
    flex-direction: column;
  }

  .tabContainer {
    padding: 0px;
    /* CHANGE: in case of header height change */
    /* Header + 20px margin covered here */
    padding-bottom: 70px;
  }

  .pageContainer {
    background-color: #fafafa;
    overflow: auto;
  }
}

.contentBody {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.btn {
  width: 20%;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.questionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 80%; */
}

.Title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.5rem;
  color: #393939;
  margin-bottom: 3.2rem;
}

.answerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.ButtonWrapper {
  display: flex;
}

.answerButton {
  background: #ffffff;
  width: max-content;
  min-width: 30%;
  height: 6.4rem;
  padding: 2.4rem 3.2rem 2.4rem 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.71px solid #7458d3;
  border-radius: 1.6rem;
  font-family: 'Jost-400';
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  color: #7458d3;
}


.answerButton:active {
  cursor: pointer;
  background: #7458d3;
  color: #ffffff;
}

.arrow {
  width: 2.5rem;
  height: 2.6rem;
}

.back {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #585858;
}

.backText {
  margin-left: 0.5rem;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #585858;
  display: flex;
  align-items: center;
}

.button {
  width: 12rem;
  height: 4.2rem;
  background: #f1eefb;
  border-radius: 10px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.6rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #7458d3;
}

@media screen and (hover: hover) {
  .button:hover {
    background: #7458d3;
    color: #ffffff;
    cursor: pointer;
  }

  .answerButton:hover {
    cursor: pointer;
    background: #7458d3;
    color: #ffffff;
  }
}

.button:active {
  cursor: pointer;
  background: #7458d3;
  color: #ffffff;
}

@media screen and (max-width: 576px) {
  .questionWrapper {
    height: 100%;
    margin-top: 4rem;
    justify-content: unset;
  }
  .Title {
    margin-bottom: 24px;
    font-size: 18px;
  }

  .backText {
    font-size: 16px;
    line-height: 23px;
  }
  .button {
    font-size: 16px;
    line-height: 23px;
  }
  .answerWrapper {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .answerButton {
    width: 100%;
    margin-bottom: 16px;
    padding: 0;
    height: 48px;
  }

  .ButtonWrapper {
    margin-top: 16px;
  }
}


@media screen and (max-width: 320px) {
  .Title {
    margin-bottom: 24px;
    font-size: 16px;
  }
}
.AddAssessmentShowQuestionMainWrapper h5 {
  font-size: 1.8rem;
}

.AddAssessmentShowQuestionMainWrapper h2 {
  font-size: 2.8rem;
}

.CustomNavbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.contentBody {
  background-color: #fff;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  overflow: auto;
  /* overflow: scroll; */
  height: calc(100vh - 80px); /* changed with header */
  /* height: 100vh; */
}


.question {
  display: flex;
  max-width: 1100px;
  min-width: 750px;
  justify-content: center;
  align-content: center;
}

.questionWrapper {
  padding-top: 25px;
  width: 100%;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  width: 100%;
  padding-left: 48px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: rgba(57, 57, 57, 0.5);
}

.TimeBody {
  background-color: #f4f4f4;
}

.btn-green img {
  filter: invert(76%) sepia(26%) saturate(810%) hue-rotate(122deg) brightness(85%) contrast(90%);
}

.btn {
  color: #676767;
}

.btn-green {
  background: rgba(74, 195, 181, 0.2);
  border: 1px solid rgba(74, 195, 181, 0.2);
  box-sizing: border-box;
  border-radius: 32px !important;
  padding: 12px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #4ac4b5;
  outline: none !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover span:first-child {
  color: #fff;
}

.btn:focus span:first-child {
  color: #fff;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3 !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}


.btn:active {
  border-color: #95afdb;
}


/* MultipleChoice.js */

.ContentBody {
  min-height: 700px;
  background-color: #f4f4f4;
  padding-bottom: 20px;
  border-radius: 24px 24px 0 0;
}

.ContentBody.no-radius {
  border-radius: 0;
}

.assessment-success-card {
  padding: 32px;
  background: #ffffff;
  border-radius: 8px !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem;
}


/* FreeText.js */

.form-control {
  align-content: stretch !important;
  background: rgba(244, 244, 244, 0.6) !important;
  border: 1px solid rgba(116, 88, 211, 0.6) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  min-height: 44px;
  padding: 12px;
  width: 100% !important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.bg-white {
  background-color: #fff !important;
}

textarea.form-control {
  height: auto;
}


/* Coding.js */
/* No additional classes */

/* addAssessmentShowQuestion.js */

.QWrapper {
  height: 100%;
  font-size: 1.6rem;
}

.OptionsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

.Option {
  /* height: 100% !important; */
  width: 48%;
  background: #F4F4F4;
  margin: 10px 5px;
  padding: 15px 20px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.StringOptionWrapper {
  width: 100%;
}

.HtmlOptionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  align-items: flex-start;
}

.HtmlOptionWrapper p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.HtmlOptionWrapper img {
  /* max-width: 100%;
  max-height: 100%; */
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center;
  justify-content: center; */
  max-height: 500px;
  max-width: 300px;
}


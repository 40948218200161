.containerMobile {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-top: 8px;
}

.containerMobile:hover {
  cursor: pointer;
}

.wrapperMobile {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
}
.container:hover {
  background: rgba(244, 244, 244, 0.6);
  border-radius: 10px;
  cursor: pointer;
}
.title {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  color: #393939;
}

.connectText {
  font-family: 'Jost-400';
  font-size: 1.4rem;
  text-align: right;
  color: #7458d3;
  margin-right: 8px;
  line-height: 22px;
}
.connectTextMobile {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  text-align: right;
  color: #7458d3;
  margin-right: 8px;
  line-height: 22px;
}
.wrapper {
  display: flex;
  flex-direction: row;
}

.logo {
  margin-right: 8px;
}

.openWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.tutorialWrapper {
  background: #ffffff;
  box-shadow: 0px 1px 24px rgba(98, 98, 98, 0.1);
  border-radius: 12px;
  width: 100%;
  height: 9.2rem;
  display: flex;
  flex-direction: column;
  padding: 18px 12px;
  margin-bottom: 10px;
}

.copyWrapper {
  background: #ffffff;
  box-shadow: 0px 1px 24px rgba(98, 98, 98, 0.1);
  border-radius: 12px;
  width: 100%;
  height: 7rem;
  padding: 12px 16px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  color: #32cbe0;
  text-decoration-line: underline;
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
}
.play:hover {
  filter: invert(39%) sepia(70%) saturate(2780%) hue-rotate(196deg) brightness(108%) contrast(100%);
}

.outerWrapper {
  display: flex;
  flex-direction: column;
}

.tutorialText {
  color: rgba(57, 57, 57, 1);
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
}
.switchContainer {
  width: 42px;
  height: 22.5px;
  margin-right: 12px;
  margin-left: 12px;
  position: relative;
  display: flex;
  background: rgba(88, 88, 88, 0.2);
  border-radius: 11.25px;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
}
.active {
  background: #7458d3;
}
.switchWrapper {
  display: flex;
  flex-direction: row;
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  align-items: center;
  margin-right: 0px;
  margin-left: auto;
}
.switchButton {
  width: 19.5px;
  height: 19.5px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s linear;
}
.htmlText {
  color: rgba(88, 88, 88, 0.3);
}
.textText {
  color: #7458d3;
  margin-right: 12px;
}
.arrowImg {
  background-image: url('../../AddAssessment/Images/arrowRight.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  width: 18px !important;
  height: 18px !important;
}

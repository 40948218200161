.barWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  gap: 20px;
}
.barItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
}
.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 35px;
  margin-top: 28px !important;
  color: #393939;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
}

.category {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 29px;
  color: rgba(57, 57, 57, 0.5);
}
.titleIcon {
  margin-top: 28px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 13.5px !important;
  height: 13.5px !important;
  align-items: center;
  display: flex;
}

.companyText {
  margin-top: 40px;
}

.barItemText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 20px;
  line-height: 29px;
  white-space: nowrap;
  color: #393939;
}

.icon {
  width: 17px;
  height: 17px;
  align-items: center;
  display: flex;
}

.wrapper {
  overflow: auto;
  align-content: flex-start; /*To have no default margin*/
}

.barItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.active {
  color: #7458d3;
}


/* mobile */
.mobileWrapper {
  position: relative;
  overflow-x: auto;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
}

.blurRight {
  display: flex;
  width: 50px;
  height: 50px;
  right: 0;
  position: absolute;
  z-index: 100;
  margin-left: auto;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.9) 40%,
    #fff 70%
  );
  margin-top: -16px;
  transition: opacity 0.4s linear;
  pointer-events: none;
}

.blurLeft {
  display: flex;
  width: 50px;
  height: 50px;
  left: 0;
  position: absolute;
  z-index: 100;
  margin-left: auto;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.9) 40%,
    #fff 70%
  );
  margin-top: -16px;
  transition: opacity 0.4s linear;
  pointer-events: none;
}
.mobileWrapper::-webkit-scrollbar {
  display: none;
}

.container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-bottom: 8px;
}

.mobileTabWrapper {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: rgba(57, 57, 57, 0.8);
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}
.active {
  color: #7458d3;
}

.mobileTabWrapper:hover {
  cursor: pointer;
}

.borderBottom {
  display: block;
  position: absolute;
  bottom: 0;
  transition-property: all;
  transition-duration: 400ms;
  height: 2px;
  background-color: #7458d3;
}
.disabled {
  color: rgba(57, 57, 57, 0.15);
  cursor: auto !important;
}

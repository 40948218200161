.wrapper {
  display: flex;
  height: 86px;
  margin-bottom: 17px;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 10px 20px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.more {
  cursor: pointer;
}

.skillList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.skillElement {
  padding: 4px;
  display: flex;
  align-items: center;
}

.skillElement::before {
  content: '';
  display: inline-block;
  width: 2px;
  margin-right: 8px;
  height: 2px;
  border-radius: 50%;
  background: #585858;
}

.skillElement span {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #585858;
}

.questionNo {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  color: rgba(88, 88, 88, 0.6);
  margin-right: 3px;
  width: 20px;
}
.languageLabel {
  margin-top: 1px;
  display: flex;
  align-items: center;
  margin-left: 14px;
}

.questionLanguage {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 3px;
}
.worldIcon {
  width: 15px;
  height: 15px;
}

.questionDifficulty {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 8px;
  margin-top: 1px;
}

.questionDifficulty::before {
  content: '-';
  margin-right: 8px;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: flex-end;
  margin-bottom: 15px;
  padding-top: 3px;
  /* margin-left: 23px; */
}

.durationWrapper {
  display: flex;
  flex-direction: row;
  padding: 2.5px 12px 2.5px 8px;
  gap: 6px;
  height: 22px;
  background: #f4f4f4;
  border: 0.766197px solid #f4f4f4;
  border-radius: 7px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
}

.skillWrapper {
  background: #ffffff;
  border: 0.766197px solid #585858;
  border-radius: 7px;
  padding: 2px 6px 2px 6px;
  font-family: 'Jost-400';
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.statusIcon {
  margin-bottom: 4px;
  margin-left: 11px;
}
.purchasedIcon {
  margin-bottom: 3px;
  margin-left: 11px;
}
.duration {
  margin-right: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.imgWrapper1 {
  display: flex;
  justify-content: center;
}

.imgWrapper2 {
  display: flex;
  justify-content: center;
}

.imgWrapper {
  display: flex;
  justify-content: center;
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 0;
  margin-left: auto;
  margin-top: -5px;
  padding-bottom: 10px;
}

.imgWrapper1:hover {
  background: rgba(88, 88, 88, 0.1);
  border-radius: 6px;
}

.imgWrapper2:hover {
  background: rgba(116, 88, 211, 0.1);
  border-radius: 6px;
}

.imgWrapper1:active {
  background: rgba(88, 88, 88, 0.2);
  border-radius: 6px;
}

.imgWrapper2:active {
  background: rgba(116, 88, 211, 0.2);
  border-radius: 6px;
}

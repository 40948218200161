.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
  padding: 0px !important;
}

.modal-content {
  max-width: 100vw;
  height: 252px !important;
  width: 572px !important;
  left: 0px;
  top: 0px;
  border-radius: 0px;
}
.modalContent {
  border: none;
  padding: 27px 40px 0px 40px !important ;
  max-width: 572px;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  margin-bottom: 36px;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #585858;
}
.row {
  margin-top: 42px;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 700px) {
  .title {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 23px;
  }
}

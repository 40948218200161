.container {
  padding: 20px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filterText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #585858;
}

.clearAll {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  text-decoration-line: underline;
  color: #7458d3;
  cursor: pointer;
}

.clearAll[mydisabled='true'] {
  text-decoration-line: none;
  color: rgba(88, 88, 88, 0.2);
  cursor: default;
  pointer-events: none;
}

.buttonWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-column-gap: 10px;
}


.p {
  animation: big 1s ease  ;
}

.C {
  animation: he1 2s linear infinite alternate ;
}
.O {
  animation: he2 2s  linear infinite alternate;
}
.E {
  animation: he3 2s linear infinite alternate;
}
.N {

  animation: he4 2s linear infinite alternate;
}
.S {

  animation: he5 2s linear infinite alternate;
}
.I {

  animation: he6 2s linear infinite alternate;
}
.O2{
  animation: he7 2s linear infinite alternate;
}

@keyframes big {
  to {
    transform: translateY(-40px) scale(0.5);

  }

}
@keyframes he1 {

  0% {
    padding-bottom: 0px;
  }

  /* Finish changes by here */
  14% {
    padding-bottom: 10px;
    opacity: 0.5;
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    padding-bottom: 10px;
  }
}
@keyframes he2 {
  0%,14% {
    padding-bottom: 0px;
  }

  /* Finish changes by here */
  28% {
    padding-bottom: 10px;
    opacity: 0.5;
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    padding-bottom: 10px;
  }

}
@keyframes he3 {
  0%,28% {
    padding-bottom: 0px;
  }

  /* Finish changes by here */
  42% {
    padding-bottom: 10px;
    opacity: 0.5;
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    padding-bottom: 10px;
  }

}
@keyframes he4 {
  0%,42% {
    padding-bottom: 0px;
  }

  /* Finish changes by here */
  56% {
    padding-bottom: 10px;
    opacity: 0.5;
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    padding-bottom: 10px;
  }

}
@keyframes he5 {
  0%,56% {
    padding-bottom: 0px;
  }

  /* Finish changes by here */
  70% {
    padding-bottom: 10px;
    opacity: 0.5;
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    padding-bottom: 10px;
  }

}
@keyframes he6 {
  0%,70% {
    padding-bottom: 0px;
  }

  /* Finish changes by here */
  84% {
    padding-bottom: 10px;
    opacity: 0.5;
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    padding-bottom: 10px;
  }

}
@keyframes he7 {
  0%,84% {
    padding-bottom: 0px;
  }

  /* Finish changes by here */
  96% {
    padding-bottom: 10px;
    opacity: 0.5;
  }

  /* Between 20% and 100%, nothing changes */
  100% {
    padding-bottom: 10px;
  }

}

.AssessmentListHeader {
  display: flex;
  flex-direction: column;
}

.assessment-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.2rem;
  margin-bottom: 1.8rem;
  padding: 0 !important;
}

.assessment-header-container .SearchWrapper {
  display: initial;
}

.SearchWrapper {
  display: none;
}

.plusIcon {
  display: none;
  cursor: pointer;
}

.plusIcon rect {
  fill: #7458d3;
}

.plusIcon path {
  fill: white;
  stroke: #7458d3;
}

.plusIcon:hover rect {
  fill: rgba(116, 88, 211, 0.9);
}

.plusIcon:active rect {
  fill: rgba(116, 88, 211, 0.9);
}

.plusIcon:active path {
  fill: rgba(255, 255, 255, 0.6);
}

.AddAssessmentButton {
  display: initial;
}

.emptyContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50vh;
}

@media screen and (max-width: 576px) {
  .emptyContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: fit-content;
  }
}

.Container {
  width: 100%;
  background: #fafafa;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  /* padding-left: 3rem; */
  padding: 0rem 1rem;
  border-radius: 12px;
}
.assessments {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  border-radius: 12px;
  width: 100%;
  /* padding-left: 3rem; */
  padding-left: 1.4rem;
  padding-top: 2.4rem;
  padding-bottom: 1.9rem;
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #393939;
}

@media screen and (max-width: 876px) {
  .Container {
    width: unset;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px;
    border-radius: 0px;
  }

  .assessments {
    font-size: 12px;
    line-height: 17px;
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 710px) {
  .assessment-header-container {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .SearchWrapper {
    display: initial;
    margin-bottom: 16px;
  }
  .assessment-header-container .SearchWrapper {
    display: none;
  }

  .AddAssessmentButton {
    display: none;
  }

  /* Disabled until Add Assessment Mobile version comes */
  .plusIcon {
    display: initial;
  }
  
}

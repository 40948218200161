.PageWrapper {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(92.23deg, #171717 1.56%, #1e1e1e 98.26%);
}

.Logo {
  position: absolute;
  left: 4rem;
  top: 2.5rem;
  z-index: 1;
}

.Circles {
  position: absolute;
}

.TextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0px 80px;
  background-image: url('404background1440.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100vw auto;
}


.TextHeader {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 5.2rem;
  color: #ffffff;
  text-align: center;
}

.Subtext {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.6rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 2.8rem;
}

@media screen and (max-width: 1200px) {
  .TextWrapper {
    background-image: url('404background1200.svg');
  }
}

@media screen and (max-width: 960px) {
  .TextWrapper {
    background-image: url('404background960.svg');
  }
}

@media screen and (max-width: 720px) {
  .TextWrapper {
    background-image: url('404background620.svg');
  }
}

@media screen and (max-width: 470px) {
  .TextWrapper {
    background-image: url('404background370.svg');
    padding: 0px 30px;
  }
}

@media screen and (max-width: 360px) {
  .TextWrapper {
    background-image: url('404background360.svg');
    padding: 0px 30px;
  }
}

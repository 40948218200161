body:has(.weightWrapper) :global(#hubspot-messages-iframe-container) {
  display: none !important;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  padding-top: 16px;
  width: 408px;
}

.weightWrapper {
  margin-right: 0;
}

.title {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  margin-top: 24px;
}

.weightText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(88, 88, 88, 0.6);
}

.detailsColumn {
  display: flex;
  flex-direction: row;
}

.questionInfo {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.detailsRowRight {
  display: flex;
  margin-right: 1.2rem;
  flex-direction: row;
  margin-left: auto;
}

.weightInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 6px;
}

.questionDetailsRow {
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  flex-direction: row;
  padding-right: 3px;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.questionListWrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  height: calc(100vh - 100px);
  padding-bottom: 200px;
  margin-top: 12px;
}

.weightOptions {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-right: 15px;
  margin-left: 3px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(88, 88, 88, 1);
}

.infoIcon {
  margin-right: 3px;
  cursor: pointer;
  height: 12px;
  width: 12px;
}

.questionNumber {
  font-family: 'Jost-500';
  font-style: normal;
  margin-left: 3px;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.detailsBar {
  display: flex;
  padding: 9px 27px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 47px;
  margin-left: auto;
  margin-right: auto;
  background: #f4f4f4;
  border-radius: 12px;
  margin-bottom: 24px;
  /* margin-right: 27px; */
}

.noQText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #393939;
  display: flex;
  flex-direction: row;
  width: 408px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}

.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100dvh;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card {
  max-width: 1000px;
  min-width: 576px;
  /* min-height: 288px; */
  background: #ffffff;
  display: flex;
  padding: 3.2rem 3.2rem 6.4rem 3.2rem;
  width: 60%;
  flex-direction: column;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}
.cardTextBox {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* min-height: 114px; */
}

.resendWrapper {
  display: inline;
}
.cardTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
  margin-bottom: 1.6rem;
}
.cardText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  margin-bottom: 2.4rem;
}
.cardSubText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: rgba(88, 88, 88, 0.6);
}

.link {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #31cbe0;
  cursor: pointer;
}

.logo {
  margin-bottom: 3.2rem;
}

@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }

  .CardWrapper {
    justify-content: flex-start;
  }
  .card {
    width: 100%;
    flex-direction: column;
    max-width: none;
    min-width: 0;
    box-shadow: none;
    justify-content: flex-start;
  }
  .logo {
    margin-bottom: 32px;
  }
  .cardTitle {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 24px;
  }
  .cardText {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 24px;
  }
  .cardSubText {
    font-size: 16px;
    line-height: 23px;
  }
  .link {
    font-size: 16px;
    line-height: 23px;
  }
}

.wrapper {
  position: relative;
  margin-bottom:12px;
}

.container {
  display: flex;
  flex-direction: row;
  gap: 56px;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
  padding-bottom: 8px;
}

.tabWrapper {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: rgba(57, 57, 57, 0.8);
  padding-left: 20px;
  padding-right: 20px;
}
.active {
  color: #7458d3;
}

.tabWrapper:hover {
  cursor: pointer;
}

.borderBottom {
  display: block;
  position: absolute;
  bottom: 0;
  transition-property: all;
  transition-duration: 400ms;
  height: 2px;
  background-color: #7458d3;
}

/* Default checkbox view */
.container {
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid #585858;
  border-radius: 5px;
  min-height: 14px;
  min-width: 14px;
  cursor: pointer;
}

/* ---------------------- */

/* Disabled checkbox */
.disabled {
  border: 1px solid rgba(88, 88, 88, 0.2);
  pointer-events: none;
  opacity: 0.4;
}
/* ------------------ */

/* Checked view */
.checked {
  background-color: #7458d3;
  border: 1px solid #7458d3;
  background-image: url('./tick.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}
/* ------------ */

/* Indeterminate */
.indeterminate {
  background: #7458d3;
  border: 1px solid #7458d3;
  background-image: url('./line.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

@media screen and (hover: hover) {
  .indeterminate:hover {
    background-color: rgba(73, 48, 152, 1);
    border: 1px solid rgba(73, 48, 152, 0.2);
  }

  .checked:hover {
    background-color: rgba(73, 48, 152, 1);
    border: 1px solid rgba(73, 48, 152, 0.2);
  }

  .disabled:hover {
    border: 1px solid rgba(88, 88, 88, 0.2);
    cursor: not-allowed;
  }

  .container:hover {
    border: 1px solid #7458d3;
  }
}

.backdrop {
  background: rgba(0, 0, 0, 0.25);
}

.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.addOnmodal-content {
  max-width: 100vw;
  width: 572px;
  height: 350px;
}
.plaModal-content {
  max-width: 100vw;
  width: 572px;
  height: 252px !important;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #585858;
}
.subtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #585858;
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 45px;

  justify-content: flex-end;
}
.modalContent {
  border: 1px solid rgba(88, 88, 88, 0.2);
  padding: 24px 24px 24px 24px !important ;
  min-width: 572px;
  max-width: 572px;
}
.addOnWrapper {
  background: #ffffff;
  filter: drop-shadow(0px 1px 24px rgba(131, 131, 131, 0.1));
  height: 86px;
  padding: 16px 12px 0px 22px !important;
}
.rowWrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 8px;
}

.addOnTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(57, 57, 57, 0.49);
  text-transform: uppercase;
  margin-bottom: 7px;
}

.addOnInfo {
  font-family: 'Jost-400';
  font-size: 20px;
  line-height: 29px;
  color: rgba(57, 57, 57, 0.84);
  text-transform: lowercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconsWrapper {
  width: 72px;
  border-radius: 9px;
  background: rgba(244, 244, 244, 0.6);
  display: flex;
  align-items: stretch;
  height: 36px;
  justify-content: space-between;
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 7px;
}
.icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
/* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .addOnmodal-content {
    width: fit-content;
  }
  .plaModal-content {
    width: fit-content;
  }
  .modalContent {
    min-width: fit-content !important;
    height: fit-content !important;
  }
  .row {
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
    justify-content: flex-end;
  }
}
/* Temporary mobile solution */

@font-face {
  font-family: Jost-400;
  src: url('fonts/Jost-Regular-400.ttf');
}

@font-face {
  font-family: Jost-300;
  src: url('fonts/Jost-Light-300.ttf');
}

@font-face {
  font-family: Jost-500;
  src: url('fonts/Jost-Medium-500.ttf');
}

@font-face {
  font-family: Inter-300;
  src: url('fonts/Inter-Light-300.ttf');
}

@font-face {
  font-family: Inter-400;
  src: url('fonts/Inter-Regular-400.ttf');
}

@font-face {
  font-family: Inter-500;
  src: url('fonts/Inter-Medium-500.ttf');
}

body,
html {
  /* background-color: #fafafa !important; */
  font-family: Jost-400 !important;
  font-size: 14px;
}

a:hover {
  color: unset;
  text-decoration: unset;
}

/* table {
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-top: 1rem;
} */

/* th {
  background-color: #4287f5;
  color: white;
}

td,
th {
  width: 150px;
  padding: 5px;
} */
.dragging-helper-class {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  /* padding: 20px 12px 5px 16px !important; */
  /*font-size: 16px; */
}

/* tr {
  cursor: pointer;
}

tr td:first-child {
  border-top-left-radius: 16px;
}

tr td:last-child {
  border-top-right-radius: 16px;
}

tr td:first-child {
  border-bottom-left-radius: 16px;
}

tr td:last-child {
  border-bottom-right-radius: 16px;
} */

input:checked + .slider-switch {
  background-color: #4ac389;
}

input:focus + .slider-switch {
  box-shadow: 0 0 1px #4ac389;
}

input:checked + .slider-switch:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

*:focus {
  outline: none !important;
  /* box-shadow: none !important; */
}

/* * {
  outline: none !important;
} */

.dropdown-toggle:focus {
  box-shadow: none !important;
}

input:checked + .slider-switch-reject {
  background-color: #4ac3b5 !important;
}

input:focus + .slider-switch-reject {
  box-shadow: 0 0 1px #4ac3b5;
  background-color: #f4f4f4;
  /* border: 1px solid rgba(88, 88, 88, 0.2); */
}

input:checked + .slider-switch-reject:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Tooltip */

.tooltip,
.tooltip-inner {
  background: #fff !important;
  color: #787878 !important;
  border-radius: 12px !important;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[data-popper-placement^='top'],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #fff;
}

.bs-tooltip-auto[data-popper-placement^='right'],
.bs-tooltip-end {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}

.bs-tooltip-auto[data-popper-placement^='bottom'],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fff;
}

.bs-tooltip-auto[data-popper-placement^='left'],
.bs-tooltip-start {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #fff;
}

/* .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #fff;
  border-radius: 0.25rem;
}
.tooltip-text {
  position: relative;
  display: inline-block;
}
.tooltip-text .tooltip-inner {
  visibility: hidden;
  min-width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -76px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

.tooltip-a .tooltip-inner {
  max-width: 100% !important;
  width: 380px !important;
  height: fit-content !important;
  box-shadow: 0px 1px 18px rgba(88, 88, 88, 0.2);
  border: 1px solid rgba(88, 88, 88, 0.1);
}

.tooltip-text:hover .tooltip-inner {
  visibility: visible;
}

.tooltip-text .tooltip-inner::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
*/
.tooltip {
  border-radius: 100px !important;
  background-color: transparent !important;
}

/* modal-content */

.modal-content {
  border-radius: 25px;
  padding: 24px;
  height: 85%;
  width: 100%;
}

.modal-content {
  border-radius: 25px;
  padding: 24px;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mt-32 {
  margin-top: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-56 {
  margin-top: 56px;
}

.mb-56 {
  margin-bottom: 56px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mt-200 {
  margin-top: 200px;
}

.mb-200 {
  margin-bottom: 200px;
}

@media (max-width: 480px) and (min-width: 280px) {
  .col-md-5 {
    height: 0vh;
  }

  .col-md-7 {
    min-height: 100vh !important;
    height: auto;
  }
}

/* Scrollbar trials */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 21px;
  border: 2px solid transparent;
  background-clip: content-box;
  transition: all linear 0.2s;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(139, 139, 139);
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

.white::-webkit-scrollbar-track {
  background-color: white !important;
}

/* Modifies the padding between each <p> tag in questions. Do not change */
p.pt-5 {
  padding-top: 1rem !important;
}

/*  */
/*
.tooltip-a :global(.tooltip-inner) {
  background: #585858 !important;
  opacity: 0.8 !important;
  border-radius: 4px !important;
}

.tooltip-a :global(.tooltip .tooltip-arrow::before) {
  visibility: hidden !important;
}

.tooltip-a :global(.bs-tooltip-top) {
  padding: 0 0 0 0 !important;
  margin-top: 5px !important;
  height: 30px !important;
} */

.grecaptcha-badge {
  display: none !important;
  /* right: 150px !important; */
}

.modalTransparent {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  display: flex;
  /* overflow: auto; */
  justify-content: center;
  align-items: center;
  align-content: center;
  left: 0;
  z-index: 1300;
}

.myBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s linear;
  z-index: 1200;
  background-color: #000;
}

.showBackdrop {
  opacity: 0.5;
}

.fadeBackdrop {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.myParentModal {
  position: relative;
  z-index: 1201;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  opacity: 0;
  transform: translateY(-200px);
  transition: opacity 0.3s linear, transform 0.3s linear;
}

.showParent {
  opacity: 1;
  transform: translateY(0px);
}

.fadeParent {
  opacity: 0;
  transform: translateY(-200px);
}

#package {
  overflow: hidden;
}

/*
  rem size adjustment for the responsive design
 */

@media screen and (max-width: 576px) {
  html {
    font-size: 10px;
  }
}

/* @media screen and (max-width: 576px) {
  html {
    font-size: 8px;
  }
} */

@media screen and (min-width: 576px) {
  html {
    /* font-size: 12px; */
    font-size: 8px;
  }
}

@media screen and (min-width: 768px) {
  html {
    /* font-size: 12px; */
    font-size: 9px;
  }
}

@media screen and (min-width: 992px) {
  html {
    /* font-size: 13px; */
    font-size: 9px;
  }
}

@media screen and (min-width: 1200px) {
  html {
    /* font-size: 14px; */
    font-size: 10px;
  }
}

@media screen and (min-width: 1600px) {
  html {
    /* font-size: 16px; */
    font-size: 11px;
  }
}



label {
  margin-bottom: 0px;
}

.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.InnerCard {
  max-width: 1000px;
  min-width: 576px;
  background: #ffffff;
  display: flex;
  width: 60%;
  flex-direction: row;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}

.Left {
  /* background-image: url('../../Images/circles.svg');
  background-repeat: no-repeat; */
  /* background-size: 100% 100%; */
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid rgba(57, 57, 57, 0.15);
}

.TextWrapper {
  display: flex;
  flex-direction: column;
  font-family: 'Jost-500';
  font-size: 8rem;
  line-height: 11.6rem;
  margin-left: 3.2rem;
  margin-bottom: 4rem;
}

.TextWrapperLastLine {
  line-height: 9.8rem;
}

@media screen and (max-width: 1420px) {
  .TextWrapper {
    font-size: 70px;
    line-height: 102px;
  }
  .TextWrapperLastLine {
    line-height: 84px;
  }
}

@media screen and (max-width: 1240px) {
  .TextWrapper {
    font-size: 62px;
    line-height: 88px;
  }
  .TextWrapperLastLine {
    line-height: 71px;
  }
}

@media screen and (max-width: 1100px) {
  .TextWrapper {
    font-size: 52px;
    line-height: 74px;
  }
  .TextWrapperLastLine {
    line-height: 60px;
  }
}

.TextWrapper text {
  background: linear-gradient(276.71deg, #32CBE0 -0.29%, #7458D3 83.57%, #7458D3 83.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Right {
  width: 55%;
  height: 100%;
  padding: 4rem 3.2rem 4rem 3.4rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.logo {
  margin-left: 3.2rem;
  margin-top: 3.2rem;
}

.loginText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #7458d3;
}

.text {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  cursor: pointer;
}
.link {
  /* margin-left: 0.5rem;
  margin-right: 0.5rem; */
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #31cbe0;
  /* text-decoration: underline; */
  cursor: pointer;
}

.link:hover {
  color: #31cbe0;
  text-decoration: underline;
}
.terms {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}

.almostThereText {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
  margin-bottom: 3rem;
}

.errorWrapper {
  position: absolute;
  display: flex;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #de4747;
  margin-top: 4.8rem;
  margin-left: 0.5rem;
}

.error {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #de4747;
}
.NameSurnameWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}
.NameInput {
  width: 48%;
}

.TextInputWrapper {
  /* display: flex; */
  margin-bottom: 0.7rem;
}

.smallScreenConstraint {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 1.2rem;
  line-height: 1rem;
  color: #de4747;
  margin-top: 4.8rem;
  margin-left: 0.5rem;
}

@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: space-between;
  }

  .CardWrapper {
    justify-content: flex-start;
    padding-bottom: 2.4rem;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
  }
  .InnerCard {
    width: 100%;
    /* height: 100%; */
    min-height: 660px;
    flex-direction: column;
    max-width: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    box-shadow: none;
    align-items: center;
    /* justify-content: center;*/
  }
  .Left {
    /* background-image: url('../../Images/mobile_circles.svg');
    background-size: 100%;
    background-repeat: no-repeat; */
    width: 100%;
    border-right: none;
    height: 32px;
    padding-left: 0;
    padding-right: 0;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 1rem;
  }

  .TextWrapper {
    flex-direction: row;
    font-size: 22px;
    line-height: 32px;
    margin-left: 4px;
    margin-bottom: 0px;
    min-height: 32px;
    margin-right: 20px;
    min-width: 200px;
  }

  .Right {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    width: 100%;
    min-height: 480px;
    height: 480px;
  }
  .NameSurnameWrapper {
    margin-bottom: 10px;
  }
  .TextInputWrapper {
    margin-bottom: 10px;
  }

  .logo {
    margin-left: 2rem;
    margin-top: 0px;
    max-height: 18px;
  }

  .link {
    font-size: 14px;
    line-height: 23px;
  }
  .text {
    font-size: 14px;
    line-height: 23px;
  }
  .errorWrapper {
    font-size: 12px;
  }
  .almostThereText {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 16px;
  }
  .terms {
    margin-bottom: 24px;
  }
}

.ContentBody {
  background-color: #fff;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  /* overflow: scroll; */
  height: calc(100vh - 80px); /* changed with header */
  /* height: 100vh; */
}

/* @media screen and (max-width: 1500px) {
  .ContentBody {
    transform: scale(0.7);
  }
} */

.pageWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(100% - 8px);
  min-width: 1200px;
}

.rightColumn {
  flex-grow: 7;
  height: 100%;
  /* min-width: 800px !important; */
  margin-top: 15px;
  flex-basis: 70%;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 825px;
}

.leftColumn {
  background: linear-gradient(
    177.49deg,
    rgba(116, 88, 211, 0) 11.16%,
    rgba(116, 88, 211, 0.4) 105.39%
  );
  flex-grow: 3;
  /* min-width: 432px; */
  margin-top: 15px;
  flex-basis: 30%;
  height: 100%;
  min-width: 368.5px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center; */
}

.resultReport {
  /* result report wrapper class */
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 20px;
  align-items: center;
  /* margin-top: 10px; */
  /* padding-top: 20px; */
}

.commentWrapper {
  /* comment wrapper class */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-bottom: 20px;
}

.headerAndSwitchWrapper {
  /* width: calc((100% - 12px)*0.9); */
  width: 100%;
  height: 137px;
  background-color: white;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

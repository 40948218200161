.modalContent {
  padding: 28px !important;
  border: none !important;
  border-radius: 16px !important;
  display: flex;
  justify-content: center;
}

.container {
  display: grid;
  grid-template-rows: 1fr 4fr;
}

.close {
  justify-self: flex-end;
  cursor: pointer;
}

.warningText {
  color: #7458d3;
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  justify-self: center;
  align-self: center;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headerWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: auto 30px;
  padding: 0rem 1.2rem;
}

.innerHeaderWrapper {
  /* display: grid; */
  align-items: center;
  /* grid-template-columns: auto 40px; */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  line-height: 3.5rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
}

.upperWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  margin-right: 0;
  margin-left: auto;
  margin-top: auto;
  padding-top: 12px;
}

.filterItemWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemContainer {
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.itemHeader {
  font-family: 'Jost-300';
  font-size: 2rem;
  line-height: 2.9rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
}

.itemHeaderBold {
  font-family: 'Jost-400';
  font-size: 2rem;
  line-height: 2.9rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
}

.itemHeaderMobile {
  font-size: 16px;
  line-height: 23px;
}

.ibeWrapper {
  display: flex;
  flex-direction: row;
  padding: 5px 0px 1.4rem 1.2rem;
  align-items: center;
}
.ibeText {
  margin-left: 10px;
  font-family: 'Jost-300';
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}

.scoreContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.2rem;
  align-items: center;
}
.inputBox {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  width: 8rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* To disable the up-down arrows in score inputs */

input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Input Range Styling */
.range-input :global(.input-range__track) {
  background: rgba(88, 88, 88, 0.2) !important;
  height: 12px !important;
}

.range-input :global(.input-range__track)--background {
  border-radius: 32px;
}
.range-input :global(.input-range__track--active) {
  background: #7458d3 !important;
  height: 12px !important;
}
.range-input :global(.input-range__slider) {
  background: #fff !important;
  border: 1px solid rgba(88, 88, 88, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 2px 1px 8px rgba(0, 0, 0, 0.15) !important;
  width: 22px !important;
  height: 22px !important;
  margin-top: -1.8rem !important;
  margin-left: -1rem !important;
}

.range-input :global(.input-range__label-container) {
  display: none;
}
.range-input {
  width: 30rem;
  padding: 0 12px;
  /* margin-top: -1.8rem; */
}

/* DATE Filter */
.dateContainer {
  background: #ffffff;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  height: 40px;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
  /* padding: 0px 2px; */
  margin: 1rem 2px 2rem 2px;
}

.dateRangeActive {
  background: #7458d3;
  border-radius: 12px;
  color: #ffff !important;
}

.dateRange {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #585858;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radioWrapper {
  display: flex;
  flex-direction: column;
}

/* Calendar Styling */
.calendarContainer {
}

.calendarContainer :global(.react-calendar__navigation button) {
  min-width: 44px;
  background: none;
  border: none;
}
.calendarContainer :global(.react-calendar__navigation button:enabled:hover) {
  background-color: #e6e6e6;
}
.calendarContainer :global(.react-calendar__navigation button:enabled:focus) {
  background-color: #e6e6e6;
}

.calendarContainer :global(.react-calendar__navigation button[disabled]) {
  background-color: #f0f0f0;
}

.calendarContainer :global(.react-calendar__month-view__weekdays) {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.calendarContainer :global(.react-calendar__month-view__weekdays__weekday) {
  padding: 0.5em;
}

.calendarContainer :global(.react-calendar__month-view__weekNumbers) {
  font-weight: bold;
}

.calendarContainer :global(.react-calendar) {
  max-width: 100%;
  border: 1px solid rgba(88, 88, 88, 0.2);
  line-height: 0.5em;
  margin-left: 0;
  margin-right: auto;
  border-radius: 10px;
  background: white;
  margin-top: 10px;
}

.calendarContainer :global(.react-calendar button) {
  margin: 0;
  border: 0;
  outline: none;
  min-height: 25px;
}

.calendarContainer :global(.react-calendar button:hover) {
  cursor: pointer;
}

.calendarContainer :global(.react-calendar__viewContainer) {
  padding: 0rem 0.3rem 0.3rem;
}

.calendarContainer :global(.react-calendar__navigation) {
  /* padding-left: 25%;
      padding-right: 25%; */
  padding-top: 2%;
  height: 25px;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-content: center;
  text-align: center !important;
}
.calendarContainer :global(.react-calendar__navigation__next-button:focus) {
  outline: none;
}
.calendarContainer :global(.react-calendar__navigation__prev-button:focus) {
  outline: none;
}
.calendarContainer :global(.react-calendar__navigation__next2-button) {
  display: none;
}
.calendarContainer :global(.react-calendar__navigation__prev2-button) {
  display: none;
}

.calendarContainer :global(.react-calendar__month-view__weekNumbers .react-calendar__tile) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.calendarContainer :global(.react-calendar__month-view__days__day--weekend) {
  /* color: #d10000; */
}

.calendarContainer :global(.react-calendar__month-view__days__day--neighboringMonth) {
  color: #757575;
}
.calendarContainer :global(.react-calendar__year-view .react-calendar__tile) {
  padding: 2em 0.5em;
}
.calendarContainer :global(.react-calendar__decade-view .react-calendar__tile) {
  padding: 2em 0.5em;
}
.calendarContainer :global(.react-calendar__century-view .react-calendar__tile) {
  padding: 2em 0.5em;
}
.calendarContainer :global(.react-calendar__tile) {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  font-size: 0.95em;
}
.calendarContainer :global(.react-calendar__tile:focus) {
  outline: none !important;
}
.calendarContainer :global(.react-calendar__tile:disabled) {
  background-color: #f0f0f0;
}
.calendarContainer :global(.react-calendar__tile:enabled:hover) {
  background-color: #e6e6e6;
}
.calendarContainer :global(.react-calendar__tile:enabled:focus) {
  background-color: #e6e6e6;
}

.calendarContainer :global(.react-calendar__tile--now) {
  /* background: #4ac3b599; */
  color: rgba(116, 88, 211, 1);
  font-weight: bolder;
  border: 2px solid rgba(116, 88, 211, 1) !important;
}

.calendarContainer :global(.react-calendar__tile--hasActive) {
  background: #76baff;
}
.calendarContainer :global(.react-calendar__tile--hasActive:enabled:hover) {
  background: #a9d4ff;
}
.calendarContainer :global(.react-calendar__tile--hasActive:enabled:focus) {
  background: #a9d4ff;
}

.calendarContainer :global(.react-calendar__tile--active) {
  background: rgba(116, 88, 211, 0.2) !important;
  color: rgba(116, 88, 211, 1) !important;
  font-weight: bold;
}
.calendarContainer :global(.react-calendar__tile--active:enabled:hover) {
  background: #1087ff;
}
.calendarContainer :global(.react-calendar__tile--active:enabled:focus) {
  background: #1087ff;
}

.calendarContainer :global(.react-calendar--selectRange .react-calendar__tile--hover) {
  background-color: #e6e6e6;
}
.calendarContainer :global(.react-calendar__tile--rangeStart) {
  background-color: rgba(116, 88, 211, 1) !important;
  color: white !important;
  font-weight: bold;
}
.calendarContainer :global(.react-calendar__tile--rangeEnd) {
  background-color: rgba(116, 88, 211, 1) !important;
  color: white !important;
  font-weight: bold;
}

.startEndDateWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 5rem;
  grid-column-gap: 8px;
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
  padding: 0px 2px;
}
.dateStartEndContainer {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;

  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  padding-left: 1.6rem;
  display: flex;
  flex-direction: column;
}
.dateStartEndContainer::before {
  background: #ffffff;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;

  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  padding-left: 1.6rem;
  display: flex;
  flex-direction: column;
}

.dateStartEnd {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  letter-spacing: -0.02em;
  color: #393939;
}

.dateStartEndTitle {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: -3px;
  color: rgba(57, 57, 57, 0.6);
}

.dateStartEndTitle.filled {
  font-family: 'Jost-400';
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgba(116, 88, 211, 0.6);
}

.dropdownContainer {
  padding-top: 16px;
}

.lastDropdownContainer {
  padding-top: 22px;
}

.includeContainer {
  background: rgba(244, 244, 244, 0.6);
  border-radius: 10px;
  height: 4.8rem;
  padding: 12px 16px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}
.includeText {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 23px;
  color: rgba(57, 57, 57, 0.5);
  margin-right: 16px;
}
.screeningSubheader {
  font-family: 'Jost-500';
  font-size: 1.8rem;
  line-height: 2.6rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
}
.screeningWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
}
.subDivWrapper {
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.expListWrapper {
  gap: 34px;
  display: flex;
  flex-direction: row;
}
.salaryWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.yellowIcon {
  width: 8px;
  height: 8px;
  background: #ffd600;
  border-radius: 50%;
}

.rightHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 576px) {
  .header {
    font-size: 16px;
    line-height: 23px;
  }

  .itemHeader {
    font-size: 16px;
    line-height: 23px;
  }

  .itemHeaderBold {
    font-size: 16px;
    line-height: 23px;
  }

  .innerHeaderWrapper {
    grid-template-columns: auto 40px;
  }

  .icon {
    width: 23px;
    height: 23px;
  }

  .ibeText {
    margin-left: 8px;
  }

  .buttonWrapper {
    width: 100%;
    gap: 10px;
    padding: 8px 8px 0px 12px;
  }

  .startEndDateWrapper {
    height: 46px;
    grid-column-gap: 4px;
    padding: 0px 2px;
  }

  .dateStartEndContainer {
    padding-left: 10px;
  }

  .dateStartEnd {
    font-size: 12px;
    color: #393939;
  }
  .includeText {
    margin-right: 12px;
  }
  .screeningSubheader {
    font-size: 16px;
    font-family: 'Jost-400';
  }
}

.customModal {
  min-height: 36rem !important;
  min-width: 52rem !important;
  max-width: fit-content;
  padding: 20px 20px 20px 28px;
}
.nameContent {
  padding: 20px 24px 24px 24px;
  border-radius: 10px;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.2);
  border: none;
  /* margin-left: 2rem; */
  min-width: 48rem !important;
}
.connectionContent {
  padding: 20px 24px 24px 24px;
  border-radius: 10px;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.2);
  border: none;
  min-height: 42rem !important;
  max-height: 42rem !important;
  min-width: 50rem !important;
  max-width: 50rem !important;
  margin-top: 10rem;
}
.dialogCustomModal {
  overflow-y: clip !important;
  min-width: fit-content !important;
  max-width: fit-content;
}
.blurredModal {
  background: rgba(255, 255, 255, 1);
  filter: blur(2px);
}

.customModalBig {
  min-height: 54rem !important;
  min-width: 52rem !important;
  padding: 20px 20px 20px 28px;
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.borderedRow {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(88, 88, 88, 0.1);
}
.header {
  font-family: 'Jost-400';
  font-size: 2rem;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #585858;
}
.name {
  font-family: 'Jost-400';
  font-size: 1.2rem;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(88, 88, 88, 0.6);
}

.text {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 23px;
  color: #585858;
}
.authorizedLine {
  margin-bottom: 22px;
}

.authorizedLine:hover .editIcon2 {
  display: flex;
  cursor: pointer;
}

.positionLine:hover .editIcon1 {
  display: flex;
  cursor: pointer;
}

.editIcon1 {
  display: none;
}
.editIcon2 {
  display: none;
}

.hoverHeight {
  height: 24px;
}

.iconWrapper {
  height: 24px;
  width: 24px;
  padding-top: 5px;
  padding-left: 5px;
}

.iconWrapper:hover {
  background-color: rgba(88, 88, 88, 0.1);
  border-radius: 50%;
}

.addConnection {
  width: 100%;
  display: flex;
  justify-content: center;
}
.addConnectionText {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  text-align: center;
  color: #7458d3;
  width: fit-content;
}
.addConnectionText:hover {
  cursor: pointer;
}
.nameHeader {
  font-family: 'Jost-400';
  font-size: 2rem;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #585858;
  margin-bottom: 14px;
}

.atsRow {
  display: flex;
  padding-bottom: 12px;
  flex-direction: row;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
  align-items: center;
}

.active {
  background: #4ac389 !important;
}

.switchContainer {
  width: 42px;
  height: 22.5px;
  margin-right: 12px;
  position: relative;
  display: flex;
  background: rgba(88, 88, 88, 0.2);
  border-radius: 11.25px;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
}
.switchText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 26px;
  color: #393939;
}
.switchButton {
  width: 19.5px;
  height: 19.5px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s linear;
}
.listWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 21px;
}
.buttonWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 24px;
  right: 24px;
  padding-top: 12px;
}
.buttonContainer {
  border-top: 1px solid rgba(57, 57, 57, 0.1);
  width: 100%;
}
.userListWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 26rem;
}
.userRow {
  display: flex;
  flex-direction: row;
  line-height: 46px;
  align-items: center;
}
.userRow label {
  margin-bottom: 0 !important;
}
.userNameLabel {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 23px;
  color: #393939;
}
/* //////////////////////////////////////////////////////////////// */
.statusListUser2 {
  border-radius: 8px;
  background: rgba(74, 195, 137, 0.1);
  border: 1px solid rgba(74, 195, 137, 0.1);
  height: 38px !important;
  width: 142px !important;
}

.statusListUser2 span {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #4ac389;
}

.user-role-dropdown1 .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.user-role-dropdown1 .fd-inner {
  width: 100%;
}

.user-role-dropdown1 .dropdown-item:nth-child(3),
.user-role-dropdown1 .dropdown-item:nth-child(2) {
  text-align: center !important;
  align-items: center !important;
  display: block !important;
}

.user-role-dropdown1 {
  max-height: 40px !important;
  max-width: 142px !important;
}

.funnel-dropdown .fd-inner {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px 0px 24px;
}

.funnel-dropdown .fd-inner .dropdown-item {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 32px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #585858;
  padding: 16px;
  cursor: pointer;
}

.edit-modal {
  background-color: rgba(247, 247, 247, 1) !important;
}

.EditModalContainer {
  display: grid;
  grid-template-rows: auto auto auto auto;
}

.EditModalHeader {
  display: grid;
  margin-bottom: 24px !important;
  grid-template-columns: 16fr 1fr;
}

.EditModalHeaderText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
}

.EditModalCloseButton {
  float: right;
  margin-left: auto;
  border: none;
  background: transparent;
  align-self: center;
}

.filterModalCloseButton2 {
  vertical-align: middle;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
}

.filterModalCloseButton2:hover {
  color: #fff;
  background-color: #f4f4f4cc;
}

.EditModalTypeContainer {
  display: grid;
  border-bottom: 1px solid rgba(88, 88, 88, 0.2);
  padding-bottom: 10px;
  padding-top: 10px;
}

.AssesmentNameTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  align-self: center;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.4);
}

.EditMenu {
  min-width: 100% !important;
  border-radius: 8px !important;
}

.editModalItem {
  padding: 7px 0 15px 0 !important;
  border-radius: 8px;
  width: 142px !important;
  height: 40px !important;
  font-size: 16px;
}

.EditModalNameContainer {
  display: grid;
  border-bottom: 1px solid rgba(88, 88, 88, 0.2);
  grid-template-rows: auto auto;
  font-size: 1.6rem;
  padding-bottom: 10px;
  padding-top: 10px;
}

.NameText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.4);
}

.EditButton {
  float: right;
  width: 30px !important;
  height: 30px !important;
}

.EditButton:hover {
  cursor: pointer;
}

.EditNameValue {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  display: flex !important;
  letter-spacing: -0.02em;
  color: #585858;
  align-items: center !important;
}

.user-profile-img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid #f4f4f4;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  display: inline-block;

  color: #7458d3;
  background: #c3b6ef;
}

.user-profile-img:nth-child(3) {
  background: #c3b6ef;
  color: #7458d3;
}

.user-profile-img:nth-child(5) {
  background: #c3b6ef;
  color: #7458d3;
}

.user-profile-img:nth-child(7) {
  background: #c3b6ef;
  color: #7458d3;
}

.NestedPeople {
  margin-left: -14px;
  background: #f9d7d1 !important;
}

.editNameScreenModal {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 40px;
  height: 60%;
}

.EditNameHeader {
  display: grid;
  grid-template-columns: 1fr 15fr 1fr;
}

.EditModalChangePage {
  float: left;
  margin-right: auto;
  border: none;
  background: transparent;
  align-self: center;
}

.changePage {
  vertical-align: middle;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
}

.changePage:hover {
  color: #fff;
}

.changeTitleBody {
}

.EditModalButton {
  background: rgba(116, 88, 211, 0.2);
  border: 1px solid rgba(116, 88, 211, 0.2);
  box-sizing: border-box;
  border-radius: 32px;
  width: 30%;
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #7458d3;
  text-align: start;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  cursor: pointer;
}

.EditModalButton:focus {
  outline: none;
}

.EditModalButton:hover {
  box-shadow: 6px 6px 6px rgb(0 0 0 / 10%);
}

.EditModalButton:disabled {
  color: rgba(88, 88, 88, 0.2) !important;
  background: transparent !important;
  border: 1px solid rgba(88, 88, 88, 0.2);
}

.EditModalButton:disabled {
  color: rgba(88, 88, 88, 0.2) !important;
  background: transparent !important;
  border: 1px solid rgba(88, 88, 88, 0.2);
}

.filterModalOkImg {
  align-self: center;
  margin-right: 8px;
  margin-left: auto;
}

.EditModalButton:disabled .filterModalOkImg {
  color: #58585833 !important;
}

.funnel-dropdown :global(.dropdown-toggle.btn) {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  text-align: start;
  font-size: larger;
  padding: 0;
  align-self: center !important;
  margin-bottom: 5%;
}

.funnel-dropdown :global(.dropdown-toggle::after) {
  float: right;
}

.funnel-dropdown :global(.dropdown-toggle.btn::after) {
  align-self: center;
  margin-top: 3%;
}

.funnel-dropdown :global(.dropdown-menu.show) {
  padding: 0px !important;
  box-shadow: 0px 1px 6px rgb(0 0 0 / 15%);
}

.funnel-dropdown :global(.dropdown-toggle) {
  background: transparent !important;
}

.funnel-dropdown :global(.dropdown-toggle::after) {
  display: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.funnel-dropdown :global(.btn) {
  color: #676767;
}

.animate {
  transform: rotate(180deg);
  border: none;
}

/* MOBILE Styling */

.mobileWrapper {
  display: flex;
  flex-direction: column;
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.assmSettingsText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #585858;
}

.subheaderName {
  font-family: 'Jost-500';
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: rgba(88, 88, 88, 0.4);
}

.nameHeaderMobile {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}
.buttonWrapperMobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin-top: 24px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}
.nameHeaderMobile {
  font-family: 'Jost-400';
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #585858;
  align-items: center;
}

.listWrapperATSMobile {
  display: flex;
  flex-direction: column;
  margin-top: 21px;
  height: 200px;
  overflow-y: hidden;
  padding-right: 20px;
  padding-left: 20px;
}

.listWrapperMobile {
  display: flex;
  flex-direction: column;
  margin-top: 21px;
  height: 200px;
  overflow-y: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.switchTextMobile {
  font-family: 'Jost-300';
  font-size: 16px;
  line-height: 26px;
  color: #393939;
}
.editIcons {
  cursor: pointer;
  height: 31px;
  width: 31px;
}
.editIcons:hover {
  background-color: rgba(88, 88, 88, 0.1);
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
}
.editIcons:active {
  background-color: rgba(88, 88, 88, 0.2);
}

.headerRowMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  height: 31px;
}

.userListWrapperMobile {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 26rem;
  top: 58px;
  position: sticky;
}

/* MOBILE Styling */

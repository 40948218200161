.wrapper {
  font-family: Jost-400;
  height: 28px;
  width: 52px;
  cursor: pointer;
}

.innerWrapper {
  height: 28px;
  width: 52px;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 8px;
  padding: 5px 3px 5px 10px;
}

.list {
    z-index: 50;
    box-sizing: border-box;
    background: #ffffff;
    margin-top: 8px;
    border: 1px solid rgba(88, 88, 88, 0.2);
    border-radius: 10px;
    text-align: left;
    z-index: 5;
    position: sticky;
    overflow-y: hidden;
  }

.listItem {
  overflow: hidden;
  width: 100%;
  border: none;
  background: transparent;
  white-space: nowrap;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  height: 28px;
  text-align: left;
  padding-left: 10px;
  color: #585858;
}

.firstItem {
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
}

.firstItem:hover {
  background: rgba(244, 244, 244, 0.8);
}

.lastItem {
  border-radius: 0 0 8px 8px;
}

.lastItem:hover {
  background: rgba(244, 244, 244, 0.8);
}

.selectedItem {
  background: rgba(244, 244, 244, 0.8);
}

.selectedItem:hover {
  color: #7458d3;
}

.listItem:hover {
  background: rgba(244, 244, 244, 0.8);
}

.arrowUp {
    background-image: url('../Images/smallArrowUp.svg');
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  display: flex;
  /* margin-right: 5px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto; */
  transition: 0.3s ease-out;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}

.arrowDown {
  background-image: url('../Images/smallArrowDown.svg');
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  display: flex;
  transition: 0.3s ease-out;
  justify-content: center;
  margin: 0 !important;
}

.closeNoSelect:hover .arrowDown {
  background-image: url('../../images/dropdown/arrowDownPurple.svg');
  transition: 0.3s ease-out;
}

.closeSelect:hover .arrowDown {
  background-image: url('../../images/dropdown/arrowDownPurple.svg');
  transition: 0.3s ease-out;
}

.placeholder {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #585858;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  padding-top: 2px;
}

/* FRESH START */

/* .dd-container {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 18px;
    min-height: 50px;
    border-radius: 10px;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  }
  
  .closeNoSelect {
    background: rgba(244, 244, 244, 0.4);
    border: 1px solid rgba(88, 88, 88, 0.2);
  }
  
  .closeSelect {
    background: #ffffff;
    border: 1px solid rgba(88, 88, 88, 0.2);
  }
  
  .openNoSelect {
    background: #ffffff;
    border: 1px solid #7458d3;
  }
  
  .openSelect {
    background: #ffffff;
    border: 1px solid #7458d3;
  }

  
  .openNoSelect .placeholder {
    color: rgba(88, 88, 88, 0.2);
  }
  
  .closeNoSelect .title {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 18px;
    margin-bottom: 0;
    color: rgba(88, 88, 88, 0.6);
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  }
  
  .openNoSelect .title {
    position: absolute;
    transform: translate(0, 6px) scale(0.8) !important;
    transform-origin: top left !important;
    font-size: 12px !important;
    line-height: 17px;
    letter-spacing: -0.02em;
    padding-left: 18px !important;
    color: #7458d3;
  }
  .openSelect .title {
    position: absolute;
    transform: translate(0, 6px) scale(0.8) !important;
    transform-origin: top left !important;
    font-size: 12px !important;
    line-height: 17px;
    letter-spacing: -0.02em;
    padding-left: 18px !important;
  }
  
  .closeSelect .title {
    position: absolute;
    transform: translate(0, 6px) scale(0.8) !important;
    transform-origin: top left !important;
    font-size: 12px !important;
    line-height: 17px;
    letter-spacing: -0.02em;
    padding-left: 18px !important;
  }
  
  .closeSelect:hover .title {
    color: rgba(116, 88, 211, 1) !important;
  }
  .closeNoSelect:hover .title {
    color: rgba(116, 88, 211, 0.6) !important;
  }
   */

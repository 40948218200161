.header {
  width: 100%;
  height: fit-content;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  cursor: pointer;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #393939;
}

.closeWindow {
  position: relative;
  display: flex;
  justify-content: center;
  font-family: 'Jost-400';
  margin-top: max(12rem, 160px);
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #393939;
}

.bold {
  font-family: 'Jost-500';
  /* margin-left: 0.8rem; */
}

.baseIcon {
  transition: transform 0.2s linear;
  cursor: pointer;
}

.openIcon {
  transform: rotate(0deg);
}

.closedIcon {
  transform: rotate(-90deg);
}

.cardComponent {
  transition: all 1s linear;
  background-color: white;
  box-shadow: 0px 1px 24px rgba(208, 208, 208, 0.15);
  border-radius: 12px;
  width: 100%;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  padding-left: 3.2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  overflow: hidden;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 3.2rem;
}

.baseInputBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.5s linear;
}

.result {
  margin-bottom: 1.4rem;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  margin-top: 2.4rem;
  justify-content: flex-end;
}

.cvPart {
  height: fit-content;
  width: 100%;
  margin-top: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phoneInput {
  position: relative !important;
  letter-spacing: 0.01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0;
  height: 35px;
  outline: none;
  display: block;
  color: #495057;
}

.result :global(.form-control) {
  align-content: stretch !important;
  background: rgba(244, 244, 244, 0.6) !important;
  border: 1px solid rgba(53, 48, 68, 0.6) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  min-height: 50px;

  padding: 12px;
  width: 100% !important;
}

.result :global(.react-tel-input) {
  margin-bottom: 0.5rem;
}

.result :global(.flag-dropdown .country-list) {
  height: fit-content;
}

.LoginBoxFooter {
  margin-top: 24px;
  justify-content: end;
  text-align: end;
  font-family: 'Belleza', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #585858;
}

.result :global(.country-name) {
  padding-left: 65px;
  margin-left: 25px;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.result :global(.dial-code) {
  padding-left: 23px;
  margin-left: 15px;
  top: 0 !important;
  left: 0 !important;
  position: absolute !important;
}

.result :global(.country) {
  height: 30px;
  align-items: center !important;
  align-content: center !important;
  padding: 0 !important;
}

.result :global(.flag) {
  margin-bottom: 5px;
}

.cvParser {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  margin-left: 2.5rem;
  display: flex;
  color: #7458d3;
  cursor: pointer;
}

.linkedIn {
  position: absolute;
  left: calc(100% - 40px);
  transition: 0s;
  z-index: 1;
}

.linkedInWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  align-items: center;
}

.namePart {
  display: flex;
  margin-bottom: 1.4rem;
  flex-direction: row;
  margin-top: 2.8rem;
  align-items: center;
  justify-content: space-between;
}

.phoneInput {
  position: relative !important;
  letter-spacing: 0.01rem !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0;
  height: 50px;
  outline: none;
  width: 100%;
  display: block;
  color: #495057;
}

.fileInputBase {
  padding-left: 1.2rem;
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
  width: 48%;
  padding-right: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 12px;
  cursor: pointer;
}

.showCv path {
  stroke: #7458d3;
}

.title {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  text-align: center;
  color: #393939;
  margin-bottom: 1.6rem;
}

.subtitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #393939;
}

.fileInputBase:focus {
  border: 1px solid rgba(57, 57, 57, 0.15);
}

.inputPlaceholder {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.2rem;
  text-align: center;
  word-break: break-all;
  /* color: rgba(57, 57, 57, 0.6); */
  color: #393939;
}

.closedInputBody {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
}

.openInputBody {
  max-height: 500px;
  opacity: 1;
  visibility: visible;
}

.inputWrapper {
  width: 48%;
}

.NewOpWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.NewOpInfoWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.NewOpHeaderInfoTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.DoubleArrowIconWrapper {
  margin: 0px 21px;
}

.NewOpInfoHeader {
  font-family: 'Jost-500';
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: center;
  text-transform: uppercase;
  color: #7458d3;
  width: 100%;
}

.NewOpInfoSubtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;

  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;

  color: #393939;
}

@media screen and (max-width: 576px) {
  .DoubleArrowIconWrapper {
    margin: 0px 4px;
  }

  .namePart {
    flex-direction: column;
  }

  .fileInputBase {
    width: 50%;
  }

  .inputWrapper {
    width: 100%;
  }

  .topInput {
    margin-bottom: 1.4rem;
  }
}

.CandidateWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 196px);
  padding: 1.4rem 2.1rem 1.4rem 2rem;
  /* padding: 1.4rem 0.8rem 1.4rem 2rem; */
  background: #f4f4f4;
}

.candidateTypeWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;
}

.candidateTypeText {
  font-family: 'Jost-500';
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  color: #393939;
  margin-bottom: 1.6rem;
  /* margin-top: 15px; */
}

@media screen and (max-width: 576px) {
  .CandidateWrapper {
    padding: 1.4rem 0.8rem 1.4rem 2rem;
  }

  .emptyWrapper {
    padding: 1rem 0.8rem 1.4rem 1rem;
  }

  .candidateTypeText {
    font-size: 14px;
    margin-bottom: 1.6rem;
  }
}
.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  margin-top: 29px;
  color: #393939;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.subtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  margin-top: 12px;
  line-height: 26px;
  color: rgba(57, 57, 57, 0.8);
}
.videoWrapper {
  height: 337px;
  background: #ffffff;
  border-radius: 12px;
  width: 90%;
  display: grid;
  /* justify-content: center; */
  /* align-items: center; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  filter: drop-shadow(0px 1px 24px rgba(98, 98, 98, 0.1));
  grid-template-rows: 28px auto;
  grid-row-gap: 5px;
  padding: 11px 8px 11px 11px;
}
.videoContainer {
}

.videoTitleContainer {
  display: grid;
  grid-template-columns: 28px auto;
  grid-column-gap: 8px;
  align-items: center;
}
.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-iframe {
  overflow: hidden;
  /* margin-top: 30px; */
  height: 285px;
  width: 100%;
  border-radius: 10px;
}
.connectionName {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}
.rightButtons {
  display: grid;
  grid-template-columns: 85px 85px;
  grid-column-gap: 5px;
  margin-right: 0;
  margin-left: auto;
}
.buttonsWrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  bottom: 0px !important;
  position: relative;
  justify-items: stretch !important;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.footerWrapper {
  height: 178px;
  margin-top: 16px;
}
.footer {
  background: #ffffff;
  box-shadow: 0px 1px 24px rgba(98, 98, 98, 0.1);
  border-radius: 12px;
  width: 90%;
  height: fit-content;
  padding: 16px 12.5px 18px 16px;
  margin-left: auto;
  margin-right: auto;
}
.footerTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #393939;
}
.footerTitleWrapper {
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
}
.footerText {
  font-family: 'Jost-300';
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

/* Switch */
.switchContainer {
  width: 42px;
  height: 22.5px;
  margin-right: 12px;
  margin-left: 12px;
  position: relative;
  display: flex;
  background: rgba(88, 88, 88, 0.2);
  border-radius: 11.25px;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
}
.active {
  background: #7458d3;
}
.switchButton {
  width: 19.5px;
  height: 19.5px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s linear;
}

.switchWrapper {
  display: flex;
  flex-direction: row;
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  align-items: center;
  margin-right: 0px;
  margin-left: auto;
}
.htmlText {
  color: rgba(88, 88, 88, 0.3);
}
.textText {
  color: #7458d3;
  margin-right: 12px;
}
.arrowImg {
  background-image: url('../Images/arrowRight.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  width: 18px !important;
  height: 18px !important;
}

@media screen and (max-width: 700px) {
  .subtitle {
    width: 100%;
    font-size: 16px;
    margin: 16px 0px 20px 0px;
    line-height: 26px;
    color: rgba(57, 57, 57, 0.8);
  }

  .buttonsWrapperMobile {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100vw;
    height: fit-content;
    gap: 10px;
    left: 0px;
    bottom: 0px;
    padding: 20px;
    box-shadow: 0px -1px 12px rgba(134, 134, 134, 0.15);
    background-color: #fff;
  }

  .footer {
    margin: 0px;
    width: 100%;
  }

  .footerTitle {
    font-size: 16px;
  }
  .footerTitleWrapper {
    margin-bottom: 0px;
  }
  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100vw;
    height: fit-content;
    gap: 10px;
    left: 0px;
    bottom: 0px;
    padding: 20px;
    box-shadow: 0px -1px 12px rgba(134, 134, 134, 0.15);
    background-color: #fff;
  }
}

.contentBody {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.contentBody h1 {
  color: black;
}

.row {
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  align-items: center;
  height: fit-content;
}

.row label {
  margin-bottom: 0;
  width: 15%;
}

.row input {
  margin-left: 2rem;
}

.privacy {
  width: 15%;
  min-width: 180px;
  margin-left: 1rem;
}

.btn {
  padding-left: 0.5rem;
  margin-left: 1rem;
  padding-right: 0.5rem;
}

.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.outerCard {
  max-width: 1000px;
  min-width: 676px;
  width: 45%;
  background: #ffffff;
  display: flex;
  padding: 6.4rem 12.8rem;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
  /* padding: 3.2rem; */
  /* width: 47%; */
  /* min-height: 534px; */
}
.loginCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 60%;
  height: 70%; */
}

.logo {
  margin-bottom: 3rem;
}

.textInputWrapper {
  margin-bottom: 0.2rem;
}

.forgotPasswordWrapper {
  margin-bottom: 2.4rem;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #31cbe0;
}

.forgotPasswordWrapper a {
  color: unset;
}

.forgotPasswordWrapper a:hover {
  color: unset;
}

.buttonWrapper {
  margin-bottom: 2.7rem;
}

.cardTitle {
  font-family: 'Jost-500';
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
  margin-bottom: 3.2rem;
}

.noAccount {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}
.signup {
  font-family: 'Jost-500';
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #7458d3;
  cursor: pointer;
}

/* @media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }

  .outerCard {
    width: 100%;
    flex-direction: column;
    max-width: none;
    min-width: 0;
    box-shadow: none;
    padding: 3rem;
    min-width: 375px;
  }
  .loginCard {
    width: 100%;
  }

} */

@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }
  .outerCard {
    width: 60%;
    min-width: 375px;
    padding: 3rem;
    box-shadow: none;
  }
  .cardTitle {
    font-size: 20px;
    line-height: 29px;
  }
  .forgotPasswordWrapper {
    font-size: 16px;
    line-height: 23px;
  }
  .noAccount {
    font-size: 16px;
    line-height: 23px;
  }
  .signup {
    font-size: 16px;
    line-height: 23px;
  }
}
@media screen and (min-width: 577px) and (max-width: 780px) {
  .outerCard {
    min-width: 575px;
  }
  .loginCard {
    min-width: 445px;
    max-width: 445px;
  }
}

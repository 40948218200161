.BodyWrapper {
  /* height: 107px;
    max-height: 107px; */
  background: rgba(244, 244, 244, 0.6);
  border-radius: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: 1s;
  cursor: pointer;
  font-family: 'Jost-400';
}

.questionDifficulty {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 8px;
}

.questionDifficulty::before {
  content: "-";
  margin-right: 5px;
}

.Header {
  height: 51px;
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  /* font-weight: 400; */
  font-size: 16px;
  line-height: 26px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid rgba(88, 88, 88, 0.05);
}

.HeaderRight {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  /* color: white; */
}

.HeaderLeft {
  /* margin-left: 20px; */
  /* color: white; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Body {
  max-height: 0px;
  /* height: 0px; */
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
  margin: 0px !important;
}

.Extended {
  max-height: 440px;
  /* height: max-content; */
  visibility: visible;
  opacity: 1;
  transition: 1s;
}

.Tags {
  height: 56px;
  margin-left: 20px;
  /* color: white; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  border-top: 1px solid rgba(88, 88, 88, 0.05);
  font-size: 13px;
  line-height: 19px;
}

.QuestionLabel {
  margin-left: 10px;
  background: #ffffff;
  border: 0.8px solid rgba(88, 88, 88, 0.8);
  border-radius: 8px;
  padding: 1px 10px;
}

.QuestionCorrectness {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  padding: 3px 12px 3px 8px;
  border-radius: 8px;
  border: 0.8px solid;
}

.GreenBackground {
  background: rgba(74, 195, 137, 0.1);
  border: 0.8px solid rgba(74, 195, 137, 0.1);
  color: #4ac389;
}

.RedBackground {
  background: rgba(221, 58, 58, 0.1);
  border: 0.8px solid rgba(221, 58, 58, 0.1);
  color: #de4747;
}

.YellowBackground {
  background: rgba(255, 214, 0, 0.1);
  border: 0.8px solid rgba(255, 214, 0, 0.1);
  color: #FFD600;
}

.GreyBackground {
  background: rgba(88, 88, 88, 0.2);
  border: 0.8px solid rgba(88, 88, 88, 0.1);
  color: #585858;
}

.NewOpWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NewOpInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NewOpInfoHeader {
  font-family: 'Jost-500';
  font-size: 2rem;
  line-height: 2.9rem;
  text-align: center;
  color: #393939;
  margin-bottom: 0.6rem;
}

.NewOpInfoSubtitle {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #393939;
  margin-top: 1rem;
}

.closeWindow {
  position: relative;
  display: flex;
  justify-content: center;
  font-family: 'Jost-400';
  margin-top: max(12rem, 160px);
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #393939;
}

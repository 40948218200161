.GoBackButtonContainer {
  float: left;
  margin-right: auto;
  border: none;
  background: transparent;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.GoBackButtonImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.GoBackButtonImage:hover {
  background-color: rgba(88, 88, 88, 0.1);
}

.GoBackButtonImage:active {
  background-color: rgba(88, 88, 88, 0.2);
}

.QuestionDetailsWrapper {
    font-family: Jost-300;
    font-size: 14px;
    width: 50%;
    padding: 5px;
    margin: 5px;
}

.FormInput {
    background: rgba(244, 244, 244, 0.6);
    border: 1px solid rgba(116, 88, 211, 0.6);
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 44px;
    padding: 10px 12px;
    /* font-family: 'News Cycle';
    font-style: normal;
    font-weight: 400;
    font-size: 18px; */
    letter-spacing: -0.02em;
    color: #585858;
}

.FormInput:focus {
    background: white;
    border: 1px solid rgba(116, 88, 211, 0.6);
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 44px;
    padding: 10px 12px;
}

.FormInput:not(:placeholder-shown) {
    border-color: rgba(88, 88, 88, 0.2) !important;
    background-color: #fff !important;
}
.FormInput:-internal-autofill-selected,
.FormInput::autofill {
    border-color: rgba(88, 88, 88, 0.2) !important;
    background-color: #fff !important;
}

.FormInput:-webkit-autofill,
.FormInput:-webkit-autofill:hover,
.FormInput:-webkit-autofill:focus,
.FormInput:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.IndividiualRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;
    height: 40px;
}

.NameWrapper {
    width: 35%;
    min-width: 170px;
}

.DropdownWrapper {
    width: 40%;
    height: 100%;
}

.RadioButtonWrapper {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.IndividualRadioButton {
    width: 30%;
    display: flex;
}
.emailContainer {
  min-height: fit-content;
  background: #ffffff;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}

.leftColumn {
  border-right: 1px solid rgba(57, 57, 57, 0.15);
  height: 100%;
  padding-right: 3.4rem;
  width: 45%;
  /* display: flex; */
  flex-direction: column;
}

.rightColumn {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 3.4rem;
  width: 55%;
}

.lowerColumn {
  min-height: 180px;
  max-height: 220px;
  display: flex;
  flex-direction: column;
}

.icon {
  display: block;
  max-width: 13rem;
  max-height: 4rem;
  margin-bottom: 6.5rem;
}

.centerText {
  margin-bottom: 4.2rem;
}

.iconColumn {
  display: flex;
  height: fit-content;
  max-height: fit-content;
}

.bottomText {
  margin-bottom: 4.2rem;
}

.assessmentTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
}

.emailTitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.3rem;
  margin-bottom: 1.2rem;
  color: #393939;
}

.powered {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.9rem;
  display: flex;
  margin-right: 0.8rem;
  align-items: center;
  color: #585858;
}

.text {
  font-family: 'Jost-400';
  font-style: normal;
  /* display: flex; */
  height: fit-content;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}

.rightIcon {
  display: none;
  max-width: 15.25rem;
  max-height: 3.75rem;
  margin-bottom: 4.4rem;
}

.coensio {
  margin-left: 0.8rem;
}

.heading {
  display: none;
}

.ImageWrapper {
  display: none;
  flex-direction: row;
  justify-content: space-between;
}

.coensioLogo {
  display: none;
}

@media screen and (max-width: 576px) {
  .leftColumn {
    width: 100%;
    border-right: none;
    padding-bottom: 2.5rem;
    padding-top: 2rem;
    order: 2;
  }

  .rightColumn {
    order: 1;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(57, 57, 57, 0.15);
    padding-left: 0;
    width: 100%;
  }

  .text {
    font-size: 2rem;
  }

  .emailTitle {
    font-size: 2rem;
  }

  .assessmentTitle {
    font-size: 2.25rem;
  }

  .icon {
    display: none;
  }

  .rightIcon {
    display: block;
    flex-shrink: 0;
    margin-bottom: 5.5rem;
  }

  .lowerColumn {
    min-height: unset;
    max-height: 220px;
    display: flex;
    flex-direction: column;
  }

  .heading {
    display: flex;
    font-family: Jost-500;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 2rem;
    color: #393939;
  }

  .ImageWrapper {
    display: flex;
  }

  .coensioLogo {
    display: flex;
    width: 30px;
    height: 30px;
  }
}

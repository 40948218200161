.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  margin-top: 29px;
  color: #393939;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.subtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  margin-top: 14px;
  line-height: 26px;
  color: rgba(57, 57, 57, 0.8);
  margin-bottom: 32px;
}

.okayImg {
  background-image: url('../Images/okayHandIcon.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  margin-right: 9px;
}

.purpleText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  color: #7458d3;
}

.purpleTextWrapper {
  margin-top: 14px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  margin-right: 46px;
  margin-left: 25px;
  padding-left: 23px;
  align-items: center;
}

.buttonsWrapper {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  bottom: 0px !important;
  position: relative;
  justify-items: stretch;
  width: 89%;
}

.container {
  /* height: 100%; */
  /* display:flex; */
  /* flex-direction: column; */
  display: grid;
  grid-template-rows: auto 42px;
}

@media screen and (max-width: 700px) {
  .subtitle {
    font-family: 'Jost-300';
    font-size: 14px;
    line-height: 20px;
    color: rgba(57, 57, 57, 0.8);
    margin: 16px 0px;
  }

  .buttonsWrapper {
    /* margin-left: -20px; */
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100vw;
    height: fit-content;
    gap: 10px;
    left: 0px;
    bottom: 0px;
    padding: 20px;
    box-shadow: 0px -1px 12px rgba(134, 134, 134, 0.15);
    background-color: #fff;
  }
}

.contentWrapper {
  min-width: 550px;
  max-width: 650px;
  display: flex;
  width: 60%;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  /* margin-bottom: 24px; */
  line-height: 35px;
  color: #393939;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 14px 14px 26px;
  gap: 8px;
}

.profileIcon {
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(116, 88, 211, 0.2);
  border-radius: 50%;
  font-family: 'Jost-400';
  font-size: 20px;
  margin: 18px auto;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #7458d3;
}


.iconWrapper {
  position: absolute;
  top: 14px;
  right: 14px;
}

.name {
  font-family: 'Jost-400';
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: rgba(57, 57, 57, 0.84);
}

.email {
  font-family: 'Jost-400';
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: rgba(57, 57, 57, 0.84);
}

.logout {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #DE4747;
}

.row {
  display: flex;
  /* margin-top: 16px; */
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
  padding: 4px 10px;
  border-radius: 10px;
}

.editIcon {
  visibility: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.wrapper:hover .editIcon {
  visibility: visible;
}

@media screen and (max-width: 800px) {
  .contentWrapper {
    width: 100%;
    max-width: none;
    min-width: 0;
  }
}

@media screen and (max-width: 576px) {
  .contentWrapper {
    padding: 0px 0px 24px;
  }

  .title {
    padding: 0px 12px;
    /* margin-bottom: 12px; */
  }

  .row {
    /* margin-top: 12px; */
  }

  .editIcon {
    visibility: visible;
  }
}

@media screen and (hover: hover) {
  .row:hover {
    background-color: #f4f4f4;
  }
  
  .editIcon:hover {
    background-color: rgba(116, 88, 211, 0.1);
  }
}
.headText {
  font-family: Jost-400;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: #585858;
}

.belowInfoText {
  color: #585858;
  font-family: Jost-300;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.hourglass {
  width: 42px;
  height: 48px;
}

.infoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.closeButton {
  position: absolute;
  top: 22px;
  right: 32px;
}

@media screen and (max-width: 576px) {
  .closeButton {
    top: 20px;
    right: 20px;
  }
}
.mainWrapper {
  width: 90%;
  max-width: 1100px;
  min-width: 750px;
  display: flex;
  justify-content: center;
  color: #585858;
}
.wrapper {
  width: 100%;
  /* margin-top:42px; */
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  height: 220px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.LeftInnerWrapper {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
}

.CircularWrapper {
  /* margin-top: 2px; */
  /* width: 13%; */
  /* width: 40%;
  min-width: 110px; */
  width: 133px;
}

.TableWrapper {
  width: 149px;
}

.unlock {
  position: absolute;
  z-index: 2;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.rightWrapper {
  width: 60%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

.right {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* overflow-y: auto; */
  /* pointer-events: none; */
  /* width: 535px; */
  height: 134px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  overflow-y: hidden;
}
.right:global(::-webkit-scrollbar-thumb) {
  background-color: transparent;
}
.rightJustify {
  justify-content: space-around;
}

.rightRow {
  display: flex;
  width: 100%;
  min-height: 23px;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 14px;
  font-family: 'Jost-400';
  color: #585858;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
}
.labelText {
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 200px; */
  min-width: 150px;
  /* max-width: 150px; */
  /* width: 30%; */
  width: calc((100% - 30px) * 0.4);
  display: inline-block;
  /* margin-right: 70px; */
  cursor: default !important;
}

.percantage {
  margin-left: 16px;
  display: flex;
  /* min-width: 25px;
  width: 5%; */
  flex-direction: row-reverse;
  align-items: flex-end;
}

.percantageWrapper {
  margin-left: 16px;
  display: flex;
  width: 30px;
  /* flex-direction: row; */
  /* align-items: flex-end; */
  justify-content: end;
}

.scrollButton {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  margin: 20px;
  /* background: linear-gradient(270deg, rgba(244, 244, 244, 0.3) -4.37%, #F4F4F4 49.66%, rgba(244, 244, 244, 0.3) 107.17%); */
}

.borderTop {
  border-top: 1px solid rgba(252, 248, 248, 1);
}

.borderBottom {
  border-bottom: 1px solid rgba(252, 248, 248, 1);
}

.button {
  cursor: pointer !important;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  background: #ffffff;
  margin-left: 50%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.button:hover {
  background: #585858;
}

.button:hover img {
  filter: invert(100%) sepia(0%) saturate(7496%) hue-rotate(143deg) brightness(10002%)
    contrast(101%);
}

.MainWrapper {
  display: flex;
  flex-direction: row;
}

.DropdownWrapper {
  /* width: 90px; */
}

.PhoneWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 1.8rem;
  background: rgba(244, 244, 244, 0.4);
  border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 10px;
  padding-left: 1.6rem;
  font-family: Jost-400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: rgba(57, 57, 57, 0.6);
}
.PhoneWrapper::before {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 1.8rem;
  background: rgba(244, 244, 244, 0.4);
  border: 2px solid rgba(57, 57, 57, 0.15);
  border-radius: 10px;
  padding-left: 1.6rem;
  font-family: Jost-400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: rgba(57, 57, 57, 0.6);
}

.basePlaceholder {
  transition: all 0.2s;
  pointer-events: none;
}

.PhoneInputField {
  margin-right: 5px;
  width: 100%;
  border: none;
  padding-top: 1rem;
  padding: 2rem 1.2rem 0.5rem 0rem;
  background: rgba(244, 244, 244, 0.4);
}

.PhoneInputField ~ .placeholder {
  font-size: 1rem;
  position: absolute;
  line-height: 1.4rem;
  padding-top: 0.5rem;
}

.PhoneInputField:placeholder-shown ~ .placeholder {
  position: absolute;
  font-size: 1.6rem;
  line-height: 2.3rem;
  transform: translateY(0.8rem);
}

.PhoneWrapper:has(.PhoneInputField:not(:placeholder-shown)) {
  background: white;
}

.PhoneInputField:not(:placeholder-shown) {
  background: white;
  color: #393939;
}

.PhoneWrapper:has(.PhoneInputField:focus) {
  background: white;
}

.PhoneInputField:focus {
  background: white;
}

.PhoneInputField:hover ~ .placeholder {
  color: rgba(116, 88, 211, 0.6);
}

.PhoneWrapper:has(.PhoneInputField:hover) {
  border-color: rgba(116, 88, 211, 0.2);
}

.PhoneInputField:focus ~ .placeholder {
  color: #7458d3;
  font-size: 1rem;
  position: absolute;
  line-height: 1.4rem;
  transform: none;
  /* padding-top: 0.5rem; */
}

.PhoneWrapper:has(.PhoneInputField:focus) {
  border-color: #7458d3;
}


.Invalid:hover ~ .placeholder, .Invalid:focus ~ .placeholder, .Invalid ~ .placeholder {
  color: #DE4747;
}

.PhoneWrapper:has(.Invalid:hover), .PhoneWrapper:has(.Invalid:focus), .PhoneWrapper:has(.Invalid) {
  border-color: #DE4747;
}

.CountryCodeWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.CountryCodeInput {
  border: none;
}

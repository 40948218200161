.container {
  display: flex !important;
  flex-direction: column !important;
  background-color: #fafafa;
  height: 100vh;
}

.onboardingElement {
  color: rgba(116, 88, 211, 0.8);
  background: #f1eefb;
  border-radius: 24px;
  height: 7.5rem;
  justify-content: center;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
}

.onboardingText {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: auto;
  margin-bottom: auto;
  justify-self: center;
  align-self: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.arrowStyle {
  margin-right: auto;
  margin-left: auto;
  margin-top: -5rem;
  height: 7rem;
  width: 7rem;
}

.buttonStyle {
  right: 20px;
  top: 100px;
}

.middleDiv {
  position: absolute;
  right: 300px;
  top: 250px;
}

.vectorStyle {
  margin-left: 38%;
  margin-top: -10%;
  width: 60rem;
  height: 35rem;
}
.myButton:hover {
  background: rgba(116, 88, 211, 0.8);
}
.myButton:hover .buttonText {
  color: #f1eefb;
}
.myButton:hover .iconWrapper path {
  fill: #f1eefb !important;
}
.myButton {
  background: #f1eefb;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 24px 0px 14px;
}
.buttonText {
  color: #7458d3;
  font-family: 'Jost-400';
  font-style: normal;
}
.iconWrapper {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin-right: 8px;
  margin-top: 2px;
}

.imgWrapper {
}

.xarrow path {
  stroke-dasharray: 7 4 !important;
}
/* 

body:has(.container) {
  overflow-y: hidden;
} */

@media screen and (max-width: 576px) {
  .onboardingText {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #7458d3;
  }
  .onboardingElement {
    background: #ffffff;
    box-shadow: 0px -2px 24px rgba(134, 134, 134, 0.15);
    border-radius: 10px 10px 0px 0px;
    width: 100vw;
    height: 70px;
  }
  .myButtonMobile {
    padding: 0;
    background: #ffffff;
    border: 1px solid #7458d3;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    top: 14px;
    position: relative;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  background-color: white;
  justify-content: center;
  min-width: 1200px;
  overflow-y: hidden;
  height: 100vh;
}

.leftColumn {
  flex-grow: 3;
  /* min-width: 432px; */
  padding-top: 30px;
  flex-basis: 30%;
  height: 100%;
  min-width: 368.5px;
}

.rightColumn {
  flex-grow: 7;
  height: 100%;
  padding-top: 15px;
  flex-basis: 70%;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 825px;
  overflow-y: auto;
}

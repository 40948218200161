.container {
    max-width: fit-content !important;
    max-height: fit-content !important;
    padding: 0px !important;
  }

  .modal-content {
    /* width: 694px; */
    max-width: 100vw;
    /* height: 456px !important; */
  }
  
  .modalContent {
    border: none;
    padding: 24px 32px !important ;
    width: 694px !important;
    max-width: 694px;
    height: 456px !important;
  }

  .ModalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: Jost-400;
    font-size: 24px;
  }

  .Button {
    border: none;
    border-radius: 10px;
    background-color: rgba(116, 88, 211, 0.4);
    /* margin: 100px 0px 0px 0px; */
    position: absolute;
    top: 380px;
    font-family: Jost-300;
    font-size: 18px;
    padding: 5px 10px;
    cursor: pointer;
  }
.container {
  height: 100%;
  border-right: 1px solid rgba(88, 88, 88, 0.2);
  width: calc(100%);
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: 12px;
  padding-bottom: 13rem;
}

.container:hover {
  overflow-y: scroll;
  padding-right: 0px !important;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  position: fixed;
  position: -webkit-sticky;
  bottom: 2%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 25%,
    #ffffff 74.55%,
    rgba(255, 255, 255, 0) 100%
  );
  height:10rem;
  margin-top: 20px;
  margin-left: 15px;
}

.header {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 10px;
}

.headerBorder {
  border-bottom: 1px solid #f4f4f4;
  width: calc(100% - 12px);
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  margin-right: 5px;
}

.skillContainer {
  transition: 0.2s linear;
  overflow: hidden;
}

.showMore {
  font-family: 'Jost-400';
  white-space: nowrap;
  color: #7458d3;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  width: 279px;
  margin-left: auto;
  padding-left: 20px;
  margin-right: auto;
  font-size: 16px;
  line-height: 17px;
  text-decoration: underline;
  cursor: pointer;
}

.showMore:active {
  opacity: 0.8;
}

.dropDownQuestions {
  text-transform: uppercase;
  font-family: 'Jost-400';
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(88, 88, 88, 0.6);
}

.questionRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 31px;
}

.question {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 15px;
  line-height: 23px;
  color: #585858;
  cursor: pointer;
}

.questionDropdown {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.skillDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  justify-content: space-between;
}

.search {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.skillDropdown:hover {
  cursor: pointer;
}

.QuestionIcon {
  transition: 0.2s linear;
  width: 26px;
  height: 26px;
  /* margin-left: 149px; */
  margin-right: 5px;
  flex-shrink: 0;
  stroke: rgba(88, 88, 88, 0.6);
}
.tooltip {
  margin-top: 50px;
}

.icon {
  transition: 0.2s linear;
  margin-left: 203px;
  width: 26px;
  flex-shrink: 0;
  height: 26px;
  margin-right: 5px;
  stroke: rgba(88, 88, 88, 0.6);
}

.extented {
  transform: rotate(90deg);
}

.dropDownSkills {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(88, 88, 88, 0.6);
}

.headerTitle {
  font-family: 'Jost-400';
  font-style: normal;
  width: calc(100% - 12px);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(88, 88, 88, 0.6);
}

.title {
  display: flex;
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}

.reset {
  font-family: 'Jost-400';
  font-style: normal;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: rgba(222, 71, 71, 0.8);
}

.reset:hover {
  text-decoration: underline;
  cursor: pointer;
}

.rightColumn {
}

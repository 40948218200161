.QuestionWrapper {
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.InfoRow {
  display: flex;
  flex-direction: row;

  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;

  align-items: center;
  color: #393939;
  padding: 0px 2px;
}

.headerTextFlexGrow {
  flex-grow: 1;
}

.label {
  min-width: 85px;
  width: 85px;
  display: flex;
  justify-content: end;
}

.noWrapper {
  width: 44px;
}

.tabSwitch {
  color: #ca1515;
}

.QuestionRow {
  background: #fafafa;
  border-radius: 8px;
  padding: 14px 18px;
}

.QuestionLabelRow {
  font-family: 'Jost-400';
  font-size: 13px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: rgba(57, 57, 57, 0.6);
  gap: 12px;
}

.QuestionCorrectness {
    border-radius: 6px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-left: auto;

    font-family: 'Jost-500';
font-size: 13px;
line-height: 19px;
text-transform: uppercase;

}

.GreenBackground {
    color: #4AC389;
    background: rgba(74, 195, 137, 0.1);
}

.YellowBackground {
    color: #FFB800;
    background: rgba(255, 184, 0, 0.1);
}

.RedBackground {
    color: #CA1515;
    background: rgba(202, 21, 21, 0.1);
}

.GreyBackground {
  color: #585858;
  background: rgba(88, 88, 88, 0.2);
}
.contentBody {
  /* background-color: #fff; */
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  height: calc(100vh - 80px); /* changed with header */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pageWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.filterColumn {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-basis: 20%;
  height: calc(100vh - 80px); /* changed with header */
  /* height: calc(95vh - 80px); */
  background-color: #fff;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 319px;
}

.middleColumn {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-basis: 60%;
  height: calc(100vh - 80px); /* changed with header */
  background-color: #fff;
  flex-shrink: 1;
  flex-grow: 0;
  background: rgba(244, 244, 244, 0.6);
  /* width: 60vw; */
  /* max-width: 60vw;
    min-width: 50vw; */
}

.rightColumn {
  display: flex;
  min-width: 450px;
  position: relative;
  flex-basis: 30%;
  flex-direction: column;
  height: calc(100vh - 80px); /* changed with header */
  background-color: #fff;
  flex-shrink: 1;
  flex-grow: 1;
}

.WelcomeCandidatePageWrapper {
    display: flex;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 576px) {
    html:has(.WelcomeCandidatePageWrapper) {
        font-size: 8px;
    }
}
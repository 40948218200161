.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.InnerCard {
  max-width: 1000px;
  min-width: 576px;
  background: #ffffff;
  display: flex;
  width: 60%;
  flex-direction: row;
  padding: 3.2rem;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}

.Left {
  width: 45%;
  padding: 0rem 3.4rem 1.2rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid rgba(57, 57, 57, 0.15);
}

.Right {
  width: 55%;
  padding: 0.8rem 0rem 1rem 3.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.signup {
  width: 100%;
  /* height: 75%; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.button {
  background: #7458d3;
  border-radius: 1rem;
  text-align: center;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.6rem;
}
.login {
  display: flex;
  flex-direction: row;
}
.email {
  width: 100%;
}
.loginText {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #7458d3;
  cursor: pointer;
}

.catchPhrase {
  margin-top: 2.4rem;
  display: flex;
}
.text {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;

  color: #393939;
}
.trialText {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;

  margin-bottom: 3rem;
}
.tick {
  width: 2rem;
  height: 2rem;
}

.error {
  position: absolute;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #de4747;
  margin-top: 4.8rem;
  margin-left: 0.5rem;
}

.catchphraseText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  margin-left: 1.2rem;
}

@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: space-between;
  }

  .CardWrapper {
    justify-content: flex-start;
    padding: 2.4rem;
    padding-bottom: 0;
    height: 100%;
  }
  .InnerCard {
    width: 100%;
    flex-direction: column;
    max-width: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    box-shadow: none;
  }
  .Left {
    width: 100%;
    min-height: 220px;
    border-right: none;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(57, 57, 57, 0.15);
  }
  .Right {
    width: 100%;
    min-height: 200px;
    padding: 0;
  }

  .logo {
    min-height: 45px;
    min-width: 119px;
    margin-left: 0;
  }
  .signup {
    width: 100%;
    margin-top: 2rem;
  }

  .tick {
    width: 20px;
    height: 20px;
  }

  .trialText {
    font-size: 20px;
    line-height: 29px;
  }

  .text {
    font-size: 16px;
    line-height: 23px;
  }

  .loginText {
    font-size: 16px;
    line-height: 23px;
  }

  .catchphraseText {
    font-size: 16px;
    line-height: 23px;
  }
}

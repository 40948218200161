.modalContent {
  height: 47rem !important;
  padding: 20px 30px 30px 30px;
}

.modalDialog {
  min-width: 750px !important;
  max-width: 50vw !important;
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header {
  font-family: 'Jost-400';
  font-size: 2rem;
  color: #393939;
}

.shortText {
  font-family: 'Jost-300';
  font-size: 1.6rem;
  color: #393939;
}

.purpleText {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  color: rgba(116, 88, 211, 1);
}

.normalText {
  font-family: 'Jost-300';
  font-size: 1.6rem;
  color: rgba(57, 57, 57, 0.8);
}
.footerRow {
  margin-top: auto;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.CopyButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.95rem 1.4rem;
  gap: 4px;
  cursor: pointer;
}

.CopyButtonWrapper:hover {
  background: #f1eefb;
  border-radius: 10px;
}

.copyText {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #7458d3;
}

/* MOBILE IBE Styling */

.mobileHeader {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
  margin-left: 10px;
}
.mobileHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.inviteRow {
  margin-bottom: 24px;
}
/* MOBILE IBE Styling */

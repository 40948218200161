.header {
  font-family: 'Jost-300';
  font-size: 1.8rem;
  line-height: 26px;
  text-align: center;
  color: #393939;
  margin-bottom: 16px;
}

.headsup {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  line-height: 35px;
  text-align: center;
  color: #393939;
  margin-bottom: 10px;
}

.modalContent {
  width: 580px !important;
}
.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
}

/* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .modalContent {
    width: fit-content !important;
    top: 20px;
  }
}
/* Temporary mobile solution */

.container {
  min-width: 720px;
  /* display:flex;
  flex-direction: column; */
}

.modal-content {
  padding: 20px 32px 24px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-items: space-between;
}
.header {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  display: flex;
  color: #7458d3;
}

.subHeader {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  color: #585858;
}

.partWrapper {
  padding-top: 24px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  font-family: 'Jost-400' !important;
}
.partHeader {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  color: #585858;
  margin-bottom: 12px;
}
.doubleInputContainer {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.inputWrapper {
  background: rgba(244, 244, 244, 0.4);
  border: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 10px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.wrapper {
}

/* MEDIA QUERIES */
@media screen and (min-width: 1600px) {
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
}
@media screen and (min-width: 768px) and (max-width: 992px) {
}

/* NEW */
@media screen and (min-width: 576px) and (max-width: 768px) {
}
@media screen and (max-width: 576px) {
  .partWrapper {
    gap: 12px;
  }
  .partHeader {
    margin-bottom: 0;
  }
}

body {
  background-color: #fff !important;
}

.QuestionWrapperPane {
  background-color: white;
  height: calc(100vh - 100px);
  border-radius: 24px 24px 0 0;
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
}

.QuestionHeaderRow {
  display: flex;
  flex-direction: row;
  height: 35px;
}
.QuestionHeader {
  text-align: left;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.QuestionPane {
  position: relative;
  grid-row-start: 2;
  background-color: #eee !important;
  border: 1px solid grey;
  display: flex;
  flex-direction: row;
  /* height: 88%; */
  height: calc(100vh - 185px);
  overflow: hidden;
}
.LeftPane {
  display: flex;
    flex-direction: column;
    height: calc(100vh - 185px);
    /* margin: 5px; */
    /* margin-bottom: 10px; */
    padding: 5px 5px 10px 5px;
  width: 50%;
}

.toolbarBorder {
  border-right: 2px solid rgba(190, 190, 190, 0.4);
  border-left: 2px solid rgba(190, 190, 190, 0.4);
  height: 100%;
}

.RightPane {
  position: relative;
  margin: 5px;
  margin-bottom: 10px;
  height: 100%;
  font-family: Jost-300;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.nav-question {
  background-color: rgba(114, 71, 170, 0.884);
  height: 50px;
  /* height: 8%; */
  display: grid;
  grid-template-columns: 10fr 1fr 1fr 10fr;
}

.questionNavButton {
  width: 50px;
  height: 30px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

/*  Text editor related stuff */

.height30px {
  height: 30px;
  min-height: 30px;
    font-family: Jost-400;
    font-size: 1.6rem;
}

.TextEditorQuestionText {
  position: relative !important;
  height: calc(100vh - 215px);
  /* width: 90% !important; */
  /* max-height: inherit !important; */
  /* grid-row-start: 2 !important;
  grid-row-end: 3 !important;
  margin-bottom: 100px; */
}

.TextEditorQuestionText > .TextEditor > .wrapper > .toolbar {
  border: 2px solid #ccc;
  /* height: min-content; */
  background-color: rgba(116, 88, 211, 0.4) !important;
  border-radius: 8px !important;
  margin: 0 !important;
}

.TextEditorQuestionText > .TextEditor > .wrapper {
  height: 100% !important;
  /* background-color: red; */
}

.TextEditorQuestionText > .TextEditor > .wrapper > .editor {
  position: absolute;
  width: 100%;
  height: 90% !important;
  background-color: white;
  padding: 1rem;
  border: 1px solid rgba(116, 88, 211, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 100% !important;
  max-height: 100% !important;
}

/*-------------------------------------*/


/* Sql Signature */

.SignatureWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.InitialDatabaseWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    height: 75%;
}

.InitialDatabaseDropdownWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 10px 0px;
    width: 100%;
}

.InitialDatabaseDropdown {
    margin-left: 20px;
    width: 200px;
}

.HorizontalDivider {
    height: 1px;
    margin: 10px 0px;
    border: 1px solid grey;
}

.InitialDatabaseEditorWrapper {
    height: 100%;
}



.InitialCodeWrapper {
    margin: 5px 0px 10px 0px;
    height: 25%;
    border-top: 2px solid grey;
    display: flex;
    flex-direction: column;
}

/*-------------------------------------*/


/* Sql Test Cases */

.CasesWrapper {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 10px 5px;
    height: 100%;
    overflow-y: auto;
}

.TestCases {
    margin-bottom: 50px;
}

.CasesWrapper p {
    margin-bottom: 0px;
}

.CaseWrapper {
    padding: 10px 0px;
}

.CaseHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border: 1px solid grey;
    border-radius: 5px;
    padding: 2px 5px;
    /* margin: 5px 0px; */
    width: 100%;
    font-size: 16px;
}

.ExpandedWrapper {
    display: flex;
    flex-direction: column;
}

.CaseSetup {
    display: flex;
    flex-direction: column;
    height: 200px;
}

.CaseCheckingQuery {
    display: flex;
    flex-direction: column;
    height: 200px;
}

.CaseResult {
    display: flex;
    flex-direction: column;
    /* height: 200px; */
}

.Weight {
    margin: 5px 0px;
}

.CaseWeightInputField {
    margin-left: 20px;
}

.CheckingEditor {
    border-radius: 10px;
}

.TableLayout {

}

.TableLayout table, td {
  border: 1px solid black;
}

.TableLayout td {
  width: 100px;
  height: 30px;
  /* padding: 4px; */
}

.AddButtons {
  cursor: pointer;
  color:rgb(89, 89, 247);
  display: inline-block;
  width: 120px;
}

.AddButtons:hover {
  text-decoration: underline;
}

.RemoveButtons {
  cursor: pointer;
  color:rgb(240, 118, 118);
  display: inline-block;
  width: 120px;
}

.RemoveButtons:hover {
  text-decoration: underline;
}

/*-------------------------------------*/

/* Sql Verification Code */

.SqlVerificationCodeWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SqlVerificationCodeInfo {
  height: 6%;
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
}

.SqlVerificationCodeCompilerWrapper {
  display: flex;
  flex-direction: column;
  height: 94%;
}

.SqlVerificationCodeCompiler {
  height: 75%;
}

.SqlVerificationCodeTestOutput {
  height: 23%;
  display: flex;
  flex-direction: column;
}

.SqlVerificationCodeTestOutputHeader{
  height: 25%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  background-color: rgb(20, 62, 83);
  align-items: center;
}

.SqlVerificationCodeTestOutputResult{
  height: 75%;
  background-color: rgb(14, 44, 59);
  overflow-y: scroll;
}

/*-------------------------------------*/
.BarWrapper {
  background: #7458d3;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  padding: 10px 18px;

  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;

  align-items: center;
}

.headerTextFlexGrow {
  flex-grow: 1;
}

.label {
    width: 85px;
    display: flex;
    justify-content: end;
}

.noWrapper {
    width: 44px;
}
/* DEFAULT VALUES */

.CircularWrapper :global(.CircularProgressbar) {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
  width: 100%;
}

.CircularWrapper {
  display: flex;
  flex-direction: row;
}
.CircularWrapper :global(.CircularProgressbar .CircularProgressbar-path) {
  stroke:rgba(74, 195, 137, 0.8);
  stroke-linecap: butt;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularWrapper :global(.CircularProgressbar .CircularProgressbar-trail) {
  stroke:#F4F4F4;
}

.CircularWrapper :global(.CircularProgressbar .CircularProgressbar-text) {
  fill: rgba(74, 195, 137, 0.8);
  dominant-baseline: middle;
  text-anchor: middle;
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  transform: translate(2px, 3px);

}

.CircularWrapper :global(.CircularProgressbar .CircularProgressbar-background) {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
 .CircularWrapper :global(.CircularProgressbar.CircularProgressbar-inverted
.CircularProgressbar-background) {
  fill: white;
}

.CircularWrapper :global(.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text) {
  fill: #fff;
}

.CircularWrapper :global(.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path) {
  stroke: #fff;
}

.CircularWrapper :global(.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail) {
  stroke:rgba(74, 195, 137, 0.8);
}



/* RED */
.red :global(.CircularProgressbar .CircularProgressbar-path) {
  stroke: #FF7E62;
}

.red :global(.CircularProgressbar .CircularProgressbar-text) {
  fill: #FF7E62;
}

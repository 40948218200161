.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}
.loadingWrapper {
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
.failWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}
.close {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: flex-end;
}
.close:hover {
  cursor: pointer;
}
.text {
  font-family: 'Jost-300';
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 0px;
  color: rgb(88, 88, 88);
}
.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: rgb(88, 88, 88);
  margin-bottom: 10px;
}
.failText {
  font-family: 'Jost-300';
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-top: 15px;
  color: #585858;
}

.subText {
  margin-top: 25px;
  font-family: 'Jost-400';
  font-size: 20px;
  color: #393939;
}

.icon {
  width: 42px;
  height: 42px;
}

.success {
  margin-top: 8px;
  margin-bottom: 15px;
}

.info {
  margin-bottom: 20px;
}

.divider {
  height: 1px;
  width: 90%;
  background-color: rgba(88, 88, 88, 0.1);
  margin-top: 28px;
  margin-bottom: 24px;
}

.purple {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  font-size: 18px;
  line-height: 26px;
  margin-top: 24px;
  color: #7458d3;
  text-align: center;
}

.purple:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .modalContent {
    padding: 0px;
  }

  .mobileHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 24px;
  }

  .icon {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
  }

  .success {
    margin: 0px 12px 0px 0px;
  }

  .title {
    font-size: 16px;
    line-height: 23px;
    color: #585858;
    margin-bottom: 0px;
  }

  .close {
    display: flex;
    width: unset;
    justify-content: end;
    align-items: flex-end;
    margin-left: auto;
  }

  .text {
    text-align: left;
    color: #585858;
  }

  .divider {
    width: 100%;
    background-color: 1px solid rgba(57, 57, 57, 0.1);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .info {
    margin: 0px 12px 0px 0px;
  }

  .purple {
    font-family: 'Jost-400';
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    line-height: 23px;
    margin-top: 0px;
    color: #7458d3;
    text-align: unset;
    text-decoration: underline;
    cursor: pointer;
  }
  
}

.allCandidatesWrapper {
  overflow-y: auto;
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.container {
  overflow: hidden;
  /* min-width: 700px; */
}

.col1 {
  display: flex;
  order: 1;
  flex-basis: 20%;
  min-width: 216px;
  max-width: 264px;
}

.CandidateMainWrapper {
  display: flex;
  order: 2;
  flex-basis: 50%;
  overflow-x: auto;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
}

body:has(.CandidateMainWrapper) :global(#hubspot-messages-iframe-container) {
  display: none !important;
}

body:has(.CandidateMainWrapper) {
  overflow-y: hidden;
}

/* Media Queries */

@media screen and (min-width: 1920px) {
  .col1 {
    min-width: 332px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
}
@media screen and (min-width: 960px) and (max-width: 1280px) {
  .col1 {
    min-width: 280px;
  }
}
@media screen and (min-width: 720px) and (max-width: 960px) {
  .col1 {
    min-width: 280px;
  }
}
@media screen and (max-width: 720px) {
  .col1 {
    min-width: 280px;
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    display: unset;
  }

  body:has(.CandidateMainWrapper) {
    overflow-y: auto;
  }
}

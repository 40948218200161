.container {
  position: absolute;
  background: #ffffff;
  border: 0.5px solid rgba(57, 57, 57, 0.1);
  border-radius: 16px;
  z-index: 10010;
  width: 290px;
  padding: 2rem 1.6rem;
  transition: 0.2s linear;
  /* causing problem in old versions of Safari */
  min-height: 250px;
}

.borderBottom {
  padding-bottom: 1.6rem;
  border-bottom: 1px solid #d9d9d9;
}

.container {
  height: 48px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 23px;
  color: #393939;
  transition: height 0.2s linear 0.6s;
}

.deleteContainer {
  position: relative;
  width: 10px;
  height: 13px;
  margin-right: 20px;
  margin-left: 10px;
  padding-left: 10px;
  background-color: #333;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: transform 0.5s;
  cursor: pointer;
  opacity: 0;
}

.questionDelete {
  margin-right: 0px;
  margin-left: 0px;
}

.deleteContainer::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 1px;
  background-color: #333;
  top: -2px;
  left: -1px;
  box-shadow: -8px -1px 0 0 white, 8px -1px 0 0 white, 0px -2px 0 0 white, 0px -1px 0 0 #333333;
  transition: transform 0.5s;
}

.deleteContainer:hover {
  transform: translateY(0px);
}

.deleteContainer:hover::before {
  transform: translateY(-10px);
}

.container:not(.extented):hover {
  background-color: #f4f4f4;
}

.container:not(.extented):hover .deleteContainer::before {
  box-shadow: -8px -1px 0 0 #f4f4f4, 8px -1px 0 0 #f4f4f4, 0px -2px 0 0 #f4f4f4,
    0px -1px 0 0 #333333;
}

.container:not(.extented):hover .deleteContainer {
  display: inline-block;
  opacity: 1;
}

.container:not(.extented):hover .dropdown {
  background: #ffffff;
}

.container:not(.extented):hover #points {
  background: #ffffff;
}

#points {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 12px;
  padding-left: 12px;
  width: 40px;
  display: flex;
  justify-content: center;
}

.container.extented {
  color: rgba(88, 88, 88, 0.1);
}

.container.extented #points,
.container.extented .dropdown {
  background-color: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.1);
  border-radius: 8px;
  color: rgba(88, 88, 88, 0.1);
}

.innerContainer {
  max-height: fit-content;
  width: 100%;
  padding-left: 6%;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  margin-top: 8px;
  transition: margin-top 0.2s linear 0.4s, margin-bottom 0.2s linear 0.4s;
}

.extentedInner {
  margin-top: 0px;
  margin-bottom: 59px;
  transition: margin-top 0.2s linear, margin-bottom 0.2s linear;
}

.nestedTable {
  overflow: clip;
  width: 34px;
  margin-left: -10px;
  max-width: 250px;
  display: flex;
  height: 30px;
  flex-direction: row;
  position: absolute;
  outline: 1px solid rgba(88, 88, 88, 0.1);
  box-shadow: 0px 2px 24px -4px rgba(124, 124, 124, 0.15);
  border-radius: 8px;
  /*animation: change-height 0.6s ease-out 1 forwards;*/
  transition: top 0.2s linear, transform 0.22s linear 0.2s, width 0.2s linear 0.2s,
    opacity 0.2s linear;
}

@keyframes change-height {
  0% {
    width: 50px;
    transform: translate(0px, 0px);
    opacity: 0;
  }
  50% {
    width: 50px;
    transform: translate(0px, 50px);
    opacity: 1;
  }
  100% {
    width: 250px;
    opacity: 1;
    transform: translate(-205px, 50px);
  }
}

@keyframes change-height2 {
  0% {
    width: 250px;
    opacity: 1;
    transform: translate(-250px, 50px);
  }
  50% {
    width: 50px;
    transform: translate(0px, 50px);
    opacity: 1;
  }
  100% {
    width: 50px;
    transform: translate(0px, 0px);
    opacity: 0;
  }
}

.innerAnimation {
  display: flex;
  flex-direction: row;
  color: #585858;
  position: absolute;
  transition: left 0s linear 0.2s;
}

.weightbar {
  overflow: clip;
  transition: top 0.2s linear 0.4s, transform 0.2s linear, width 0.2s linear,
    opacity 0.2s linear 0.6s;
  max-width: 250px;
  width: 34px;
  /*opacity: 0; */
  margin-left: -10px;
  height: 30px;
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  position: absolute;
  border: 1px solid rgba(88, 88, 88, 0.1);
  box-shadow: 0px 2px 24px -4px rgba(124, 124, 124, 0.15);
  border-radius: 8px;
}

.questionIcon {
  margin-right: 10px;
  width: 26px;
  height: 23px;
}

.weightbarInner {
  padding-top: 4px;
  cursor: pointer;
  width: 50px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  border-right: 1px solid rgba(88, 88, 88, 0.1);
  padding-bottom: 4px;
  padding-right: 12px;
  padding-left: 12px;
}

.weightbarInner[type='5'] {
  border-right: none;
}

.extentedBar {
  width: 250px;
}

.icon {
  margin-left: 8px;
}
.questionLabels {
  margin-left: 3px;
}

.weightRowRight {
  display: flex;
  margin-right: 0px;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.dropdown {
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 5px;
  padding-left: 12px;
  margin-right: 13px;
}

.extented {
  height: 94px;
  transition: height 0.2s linear;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.weight {
  margin-right: 10px;
  font-size: 15px;
}

.deleteIcon[show='false'] {
  margin-left: 10px;
  cursor: pointer;
  visibility: hidden;
}

.deleteIcon[show='true'] {
  margin-left: 10px;
  cursor: pointer;
}

.container:not(.extented):hover .deleteIcon[show='false'] {
  visibility: visible;
}

.questionContainer {
  padding: 0;
  width: 90%;
}

.contentBody {
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  display: flex;
  overflow-x: auto;
}

.pageWrapper {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: scroll;
  min-width: 1200px;
  /* margin-top: 50px;
  margin-bottom: 70px; */
}

.contentWrapper {
  background: #ffffff;
  box-shadow: 1px 0px 24px rgba(0, 0, 0, 0.1);
  min-height: 95%;
  height: fit-content;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: auto;
  /* overflow: auto; */
  padding: 10px;
  width: 50%;
  flex-direction: column;
  position: relative;
  display: flex;
  padding-bottom: 28px;
}

@media screen and (max-width: 1726px) {
  .contentWrapper {
    width: 50%;
  }

  .gridWrapper {
    width: 50%;
    min-width: fit-content;
  }

  .searchWrapper {
  }
}

@media screen and (max-width: 1396px) {
  .contentWrapper {
    width: 60%;
  }

  .gridWrapper {
    width: 60%;
    min-width: fit-content;
  }
}

@media screen and (max-width: 1060px) {
  .contentWrapper {
    width: 70%;
  }

  .gridWrapper {
    width: 70%;
    min-width: fit-content;
  }
}

@media screen and (max-width: 760px) {
  .contentWrapper {
    width: 80%;
  }

  .gridWrapper {
    width: 80%;
    display: flex;
  }
}

.gridWrapper {
  background: #ffffff;
  box-shadow: 1px 0px 24px rgba(0, 0, 0, 0.1);
  min-height: 95%;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: auto;
  padding: 10px;
  width: 50%;
  display: grid;
  grid-template-rows: 52px auto;
  padding-bottom: 28px;
  min-width: 700px;
}

.gridContainer {
  display: grid;
  grid-template-rows: 52px auto;
}

@media screen and (max-width: 700px) {
  .mobileContentWrapper {
    background-color: #fff;
    padding: 20px;
    width: 100vw;
  }

  .contentBody {
    width: 100%;
    overflow-y: hidden;
    display: flex;
    overflow-x: hidden;
  }
}

.goBackRow {
  height: 34px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.goBackText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: rgba(116, 88, 211, 0.6);
}

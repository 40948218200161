.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.outerCard {
  max-width: 1000px;
  min-width: 576px;
  background: #ffffff;
  display: flex;
  padding: 6.4rem 12.8rem 9.6rem 12.8rem;
  justify-content: center;
  width: 47%;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}
.innerCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo {
  margin-bottom: 1.6rem;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.2rem;
}

.cardTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
}

.logoWrapper img{
  max-height: 2.9rem;
  max-width: 20rem;
}

.passwordWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.text {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  cursor: pointer;
}
.link {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #31cbe0;
  cursor: pointer;
}

.link:hover {
  color: #31cbe0;
  text-decoration: underline;
}

.terms {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.4rem;
}
.errorWrapper {
  position: absolute;
  display: inline-block;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 1rem;
  line-height: 1.7rem;
  color: #de4747;
  margin-top: 4.8rem;
  margin-left: 0.5rem;
}
.error {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #de4747;
}
@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }

  .outerCard {
    width: 100%;
    flex-direction: column;
    max-width: none;
    min-width: 0;
    box-shadow: none;
    justify-content: flex-start;
    padding: 20px;
  }
  .innerCard {
    width: 100%;
    height: 100%;
  }
  .error {
    font-size: 13px;
  }
  .cardTitle {
    font-size: 20px;
    line-height: 29px;
  }
  .text {
    font-size: 14px;
    line-height: 20px;
  }
  .link {
    font-size: 14px;
    line-height: 20px;
  }
  .logo {
    margin-bottom: 32px;
  }
  .titleWrapper {
    margin-bottom: 32px;
  }
  .passwordWrapper {
    margin-bottom: 10px;
  }
  .terms {
    margin-bottom: 22px;
  }
}

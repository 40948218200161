.BodyWrapper {
  width: 90%;
  max-width: 1100px;
  min-width: 750px;
  /* height: calc(100vh - 80px - 137px - 225px - 40px - 10px); */
  display: flex;
  flex-direction: column;
  /* justify-content: center;
    align-items: center; */
  align-self: center;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.InfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Jost-300;
  margin-top: 42px;
  font-size: 18px;
  line-height: 29px;
  color: rgba(88, 88, 88, 0.6);
}

.unlock {
  position: fixed;
  top: 0;
  left: 59%;
  z-index: 2;
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  cursor: pointer;
  text-align: center;
  color: #393939;
}

.textWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 8px 24px 8px 24px;
  width: 100px;
  border-radius: 12px;
  align-content: center;
}

.textWrapperHover {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 8px 24px 8px 24px;
  width: 100px;
  width: fit-content;
  border-radius: 12px;
  align-content: center;
  background-color: #7458d3;
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-top: 5px;
  text-align: center;
  color: #ffffff;
}
.icon {
  width: 36px;
  height: 36px;
}

.freeText {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

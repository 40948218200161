.DropdownClassName {
  /* border: 1px solid rgba(57, 57, 57, 0.1);
border-radius: 10px; */
}

.ControlClassName {
  border: none;
  outline: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 10px;
  padding: 0.95rem 1.6rem;

  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
}

.ControlClassNamePx {
  border: none;
  outline: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 10px;
  padding: 9.5px 16px;

  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
}

.ControlClassName:hover, .ControlClassNamePx:hover {
  box-shadow: unset;
  cursor: pointer;
}

.PlaceholderClassName {
  padding-right: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PlaceholderClassNamePx {
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MenuClassName {
  margin-top: 5px;
  border: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 10px;

  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;

  max-height: 12.6rem;
}

.MenuClassNamePx {
  margin-top: 5px;
  border: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 10px;

  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;

  max-height: 126px;
}

.MenuClassName :global(.Dropdown-option) {
  padding-left: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MenuClassNamePx :global(.Dropdown-option) {
  padding-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ArrowClassName {
  top: 1.8rem;
  right: 1.8rem;
  border-color: #393939 transparent transparent;
}

.ArrowClassNamePx {
  top: 18px;
  right: 18px;
  border-color: #393939 transparent transparent;
}
.mybanner {
  position: fixed;
  display: none;
  overflow: auto;
  z-index: 10005;
  transition: all 0.3s linear;
  background-color: #fff;
}
.mybanner.myshow {
  display: block;
}

body div[role='dialog']:has(.myModalContainer) {
  padding: 0 !important;
}

.modal-content {
  padding: 24px 40px !important;
  width: calc(100vw + 12px) !important;
  border-radius: 16px 16px 0px 0px !important;
  min-height: calc(100vh - 3.5rem) !important;
  margin-top: 3.5rem !important;
  background: #fafafa;
}
.myModalContainer {
}
.modalBody {
  /* width: calc(100vw + 12px); */
}
.colContainer {
  display: grid;
  grid-template-columns: 55% 43%;
  grid-column-gap: 2%;
  margin-top: 28px;
}
.closeButton {
  position: absolute;
  top: -30px;
  right: 6px;
}
.closeButton img {
  content: url('../../Auth/Images/seePlansClose.svg') !important;
}
.rightCol {
  margin-right: 40px;
}

.cardsContainer {
  display: flex;
  flex-direction: row;
}
.cardIcon {
  height: 22px;
  width: fit-content;
  margin-left: 8px;
}
.gradientWrapper {
  margin-right: auto;
  margin-left: auto;
  height: 10px;
  width: 90vw;
}
.gradientContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 64px);
  position: absolute;
  top: -10px;
  justify-content: center;
  align-items: center;
}
.container {
  width: auto;
  max-width: 100% !important;
  height: fit-content;
  min-height: fit-content !important;
  margin: 0 !important;
  min-width: calc(100vw - 12px);
}

.titleContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.backIcon {
  position: absolute;
  top: -30px;
  left: 6px;
}
.backIcon path {
  stroke: #fff;
}

.purchaseTitle {
  font-family: 'Jost-500';
  font-size: 2.4rem;
  line-height: 35px;

  text-align: center;

  background: linear-gradient(276.71deg, #32cbe0 -0.29%, #7458d3 83.57%, #7458d3 83.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  mix-blend-mode: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.backIcon {
  display: flex;
  align-self: center;
  cursor: pointer;
}

.paymentCardContainer {
  padding: 1.6rem 2.8rem 2.8rem 2.8rem;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.cardTitle {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  line-height: 26px;
  color: #393939;
  margin-bottom: 1.6rem;
}

.descRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.descText {
  font-family: 'Jost-300';
  font-size: 1.8rem;
  line-height: 26px;
  color: #393939;
}

.inputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
}

.planContainer {
  padding: 1.6rem 2.8rem 2.8rem 2.8rem;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 12px;
}

.rightColRows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 19px;
}

.subtitleText {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  line-height: 23px;
  color: #848484;
}

.infoText {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  line-height: 23px;
  color: #393939;
}

.totalText {
  font-family: 'Jost-500';
  font-size: 2.4rem;
  line-height: 35px;
  color: #393939;
}

.errorMessageDiv {
  display: flex;
  background: #de4747;
  border-radius: 12px;
  height: 48px;
  font-family: 'Jost-400';
  font-size: 1.7rem;
  line-height: 29px;
  color: #ffffff;
  padding: 14px 0px 15px 22px;
  margin-bottom: 16px;
  align-items: center;
}
.loadingDiv {
  background: linear-gradient(276.71deg, #32cbe0 -0.29%, #7458d3 83.57%, #7458d3 83.57%);
  border-radius: 10px;
  height: 4.8rem;
  margin-top: 16px;
  font-family: 'Jost-400';
  font-size: 1.8rem;
  line-height: 26px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 2.8rem;
}

.alertImg {
  margin-right: 8px;
}
/* MEDIA QUERIES */
@media screen and (min-width: 1600px) {
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .colContainer {
    display: grid;
    grid-template-columns: 55% 43%;
    grid-column-gap: 2%;
    margin-top: 28px;
  }
  .cardIcon {
    height: 18px;
  }
  .modal-content {
    padding: 20px 50px;
  }
  .rightCol {
    margin-right: 25px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .cardIcon {
    height: 16px;
  }
  .colContainer {
    display: grid;
    grid-template-columns: 55% 42%;
    grid-column-gap: 3%;
    margin-top: 28px;
  }
  .modal-content {
    padding: 20px 50px;
  }
  .rightCol {
    margin-right: 15px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .cardIcon {
    height: 14px;
  }
  .colContainer {
    display: grid;
    grid-template-columns: 55% 42%;
    grid-column-gap: 3%;
    margin-top: 28px;
  }
  .inputGrid {
    display: grid;
    grid-template-columns: calc(50% - 2px) calc(50% - 2px);
    grid-column-gap: 4px;
  }
  .modal-content {
    padding: 20px 50px;
  }
  .rightCol {
    margin-right: 20px;
  }
}

/* NEW */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .modal-content {
    padding: 10px 20px;
  }
  .cardIcon {
    height: 12px;
  }
  .rightCol {
    margin-right: 10px;
  }
  .inputGrid {
    display: grid;
    grid-template-columns: calc(50% - 2px) calc(50% - 2px);
    grid-column-gap: 4px;
  }
}
@media screen and (max-width: 575px) {
  .modal-content {
    padding: 10px 20px;
  }
  .cardIcon {
    height: 10px;
  }
  .rightCol {
    margin-right: 10px;
  }
  .inputGrid {
    display: grid;
    grid-template-columns: calc(50% - 2px) calc(50% - 2px);
    grid-column-gap: 4px;
  }
}

/* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .modal-content {
    padding: 24px 20px !important;
    width: calc(98vw) !important;
  }
  .colContainer {
    display: block;
    margin-top: 28px;
  }

  .paymentCardContainer {
    padding: 1.6rem 1.8rem 2.8rem 1.8rem;
    max-width: 100%;
  }
}
/* Temporary mobile solution */

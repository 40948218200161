.headerContainer {
  display: none;
}
.headerMenu {
  display: none;
}

@media screen and (max-width: 576px) {
  .headerContainer {
    position: relative;
    z-index: 1000;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100%;
  }

  .CustomNavbar {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .headerMenu.headerOpen {
    display: flex;
    padding: 20px;
    background-color: #fafafa;
    min-width: calc(100% + 40px);
    margin-left: -20px;
    flex-direction: column;
  }
  .headerIcons {
    cursor: pointer;
  }
  .logoContainer {
    width: 100%;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
    border-radius: 8px;
    padding: 16px 24px 20px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .title {
    font-family: 'Jost-400';
    font-size: 12px;
    line-height: 17px;
    color: rgba(57, 57, 57, 0.6);
    text-transform: uppercase;
  }

  .usageContainer {
    background: #ffffff;
    box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
    border-radius: 8px;
    padding: 12px 30px 16px 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .settingsButtonWrapper {
    background: #ffffff;
    box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 16px;
  }

  .settingsButtonWrapper text {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    color: #393939;
  }

  .logoutContainer {
    border-top: 1px solid rgba(57, 57, 57, 0.1);
    padding-top: 16px;
  }

  .logoutRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    justify-content: center;
    padding: 4px 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .logoutText {
    font-family: 'Jost-400';
    font-size: 16px;
    cursor: pointer;
    line-height: 18px;
    color: #de4747;
    margin-left: 12px;
  }

  /* Progress bars */
  .usageBlock {
    margin-top: 2.4rem;
    padding-bottom: 1.7rem;
    width: 100%;
  }
  .usageRow {
    display: flex;
    height: fit-content;
    justify-content: space-between;
    position: relative;
    flex-direction: row;
  }

  .circleColumn {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .topRow {
    margin-bottom: 2.2rem;
    width: 100%;
  }
  .planNames {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    color: rgba(57, 57, 57, 0.84);
  }
  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .planAmount {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    color: #393939;
  }
  .current {
    font-family: 'Jost-400';
    font-size: 12px;
    line-height: 17rem;
    text-transform: uppercase;
    color: rgba(57, 57, 57, 0.49);
  }

  .available {
    font-family: 'Jost-400';
    font-size: 14px;
    line-height: 20px;
    color: rgba(57, 57, 57, 0.4);
    white-space: nowrap;
  }
  .circleContainer {
    height: 38px;
    width: 38px;
    align-self: center;
    display: flex;
    margin-right: 16px;
  }
  /* Progress bars */
}
body:has(.headerOpen) {
  overflow-y: hidden !important;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3.2rem;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 17.7rem;
}

.image {
  width: 14rem;
  height: 17.7rem;
}

.title {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  padding-left: 4rem;
  color: #393939;
}

.text {
  /* margin-top: 16px; */
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  padding-left: 4rem;
}

.popup {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

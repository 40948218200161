.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  line-height: 3.5rem;
  color: #585858;
}
.warningTitle {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #585858;
}

.text {
  font-family: 'Jost-300';
  font-size: 1.8rem;
  line-height: 2.6rem;
  letter-spacing: -0.02em;
  color: #585858;
  text-align: center;
}
.boldText {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  line-height: 2.6rem;
  letter-spacing: -0.02em;
  color: #585858;
  text-align: center;
}

.endWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  margin-top: 3.2rem;
}

.headerWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.back {
  width: 0.6rem;
  height: 1.3rem;
}

.back:hover {
  cursor: pointer;
}

.crossWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.sentenceWrapper {
  display: inline-block;
  /* text-align: center; */
  margin-top: 2.4rem;
  overflow-wrap: break-word;
}
.cancelButton {
  width: 101px;
  height: 42px;
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  align-items: center;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  justify-content: center;
  padding: 8px 24px;
  color: #585858;
}

.cancelButton:hover {
  cursor: pointer;
}

.deleteButton {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #de4747;
  align-items: center;
  margin-left: 0.8rem;
}
.deleteButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 12px;
}
.deleteButtonWrapper:hover {
  cursor: pointer;
  background-color: rgba(222, 71, 71, 0.1);
}

.deleteStage {
  padding: 8px 24px;
  display: flex;
  width: 142px;
  height: 42px;
  background: rgba(222, 71, 71, 0.8);
  border-radius: 10px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.deleteStage:hover {
  cursor: pointer;
}

.tipText {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  line-height: 3.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(50, 203, 224);
}

/* MOBILE Styling */

.titleMobile {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #585858;
}
.tipTextMobile {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(50, 203, 224);
}

.textMobile {
  font-family: 'Jost-300';
  font-size: 1.6rem;
  letter-spacing: -0.02em;
  color: #585858;
}
.endWrapperMobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  width: 100%;
  margin-top: 3.2rem;
}

.contentWrapperMobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
.boldTextMobile {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  letter-spacing: -0.02em;
  color: #585858;
  text-align: center;
}

/* MOBILE Styling */

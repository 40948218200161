.modalWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTextWrapper {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;

  display: flex;
  align-items: center;
  gap: 4px;
}

.headerTextWrapper svg {
  height: 23px;
  width: 23px;
}

/* .headerTextWrapper path {
  fill: #7458d3;
  stroke: #7458d3;
} */

.headerTextWrapper rect {
  fill: #7458d3;
  stroke: #7458d3;
}

.inputFieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nameSurnameWrapper {
  display: flex;
  gap: 16px;
}

.buttonRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}

.isAdminWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  padding-left: 10px;
  cursor: pointer;
  width: fit-content;
}

.adminText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}

@media screen and (max-width: 576px) {
  .nameSurnameWrapper {
    flex-direction: column;
  }
}

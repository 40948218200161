.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #fafafa;
}

.PageWrapper label {
  margin-bottom: 0px;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.outerCard {
  max-width: 1000px;
  min-width: 576px;
  background: #ffffff;
  display: flex;
  padding: 6.4rem 12.8rem;
  justify-content: center;
  width: 47%;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}
.innerCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo {
  margin-bottom: 3rem;
}

.resendWrapper {
  display: flex;
  flex-direction: column;
  display: flex;
  margin-bottom: 8rem;
}
.cardTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
  /* margin-bottom: 3.2rem; */
}
.cardText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  margin-bottom: 2.4rem;
}
.cardSubText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: rgba(88, 88, 88, 0.6);
}

.link {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.3rem;
  font-weight: 400;
  color: #31cbe0;
  cursor: pointer;
}
.remaining {
  /* margin-left: 1rem; */
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.3rem;
  font-weight: 400;
  color: rgba(88, 88, 88, 0.6);
}
.backWrapper {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.4rem;
}
/* 
.arrow {
  width: 2.5rem;
  height: 2.6rem;
}

.back {
  display: flex;
  align-items: center;
  color: #585858;
}

.BackText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  display: flex;
  align-items: center;


  color: #585858;
} */

@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }

  .outerCard {
    width: 100%;
    flex-direction: column;
    max-width: none;
    min-width: 0;
    padding: 5rem;
    width: 100%;
    box-shadow: none;
    justify-content: flex-start;
  }
  .innerCard {
    width: 100%;
    height: 100%;
    /* margin-left: 4rem; */
  }
  .cardTitle {
    font-size: 20px;
  }
  .cardText {
    font-size: 16px;
  }
  .cardSubText {
    font-size: 16px;
  }
  .link {
    font-size: 16px;
  }
  .remaining {
    font-size: 16px;
  }
}

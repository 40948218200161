.wrapper {
  animation: skeleton 1s ease infinite alternate;
  width: 30%;
  height: 70%;
}
.radioMainWrapper {
  width: 90%;
  max-width: 460px;
  min-width: 335px;
  height: 158px;
  margin: 10px 5px 10px 5px;
  border-radius: 18px;
  border: none;
  background-color: rgba(88, 88, 88, 0.2);
  cursor: pointer;
  flex-direction: row;
  animation: skeleton 1s ease infinite alternate;
}
.radioCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
}
.commentWrapper {
  width: 100%;
  height: 115px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  border-radius: 18px;
}
.icon {
  background: rgba(88, 88, 88, 0.1);
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 2%;
  flex-shrink: 0;
}
.iconLeft {
  margin-left: 2%;
}

.commentBody {
  width: 60%;
  height: 100%;
  border-radius: 18px;
  background: rgba(88, 88, 88, 0.1);
}

.radioBottomInfo {
  width: 146px;
  height: 10px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  margin-bottom: 10px;
}
.radioText {
  margin-left: 10px;
  width: 84px;
  height: 18px;
  margin-bottom: 11px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.6);
}
.candidateName {
  width: 279px;
  height: 24px;
  background: rgba(88, 88, 88, 0.1);
  border-radius: 14px;
}
.CVWrapper {
  width: 90%;
  background: rgba(88, 88, 88, 0.2);
  max-width: 460px;
  min-width: 335px;
  box-shadow: 0px 2px 24px rgb(0 0 0 / 10%);
  border-radius: 18px;
  height: 60px;
  align-items: center;
  display: grid;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
}
.bigBar {
  width: 140px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 140px;
  background: rgba(88, 88, 88, 0.1);
  margin-right: 90px;
}

.progressText {
  width: 25%;
  height: 18px;
  background: rgba(88, 88, 88, 0.1);
  border-radius: 10px;
}
.progress {
  width: 70%;
  height: 16px;
  background: rgba(88, 88, 88, 0.1);
  border-radius: 5px;
}
.bigBarCircle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #ffffff;
}
.progressSmallText {
  width: 46px;
  height: 18px;
  background: rgba(88, 88, 88, 0.1);
  border-radius: 10px;
  margin-left: 15px;
}

.candidateEmail {
  width: 217px;
  height: 12px;
  background: rgba(88, 88, 88, 0.1);
  border-radius: 12px;
}
.Question {
  width: 100%;
  border-radius: 16px;
  height: 117px;
  margin-bottom: 16px;
  background: rgba(88, 88, 88, 0.1);
}
.QuestionTitle {
  width: 95%;
  height: 25px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 14px;
  margin-top: 14px;
  background: rgba(255, 255, 255, 0.6);
}
.QuestionBody {
  width: 95%;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.QuestionElement {
  height: 25px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
}
.radioLongText {
  margin-left: 10px;
  width: 217px;
  height: 12px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.6);
}

.radioWrapper {
  height: 158px;
  border-radius: 18px;
  display: grid;
  grid-template-columns: 1fr 18fr;
}
.radioTitle {
  width: 279px;
  background: rgba(255, 255, 255, 0.6);
  height: 24px;
  margin-top: 10px;
  border-radius: 14px;
}

.radioBodyWrapper {
  grid-column-start: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Jost-400;
  font-size: 20px;
  width: 100%;
  display: flex;
  flex: 1 1;
  padding-left: 10px;
  border-radius: 18px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.infoWrapper {
  animation: skeleton 1s ease infinite alternate;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(88, 88, 88, 0.1);
  border-radius: 18px;
}

.text {
  height: 24px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 14px;
}

.circleContainerSkeleton {
  animation: skeleton 1s ease infinite alternate;
  height: 38px;
  width: 38px;
  align-self: center;
  display: flex;
  margin-right: 1rem;
  border-radius: 20px;
  background: rgba(88, 88, 88, 0.1);
}
.contentWrapperSkeleton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.contentSkeleton {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.placeholderSkeleton {
  width: 96px;
  height: 70%;
  margin: 5px 5px;
  border-radius: 3px;
  background: rgba(88, 88, 88, 0.1);
  animation: skeleton 1s ease infinite alternate;
}

.CandidateListComponentWrapperSkeleton {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 0.8rem;
}

.CandidateListComponentCandidateWrapperSkeleton {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  background: rgba(88, 88, 88, 0.15);
  border-radius: 16px;
  padding: 1.1rem 1.2rem;
}

.CandidateListComponentLeftPartSkeleton {
  width: 40%;
  margin-left: 20px;
  border-radius: 16px;
  height: 80%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  animation: skeleton 0.6s ease infinite alternate;
}

.CandidateListComponentRightPartSkeleton {
  width: 30%;
  margin-right: 20px;
  border-radius: 16px;
  height: 80%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  animation: skeleton 0.6s ease infinite alternate;
}

@keyframes skeleton {
  to {
    opacity: 0.5;
  }
}

.funnelSkeletonWrapper {
  height: 65%;
  background: rgba(1, 1, 1, 0.05);
  margin: 1.4rem 1.6rem 1.4rem 1.6rem;
  border-radius: 16px;
  padding: 1.4rem 1.6rem 1.4rem 1.6rem;
  animation: skeleton 0.6s ease infinite alternate;
}

.funnelSkeleton {
  height: 45px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  margin-bottom: 15px;
  animation: skeleton 0.6s ease infinite alternate;
}

.profileSkeleton {
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  background: rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  display: flex;
  height: 190px;
  animation: skeleton 0.6s ease infinite alternate;
}

.assessmentReportSkeletonWrapper {
  filter: drop-shadow(0px 2px 24px rgba(134, 134, 134, 0.15));
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.assessmentReportQuestionBarSkeleton {
  border-radius: 10px;
  padding: 10px 18px;
  height: 42px;
  font-size: 15px;
  line-height: 22px;
  background: rgba(88, 88, 88, 0.1);

  animation: skeleton 0.6s ease infinite alternate;
}

.assessmentReportQuestionSkeleton {
  border-radius: 10px;
  padding: 12px 16px 16px;
  display: flex;
  flex-direction: column;
  height: 300px;
  background: rgba(88, 88, 88, 0.1);

  animation: skeleton 0.6s ease infinite alternate;
}

.positionCardWrapper {
  background: rgba(88, 88, 88, 0.1);
  border-radius: 10px;
  padding: 16px;
  margin-top: 12px;
  width: 390px;
  min-width: 360px;
  height: 235px;

  animation: skeleton 0.6s ease infinite alternate;
}

.salaryQuestionWrapper {
  height: 8.6rem;
  padding-bottom: 2.8rem;
}

.salaryQuestion {
  padding: 0.4rem 0.6rem;

  width: 100%;
  height: 100%;
  background: rgba(88, 88, 88, 0.1);
  border-radius: 10px;
  animation: skeleton 0.6s ease infinite alternate;
}

.funnelDropdown {
  width: calc(100% - 108px);
  height: 42px;
  background: rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  animation: skeleton 0.6s ease infinite alternate;
}

.funnelDropdownButton {
  margin-left: 18px;
  height: 42px;
  width: 88px;
  background: rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  animation: skeleton 0.6s ease infinite alternate;
}

.personalInfoWrapper {
  height: 132px;
  width: 100%;
  background: rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  animation: skeleton 0.6s ease infinite alternate;
}

/* Plan Tab in Settings */

.currentUsageInPlanWrapper{
  height: 247px;
  width: 100%;
  background: rgba(88, 88, 88, 0.2);
  animation: skeleton 0.6s ease infinite alternate;
}
.settingsGeneralCompanyWrapper {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.settingsGeneralCompanyName {
  width: 100%;
  height: 23px;
  border-radius: 4px;
  background: rgba(88, 88, 88, 0.2);
  animation: skeleton 0.6s ease infinite alternate;
}

.settingsGeneralCompanyLogo {
  width: 100%;
  height: 120px;
  border-radius: 4px;
  background: rgba(88, 88, 88, 0.2);
  animation: skeleton 0.6s ease infinite alternate;
}

.settingsGeneralInfo {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(88, 88, 88, 0.2);
  animation: skeleton 0.6s ease infinite alternate;
}

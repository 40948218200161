.CandidateResultPageWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 576px) {
    html:has(.CandidateResultPageWrapper) {
        font-size: 8px;
    }
}
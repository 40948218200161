.wrapper {
  margin-left: 35px;
  margin-right: 24px;
  min-height: 100%;
  width: 100%;
}

.questionCount {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  width: calc(100% + 12px);
}

.icon {
  margin-top: 8px;
  margin-right: 15px;
  margin-left: auto;
  cursor: pointer;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 8px;
}

.plus rect {
  fill: #7458d3;
}

.plus path {
  fill: white;
  stroke: #7458d3;
}

.icon:hover .plus rect {
  fill: rgba(116, 88, 211, 0.9);
}

.icon:active .plus rect {
  fill: rgba(116, 88, 211, 0.9);
}

.icon:active .plus path {
  fill: rgba(255, 255, 255, 0.6);
}

.noQuestionButton {
  text-decoration: none;
}

.noQuestionButton:hover {
  text-decoration: none;
}

.countText {
  font-family: 'Jost-500';
  font-size: 18px;
  line-height: 26px;
  display: flex;
  color: #585858;
  margin-left: 4px;
}

.noQuestion {
  background: #ffffff;
  box-shadow: 0px 1px 16px rgb(0 0 0 / 10%);
  border-radius: 12px;
  padding: 31px 16px 39px;
  width: calc(100% + 12px);
}

.noQuestionText {
  font-family: 'Inter-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 40px;
  color: #393939;
}

.noQuestionTextContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.listWrapper {
  background: #ffffff;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-height: calc(90%);
  /* height: 100%; */
  padding: 16px;
  width: calc(100% + 12px);
  /* margin-bottom: 250px; */
}

.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
  padding: 0px !important;
}

.title {
  display: flex;
  font-family: 'Jost-400';
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #585858;
  flex-direction: row;
}

.closeButton {
  display: flex;
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: -10px;
}

.userTitle {
  border-radius: 6px;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-right: auto;
  cursor: pointer;
  margin-left: auto;
  padding: 2px 12px 2px 12px;
  align-content: center;
}

.purpleUser {
  background: white;
  color: #7458d3;
}

.greyUser {
  background: #7458d3;
}

.buttonTitle {
  font-family: 'Jost-400';
  margin-bottom: 8px;
  margin-top: 13px;
  margin-left: auto;
  margin-right: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
}

.buttonSubtitle {
  margin-left: auto;
  margin-bottom: 4px;
  margin-right: auto;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.buttonContainer {
  cursor: pointer;
  width: 492px;
  height: 106px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
}

.greyButton {
  background: rgba(244, 244, 244, 0.6);
  border: 1px solid #f4f4f4;
  color: #585858;
}

.purpleButton {
  background: rgba(116, 88, 211, 0.1);
  border: 1px solid rgba(116, 88, 211, 0.1);
  color: #7458d3;
}

.subtitle {
  display: flex;
  font-family: 'Jost-300';
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}

.modal-content {
  width: 572px;
  max-width: 100vw;
  height: 509px !important;
}

.modalContent {
  border: none;
  padding: 27px 40px 0px 40px !important;
  /* height: 328px ; */
  width: 572px !important;
  max-width: 572px;
  height: 509px !important;
  /* width: 572px; */
}

.customTooltip {
  background: #ffffff;
  pointer-events: none;
  font-family: 'Jost-400';
  border: 1px solid rgba(88, 88, 88, 0.2);
  box-shadow: 0px 1px 18px rgba(88, 88, 88, 0.2);
  opacity: 1 !important;
  padding: 10px !important;
  max-width: none !important;
  margin-top: 4% !important;
}

.popover::after {
  position: absolute;
  left: 60px;
  top: calc(50% - 36px / 2 + 12px);
  margin-top: 18px;
  content: '';
  width: 12px;
  height: 10px;
  background: #ffffff;
  border-radius: 1px;
  transform: rotate(45deg);
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
}

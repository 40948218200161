.header {
  width: 100%;
  height: fit-content;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  cursor: pointer;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #393939;
}

.baseIcon {
  transition: transform 0.2s linear;
  cursor: pointer;
}

.openIcon {
  transform: rotate(0deg);
}

.closedIcon {
  transform: rotate(-90deg);
}

.bold {
  font-family: 'Jost-500';
  /* margin-left: 0.8rem; */
}

.baseInputBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.5s linear;
}

.closedInputBody {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
}

.openInputBody {
  max-height: 600px;
  opacity: 1;
  visibility: visible;
}

.linkedinMainWrapper {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.linkedinHeaderText {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}

.linkedinGifLink {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-decoration: underline;
  color: #7458d3;
  cursor: pointer;
}

.cvPart {
  height: fit-content;
  width: 100%;
  /* margin-top: 1.6rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 10px;
}

.fileInputBase {
  padding-left: 1.2rem;
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
  width: 100%;
  padding-right: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 12px; */
  cursor: pointer;

  overflow: hidden;
}

.inputPlaceholder {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.2rem;
  text-align: center;
  word-break: break-all;
  color: rgba(57, 57, 57, 0.6);
  /* color: #393939; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cvName {
  color: #393939;

  display: flex;
  align-items: center;
  text-align: center;
  /* word-break: break-all; */
  overflow: hidden;
  white-space: nowrap;
}

.cvNameText {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.showCv path {
  stroke: #7458d3;
}

.cvParser {
  padding: 0rem 2.4rem;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  align-items: center;
  /* margin-left: 1rem; */
  display: flex;
  color: #7458d3;
  cursor: pointer;
  margin-right: 0.7rem;
  background: rgba(116, 88, 211, 0.2);
  border-radius: 8px;
  height: 3.6rem;
  white-space: nowrap;
}

.personalInformationMainWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
}

.namePart {
  display: flex;
  /* margin-bottom: 1.4rem; */
  flex-direction: row;
  /* margin-top: 2.8rem; */
  align-items: center;
  justify-content: space-between;
}

.cvMainWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
}

.inputWrapper {
  width: 48%;
}

/* .result {
  margin-bottom: 1.4rem;
} */

.linkedInWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
}

.linkedIn {
  position: absolute;
  left: calc(100% - 40px);
  transition: 0s;
  z-index: 1;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  margin-top: 2.4rem;
  justify-content: flex-end;
}

@media screen and (max-width: 576px) {
  .topInput {
    margin-bottom: 1.4rem;
  }

  .namePart {
    flex-direction: column;
  }

  /* .fileInputBase {
    width: 50%;
  } */

  .inputWrapper {
    width: 100%;
  }
}

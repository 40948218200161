.radio-button-container {
  /* display: flex;
  flex-direction: row; */
}

.radio-button-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.itemText {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #393939;
  margin-right: 18px;
}

.box {
  background: #ffffff;
  border: 1px solid rgba(57, 57, 57, 0.2);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
  margin-right: 8px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: fit-content
}

.selected {
  /* background: aqua !important; */
  border: 1px solid #7458d3;
  background-image: url('../Images/radioButtonFill.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.wrapper {
  height: 980px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cvFrame {
  display: flex;
  height: 100%;
  /* width: 92%; */
  width: 100%;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 576px) {
  .wrapper {
    height: 650px;
  }
  .cvFrame {
    height: 100%;
  }
  .animationWraper {
    height: 400px;
  }
}

.animationWraper {
  width: 92%;
  background-color: #f4f4f4;
  height: 600px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.frameWrapper {
  height: 100%;
  width: 92%;
}

.webResumeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* background: #393939; */
  background: #525659;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px 10px 0px 0px;

  padding: 16px 24px;
}

.resumeName {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  /* white/white */
  color: #ffffff;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
}

.resumeIconWrapper {
  display: flex;
  gap: 18px;
}

.resumeIconWrapper img {
  cursor: pointer;
}
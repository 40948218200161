.cardWrapper {
  background: linear-gradient(145.98deg, #1b1b1b 24.05%, rgba(27, 27, 27, 0.75) 95.58%);
  box-shadow: 0px 1px 24px rgba(84, 84, 84, 0.15);
  border-radius: 12px;
  padding: 20px 20px 30px 30px;
  height: fit-content;
  /* min-height: 180px;
  max-width: 380px; */
  max-width: 298px;
  width: 298px;
  height: 180px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  justify-self: center;
}

.primaryInfo {
  padding-top: 3px;
  padding-left: 12px;
  padding-right: 12px;
  background: rgba(116, 88, 211, 0.1);
  width: fit-content;
  margin-bottom: 12px;
  border-radius: 6px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #7458d3;
  padding-bottom: 3px;
}

.cardName {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 9px;
  line-height: 23px;
  color: #fff;
}

.infoPart {
  border-bottom: 1px solid rgba(57, 57, 57, 0.2);
  padding-bottom: 19px;
}

.expiredRow {
  display: flex;
  /* padding-bottom: 18px; */
  flex-direction: row;
  border-bottom: 1px solid rgba(57, 57, 57, 0.2);
  justify-content: space-between;
  align-items: center;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.remove {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #dd3a3a;
  cursor: pointer;
}

.backupCard {
  border: 1px dashed rgba(57, 57, 57, 0.2);
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  padding-bottom: 23px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backupSubtitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 17px;
  width: 100%;
  text-align: center;
  color: #393939;
}

.backupIcon {
  margin-top: 25px;
  margin-bottom: 9px;
}

.backup {
  font-family: 'Jost-400';
  font-style: normal;
  width: 100%;
  margin-bottom: 19px;
  font-weight: 400;
  font-size: 9px;
  line-height: 26px;
  text-align: center;
  color: #393939;
}

.change {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  cursor: pointer;
  color: #7458d3;
}

.cardExpiration {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.enterpriseWrapper {
  font-family: Jost-400;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  /* width: 90%; */
  margin-right: auto;
  margin-left: auto;
  margin-top: 47px;
}

.contactText {
  color: #7458d3;
  align-items: center;
  text-align: left !important;
  cursor: pointer;
}
.enterpriseText {
  color: #393939;
  margin-bottom: 24px;
}

.MultipleChoiceQuestionBodyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}

.MultipleChoiceOverflowWrapper {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  color: #393939;
}

.MultipleChoiceQuestionDetailsWrapper {
  align-self: flex-start;
  max-width: 740px;
  min-width: 480px;
  margin: 1.4rem 2rem;
  width: 100%;
}

.MultipleChoiceQuestionDetailsWrapperMobile {
  align-self: flex-start;
  margin: 1rem 2rem;
  width: 100%;
}

.MultipleChoiceQuestionDescription {
  font-family: Jost-500;
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.MultipleChoiceQuestionDescription img{
  max-width: 100%;
  height: unset !important;
}

.MultipleChoiceOptionsWrapper {
  margin: 2.4rem 0rem 8rem 0rem;
}

.IndividualOptionWrapper {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin: 1rem 0.4rem;

  font-family: Jost-400;
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.Checkbox {
  min-width: 18px;
  max-width: 18px;
  width: 18px;
  min-height: 18px;
  max-height: 18px;
  height: 18px;
  background: #ffffff;
  border: 1.2px solid #7458d3;
  border-radius: 10px;
  margin-right: 1.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.SelectedCheckbox {
  min-width: 14px;
  max-width: 14px;
  width: 14px;
  min-height: 14px;
  max-height: 14px;
  height: 14px;
  background: #7458d3;
  border-radius: 10px;
  padding: 2px;
}

.OptionText {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.OptionText p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.OptionText img {
  max-width: 100%;
  max-height: 500px;
  height: unset !important;
}
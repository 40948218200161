.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.contentHeader {
  font-family: 'Jost-400';
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
}

.descriptionWrapper {
  font-family: 'Jost-300';
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  flex-direction: column;
  color: #393939;
  gap: 0.6rem;
}

.coensioPurple {
  color: #7458d3;
}

.mailWrapper {
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid rgba(57, 57, 57, 0.2);
  border-radius: 8px;

  font-family: 'Jost-400';
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #393939;
}

.mailSubject {
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.mailContent {
  height: 24.8rem;
  padding: 1.2rem 0.8rem 1.2rem 1.6rem;
  overflow-y: auto;
}

.buttonWrapper {
    display: flex;
    margin-left: auto;
    gap: 0.6rem;
}

@media screen and (max-width: 576px) {
  .contentWrapper {
    gap: 24px;
  }

  .buttonWrapper {
    display: flex;
    gap: 10px;
    width: 100%;
}

.mobileCloseButtonWrapper {
  position: absolute;
  top: 20px;
  right: 20px;
}
}
.funnelName{
  display: flex;
  align-items: flex-end;
  font-family: 'Jost-400';
  font-style: normal;
  white-space: nowrap;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #7458D3;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.wrapper{
  margin-right: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.funnelName[stage="1"]{
  color: #32CBE0;
}
.funnelName[stage="2"]{
  color: #4AC389;
}
.funnelName[stage="3"]{
  color: #DE4747;
}
.drop{
  cursor: pointer;
}


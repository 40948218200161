.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Card {
  max-width: 1000px;
  min-width: 576px;
  background: #ffffff;
  display: flex;
  padding: 3.2rem 3.2rem 4.8rem 3.2rem;
  width: 60%;
  flex-direction: column;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}
.CardTextBox {
  display: flex;
  flex-direction: column;
}

.CardTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
  /* margin-left: 5px; */
}
.CardInvitationTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
  margin-bottom: 1.6rem;
}

.CardText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Logo {
  margin-bottom: 3.2rem;
}

.backWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-bottom: 1.6rem;
}

@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }
  .Card {
    flex-direction: column;
    max-width: none;
    min-width: 375px;
    box-shadow: none;
    padding: 3rem 2.5rem;
  }
  .Logo {
    margin-bottom: 32px;
  }
  .CardTitle {
    font-size: 20px;
    line-height: 29px;
  }
  .CardInvitationTitle {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 32px;
  }
  .CardText {
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 32px;
  }
  .backWrapper {
    margin-bottom: 24px;
  }
}
/* 
@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }

  .outerCard {
    min-width: 375px;
    box-shadow: none;
    padding: 3rem;
  }
  .innerCard {
    width: 100%;
    height: 100%;
  }
  .cardTitle {
    font-size: 20px;
    line-height: 29px;
    /* margin-bottom: 32px; 
  }
  .cardText {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 2.4rem;
  }
} */

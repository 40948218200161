.ProgressBarWrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 4px;
}

.ProgressBarScore {
    font-family: 'Jost-500';
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center; 
    color: #393939;
    margin-top: 6px;
}

.ProgressBarBackDiv {
    background: #F4F4F4;
    border-radius: 3px;
    width: 100%;
    height: 12px;
    /* margin-right: min(34px, calc(3.4rem - 10px)); */
    align-self: center;
}

.ProgressBarFrontDiv {
    height: 100%;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.UltimateInnerWrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.InnerWrapper {
    width: 100%;
    padding-top: 3px;
    
}

.polygon {
    min-width: 10px;
    min-height: 8px;
    background-color: #393939;
    clip-path: polygon(0px 0px, 5px 8px, 10px 0px);
  }
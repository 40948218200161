.Wrapper {
  display: flex;
  flex-direction: column;
  width: 34%;
  min-width: 300px;
  align-items: center;
  gap: 3.2rem;
}

.TextWrapper {
  display: flex;
  flex-direction: column;
  gap: 2.3rem;
  align-items: center;
}

.header {
  font-family: 'Jost-500';
  font-size: 2.4rem;
  line-height: 3.5rem;
  text-align: center;
  color: #393939;
}

.subtext {
  font-family: 'Jost-400';
  font-size: 2rem;
  line-height: 2.9rem;
  text-align: center;
  color: #393939;
}

@media screen and (max-width: 710px) {
  .Wrapper img {
    max-width: 225px;
  }
}

@media screen and (max-width: 576px) {
  .header {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #7458d3;
  }

  .subtext {
    font-family: 'Jost-300';
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #393939;
  }
}

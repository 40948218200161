.container {
  width: 279px;
  height: 38px;
  display: flex;
  padding-left: 20px;
  background: rgba(244, 244, 244, 0.6);
  border-radius: 10px;
  padding-top: 8px;
  padding-right: 7px;
  padding-bottom: 7px;
  margin-bottom: 6px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.rightColumn {
  display: flex;
  flex-direction: row;
}

.extentedMain {
  max-height: 300px !important;
}

.mainContainer {
  width: 279px;
  max-height: 38px;
  margin-bottom: 6px;
  transition: 0.2s linear;
  overflow: hidden;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
.scrollbarWrapper{
  height: 100%;
}

.mainTypeContainer {
  width: 279px;
  display: flex;
  position: relative;
  margin-left: auto;
  overflow: hidden;
  margin-right: auto;
  flex-direction: column;
  height: 100%;
}
.extentedMainTypeContainer:hover {
  overflow-y: auto;
}
.extentedMainTypeContainer {
  width: 279px;
  display: flex;
  position: relative;
  margin-left: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-right: auto;
  flex-direction: column;
  height: 100%;
}

.item {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}

.typeContainer {
  display: flex;
  transition: 0.2s linear;
  width: 279px;
  padding-left: 11px;
  opacity: 0;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0.4rem;
}

.extented {
  transform: rotate(90deg);
}

.amountText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  display: flex;
  line-height: 20px;
  height: fit-content;
  background: #ffffff;
  border-radius: 6.75px;
  padding-right: 10px;
  padding-bottom: 3px;
  padding-top: 3px;
  padding-left: 10px;
  margin-right: 2px;
  color: #585858;
}

.typeText {
  margin-left: 14px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;

  /* darkGray/darkGray */

  color: #585858;
}

.icon {
  stroke: #585858;
  transition: 0.2s linear;
  width: 26px;
  height: 26px;
}

.typeExtented {
  opacity: 1;
}

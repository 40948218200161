.contentBody {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dropDownContainer {
  width: 20%;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.buttonContainer {
  margin-left: auto;
  margin-right: auto;
}

.contentBody button {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-right: 1rem;
}

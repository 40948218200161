.modalHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
}

.headerText {
  font-family: 'Jost-400';
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #585858;
}

.subHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lightText {
  font-family: 'Jost-300';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.boldText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.hrList {
  height: 198px;
  overflow-y: scroll;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 18px;
}

.checkboxWrapper {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------------- */

.modal-content {
  border-radius: 25px !important;
  padding: 20px 28px !important;
  height: 450px;
  width: 572px;
  padding-bottom: 24px !important;
}

.ModalWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.blurred {
  filter: blur(10px);
}

.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
  padding: 0px !important;
  align-self: center;
}

.successContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.successFootNote {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(88, 88, 88, 0.6);
  margin-top: 104px;
}

.successFootNoteSmall {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 39px;
  text-align: center;
  color: rgba(88, 88, 88, 0.6);
}

.successIconSmall {
  margin-top: 16px;
  margin-bottom: 40px;
}

.successTitleSmall {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #585858;
}

.successIcon {
  margin-top: 26px;
  margin-bottom: 37px;
}

.successSubtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #585858;
}

.successTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 8px;
  text-align: center;
  color: #585858;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 13px;
}

.headerTitle {
  font-family: Jost-400;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #585858;
  /* margin-bottom: 13px; */
}

.lineWrapper {
  display: grid;
  grid-template-columns: auto 173px;
  grid-column-gap: 10px;
  align-items: center;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
  margin-bottom: 12px;
}

.textNormal {
  font-family: 'Jost-300';
}

.textBold {
  font-family: 'Jost-400';
}

.inviteButton {
  border: 1px solid #7458d3;
  border-radius: 10px;
  background-color: transparent;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
  width: 161px;
  height: 35px;
  padding-right: 24px;
  padding-left: 17px;
  text-align: end;
  background-image: url('../../../../images/newUserIcon.svg');
  background-repeat: no-repeat;
  background-position-x: 17px;
  background-position-y: center;
  margin-right: 8px;
  margin-left: auto;
  cursor: pointer;
}

.checkboxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-left: 11px;
  margin-bottom: 18px;
}

.searchWrapper {
  margin-bottom: 18px;
}

.checkboxWrapper {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hrText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.disabledHr {
  color: rgba(57, 57, 57, 0.3);
}

.hrList {
  height: 195px;
  overflow-y: scroll;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  /* height: fit-content; */
  margin-bottom: 18px;
}

.linkRow {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  margin-left: 11px;
  margin-top: auto;
}

.imgWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.disabledHR {
  color: rgba(57, 57, 57, 0.3);
}

.copyLink {
  display: flex;
  flex-direction: row;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
  align-items: center;
  cursor: pointer;
}

.sendButton {
  margin-right: 0;
  margin-left: auto;
}

.inviteContainer {
  padding: 17px 20px 16px 24px !important;
  /* height: 240px; */
  width: 540px;
  background: #ffffff;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
  border: none;
  font-family: 'Jost-400' !important;
}

.successContainerModal {
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  width: 524px;
  height: 233px;
  background: white;
  font-family: 'Jost-400' !important;
}

.inviteDialog {
  transform: translate(17%, -5%) !important;
}

.inviteUserHeader {
  height: 23px;
  width: 161px;
  background-image: url('../../../../images/newUserIcon.svg');
  background-repeat: no-repeat;
  /* background-position-x: 17px; */
  background-position-y: 2px;
  /* text-align: 22px; */
  margin-bottom: 14px;
}

.inviteTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
  margin-left: 22px;
}

.row1 {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 1.6rem;
}

.row2 {
  margin-bottom: 1.6rem;
}

.row3 {
  margin-right: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.invitedText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  color: #4ac389;
}

.invitedWrapper {
  margin-right: 10px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .inviteUserHeader {
    height: 23px;
    width: 100%;
    background-image: url('../../../../images/newUserIcon.svg');
    background-repeat: no-repeat;
    background-position-y: 2px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
  }

  .mobileButtonWrapper {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 24px;
  }
}

@media screen and (max-width: 576px) {
  .lineWrapper {
    display: grid;
    grid-template-columns: auto 95px;
    grid-column-gap: 10px;
    align-items: center;
    font-family: 'Jost-300';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: #393939;
    margin-bottom: 12px;
  }

  .headerTitle {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    color: #7458d3;
  }
  .headerWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .header {
    margin-bottom: 24px;
  }
  .checkboxRow {
    margin-bottom: 20px;
  }
  .linkRow {
    gap: 10px;
  }
  .hrList {
    height: 198px;
    overflow-y: scroll;
    position: relative;
    border-bottom: none;
    margin-bottom: 18px;
  }
}

.dropDownClass {
  width: fit-content;
  font-family: 'Jost-400';
  height: fit-content;
}


.row {
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  align-items: center;
  height: fit-content;
}

.column {
  display: flex;
  width: 30%;
  margin-top: 20%;
  flex-direction: column;
  height: fit-content;
}

.row label {
  margin-bottom: 0;
}

.assessmentInput {
  margin-left: 2rem;
}

.selectGroup {
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
}

.select {
  padding: 0.2rem;
  border: 0.5px solid black;
  margin-left: 1rem;
  cursor: pointer;
}

.saveButton {
  margin-left: 10rem;
  margin-right: 2rem;
}

.templateButton {
  margin-left: 2rem;
}

.cancelButton {
  white-space: nowrap;
  width: fit-content;
}

.selected {
  background-color: lightgray;
  color: black;
}

.title {
  color: black;
  margin-right: 2rem;
}

.price {
  margin-top: 10rem;
}

.packageName {
  margin-top: 2.5rem;
}

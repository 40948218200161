.popover {
  display: flex;
  margin-right: 30px;
  align-items: center;
  flex-direction: row;
  padding: 6.5px 16px;
  gap: 12px;
  isolation: isolate;
  position: absolute;
  max-width: 135px;
  max-height: 36px;
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 0px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
  inset: unset !important;
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
.popover::after {
  position: absolute;
  right: -6.5px;
  top: calc(50% - 36px / 2 + 12px);
  content: '';
  width: 12px;
  height: 10px;
  background: #ffffff;
  border-radius: 1px;
  transform: rotate(45deg);
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
}

.raisingHands {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #585858;
  flex: none;
  order: 0;
  flex-grow: 0;
  /*z-index: 0;*/
}

.stayTunedText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #585858;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* z-index: 1;*/
}

.ContentBody {
  /* min-height: 92.5vh; */
  background-color: #ffff;
  padding-bottom: 20px;
  /* border-radius: 24px 24px 0 0; */
  padding-left: 38px;
  padding-right: 38px;
  overflow-x: hidden;
}

@media screen and (max-width: 876px) {
  .ContentBody {
    padding: 0px 20px 20px;
    overflow-x: auto;
  }
}

.wrapper {
  height: 100%;
  /* width: 260px !important; */
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: hidden;

  position: relative;
  flex: 0 1 18%;
  flex-wrap: nowrap;
  padding-left: 16px;
  pointer-events: inherit !important;

  /* There are two headers, one with 80px and one with 100px */
  max-height: calc(100vh - 180px);
  /* border-bottom: 2px solid #d2d2d2; */
  padding-bottom: 20px !important;
}

.row {
  position: absolute;
  left: 44px;
  top: 204px;
  background-color: white;
  z-index: 1;
  width: 175px;
  height: 10px;
  border: none;
}

.wrapper1 {
  height: 100%;
  /* width: 260px !important; */
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: hidden;

  position: relative;
  flex: 0 1 18%;
  flex-wrap: nowrap;
  padding-left: 16px;
  pointer-events: inherit !important;

  /* There are two headers, one with 80px and one with 100px */
  max-height: calc(100vh - 180px);
  /* border-bottom: 2px solid #d2d2d2; */
}

.backdropActive {
  /* Added gap for Cancel button */
  max-height: calc(100vh - 216px);
  padding-bottom: 50px !important;
}

.innerWrapper {
  height: 100%;
  padding-bottom: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.innerWrapper:hover {
  overflow: auto;
}

.hiringFunnelText {
  font-family: Jost-500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.4);
  margin-bottom: 10px;
}

.header {
  font-family: Jost-500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #585858;
  color: rgba(88, 88, 88, 0.4);
  margin-bottom: 10px !important;
}

.stageText {
  height: 36px;
  font-family: Jost-400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
  margin-left: 30px;
  border: none;
  background-color: transparent;
  padding: 0;
}

.stageText:hover {
  background: #f4f4f4;
  border-radius: 8px;
  height: 36px;
  font-family: Jost-400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
  border: none;
  width: 175px;
  cursor: pointer;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
}

.stageTextSelected {
  height: 36px;
  font-family: Jost-400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
  background: #585858;
  border-radius: 8px;
  border: none;
  color: #ffffff;

  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
}

.stageTextSelected:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #585858;
  height: 36px;
  cursor: pointer;
  font-family: Jost-400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
  margin-left: 30px;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
}

.rowWrapper {
  margin-left: 16px;
  margin-top: 10px;
  z-index: 3 !important;
}

.rowWrapper ul {
  margin-bottom: 10px;
}

.newStageText {
  font-family: Jost-400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: rgba(116, 88, 211, 0.6);
  border: none;
  background-color: transparent;
  margin-top: -4px;
  margin-bottom: 9px;
  margin-left: 30px;
  padding: 0 !important;
}

.newStageText:hover {
  text-decoration-line: underline;

  /* logo/purple/purple80 */

  color: rgba(116, 88, 211, 0.8);
}

.inboxText {
  height: 30px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #32cbe0;
  align-self: center;
}

.inboxWrapper {
  margin-top: 5%;
  max-width: 100%;
  display: flex;
}

.inboxOuterWrapper {
  display: grid;
  grid-template-rows: 1fr auto;
}

.inProcessOuterWrapper {
  display: grid;
  /* grid-template-rows: 1fr auto 1fr; */
}

.inProcessWrapper {
  margin-top: 5%;
  max-width: 100%;
  display: flex;
}

.inProcessText {
  height: 33px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #7458d3;
}

.hiredOuterWrapper {
  margin-top: 5%;
  max-width: 100%;
  display: flex;
}

.rejectedOuterWrapper {
  margin-top: 5%;
  max-width: 100%;
  display: flex;
}

.hiredText {
  height: 33px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #4ac489;
}

.rejectedText {
  height: 33px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #de4747;
}

.stageWrapper {
  max-width: 100%;
  border-left: 1px solid rgba(88, 88, 88, 0.2);
  -webkit-backface-visibility: hidden;
}

.stageWrapperBlack {
  max-width: 100%;
  border-left: 3px solid #585858;
  margin-left: -1px;
}

@media screen and (min-width: 1060px) {
}

@media screen and (max-width: 1060px) {
}

@media screen and (max-width: 760px) {
}

@media screen and (min-width: 1396px) {
}

@media screen and (min-width: 1726px) {
}

ul {
  padding-inline-start: 0px;
}

/* .noFlickr {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
} */

.dot {
  font-size: 4px;
  margin-right: 5px;
  margin-left: 5px;
  height: 32px;
  margin-top: 8px;
}

#stageName {
  font-family: Jost-400;
  font-size: 16px;
  line-height: 23px;
}

.helper div {
  color: rgba(116, 88, 211, 0.6) !important;
  background-image: none !important;
}

.helper {
  border-left-color: rgba(116, 88, 211, 0.6) !important;
}



.personalInfoWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  gap: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
}

.personalInfoHeader {
  font-family: 'Jost-500';
  font-size: 18px;
  line-height: 26px;
  color: #393939;
}

.personalInfoDoubleWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.singleInfoWrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.singleInfoIconWrapper {
  background: #f6f6f6;
  border-radius: 12px;
  width: 46px;
  min-width: 46px;
  height: 46px;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.singleInfoTextWrapper {
  display: flex;
  flex-direction: column;
}

.singleInfoTitle {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  color: rgba(57, 57, 57, 0.6);
}

.singleInfoSubtext {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.personalInfoBorder {
  border: 0.5px solid rgba(57, 57, 57, 0.1);
  width: 100%;
}

.personalInfoMobileBorder {
  display: none;
}

@media screen and (max-width: 576px) {
  .personalInfoWrapper {
    padding: 20px 28px 20px 20px;
    gap: 20px;
  }

  .personalInfoHeader {
    padding-bottom: 8px;
  }

  .personalInfoDoubleWrapper {
    flex-direction: column;
    gap: 20px;
  }

  .personalInfoMobileBorder {
    display: initial;
  }

  .singleInfoWrapper {
    width: 100%;
  }
}

.headerWrapper {
  flex-direction: row;
  position: relative;
  display: flex;
  align-items: center;
}

.headerTab {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  text-align: center;
  letter-spacing: -0.02em;
  color: #585858;

  align-items: center;
  justify-content: center;

  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 600ms;
  z-index: 2;
  position: absolute;
}

.headerTab[myDisabled='true'] {
  color: rgba(57, 57, 57, 0.3);
  cursor: default;
}

.selectedTab {
  background: #585858;
  border-radius: 12px;
  z-index: 1;
  position: absolute;
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  display: flex;
  flex-direction: row;
}

.selected {
  color: #ffffff;
}

.TabLabel {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;
  background: rgba(255, 187, 1, 0.15);
  border-radius: 6px;

  font-family: 'Jost-500';
  font-size: 12px;
  line-height: 17px;

  color: #ffbb01;
}

.wrapper {
  font-family: Jost-400;
  /* margin-top: 100px; /* delete later */
  height: 100%;
  position: relative;
}

.list {
  box-sizing: border-box;
  background: #ffffff;
  margin-top: 0.8rem;
  border: 1px solid #7458d3;
  border-radius: 10px;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  z-index: 5;
  position: absolute;
  overflow-y: auto;
  height: fit-content;
  width: 100%;
}

.listItem {
  overflow: hidden;
  width: 100%;
  border: none;
  background: transparent;
  white-space: nowrap;
  padding: 1rem 0rem;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.6rem;
  text-align: left;
  padding-left: 1.6rem;
  color: #585858;
}

.firstItem {
  border-radius: 10px 10px 0 0;
  justify-content: space-between;
}
.firstItem:hover {
  background: rgba(244, 244, 244, 0.8);
}

.lastItem {
  border-radius: 0 0 10px 10px;
}
.lastItem:hover {
  background: rgba(244, 244, 244, 0.8);
}

.selectedItem {
  color: #7458d3;
}
.listItem:hover {
  background: rgba(244, 244, 244, 0.8);
}
.arrowUp {
  background-image: url('./arrowUpPurple.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  margin-right: 1rem;
  margin-left: 0.4rem;
  align-self: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.arrowDown {
  background-image: url('./arrowDownGray.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  margin-right: 1rem;
  margin-left: 0.4rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  cursor: pointer;
}

.closeNoSelect:hover .arrowDown {
  background-image: url('./arrowDownPurple.svg');
}

.closeSelect:hover .arrowDown {
  background-image: url('./arrowDownPurple.svg');
}

.dd-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  line-height: 2.3rem;
  border-radius: 10px;
  justify-content: space-between;
}

.dd-container[aria-disabled='true'] {
  pointer-events: none;
  opacity: 0.7;
}

.closeNoSelect {
  background: #fff;
  border: 1px solid rgba(88, 88, 88, 0.2);
  align-self: center;
}
.closeNoSelect::before {
  background: #fff;
  border: 2px solid rgba(88, 88, 88, 0.2);
  align-self: center;
}

.closeSelect {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
}
.closeSelect::before {
  background: #ffffff;
  border: 2px solid rgba(88, 88, 88, 0.2);
}

.openNoSelect {
  background: #ffffff;
  border: 1px solid #7458d3;
}

.openNoSelect::before {
  background: #ffffff;
  border: 2px solid #7458d3;
}

.openSelect {
  background: #ffffff;
  border: 1px solid #7458d3;
}
.openSelect::before {
  background: #ffffff;
  border: 2px solid #7458d3;
}




.placeholder {
  color: #393939;
  padding: 2rem 0.2rem 0.5rem 1.6rem !important;
  letter-spacing: -0.02em;
  font-size: 1.6rem;
  line-height: 2.3rem;
  white-space: pre;
  align-items: center;
  display: flex;
}

.openNoSelect .placeholder {
  color: rgba(88, 88, 88, 0.2);
}
.title {
  transition-property: transform;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}
.closeNoSelect .title {
  /* padding-top: 1.5rem;
  padding-bottom: 1.5rem; */
  display: flex;
  align-items: center;
  padding-left: 1.6rem !important;
  margin-bottom: 0;
  color: rgba(88, 88, 88, 0.6);
}

.openNoSelect .title {
  position: absolute;
  transform: translate(0, 0.4rem) scale(0.8) !important;
  transform-origin: top left !important;
  font-size: 1.2rem !important;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  padding-left: 1.6rem !important;
  color: #7458d3;
}
.openSelect .title {
  position: absolute;
  transform: translate(0, 0.4rem) scale(0.8) !important;
  transform-origin: top left !important;
  font-size: 1.2rem !important;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  padding-left: 1.6rem !important;
}

.closeSelect .title {
  position: absolute;
  transform: translate(0, 0.4rem) scale(0.8) !important;
  transform-origin: top left !important;
  font-size: 1.2rem !important;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  padding-left: 1.6rem !important;
}

.closeSelect:hover .title {
  color: rgba(116, 88, 211, 1) !important;
}
.closeNoSelect:hover .title {
  color: rgba(116, 88, 211, 0.6) !important;
}

.cross {
  background-image: url('./grayCross.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  display: flex;
  flex-direction: column;
  align-self: center;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  flex-direction: row;
}

.closeSelect:hover .cross {
  background-image: url('./lightPurpleCross.svg');
}

.closeNoSelect:hover .cross {
  background-image: url('./lightPurpleCross.svg');
}

.openNoSelect .cross {
  background-image: url('./darkPurpleCross.svg');
}

.openSelect .cross {
  background-image: url('./darkPurpleCross.svg');
}

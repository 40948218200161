.inputContainer {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  font-family: Jost-400;
  font-size: 1.6rem;
}

.eye {
  position: absolute;
  left: 0;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.inputContainer:focus-within label {
  transform: translate(0, 0.5rem) !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: #7458d3 !important;
}
.errorContainer:focus-within label {
  transform: translate(0, 0.5rem) !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: #de4747 !important;
}
.inputContainer .filled {
  transform: translate(0, 0.5rem) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.errorContainer .filled {
  color: #de4747;
}

.inputContainer input:-webkit-autofill ~ label {
  transform: translate(0, 0.5rem) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.inputContainer input:-webkit-autofill:hover ~ label {
  color: rgba(116, 88, 211, 0.6);
}
.inputContainer input:-webkit-autofill {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background-color: rgba(244, 244, 244, 0.4) !important;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: rgba(88, 88, 88, 0.6);
  -webkit-text-fill-color: #585857;
  -webkit-text-size-adjust: 20px;
}
.inputContainer input:-webkit-autofill::before {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background-color: rgba(244, 244, 244, 0.4) !important;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: rgba(88, 88, 88, 0.6);
  -webkit-text-fill-color: #585857;
  -webkit-text-size-adjust: 20px;
}

input:-autofill {
  font-size: 1.6rem;
}

.inputContainer label {
  position: absolute !important;
  pointer-events: none !important;
  transform: translate(0, 1.3rem) scale(1) !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 1.6rem !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}
.errorContainer label {
  position: absolute !important;
  pointer-events: none !important;
  transform: translate(0, 1.3rem) scale(1) !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 1.6rem !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #de4747;
}

.inputContainer input:hover {
  border: 1px solid rgba(116, 88, 211, 0.2);
  border-radius: 10px;
}

.inputContainer input:hover::before {
  border: 2px solid rgba(116, 88, 211, 0.2);
  border-radius: 10px;
}

.inputContainer:hover label {
  color: rgba(116, 88, 211, 0.6);
}
.errorContainer:hover label {
  color: #de4747;
}

.inputContainer input {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background: rgba(244, 244, 244, 0.4);
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: #585857;
}
.inputContainer input::before {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background: rgba(244, 244, 244, 0.4);
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: #585857;
}
.errorContainer input {
  background: #ffffff;
  border: 1px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.errorContainer input::before {
  background: #ffffff;
  border: 2px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.inputContainer input:focus {
  background: #ffffff;
  border: 1px solid #7458d3 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.inputContainer input:focus::before {
  background: #ffffff;
  border: 1px solid #7458d3 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}

.errorContainer input:focus {
  background: #ffffff;
  border: 1px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.errorContainer input:focus::before {
  background: #ffffff;
  border: 2px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.errorContainer input:focus label {
  background: #ffffff;
  border: 1px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}
.errorContainer input:focus label::before {
  background: #ffffff;
  border: 2px solid #de4747 !important;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #393939;
  background-image: none !important;
}

.inputContainer input[type='password'] {
  font-weight: bold;
  -webkit-text-stroke-width: 0.15rem;
  letter-spacing: 0.09rem;
}
.inputContainer .filledInput {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  color: #393939;
}
.inputContainer .filledInput::before {
  background: #ffffff;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  color: #393939;
}

.inputContainer .filledInput:hover {
  background: #ffffff;
  border: 1px solid rgba(116, 88, 211, 0.2);
  border-radius: 10px;
  background-image: url('../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  transition: 0ms !important;
}
.inputContainer .filledInput:hover::before {
  background: #ffffff;
  border: 2px solid rgba(116, 88, 211, 0.2);
  border-radius: 10px;
  background-image: url('../../images/edit.svg');
  background-repeat: no-repeat;
  background-position: right 14px center;
  transition: 0ms !important;
}

.passwordConstraintErrorWrapper {
  font-family: 'Jost-400';
  flex-wrap: wrap;
  font-size: max(1.2rem, 12px);
  line-height: 1.7rem;
  color: #de4747;
  margin-left: 0.5rem;
  margin-top: 2px;

  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 576px) {
  .inputContainer input[type='password'] {
    font-weight: 400;
    -webkit-text-stroke-width: 0.05rem;
    letter-spacing: 0;
  }
}

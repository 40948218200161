.wrapper {
  /* margin-right: 46px;
  margin-left: 43px; */
  /* cursor: pointer; */
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.closeWrapper {
  height: 61px;
  width: 100%;
  padding: 16px 10px 18px 22px;
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: rgba(57, 57, 57, 0.8);
  background: rgba(244, 244, 244, 0.6);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
}

.openWrapper {
  border-radius: 12px;
  height: 341px;
  width: 100%;
  padding: 16px 10px 18px 22px;
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: rgba(57, 57, 57, 0.8);
  background: rgba(244, 244, 244, 0.6);
  display: flex;
  flex-direction: column;
}

.countWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
}

.count {
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  color: rgba(57, 57, 57, 0.8);
  margin-right: 5px;
}

.users {
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  color: rgba(57, 57, 57, 0.8);
  margin-right: 8px;
  font-family: 'Jost-300';
}

.vectorIcon {
  /* background-image: url('../Images/rightIcon.svg');
  background-position: center;
  background-repeat: no-repeat; */
  width: 24px;
  /* height: 24px; */
  cursor: pointer;
  transition-duration: 200ms;
  transition-property: all;
}

.open {
  transform: rotate(90deg);
  width: 24px;
  /* height: 24px; */
  cursor: pointer;
  transition-duration: 200ms;
  transition-property: all;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  margin-bottom: 14px;
}

.roleText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: rgba(57, 57, 57, 0.8);
  display: flex;
  flex-direction: row;
}

.searchWrapper {
  display: grid;
  grid-template-columns: auto 175px;
  grid-column-gap: 2%;
  position: relative;
  margin-bottom: 14px;
  width: 100%;
  align-items: center;
}

.SelectAllWrapper {
  margin-left: 11px;
  padding-bottom: 8px;
  margin-bottom: 4px;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
  display: flex;
  align-items: center;
}

.listWrapper {
  width: 100%;
  position: relative;
  padding-right: 12px;
  /* height: 250px; */
  overflow-y: auto;
}

.checkboxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-left: 11px;
  margin-bottom: 8px;
}

.checkboxWrapper {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hrText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.imgWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.invitedWrapper {
  margin-right: 10px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.invitedText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: right;
  color: #4ac389;
}

@media screen and (max-width: 700px) {
  .wrapper {
    width: 100vw;
    margin-left: -20px;
    margin-bottom: 60px;
  }

  .openWrapper {
    border-radius: 0px;
    height: 341px;
    width: 100%;
    padding: 16px 10px 18px 22px;
    font-family: 'Jost-400';
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    color: rgba(57, 57, 57, 0.8);
    background: rgba(244, 244, 244, 0.6);
    display: flex;
    flex-direction: column;
  }

  .closeWrapper {
    height: 61px;
    width: 100%;
    padding: 16px 10px 18px 22px;
    font-family: 'Jost-400';
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    color: rgba(57, 57, 57, 0.8);
    background: rgba(244, 244, 244, 0.6);
    border-radius: 0px;
    display: flex;
    flex-direction: row;
  }

  .roleText {
    font-size: 16px;
  }
  .count {
    font-size: 16px;
    font-family: Jost-300;
  }
  .users {
    font-size: 16px;
  }

  .searchWrapper {
    display: grid;
    grid-template-columns: auto 95px;
    grid-column-gap: 2%;
    position: relative;
    margin-bottom: 14px;
    width: 100%;
    align-items: center;
  }
  .checkboxRow {
    margin-bottom: 18px;
    margin-left: 0px;
  }

  .SelectAllWrapper {
    margin-bottom: 9px;
    margin-left: 0px;
  }
}

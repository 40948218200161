.BodyWrapper {
  padding: 20px;
  font-family: 'Jost-300';
}

.MultipleChoiceWrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  max-height: 400px;
  padding: 20px;
  overflow-y: scroll;
  /* overflow-x: hidden; */
}

.FreeTextWrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  max-height: 400px;
  padding: 20px;
  overflow-y: scroll;
}

.CodingWrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  max-height: 400px;
  padding: 20px;
  overflow-y: scroll;
  width: 49.5%;
}

.CodingEditorWrapper {
  border-radius: 8px;
  max-height: 400px;
  width: 49.5%;
}

.MonacoEditor :global(.monaco-editor) {
  max-height: 390px;
  border-radius: 8px;
  padding-top: 10px;
}

.MonacoEditor  :global(.monaco-editor .overflow-guard)   {
  /* background-color: red; */
  border-radius: 8px;
  max-height: 390px;
}


.MultipleChoiceQuestionText {
  /* font-family: 'News Cycle'; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #585858;
}

.MultipleChoiceQuestionText p {
  font-weight: 700 !important;
}

.FreeTextQuestionText {
  /* font-family: 'News Cycle'; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #585858;
}

.FreeTextQuestionText p {
  font-weight: 700 !important;
}

.CodingQuestionText {
  /* font-family: 'News Cycle'; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #585858;
}

.CodingQuestionText p {
  font-weight: 700 !important;
}

.OptionWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;
}

.FreeTextAnswerWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  margin-bottom: 10px;

  /* font-family: 'News Cycle'; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #585858;
}

.OptionCheckbox {
  width: 20px;
  min-width: 20px !important;
  height: 20px;
  background: rgba(221, 58, 58, 0.2);
  border: 1px solid rgba(222, 71, 71, 0.8);
  border-radius: 10px;
  margin-top: 5px;
}

.OptionText {
  margin-left: 12px;

  /* font-family: 'News Cycle'; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  flex-wrap: wrap;
  /* darkGray/darkGray60 */
  color: rgba(88, 88, 88, 0.6);
  /* Inside auto layout */
  /* flex: none;
  order: 1;
  flex-grow: 0; */
}

.SelectedOptionText {
  color: #585858;
}

.CorrectOption {
  /* NGreen/NGreen20 */
  background: rgba(74, 195, 137, 0.2);
  /* NGreen/NGreen80 */
  border: 1px solid rgba(74, 195, 137, 0.8);
}

.SelectedOption {
  width: 14px;
  min-width: 14px !important;
  height: 14px;
  margin-left: 2px;
  margin-top: 2px;
  border-radius: 10px;
  background: rgba(222, 71, 71, 0.8);
}

.CorrectSelectedOption {
  background: rgba(74, 195, 137, 0.8);
}

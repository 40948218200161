.headerWrapper {
  display: flex;
  flex-direction: row;
  width: 89%;
  min-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
  justify-content: space-between;
}

.headerItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerText {
  width: fit-content;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  white-space: nowrap;
  line-height: 23px;
}

.tick {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.numberText {
  max-height: fit-content;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 13.7554px;
  line-height: 20px;
  color: #ffffff;
}

.activeNumber {
  background: #7458d3;
}

.pastNumber {
  background: rgba(116, 88, 211, 0.1);
}

.inactiveNumber {
  background: #d9d9d9;
}

.past {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: rgba(116, 88, 211, 0.3);
}

.active {
  font-family: 'Jost-400';
  font-weight: 400;
  color: #7458d3;
}

.inactive {
  font-family: 'Jost-300';
  font-weight: 300;
  color: #c1c1c1;
}

.arrow {
  width: 20px;
  height: 20px;
  margin-top: 0.2rem;
}

.number {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50%;
  color: white;
}

@media screen and (max-width: 700px) {
  .headerWrapper {
    min-width: 100%;
    border-bottom: 1px solid rgba(88, 88, 88, 0.1);
    padding-bottom: 17px;
    margin-top: 16px;
  }

  .headerText {
    font-size: 14px;
  }

  .activeNumber {
    font-size: 12px;
  }

  .inactiveNumber {
    font-size: 12px;
  }

  .numberText {
    font-size: 12px;
  }
}

.title {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  text-align: center;
  color: #393939;
  margin-bottom: 1.6rem;
}

.subtitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #393939;
}

.alreadyAnsweredTextWrapper {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
}

.questionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2.8rem;
}
.question {
  display: flex;
  align-items: center;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}
.text {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.answer {
  margin-top: 1.6rem;
}
.wrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 1.6rem;
}
.multiWrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 1.6rem;
}
.inputWrapper {
  display: flex;
  height: 4.8rem;
  align-items: center;
}
.checkboxWrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;
  margin-right: 2.4rem;
  padding-left: 0.2rem;
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}



/* Multi Select Dropdown styling */
.MultiSelectDropdown {
  background: #FFFFFF;
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  min-height: 4.8rem;
  font-size: 1.6rem;
  font-family: 'Jost-400';
}
.MultiSelectDropdown::before {
  background: #FFFFFF;
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  min-height: 4.8rem;
  font-size: 1.6rem;
  font-family: 'Jost-400';
}

.MultiSelectDropdown :global(.searchWrapper) {
  height: 100%;
  border: none;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 0.8rem;
  padding-left: 1.6rem;
  min-height: 4.8rem;
  height: 100%;
}

.MultiSelectDropdown :global(.searchWrapper:focus-within) {
  border: 1px solid #7458D3 !important;
}
.MultiSelectDropdown :global(.searchWrapper:focus-within)::before {
  border: 2px solid #7458D3 !important;
}
.MultiSelectDropdown :global(.searchWrapper .chip) {
  margin: 0.2rem 0.4rem 0.2rem 0rem;
  font-size: 1.2rem;
  line-height: 1.9rem;
  background: #7458D3;
  border-radius: 8px;
  padding: 0.4rem 1rem;
}

/* .MultiSelectDropdown :global(.searchWrapper .chip .closeIcon) {
  height: 10px;
  width: 10px;
} */

.MultiSelectDropdown :global(.searchWrapper .searchBox) {
  margin: 0rem;
  color: #585858;
}

.MultiSelectDropdown :global(.searchWrapper .searchBox::placeholder) {
  color: rgba(57, 57, 57, 0.5);
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer) {
  border-radius: 10px;
  border: 1px solid #7458D3 !important;
  max-height: 15.6rem;
}

.MultiSelectDropdown :global(.optionListContainer) {
  margin-top: 10px;
  font-size: 1.6rem;
  color: #585858;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .option:hover) {
  background: rgba(244, 244, 244, 0.8);
  color: #585858;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .option) {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5.2rem;
  padding-left: 1.6rem;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .notFound) {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5.2rem;
  padding-left: 1.6rem;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .option input[type='checkbox']) {
  -webkit-appearance: none;
	background-color: rgba(255, 255, 255, 0.6);
	border: 1px solid #585858;
	/* box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05); */
	border-radius: 6px;
  height: 19.2px;
  width: 19.2px;
  margin-top: 0px;
  cursor: pointer
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .option input[type='checkbox']:active) {
	border: 1px solid #7458D3;
}

.MultiSelectDropdown :global(.optionListContainer .optionContainer .option input[type='checkbox']:checked) {
	background-color: #7458D3;
	border: 1px solid #7458D3;
  background-image: url('../../../../Common/RawCheckbox/tick.svg');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.InvalidNumber {
  color: #de4747;
  margin-top: 0.4rem;
}

.salaryWrapper {
  padding-bottom: 1.2rem;
}

@media screen and (max-width: 576px) {
  .multiWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .wrapper {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
  }

  .checkboxWrapper {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .questionWrapper {
    padding-bottom: 1.8rem;
  }

}

@media screen and (max-width: 400px) {
  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .checkboxWrapper {
    margin-bottom: 1.6rem;
  }
}
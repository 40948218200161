.mainContainer {
  display: flex;
  flex: 1 0 32%;
  margin-right: 2%;
  max-width: 32%;
}

.mainContainer2 {
  display: flex;
  flex: 1 0 48%;
  margin-right: 2%;
  max-width: 48%;
}

.container {
  display: flex;
  width: 100%;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding: 14px 9px 14px 12px;
  justify-content: space-between;
  transition: all 0.2s linear;
  margin-bottom: 16px;
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.1);
}
.mobileContainer {
  display: flex;
  width: 100%;
}

.leftColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioContainer {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-right: 14px;
  border: 1px solid #585858;
}

.text {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  line-height: 23px;
  color: #000000;
}

.active {
  border: 1px solid #7458d3;
}

.radioButton {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  background: #7458d3;
  opacity: 0;
  transition: all 0.2s linear;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
}

.pageWrapper h1 {
  font-family: Jost-400;
  font-size: 20px;
  margin: 0;
  padding-left: 48px;
  margin-bottom: 2px;
  padding-top: 18px;
  line-height: 30px;
  width: 100%;
  text-transform: uppercase;
}

/* .pageWrapper br:first-child {
  display: none;
} */

.question {
  width: 100%;
  height: fit-content;
  font-family: Jost-400;
  font-size: 18px;
}

.title {
  width: 100%;
  padding-left: 48px;
  text-transform: uppercase;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  max-width: 1100px;
  min-width: 750px;
  /* height: calc(100vh - 80px);  */
  padding-top: 25px;
}

.heading {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  width: 100%;
  padding-left: 48px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: rgba(57, 57, 57, 0.5);
}

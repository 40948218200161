.wrapper {
  width: 149px;
  height: 140px;
  display: flex;
  flex-direction: column;
}

.point{
  margin-left: 8px;
  margin-right: 6px;
}
.table {
  width: 149px;
  border: 1px solid #F4F4F4;
  border-radius: 8px;
  height: 65px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.candidate {
  background: #FFFFFF;
  /* color: #FF7E62; */
  margin-bottom: 10px;
}

.score {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  margin-left: 19px;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 23px;
}
.row{
  display: flex;
  margin-top: 6px;
  flex-direction: row;
  align-items:center;
}

.industry {
  background: #F4F4F4;
  color: rgba(88, 88, 88, 0.8);
}

.container {
  padding: 0rem;
  width: 100%;
  overflow-y: hidden;
  user-select: none;
  background: #fff;
  box-shadow: 4px 0px 12px rgba(138, 138, 138, 0.15);
}
/* 
.bottomShadow {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10001;
  box-shadow: inset 0px -45px 35px -15px rgb(88 88 88 / 55%);
  pointer-events: none;
  bottom: 0;
}
.topShadow {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10001;
  box-shadow: inset 0px 45px 35px -15px rgb(88 88 88 / 55%);
  pointer-events: none;
} */

.mobileFunnelHeader {
  display: flex;
  flex-direction: row;
  margin: 14px 18px 8px 0px;
  align-items: center;
}

.mobileFunnelHeaderText {
  padding: 0px 12px 0rem 24px;
  font-family: 'Jost-500';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #7458d3;
}

.CategoryWrapper {
  padding: 1.4rem 1.2rem 1.4rem 1.6rem;
  background: #fff;
  z-index: 1001;
  position: relative;
  border-radius: 0px 12px 12px 0px;
  overflow-y: hidden;
}

.scrollPresent {
  padding-right: 12px !important;
}

/* .CategoryWrapper:hover {
  overflow-y: auto;
  padding-right: 0px;
} */

.mouseEnter {
  overflow-y: auto !important;
  padding-right: 0px !important;
}

/* Funnel Category */
.category-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.category-name {
  font-family: 'Jost-500';
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.4);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.itemWrapper {
  border-radius: 1.5px;
}

.newStageButton {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: rgba(116, 88, 211, 0.6);
  padding-left: 1rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
}

.newStageButton:hover {
  text-decoration: underline;
}

.closeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.closeIcon {
  margin-bottom: 10px;
  cursor: pointer;
}

/* Funnel Item */
.item {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 2.3rem;
  padding-left: 1rem;
  border-left: 2px solid rgba(88, 88, 88, 0.2);
  justify-content: space-between;
}

.item-name {
  width: calc(100% - 30px);
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
}

.icon-wrapper {
  height: 2.8rem;
  width: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.icon-wrapper-mobile {
  height: 32px;
  width: 32px;
  opacity: 1 !important;
  pointer-events: auto !important;
}

.icon-wrapper-mobile img {
  scale: 1.2;
}

[categoryNo='30'] .icon-wrapper:hover {
  opacity: 1;
  cursor: pointer !important;
}
[categoryNo='20'] .icon-wrapper:hover {
  opacity: 1;
  cursor: pointer !important;
}

.infoIcon:hover > img {
  content: url('./info-icon-hover.svg');
}
.editIcon:hover > img {
  content: url('./edit-icon-hover.svg');
}

/* Funnel items when selected */

[id='funnelItem-Scored'] .selected {
  background-color: #32cbe0;
  color: #fff;
}

[id='funnelItem-Not Scored'] .selected {
  background-color: #32cbe0;
  color: #fff;
}

[id='funnelItem-All Candidates'] .selected {
  background-color: #585858;
  color: #fff;
}

[id='funnelItem-Hired'] .selected {
  background: #4ac389;
  color: #fff;
}

[id='funnelItem-Rejected'] .selected {
  background: #de4747;
  color: #fff;
}
[categoryNo='30'] .selected {
  background: #7458d3;
  color: #fff;
}
/* [categoryNo='25'] .selected {
  background: #ffb800;
  color: #fff;
} */
/* Selected funnel items' borders */

[id='funnelItem-Not Scored']:has(.selected) {
  border-left: 2px solid #32cbe0;
}
[id='funnelItem-Scored']:has(.selected) {
  border-left: 2px solid #32cbe0;
}
[id='funnelItem-Hired']:has(.selected) {
  border-left: 2px solid #4ac389;
}
[id='funnelItem-Rejected']:has(.selected) {
  border-left: 2px solid #de4747;
}
[categoryNo='30']:has(.selected) {
  border-left: 2px solid #7458d3;
}
/* [categoryNo='25']:has(.selected) {
  border-left: 2px solid #ffb800;
} */
[id='funnelItem-All Candidates']:has(.selected) {
  border-left: 2px solid #585858;
}

/* Funnel items when hover on selected */

[id='funnelItem-Scored'] .selected.moveHover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #32cbe0;
  color: #fff;
}

[id='funnelItem-Not Scored'] .selected.moveHover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #32cbe0;
  color: #fff;
}

[id='funnelItem-Hired'] .selected.moveHover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #4ac389;
  color: #fff;
}

[id='funnelItem-Rejected'] .selected.moveHover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #de4747;
  color: #fff !important;
}

[categoryNo='30'] .selected.moveHover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #7458d3;
  color: #fff;
}

/* Funnel items when hovered */

[id='funnelItem-Scored'] .moveHover {
  color: #32cbe0;
  background: rgba(57, 57, 57, 0.1);
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px !important;
}

[id='funnelItem-Not Scored'] .moveHover {
  color: #32cbe0;
  background: #f4f4f4;
}

[id='funnelItem-Hired'] .moveHover {
  color: #4ac389;
  background: rgba(57, 57, 57, 0.1);
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px !important;
}

[id='funnelItem-Rejected'] .moveHover {
  color: #de4747;
  background: rgba(57, 57, 57, 0.1);
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px !important;
}

[categoryNo='30'] .moveHover {
  color: #7458d3;
  background: rgba(57, 57, 57, 0.1);
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px !important;
}
/* [categoryNo='25'] .moveHover {
  color: #ffb800;
  background: rgba(57, 57, 57, 0.1);
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px !important;
} */

/* Funnel items when icon hovered */
.infoIcon:hover .item-name {
  color: #32cbe0;
}
.editIcon:hover + .item-name {
  color: #7458d3;
}

.ShareIconWrapper {
  display: none;
}

/* Media Queries */

@media screen and (min-width: 1920px) {
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
}
@media screen and (min-width: 960px) and (max-width: 1280px) {
}
@media screen and (min-width: 720px) and (max-width: 960px) {
}
@media screen and (max-width: 720px) {
}

@media screen and (max-height: 650px) {
  .CategoryWrapper {
    padding: 1rem 1.2rem 0.4rem 1.6rem;
  }

  .category-container {
    margin-bottom: 5px;
  }
  .closeWrapper {
    height: 18px;
  }
  .item-name {
    height: 32px;
  }
  .item {
    height: 34px;
  }
  .newStageButton {
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .CategoryWrapper {
    padding-top: 0px;
    padding-left: 24px;
    overflow-y: auto;
  }

  .category-container {
    max-width: 400px;
  }

  .item {
    height: 44px;
  }

  .item-name {
    width: calc(100% - 40px);
    height: 40px;
  }

  .icon-wrapper:hover {
    opacity: 0 !important;
    pointer-events: none;
  }
}

@media screen and (hover: hover) {
  .item-name:hover {
    background: #f4f4f4;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px !important;
  }

  .item-name:hover + .icon-wrapper {
    opacity: 1;
  }

  /* Funnel items when hover on selected */
  [id='funnelItem-Scored'] .selected:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #32cbe0;
    color: #fff;
  }

  [id='funnelItem-Not Scored'] .selected:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #32cbe0;
    color: #fff;
  }

  [id='funnelItem-All Candidates'] .selected:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #585858;
    color: #fff;
  }

  [id='funnelItem-Hired'] .selected:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #4ac389;
    color: #fff;
  }

  [id='funnelItem-Rejected'] .selected:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #de4747;
    color: #fff !important;
  }

  [categoryNo='30'] .selected:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #7458d3;
    color: #fff;
  }
  /* [categoryNo='25'] .selected:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #ffb800;
    color: #fff;
  } */

  /* Funnel items when hovered */
  [id='funnelItem-Scored']:hover {
    color: #32cbe0;
  }

  [id='funnelItem-Not Scored']:hover {
    color: #32cbe0;
  }

  [id='funnelItem-All Candidates']:hover {
    color: #585858;
  }

  [id='funnelItem-Hired']:hover {
    color: #4ac389;
  }

  [id='funnelItem-Rejected'] .item-name:hover {
    color: #de4747;
  }

  [categoryNo='30']:hover {
    color: #7458d3;
  }
  /* [categoryNo='25']:hover {
    color: #ffb800;
  } */
  [noInteraction='not-allowed']:hover {
    color: #787878;
  }
}

@media screen and (hover: hover) and (min-width: 576px) {
  /* default value - In Review background color */
  .item-name:hover .ShareIconWrapper {
    display: flex;
    margin-right: auto;
    margin-left: 10px;
    border-radius: 20px;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
  }

  .link {
    height: 14px;
    width: 14px;
    margin-top: auto;
  }

  /* Stroke colors for In Review */
  .ShareIconWrapper [id='shareIconTooltip'] .link path {
    stroke: #7458d3;
  }

  /* Stroke colors for each category */
  .item-name:hover [id='shareIcon-Scored'] [id='shareIconTooltip'] .link path {
    stroke: #32cbe0;
  }

  .item-name:hover [id='shareIcon-Not Scored'] [id='shareIconTooltip'] .link path {
    stroke: #32cbe0;
  }

  .item-name:hover [id='shareIcon-All Candidates'] [id='shareIconTooltip'] .link path {
    stroke: #585858;
  }

  .item-name:hover [id='shareIcon-Hired'] [id='shareIconTooltip'] .link path {
    stroke: #4ac389;
  }

  .item-name:hover [id='shareIcon-Rejected'] [id='shareIconTooltip'] .link path {
    stroke: #de4747;
  }
  /* .item-name:hover [id='shareIcon-Requested'] [id='shareIconTooltip'] .link path {
    stroke: #ffb800;
  } */

  /* Hover states for In Review */
  .ShareIconWrapper:hover {
    background: #7458d3;
  }

  /* Hover states for each category */
  [id='shareIcon-Scored']:hover {
    background: #32cbe0;
  }

  [id='shareIcon-Not Scored']:hover {
    background: #32cbe0;
  }

  [id='shareIcon-All Candidates']:hover {
    background: #585858;
  }

  [id='shareIcon-Hired']:hover {
    background: #4ac389;
  }

  [id='shareIcon-Rejected']:hover {
    background: #de4747;
  }
  /* [id='shareIcon-Requested']:hover {
    background: #ffb800;
  } */
  [noInteraction='not-allowed']:hover {
    color: #787878;
  }

  /* Hover state stroke colors */
  .ShareIconWrapper:hover [id='shareIconTooltip'] .link path,
  .item-name:hover.selected .ShareIconWrapper [id='shareIconTooltip'] .link path {
    stroke: #ffffff !important;
  }

  .item-name:hover.selected .ShareIconWrapper:hover {
    background: rgba(244, 244, 244, 0.2);
  }
}

/* Copy Stage Info POPUP styling */
.copyModalWrapper {
  display: flex;
  flex-direction: column;
}
.copiedSpan {
  font-size: 16px;
  font-family: Jost-400;
  margin-bottom: 50px;
  user-select: initial;
}
.copiedHeader {
  font-family: 'Jost-400';
  font-size: 20px;
  color: #585858;
}

.react-multi-email {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  padding: 0.7rem 1.2rem;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 20px;
  min-height: 44px;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: -0.02em;
  color: #585858;
  overflow-y: auto;
  max-height: 27.3rem;
}
.react-multi-email input::placeholder {
  color: #b6b6b6;
}

.react-multi-email:hover:not(:focus-within) input::placeholder {
  color: rgba(116, 88, 211, 0.6);
}

.react-multi-email:hover {
  border: 1px solid rgba(116, 88, 211, 0.2);
}

.react-multi-email:focus-within {
  border: 1px solid #7458d3;
}

.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}

.react-multi-email.empty {
  background-color: #fff;
}
.react-multi-email {
  background-color: white;
}
.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  width: 30% !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  background: transparent;
  margin-left: 8px;
  font-size: 1.6rem;
}

.react-multi-email [data-tag] {
  vertical-align: baseline;
  background-image: none;
  padding: 0.5em 1em !important;
  display: inline-block;
  font-family: 'Jost-300';
  font-size: 1.6rem !important;
  font-weight: 400;
  margin-right: 5px;
  padding: 5px;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  background-color: rgba(116, 88, 211, 0.1) !important;
  border-radius: 6px !important;
  /* border: 1px solid rgba(116, 88, 211, 0.2) !important; */
  color: #7458d3 !important;
  margin-bottom: 5px;
  margin-top: 5px;
}

.react-multi-email [myvalid='not'] {
  background-color: rgba(222, 71, 71, 0.2) !important;
  color: rgba(222, 71, 71, 1) !important;
  border-color: rgba(222, 71, 71, 0.2) !important;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
  color: rgba(88, 88, 88, 0.8);
}

/* MOBILE Styling */

.emailWrapper{
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  box-sizing: border-box;
  min-height: 44px;
  letter-spacing: -0.02em;
  color: #585858;
  overflow-y: auto;
  max-height: 240px;
}

.inviteInMobile {
  position: absolute;
  bottom: 8px;
  left: 15px;
  font-family: 'Jost-300';
  font-size: 14px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #585858;
}

.purpleText {
  color: rgba(116, 88, 211, 1);
}

.normalText {
  color: rgba(57, 57, 57, 0.8);
}

.billingContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wrapper {
  background: #fafafa;
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  overflow: auto;
  flex-shrink: 1;
  flex-grow: 1;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  width: 85%;
  color: #393939;
}

.historyWrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  background: #ffffff;
}

.titleColumn {
  text-transform: uppercase;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(57, 57, 57, 0.5);
}

.historyTitles {
  display: grid;
  width: 100%;
  flex-direction: row;
  grid-template-columns: 1.5fr 3fr 2fr 1fr;
}

.infoWrapper {
  background-color: white;
  min-height: 270px;
  display: grid;
  padding: 10px 28px 22px 28px;
  position: relative;
}

.columnCell {
  width: 30%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-height: fit-content;
}

.placeInfo {
  font-family: 'Jost-300';
  font-style: normal;
  display: flex;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 2px;
  /* line-height: 23px; */
  white-space: wrap;
  width: fit-content;
  color: rgba(57, 57, 57, 0.84);
  word-break: break-all;
  max-width: 100%;
}

.cardRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.paymentTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(57, 57, 57, 0.5);
  text-align: center;
}

.lineBreak {
  margin-bottom: 18px;
  width: 50%;
}

.userName {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  width: fit-content;
  display: flex;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 23px;
  color: rgba(57, 57, 57, 0.84);
}

.userEmail {
  font-family: 'Jost-400';
  font-style: normal;
  display: flex;
  margin-bottom: 13px;
  width: fit-content;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: rgba(57, 57, 57, 0.84);
}

.titleRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  text-align: center;
}

.editIcon {
  cursor: pointer;
  display: none;
  border-radius: 20px;
  position: absolute;
  right: 14px;
  top: 14px;
}

.row {
  display: flex;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
}
.infoColumn {
}

.infoTitle {
  display: flex;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(57, 57, 57, 0.5);
}

.paginationWrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 32px;
  margin-right: 10px;
}

.paginationArrows {
  cursor: pointer;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.billingInfoContainer {
  display: flex;
  flex-direction: row;
  gap: 5%;
  justify-content: space-between;
}
.billedToContainer {
  width: 60%;
}
.paymentMethodContainer {
  width: 40%;
}
.historyContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.header {
  font-family: 'Jost-400';
  font-size: 24px;
  line-height: 35px;
  color: #393939;
  width: fit-content;
}
.outerWrapper {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  grid-gap: 20px;
  gap: 20px;
  max-width: 1054px;
  margin-bottom: 20px;
}

@media screen and (hover: hover) {
  .billedTo:hover .editIcon {
    display: flex;
  }
  .editIcon:hover {
    background-color: rgba(57, 57, 57, 0.1);
  }

  .paymentMethod:hover .editIcon {
    display: flex;
  }
}

/* Media Queries */

@media screen and (max-width: 1240px) {
  .billingInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .billedToContainer {
    width: 100%;
  }
  .paymentMethodContainer {
    width: 100%;
  }

  .container {
    max-width: 450px;
    width: 100%;
    min-width: 350px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* @media screen and (max-width: 720px) {
  .container {
    max-width: unset;
    width: 100%;
    min-width: 350px;
    margin-right: auto;
    margin-left: auto;
  }
} */

@media screen and (max-width: 576px) {
  .container {
    width: 100%;
    max-width: none;
    min-width: auto;
  }
  .header {
    margin-left: 12px;
  }
  .editIcon {
    display: flex;
  }

}

.myrow {
  display: grid;
  width: 100%;
  padding-bottom: 12px;
  align-items: center;
  grid-template-columns: 2fr 3fr 2fr 1fr;
  padding-top: 19px;
}

.billingDate {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}
.downloadButton {
  font-family: 'Jost-400';
  font-size: 1.6px;
  line-height: 23px;
  color: #7458d3;
  cursor: pointer;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: Jost-400;
  font-size: 1.6rem;
}

.container:focus-within label {
  transform: translate(0, 0.5rem) !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: #7458d3;
}

.container input {
  height: fit-content;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  width: 100%;
}

.container input:-webkit-autofill ~ label,
.container .filled {
  transform: translate(0, 0.5rem) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.container input {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background: rgba(244, 244, 244, 0.4);
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: #585857;
}
.container input::before {
  font-size: 1.6rem;
  padding: 2rem 1.2rem 0.5rem 1.6rem !important;
  background: rgba(244, 244, 244, 0.4);
  border: 2px solid rgba(88, 88, 88, 0.2);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 2.3rem;
  color: #585857;
}

.container label {
  position: absolute !important;
  pointer-events: none !important;
  transform: translate(0, 1.3rem) scale(1) !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 1.6rem !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.container input:hover {
  border: 1px solid rgba(116, 88, 211, 0.2) !important;
}
.container input:hover::before {
  border: 2px solid rgba(116, 88, 211, 0.2) !important;
}

.container input:focus {
  border: 1.5px solid #7458d3 !important;
}
.container input:focus::before {
  border: 2.5px solid #7458d3 !important;
}

.container.errorInput input:focus {
  border: 1.5px solid #de4747 !important;
}
.container.errorInput input:focus::before {
  border: 2.5px solid #de4747 !important;
}

.container:hover:not(:focus-within):not(.errorInput) label {
  color: rgba(116, 88, 211, 0.6);
}

.hasValue {
  background-color: white !important;
}

.container.errorInput input {
  border: 1px solid #de4747 !important;
}
.container.errorInput input::before {
  border: 2px solid #de4747 !important;
}

.container.errorInput label {
  color: #de4747;
}

.container.errorInput:focus-within label {
  color: #de4747;
}

.errorMessageWrapper {
  font-family: 'Jost-400';
  flex-wrap: wrap;
  font-size: max(1.2rem, 12px);
  line-height: 1.7rem;
  color: #de4747;
  margin-left: 0.5rem;
  margin-top: 2px;

  display: flex;
  flex-direction: row;
}

.container {
  min-width: 572px;
}
/* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .modal-content {
    padding: 24px 20px !important;
    width: calc(98vw - 12px) !important;
    margin-left: auto;
    margin-right: auto;
  }
  .container {
    min-width: fit-content;
    margin: 0 !important;
    align-items: center;
  }
}
/* Temporary mobile solution */
.modal-content {
  padding: 24px 32px;
}
.header {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  display: flex;
  color: #585858;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-items: space-between;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: 1px solid #f4f4f4;
}
.infoText {
  font-family: 'Jost-300';
  font-size: 1.6rem;
  color: #393939;
  margin-bottom: 32px;
}
.subHeader {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  color: #393939;
}
.subHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}
.inputContainer {
  font-family: 'Jost-400' !important;
  margin-bottom: 48px;
  /* display: flex;
  flex-direction: column; */
  /* height: fit-content; */
  display: grid;
  grid-template-rows: 60px 60px;
  grid-row-gap: 8px;
}
.errorMessage {
  background: #de4747;
  border-radius: 18px;
  min-width: 572px;
  color: #fff;
  font-family: 'Jost-400';
  font-size: 2rem;
  margin-top: -90px;
  margin-bottom: 30px;
  padding-top: 14px;
  padding-left: 22px;
  padding-bottom: 14px;
  display: flex;
  justify-self: center;
  align-self: center;
}

.cardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 1.4rem 2.4rem;
  flex: 1;
  max-width: 100%;
}

.linkStyle {
  min-width: 360px;
  /* max-width: 535px; */
  width: 100%;
}

.card {
  /* width: 411px; */
  width: 100%;
  /* min-width: 360px;
  max-width: 535px; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.1);
  padding: 16px 20px;
}

.upperCard {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f4f4f4;
  width: 100%;
  justify-content: space-between;
}
.lowerCard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
}
.status {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  /* font-size: 1.2rem;
  line-height: 1.7rem; */
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;


}
.assessmentName {
  font-family: 'Jost-400';
  font-style: normal;
  /* font-size: 1.8rem;
  line-height: 2.6rem; */
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #393939;
  margin-bottom: 4px;
}


.textWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
}

.position {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  /* font-size: 1.6rem;
  line-height: 2.3rem; */
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: rgba(57, 57, 57, 0.8);
}
.candidateFrame {
  border: 1px solid #f4f4f4;
  border-radius: 12px;
  padding: 10px 0px;
  /* padding: 10px 20px; */
  justify-content: space-between;
  font-family: 'Jost-400';
  font-style: normal;
  /* font-size: 1.4rem;
  line-height: 2rem; */
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #585858;
  /* margin-bottom: 1.6rem; */
  margin-bottom: 18px;
}

.IconTextWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
  justify-content: center;
}

.CandidateIcon {
  width: 14px;
  height: 14px;
}

.border {
  border-left: 1px solid rgba(57, 57, 57, 0.15);
  /* height: 100%; */
  height: 20px;
  /* margin-right: 1.5rem; */
  margin-right: 15px;
}

.industryAverageWrapper {
  display: flex;
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #585858;
}

.hiringStatusWrapper {
  font-family: 'Jost-500';
  border-radius: 6px;
  padding: 3px 8px;
  color: #ffffff;
  font-size: 12px;
  line-height: 17px;
}

@media screen and (min-width: 710px) {
  .cardWrapper {
    max-width: 50%;
  }
}

@media screen and (min-width: 1272px) {
  .cardWrapper {
    /* flex: 0 0 33.333333%; */
    max-width: 33.333333%;
  }
}

@media screen and (min-width: 1674px) {
  .cardWrapper {
    max-width: 25%;
  }
}

@media screen and (min-width: 2064px) {
  .cardWrapper {
    max-width: 20%;
  }
}


/* Mobile related */
@media screen and (max-width: 876px) {
  .cardWrapper {
    padding: 0px 20px 2.4rem;
  }

  .linkStyle {
    min-width: 330px;
  }

  .card {
    padding: 14px 18px;
  }

  .lowerCard {
    margin-top: 14px;
  }

  .status {
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 15px;
  }

  .assessmentName {
    font-size: 15px;
    line-height: 22px;
  }

  .textWrapper {
    margin-bottom: 14px;
  }

  .position {
    font-size: 15px;
    line-height: 18px;
  }

  .CandidateIcon {
    width: 12px;
    height: 12px;
  }

  .candidateFrame {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 14px;
  }

  .border {
    height: 18px;
  }

  .industryAverageWrapper {
    font-size: 13px;
    line-height: 19px;
  }

  .hiringStatusWrapper {
    padding: 4px 7px 3px;
    font-size: 10px;
    line-height: 12px;
  }
}


/* @media screen and (max-width: 710px) {
  
} */


.text {
  font-family: 'Jost-500';
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #7458d3;
}
.textWrapper {
  width: max-content;
}

.barWrapper {
  background: #ffffff;
  border: 1px solid #7458d3;
  border-radius: 4px;
  height: 6px;
}

.bar {
  background: rgba(116, 88, 211, 0.8);
  border-radius: 4px;
  height: 4px;
  display: flex;
  align-items: center;
  margin-left: -1px;
}

.headerWrapper {
  padding-right: 2.3rem;
  padding-left: 1.6rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.longHeaderWrapper {
  background: #ffffff;
  outline: 1.5px solid #7458d3;
  border-radius: 12px 0px 0px 12px;
  margin-top: -1px;
  margin-left: -1px;

  padding: 0.6rem 1.8rem 0.6rem 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.purpleText {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #7458d3;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  height: 2.4rem;
  align-items: center;
}

.purpleBox {
  border: 1px solid #7458d3;
  height: 100%;
  width: 2.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8rem;
}

.grayBox {
  height: 100%;
  width: 2.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border: 1px solid #d9d9d9;
}

.purpleFill {
  background: #7458d3;
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 50%;
}
.imgWrapper {
  margin-top: 1.2rem;
  margin-bottom: 1.6rem;
  display: flex;
  justify-content: center;
  height: 8rem;
}

.actionImg {
  cursor: pointer;
}

.itemWrapper {
  transition: all 1s ease-in;
}

/* FIGURE IT OUT */
/* .container {
  transition: all 4s ease-in-out;
  transform: scale(0);
}
.container.opened {
  transform: scale(1);
} */

.doneBox {
  background: rgba(116, 88, 211, 0.3);
  height: 100%;
  width: 2.4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.onboardingItemWrapper {
  padding-bottom: 6px;
  padding-top: 6px;
}

.boldDesc {
  font-family: 'Jost-500';
}

.desc {
  font-family: 'Jost-400';
}
.descWrapper {
  font-size: 1.6rem;
  line-height: 2.0rem;
  color: #393939;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
  padding-bottom: 1.4rem;
}

.AssessmentSummaryWrapper {
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
  padding: 18px 24px 24px;
  gap: 18px;
  display: flex;
  flex-direction: column;
}

.AssessmentSummaryHeader {
  font-family: 'Jost-500';
  font-size: 18px;
  line-height: 26px;

  color: #393939;
}

.AssessmentSummaryBottomWrapper {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  gap: 14px;
}

.VerticalBorder {
  width: 0px;
  border: 0.5px solid rgba(57, 57, 57, 0.1);
}

.AssessmentSummaryBottomBox {
  width: 50%;
  min-width: calc(50% - 15px);
  display: flex;
  flex-direction: column;
}

.BoxText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;

  color: rgba(57, 57, 57, 0.5);
}

.AssessmentSummaryFunnelWrapper {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding-top: 12px;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  gap: 20px;
  max-width: 1054px;
  margin-bottom: 20px;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 24px;
  line-height: 35px;
  color: #393939;
}

.cardWrapper {
  display: flex;
  gap: 28px;
  justify-content: space-between;
}

.companyWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 28px 28px;

  background: #ffffff;
  min-height: 290px;

  position: relative;
}

@media screen and (max-width: 1040px) {
  .cardWrapper {
    flex-direction: column;
  }

  .contentWrapper {
    width: 75%;
  }

  .companyWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 750px) {
  .companyWrapper {
    width: 100%;
  }

  .contentWrapper {
    width: 100%;
  }
}

.editIcon {
  visibility: hidden;
  cursor: pointer;
  border-radius: 20px;
  position: absolute;
  right: 14px;
  top: 14px;
}

@media screen and (max-width: 576px) {
  .title {
    padding: 0px 12px;
  }

  .editIcon {
    visibility: visible;
  }
}

.companyTag {
  font-family: 'Jost-400';
  font-size: 12px;
  line-height: 17px;
  color: rgba(57, 57, 57, 0.49);
  text-transform: uppercase;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.companyWrapper:hover .editIcon {
  visibility: visible;
}

@media screen and (hover: hover) {
  .editIcon:hover {
    background-color: rgba(116, 88, 211, 0.1);
  }
}

.companyNameWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.innerTitle {
  font-family: 'Jost-500';
  font-size: 16px;
  line-height: 23px;
  color: rgba(57, 57, 57, 0.84);
}

.innerSubtitle {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: rgba(57, 57, 57, 0.84);
}

.uploadLogoWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.uploadLogoWrapper .innerTitle {
  color: #7458d3;
  text-align: center;
}

.uploadLogoWrapper .innerSubtitle {
  color: #7458d3;
  text-align: center;
  font-family: 'Jost-300';
}

.logoContainer {
  height: 130px;
  align-items: center;
  border: 1px solid rgba(116, 88, 211, 0.2);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px;
}

.logoPresent {
  border: 1px solid rgba(57, 57, 57, 0.15);
}

.logoPresent:hover {
  background: white !important;
}
.logoContainer:hover {
  background: rgba(116, 88, 211, 0.05);
}
.logoContainer path {
  stroke: #7458d3;
}

.logo {
  max-height: 95%;
  max-width: 95%;
}

.companyDescText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: rgba(57, 57, 57, 0.84);
}

.placeholderText {
  font-family: 'Jost-300';
  font-size: 14px;
  line-height: 20px;
  color: rgba(116, 88, 211, 0.6);
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #393939;
}
.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  /* margin-top: 1.5rem; */
}
.usersWrapper {
  overflow-y: auto;
}

.contentWrapper {
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 80%;
  max-height: 80vh;
  padding-top: 20px;
  padding-bottom: 15px;
}

.contentBody {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

.usersText {
  font-family: 'Jost-400';
  font-size: 18px;
  color: rgba(57, 57, 57, 0.8);
  white-space: nowrap;
}

.titleWrapper {
  display: flex;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
  margin-right: 24px;
  margin-left: 24px;
  margin-top: 30px;
  padding-bottom: 12px;
  font-family: 'Jost-400';
  font-size: 14px;
  color: rgba(57, 57, 57, 0.5);
  margin-bottom: 10px;
}

.memberWrapper {
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
}

.memberWrapperHover {
  background: rgba(116, 88, 211, 0.08) !important;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
}
.iconWrapper {
  margin-right: 10px;
}
.nameWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.nameText {
  font-family: 'Jost-400';
  font-size: 16px;
  color: #393939;
}
.roleWrapper {
  display: flex;
  width: 230px;
  min-width: 180px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

[isMobile='true'].roleWrapper {
  justify-content: flex-end;
}

.roleText {
  font-family: 'Jost-400';
  font-size: 16px;
  color: #393939;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.roleDots {
  margin-top: -10px;
  font-family: 'Jost-400';
  font-size: 25px;
  color: #393939;
  display: none;
  margin-top: auto;
  margin-bottom: auto;
}

[isMobile='true'].roleDots {
  display: flex;
  cursor: pointer;
}

.memberWrapperHover .roleDots {
  display: flex;
}
.invitedText {
  font-family: 'Jost-400';
  font-size: 16px;
  color: #4ac389;
  align-self: center;
  margin-left: 6px;
}
.inviteAgainText {
  font-family: 'Jost-400';
  font-size: 16px;
  color: rgba(116, 88, 211, 1);
  align-self: center;
  cursor: pointer;
}
.invitedWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.adminBox {
  background: rgba(255, 187, 1, 0.15);
  border-radius: 6px;
  height: 26px;
  min-width: 82px;
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  align-items: center;
  padding-left: 9px;
}

.adminStar {
  background: rgba(255, 187, 1, 0.15);
  border-radius: 50%;
  padding: 4px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.searchbarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
  align-items: center;
  gap: 10px;
}

.memberWrapperHover .adminBox {
  background: #fff;
  border-radius: 6px;
  height: 26px;
  min-width: 82px;
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  align-items: center;
  padding-left: 9px;
}

.memberWrapperHover .adminText {
  color: #7458d3 !important;
  font-family: 'Jost-400';
  font-size: 14px;
  margin-left: 9px;
}
.memberWrapperHover .starIcon path {
  /* fill: #7458d3; */
}
.adminText {
  font-family: 'Jost-400';
  font-size: 14px;
  color: #ffbb01;
  margin-left: 9px;
}
.passiveUserIcon {
  border-radius: 50%;
  background-color: rgba(57, 57, 57, 0.1);
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  color: rgba(57, 57, 57, 0.3);
  padding-top: 4px;
}
.activeUserIcon {
  border-radius: 50%;
  background: rgba(116, 88, 211, 0.2);
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  padding-top: 4px;
  color: #7458d3;
}
.userIconText {
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}
.adminTextHover {
  font-family: 'Jost-400';
  font-size: 16px;
  color: #7458d3;
  cursor: pointer;
}
.adminTextHover2 {
  font-family: 'Jost-400';
  font-size: 16px;
  color: #de4747;
  cursor: pointer;
}

.editModal {
  position: fixed;
  right: calc(4% + 15px);
  z-index: 1000;
  background-color: #fff;
  height: 90px;
  border: 1px solid rgba(57, 57, 57, 0.1);
  border-radius: 12px;
  width: 76px;
  align-items: center;
  padding: 12px 0px;
}

.editWrapper {
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
  display: flex;
  flex-direction: row;
  font-family: 'Jost-400';
  font-size: 16px;
  color: #7458d3;
  align-items: center;
  padding-bottom: 8px;
  height: 50%;
  justify-content: center;
}
.deleteWrapper {
  display: flex;
  flex-direction: row;
  font-family: 'Jost-400';
  font-size: 16px;
  color: #de4747;
  align-items: center;
  padding-top: 8px;
  height: 50%;
  justify-content: center;
}

/* Media Queries */

@media screen and (hover: hover) {
  .adminTextHover:hover {
    cursor: pointer;
  }

  .roleDots:hover {
    cursor: pointer;
  }

  .editWrapper:hover {
    cursor: pointer;
  }
  .deleteWrapper:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 820px) and (min-width: 576px) {
  .contentWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .roleWrapper {
    width: fit-content;
    min-width: unset;
  }

  .contentWrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .headerWrapper {
    margin: 0px 12px;
  }

  .pageWrapper {
    max-height: unset;
  }

  .editModal {
    right: 15px;
  }
}

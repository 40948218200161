.contentWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.contentHeader {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  line-height: 3.5rem;
  display: flex;
  align-items: center;
  color: #585858;
  margin-bottom: 2.4rem;
}

.candidateCountRow {
  font-family: 'Jost-300';
  font-size: 1.8rem;
  line-height: 2.6rem;
  display: flex;
  align-items: center;
  color: #585858;
  margin-bottom: 2.4rem;
}

.closeButton {
  position: absolute;
  right: 3.2rem;
  top: 3rem;
  width: 3.4rem;
  height: 3.4rem;
}


.SwitchButtonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Jost-300';
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: rgba(88, 88, 88, 0.8);
  gap: 1.2rem;
}

.ButtonRow {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
    justify-content: flex-end;
}

.MailPart {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(88, 88, 88, 0.2);
    height: fit-content;
}

.DropdownRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.7rem;
    height: fit-content;
}

@media screen and (max-width: 576px) {
  .contentHeader {
    font-size: 16px;
    line-height: 23px;  
  }

  .closeButton {
    right: 20px;
    top: 20px;
    width: 23px;
    height: 23px;
  }

  .candidateCountRow {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 24px;
  }

  .SwitchButtonRow {
    font-size: 16px;
    line-height: 23px;
    gap: 12px;
  }

  .ButtonRow {
    gap: 10px;
  }

  .DropdownRow {
    gap: 10px;
  }
}
.wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
  height: 376px;
  padding: 0px 24px 0px 24px;
  overflow-y: hidden;
  overflow-x: clip;
  width: 438px;
}

.wrapper:hover {
  overflow-y: auto;
}

.header {
  font-family: 'Jost-500';
  font-size: 18px;
  line-height: 26px;
  color: #393939;
  width: 100%;
  position: sticky;
  top: 0;
  padding-top: 18px;
  padding-bottom: 13px;
  background: #fff;
}

/* Note Input */
.inputWrapper {
}

.boxActive {
  resize: none;
  border: none;
  border-radius: 8px;
  transition: 0.7s;
  width: 100%;
  display: grid;
  font-family: 'Jost-400';
  padding: 12px;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  margin-top: 1px;
  background: #ffffff;
  outline: 1px solid #7458d3;
  color: #393939;
  grid-template-rows: auto;
}

.box {
  resize: none;
  border: none;
  border-radius: 8px;
  background: #f4f4f4;
  transition: 0.7s;
  width: 100%;
  display: grid;
  align-items: center;
  font-family: 'Jost-400';
  padding-top: 12px;
  padding-left: 12px;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: rgba(57, 57, 57, 0.5);
  grid-template-rows: auto;
}
.box::placeholder {
  color: rgba(57, 57, 57, 0.5);
}

.boxActive::placeholder {
  color: rgba(57, 57, 57, 0.5);
}

.myTextArea {
  resize: none;
  border: none;
  outline: none;
  background-color: transparent;
  transition: 0.7s all;
}

.noteWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 0px;
  gap: 8px;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
}

.noteWrapper:last-child {
  border-bottom: none;
}

.firstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 23px;
}

.firstRowLeft {
  gap: 8px;
  display: flex;
  flex-direction: row;
}

.textNote {
  font-family: 'Jost-300';
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #393939;
  overflow-wrap: anywhere;
}

.dateNote {
  font-family: 'Jost-300';
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(57, 57, 57, 0.5);
}
.authorNote {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #393939;
}

.emptyNote {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #393939;
  margin-top: 32px;
}
.buttonWrapper {
  display: flex;
  flex-direction: row;
  margin-right: 0;
  margin-left: auto;
  gap: 4px;
}

.dots {
  opacity: 0;
}

.noteWrapper:hover .dots {
  opacity: 1;
}
.dots:hover {
  cursor: pointer;
}

.deactiveDots.dots {
  opacity: 0 !important;
}

.editWrapper {
  width: 102px;
  height: 86px;
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  padding-left: 16px;
  padding-right: 16px;
}

.editText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
}

.deleteText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #de4747;
}

.modalRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding-top: 10px;
  width: 100%;
  padding-bottom: 10px;
  cursor: pointer;
}

.modalRow:first-child {
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
}

@media screen and (max-width: 576px) {
  .wrapper {
    border-radius: 0px;
    height: fit-content;
    padding: 18px 20px 0px 20px;
    max-height: 513px;
    width: unset;
    padding-top: 0;
  }

  .emptyNote {
    margin-bottom: 32px;
  }

  .dots {
    opacity: 1;
  }
}
@media screen and (max-width: 960px) and (min-width: 850px) {
  .wrapper {
    width: 354px;
    min-width: 354px;
    height: 303px;
  }
}

@media screen and (max-width: 850px) and (min-width: 700px) {
  .wrapper {
    width: 290px;
    min-width: 290px;
    height: 303px;
  }
}

@media screen and (max-width: 700px) and (min-width: 576px) {
  .wrapper {
    width: 100%;
  }
}

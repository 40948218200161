.mymodal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  overflow: auto;
  z-index: 10000;
  transition: all 0.3s linear;
  background: rgba(0, 0, 0, 0.5);
  transform: none !important;
  pointer-events: none;
}
.mymodal.myshow {
  opacity: 1;
  pointer-events: visible;
}

.modalBody {
  position: relative;
  transition: all 0.6s ease-in-out;
  transform: translate(0, -1200px);
}
.modalBodyBottom {
  position: absolute;
  bottom: 0;
  transition: all 0.6s ease-in-out;
  transform: translate(0, 1500px);
}
.modalContent {
  z-index: 10001;
  position: relative;
  background-color: #fff;
}

.centerH {
  display: flex;
  justify-content: center;
}

.centerV {
  display: flex;
  align-items: center;
}

body:has(.myshow) {
  overflow: hidden;
}

/* .noScrollWithoutTouch {
  overflow-y: hidden !important;
  padding-right: 20px !important;
}

.mouseEnter {
  overflow-y: auto !important;
  padding-right: 8px !important;
  overflow-x: hidden !important;
} */

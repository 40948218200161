.mainWrapper {
  /* position: sticky; */
  /* top:81px; */
  z-index: 10;
  margin-top: 21px;
  width: 90%;
  max-width: 1100px; /* 1200px */
  min-width: 750px;
  display: flex;
  /* height: 5%; */
  height: 40px;
  justify-self: flex-start;
  /* padding-left: 3%; */
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  background-color: #FFFFFF;
  border-radius: 14px;
  display: flex;
  width: 276px;
  /* height: 40px; */
  transition: 0.7s;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  position: relative;
}
.inner{
  width: 132px;
  height: 40px;
  background: #585858;
  border-radius: 14px;
  transition: 0.5s;
  position: absolute;
}
.textComment[active="Result Report"]{
  color:  #585858;
  transition: color 0.7s;
}
.textResult[active="Comment"]{
  color:  #585858;
  transition:color 0.7s;
}
.textResult[active="Comment"]:hover{
  background-color: #F4f4f4;
}
.textComment[active="Result Report"]:hover{
  background-color: #F4f4f4;
}

.text{
  border-radius: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-family: 'Jost-400';
  display: flex;
  clear: left;
  height: 100%;
  width: 132px;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition:color 0.7s;
  letter-spacing: -0.02em;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  color:  #FFFFFF;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}

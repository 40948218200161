.CustomNavbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.HeaderAddLogo path {
  stroke: #7458d3;
}

.HeaderAddLogo svg {
  width: 22px;
  height: 22px;
}

.CustomNavbarLink {
  height: 80px;
  font-size: 20px;
  line-height: 80px;
  text-align: center;
  letter-spacing: -0.02em;
  display: block;
  padding: 0 1rem;
  color: rgba(88, 88, 88, 0.5) !important;
  margin: 0 24px;
}

.CustomNavbarLink:hover {
  color: #7458d3 !important;
  background-color: #ebe7f9;
  border-bottom: 2px solid #ffa08f;
  text-decoration: none;
}

.CustomNavbarLink.disabled,
.CustomNavbarLink:disabled {
  color: rgba(88, 88, 88, 0.1) !important;
}

.CustomNavbarLink.active {
  color: #7458d3 !important;
  border-bottom: 2px solid #ffa08f;
  text-decoration: none;
}

.selectCompany {
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
  text-decoration: underline;
}

.LoggedUser {
  font-family: 'Belleza', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  text-align: right;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.8);
  padding-right: 8px !important;
}

.userProfileImg {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid #f4f4f4;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  /* color: #ff8a75;
  background: #f9d7d1; */
  color: #7458d3;
  background: #c3b6ef;
}

.userProfileImg:nth-child(3) {
  background: #c3b6ef;
  color: #7458d3;
}

.userProfileImg:nth-child(5) {
  background: #c3b6ef;
  color: #7458d3;
}

.userProfileImg:nth-child(7) {
  background: #c3b6ef;
  color: #7458d3;
}

.trial-status {
  font-family: 'Belleza', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.headerContainer {
  display: none;
}
.headerMenu {
  display: none;
}

@media screen and (max-width: 576px) {
  .headerParent {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  .selectCompany {
    display: none;
  }
  [id='navbarBrand'] {
    display: none;
  }
  [id='OnboardingAddLogoId'] {
    display: none !important;
  }

  .headerContainer {
    position: relative;
    z-index: 1000;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 100%;
  }

  .CustomNavbar {
    padding-left: 20px !important;
    padding-right: 20px !important;
    background: #fff;
  }
  .headerMenu.headerOpen {
    display: flex;
    padding: 20px;
    background-color: #fafafa;
    min-width: calc(100% + 40px);
    margin-left: -20px;
    flex-direction: column;
  }

  .CustomNavbar:has(.headerOpen) {
    background: #fafafa;
    box-shadow: none !important;
  }
  .headerIcons {
    cursor: pointer;
  }
  .logoContainer {
    width: 100%;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
    border-radius: 8px;
    padding: 16px 24px 20px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 18px;
  }
  .title {
    font-family: 'Jost-400';
    font-size: 12px;
    line-height: 17px;
    color: rgba(57, 57, 57, 0.6);
    text-transform: uppercase;
  }

  .usageContainer {
    background: #ffffff;
    box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
    border-radius: 8px;
    padding: 16px 24px 20px 24px;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  .logoutContainer {
    border-top: 1px solid rgba(57, 57, 57, 0.1);
    padding-top: 24px;
  }

  .logoutRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .logoutText {
    font-family: 'Jost-400';
    font-size: 16px;
    cursor: pointer;
    line-height: 18px;
    color: #de4747;
    margin-left: 12px;
  }

  /* Progress bars */
  .usageBlock {
    margin-top: 2.4rem;
    padding-bottom: 1.7rem;
    width: 100%;
  }
  .usageRow {
    display: flex;
    height: fit-content;
    justify-content: space-between;
    position: relative;
    flex-direction: row;
  }

  .circleColumn {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .topRow {
    margin-bottom: 2.2rem;
    width: 100%;
  }
  .planNames {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    color: rgba(57, 57, 57, 0.84);
  }
  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .planAmount {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    color: #393939;
  }
  .current {
    font-family: 'Jost-400';
    font-size: 12px;
    line-height: 17rem;
    text-transform: uppercase;
    color: rgba(57, 57, 57, 0.49);
  }

  .available {
    font-family: 'Jost-400';
    font-size: 14px;
    line-height: 20px;
    color: rgba(57, 57, 57, 0.4);
    white-space: nowrap;
  }
  .circleContainer {
    height: 38px;
    width: 38px;
    align-self: center;
    display: flex;
    margin-right: 16px;
  }
  /* Progress bars */
}
body:has(.headerOpen) {
  overflow-y: hidden !important;
}

.container {
  width: 100%;
  height: inherit !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* flex-grow: 1; */
  margin-top: auto;
  margin-bottom: auto;
}

.text {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  margin-top: 33px;
  color: #393939;
}

@media screen and (max-width: 700px) {
  .text {
    font-family: 'Jost-400';
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #393939;
    margin-top: 20px;
  }

  .container {
    height: calc(100vh - 50px) !important;
  }
}

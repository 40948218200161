.wrapper {
  width: 100%;
  display: flex;
  padding-bottom: 0.8rem;
}

.itemWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.candidateWrapper {
  margin-left: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  padding: 1.2rem 1.8rem 1.2rem 1.2rem;
  cursor: pointer;
}

.candidateWrapper:hover {
  background: hsla(0, 0%, 100%, 0.6);
  border: 1px solid rgba(88, 88, 88, 0.2);
  box-shadow: 0 2px 12px rgb(0 0 0 / 10%);
  border-radius: 12px;
}

.indAverageText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(88, 88, 88, 0.8);
  white-space: nowrap;
}

.lock {
  width: 13px;
  height: 16px;
}

/* .rightBorder {
  display: flex;
  height: 100%;
} */

@media screen and (min-width: 576px) {
  /* .rightBorder {
    border-right: 2px solid rgba(57, 57, 57, 0.2);
    padding-right: 16px;
  } */

  .rightPart {
    gap: 5px;
  }
}

.dayText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: rgba(57, 57, 57, 0.8);
  white-space: nowrap;
}
.tooltip {
  margin-left: 10px;
}
.name {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: block;
  color: #393939;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 30px; */
}
.border {
  width: 24px;
  height: 0;
  border: 1px solid rgba(88, 88, 88, 0.2);
  transform: rotate(90deg);
}

.error {
  width: 1.5rem;
  height: 1.6rem;
  /* margin-left: 1.5rem; */
}
.hoverStyle {
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.5rem;
}
.hoverStyle:hover {
  cursor: pointer;
  background-color: rgba(88, 88, 88, 0.1);
  border-radius: 50%;
}
.leftPart {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.indexLabel {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}

.rightPart {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: fit-content;
}
.lockWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  opacity: 1;
  /* margin-left: 10px; */
}
.coensioCandidate {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
}

.unlockCandidateMobile {
  width: 45px;
  height: 34px;
  left: calc(50% - 45px / 2 + 76.5px);
  top: 52px;
  background: #f4f4f4;
  border-radius: 10px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.unlockCandidateMobile:hover {
  background: #7458d3;
}
.unlockCandidateMobile:hover .lockIcon {
  display: none;
}

.unlockIcon {
  display: none;
}

.unlockCandidateMobile:hover .unlockIcon {
  display: flex;
}
.scoreWrapper {
  /* gap: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 40px;
}
.candScore {
  font-family: 'Jost-400';
  font-size: 1.5rem;
  line-height: 22px;
  color: #393939;
}
.circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  min-height: 10px;
  max-height: 10px;
  min-width: 10px;
  max-width: 10px;
}

.redCircle {
  background: #ca1515;
}
.yellowCircle {
  background: #ffb800;
}

.greenCircle {
  background: #4ac389;
}
.grayCircle {
  background: #393939;
}

@media screen and (max-width: 960px) {
  .name {
    font-size: 16px;
    line-height: 23px;
  }
  .error {
    width: 15px;
    height: 17px;
  }
  .border {
    margin-left: 2px;
  }
}

.acceptedTag {
  text-align: center;
  width: 71px;
  height: 24px;
  background: rgba(116, 88, 211, 0.15);
  border-radius: 4px;
  /* padding:4px 8px 4px 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  cursor: default;
}

.acceptedText {
  font-family: 'Jost-500';
  font-weight: 500;
  font-size: 11px;
  font-style: normal;
  color: #7458d3;
}

@media screen and (max-width: 576px) {
  .candidateWrapper {
    margin-left: 12px;
    height: 40px;
    border-radius: 10px;
  }

  .name {
    font-size: 14px;
    line-height: 20px;
  }

  .candScore {
    font-size: 14px;
    line-height: 20px;
  }

  .indexLabel {
    font-size: 14px;
  }

  .coensioCandidate {
    font-size: 14px;
  }

  .acceptedTag {
    width: 71px;
    height: 20px;
  }
  .lock {
    width: 11px;
    height: 14px;
  }
}

.user-profile-img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* border: 1px solid rgba(116, 88, 211, 0.2); */
  border: 1px solid rgb(255, 255, 255);
  background: rgb(226, 223, 244);
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
}

.NestedPeople {
  margin-left: -8px;
  /* background: #f9d7d1 !important; */
}
.comment {
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
}
.headerComment {
  width: 46px;
  height: 46px;
  margin-top: 30%;
  text-align: center;
  display: flex;
}
.userIconText {
  margin: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  color: #7458d3;
}
.headerCommentText,
.commentText {
  font-size: 14px;
  font-family: Jost-400;
  line-height: 20px;
}

.pageWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%
}

.contentBody {
  background-color: #fff;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  /* overflow: scroll; */
  height: calc(100vh - 80px); /* changed with header */
  /* height: 100vh; */
}

.container {
  width: 100%;
  height: 42px;
  background: linear-gradient(270deg, #f4f4f4 17.19%, #7458d3 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobileContainer {
  min-height: 173px;
  height: fit-content;
  background: linear-gradient(275.58deg, #f4f4f4 -16.52%, #7458d3 92.41%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 19px 20px 20px;
}
.slotFullText {
  font-family: 'Jost-500';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.headerRowMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}
.button {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  text-align: right;
  color: #7458d3;
}
.button:hover {
  cursor: pointer;
}

.bannerCell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.modal-title h3 {
  font-weight: normal;
  font-size: 29px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-bottom: 12px !important;
  display: flex;
  align-items: center;
}

.modal-title p {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #585858;
  opacity: 0.8;
}

.CompanySelectModalDialog {
  max-width: 60vw;
}

.CloseCandidateModal {
  vertical-align: middle;
  margin-top: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  filter: invert(36%) sepia(1%) saturate(0%) hue-rotate(357deg) brightness(96%) contrast(99%);
}

.top-right {
  position: absolute;
  top: 24px;
  right: 24px;
}

.modal-body {
  padding-top: 0;
  padding: 1rem 0 !important;
  width: 95%;
}

.CloseCandidateModal:hover {
  color: #fff;
  background-color: rgba(88, 88, 88, 0.8);
  filter: grayscale(1);
}

.CloseModalButton {
  float: right;
  margin-right: auto;
  margin-left: auto;
}

.company-card {
  background: #ffffff;
  border: 1px solid rgba(88, 88, 88, 0.2);
  box-sizing: border-box;
  border-radius: 24px;
  /* width: 100px; */
  height: 100px;
  font-size: 1.4rem;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.company-card:hover {
  border: 1px solid rgba(116, 88, 211, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.CompanySelectModalContent {
  border-radius: 25px !important;
  padding: 24px !important;
}

.CompanySelectModalContent :global(.modal-body) {
  padding-top: 0;
  padding: 1rem 0 !important;
  width: 100%;
}

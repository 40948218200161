.ComponentWrapper {
  display: flex;
  flex-direction: row;
  gap: 1.8rem;
  padding: 1.4rem 2.4rem;
  width: 100vw;
  /* overflow-y: auto; */
  justify-content: center;
  background: #fff;
}

.leftColumn {
  width: 100%;
  gap: 1.8rem;
  display: flex;
  flex-direction: column;

  /* flex-shrink: 1; */
  flex-grow: 1;
  max-width: 857px;
  min-width: 0;
}

.rightColumn {
  gap: 1.8rem;
  display: flex;
  flex-direction: column;

  flex-grow: 0;
}

.secondRow {
  gap: 1.8rem;
  display: none;
  flex-direction: row;
}

.positionRow {
  display: none;
}

.notesRow {
  display: none;
}

@media screen and (min-width: 960px) {
}

@media screen and (max-width: 960px) {
  .rightColumn {
    display: none;
  }

  .secondRow {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .ComponentWrapper {
    flex-direction: column;
  }

  .secondRow {
    display: none;
  }

  .positionRow {
    display: initial;
  }

  .notesRow {
    display: initial;
  }
}

@media screen and (max-width: 576px) {
  .ComponentWrapper {
    flex-direction: column;
    padding: 0;
  }
  .leftColumn {
    padding-bottom: 1.8rem;
  }
}

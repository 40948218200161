.wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
  padding: 20px 32px 0px 24px;
  max-height: 850px;
  overflow-y: hidden;
}
.wrapper:hover {
  overflow-y: auto;
}
.header {
  font-family: 'Jost-500';
  font-size: 18px;
  line-height: 26px;
  color: #393939;
}
.rowsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.rowWrapper {
  display: flex;
  flex-direction: row;
}
.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 140px;
  flex-basis: 140px;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid rgba(57, 57, 57, 0.1);
  padding-bottom: 44px;
}

.leftTextUpper {
  font-family: 'Jost-300';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #393939;
  margin-top: -4px;
}

.leftTextBottom {
  font-family: 'Jost-300';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #393939;
}
.rightColumn {
  display: flex;
  padding-left: 24px;
  margin-top: -12px;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  margin-top: -8px;
}
.dotImg {
  margin-left: -6px;
}

.firstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.secondRow {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: rgba(57, 57, 57, 0.5);
}

.wholeRow {
  /* display: flex;
  flex-direction: row; */
  display: contents;
}

.boldText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  /* display: flex;
  align-items: center; */
  color: #393939;
}

.lightText {
  font-family: 'Jost-300';
  font-size: 16px;
  line-height: 23px;
  /* display: flex;
  align-items: center; */
  color: #393939;
}

.positionTag {
  background: rgba(116, 88, 211, 0.15);
  border-radius: 4px;
  padding: 4px 8px;
  font-family: 'Jost-500';
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #7458d3;
  white-space: nowrap;
}

.noActivity {
  font-family: 'Jost-400';
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

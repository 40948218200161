.contentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.2rem 3.2rem 2rem 3.2rem;
}

.image {
  width: 16.3rem;
  height: 13.2rem;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  margin-bottom: auto;
  margin-top: 1rem;
}

.title {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  color: #393939;
}

.text {
  margin-top: 16px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}

.tick {
  /* width: 70px;
  height: 36px; */
  width: 7rem;
  height: 3.6rem;
  align-self: flex-end;
  position: absolute;
  bottom:0;
  /* margin-top: calc(175px - 36px); */
}
.tick:hover {
  cursor: pointer;
}
.popup {
  display: flex;
  flex-direction: column;
}

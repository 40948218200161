.modal-dialog {
  width: 700px;
  /* min-width: 0px; */
  max-width: 100vw;
  /* height: 328px; */
  /* min-height: 0px; */
  /* max-height: 100vh; */
}

.modal-content {
  border-radius: 24px !important;
  padding: 2rem !important;
  height: 572px !important;
  /* width: 572px; */
}

.btn {
  width: 30%;
}

.modal-content h3 {
  color: black;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  height: fit-content;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

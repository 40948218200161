
.container {
  font-family: Jost-400;
  height: 100%;
}

.credit-card {
  box-sizing: border-box;
  padding: 1.2rem 1.2rem 1.2rem 1.6rem;
  border: 1px solid rgba(88, 88, 88, 0.2);
  background-color: rgba(244, 244, 244, 0.4);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  width: 100%;
  height: 5rem;
  position: relative;
  color: #585857 !important;
  border-radius: 10px !important;
  justify-content: space-between !important;
  display: flex;
}

.cardNumber {
}
.cardCVC {
}

.cardDate {
  flex-grow: 1;
  margin-right: 0 !important;
}

.credit-card .card-type-container {
  display: inline-block;
}

.credit-card .cc-number {
  margin-right: 1rem;
  background: transparent;
  color: rgba(88, 88, 88, 1);
  width: 25rem;
  flex-grow: 25;
}
.credit-card .card-type {
  width: 2rem;
  display: flex;
}

.credit-card .input {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 1.6rem;
  letter-spacing: -0.02em !important;
}
.cc-number::placeholder,
.cc-exp::placeholder,
.cc-csc::placeholder {
  color: rgba(88, 88, 88, 0.6);
  letter-spacing: -0.02em !important;
}

.credit-card .input.cc-exp,
.credit-card .input.cc-csc {
  width: fit-content;
  background-color: transparent;
  color: rgba(88, 88, 88, 0.6);
  color: rgba(88, 88, 88, 1);
}

.credit-card .input.cc-csc {
  width: 4rem;
  flex-grow: 1;
}
.credit-card .input.cc-exp {
  width: 8rem;
  flex-grow: 1;
  margin-left: auto;
}

.credit-card:focus-within {
  border: 1px solid #7458d3 !important;
  background-color: white;
}

.input::placeholder {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cc-number:hover.input::placeholder {
  color: rgba(116, 88, 211, 0.6);
}
.cc-csc:hover.input::placeholder {
  color: rgba(116, 88, 211, 0.6);
}
.cc-exp:hover.input::placeholder {
  color: rgba(116, 88, 211, 0.6);
}

.noText {
  position: absolute !important;
  pointer-events: none !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 16px !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.9rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.input label {
  position: absolute !important;
  pointer-events: none !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 16px !important;
  background-color: transparent !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.9rem;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.6);
}

.credit-card:focus-within label {
  transform: translate(0, -14px) scale(0.8) !important;
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: -0.02em;
  color: #7458d3 !important;
  position: absolute !important;
  pointer-events: none !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 1.6rem !important;
}

.credit-card:focus-within .cc-number {
  padding-top: 0.8rem !important;
}

.credit-card .filled {
  transform: translate(0, -14px) scale(0.8) !important;
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: -0.02em;
  /* color: #7458d3 !important; */

  position: absolute !important;
  pointer-events: none !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  left: 1.6rem !important;
}

.filledLabel {
  padding-top: 0.8rem !important;
}

/* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .credit-card .cc-number {
    width: 15rem !important;
  }
  .credit-card .input.cc-exp {
    width: 8rem !important;
  }
}
/* Temporary mobile solution */
.headerTabContainer {
  flex-direction: row;
  position: relative;
  display: flex;
}

.headerTab {
  /*width: 155px;*/
  height: 40px;
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  text-align: center;
  letter-spacing: -0.02em;
  color: #585858;

  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 600ms;
  z-index: 2;
}

.selected {
  color: #ffffff;
}

.selectedTab {
  background: #585858;
  border-radius: 12px;
  z-index: 1;
  height: 40px;
  left: 0;
  position: absolute;
  transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.published {
  left: 0;
}

.archived {
  left: 165px;
}

@media screen and (max-width: 710px) {
  .headerTab {
    height: 36px;
  }

  .selectedTab {
    height: 36px;
  }
}

.wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
  height: fit-content;
  padding: 20px 24px 15px 24px;
}
.header {
  font-family: 'Jost-500';
  font-size: 18px;
  line-height: 26px;
  color: #393939;
}

.subHeader {
  font-family: 'Jost-500';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.companyText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.experienceDate {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  color: rgba(57, 57, 57, 0.6);
}

.companyLogo {
  background: #f4f4f4;
  border-radius: 8px;
  height: 54px;
  min-height: 54px;
  max-height: 54px;
  width: 54px;
  min-width: 54px;
  max-width: 54px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.logoImg {
  max-width: 100%;
  max-height: 100%;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px 0px;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
}

.description {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  color: #393939;
}

.rowWrapper:last-child {
  border: none;
}

.labelWrapper {
  height: 22px;
  max-width: fit-content;
  background: rgba(116, 88, 211, 0.15);
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Jost-500';
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  color: #7458d3;
}

.companyRowWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.descriptionWrapper {
  margin-top: 8px;
  display: inline;
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  color: #393939;
}
.seeMore {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .wrapper {
    border-radius: 0;
    padding: 20px;
  }
  .header {
    font-size: 16px;
  }
  .experienceDate {
    font-size: 14px;
  }
  .descriptionWrapper {
    font-size: 14px;
  }
}

.BodyWrapper {
  display: flex;
}

.BodyWrapper img {
  max-width: 100% !important;
}

.MultipleChoiceWrapper {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  color: #393939;
  overflow-x: auto;
}

.FreeTextWrapper {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  color: #393939;
  overflow-x: auto;
}

.CodingWrapper {
  width: 48%;
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  color: #393939;
}

.CodingEditorWrapper {
  border-radius: 8px;
  width: 49.5%;
}

.MonacoEditor :global(.monaco-editor) {
  border-radius: 8px;
  padding-top: 10px;
}

.MonacoEditor :global(.monaco-editor .overflow-guard) {
  border-radius: 8px;
}

.MultipleChoiceQuestionText {
  margin-bottom: 12px;
}

/* .MultipleChoiceQuestionText p {
} */

.FreeTextQuestionText {
  margin-bottom: 12px;
}

/* .FreeTextQuestionText p {
}

.CodingQuestionText {

}

.CodingQuestionText p {
} */

.OptionWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow-x: auto;
}

.FreeTextAnswerWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  margin-bottom: 10px;

  font-family: Jost-300;
}

.OptionCheckbox {
  width: 16px;
  min-width: 16px !important;
  height: 16px;
  background: rgba(221, 58, 58, 0.2);
  border: 1px solid rgba(222, 71, 71, 0.8);
  border-radius: 10px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OptionText {
  margin-left: 12px;
  font-family: Jost-300;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  flex-wrap: wrap;
  color: rgba(88, 88, 88, 0.6);
  overflow-x: auto;
}

.SelectedOptionText {
  color: #585858;
}

.CorrectOption {
  background: rgba(74, 195, 137, 0.2);
  border: 1px solid rgba(74, 195, 137, 0.8);
}

.SelectedOption {
  width: 11px;
  min-width: 11px !important;
  height: 11px;
  max-width: 11px;
  max-height: 11px;
  min-height: 11px;
  /* margin-left: 2px; */
  /* margin-top: 2px; */
  border-radius: 10px;
  /* align-self: center; */
  background: rgba(222, 71, 71, 0.8);
}

.CorrectSelectedOption {
  background: rgba(74, 195, 137, 0.8);
}

.CodingBodyWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .BodyWrapper * {
  min-width: 0;
}

.CodingBodyWrapper * {
  min-width: 0;
} */

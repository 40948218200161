.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.backButtonInfoWrapper{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: center;
  justify-content: center;
  /* align-content: center;
  justify-self: center; */

}

.goBack {
  /* width: 19px; */
  /* margin-top: 5px; */
  /* margin-left: -5px; */
  margin-left: auto;
  /* padding-right: 20px; */
  margin-right: 6px;
  margin-top: 28px;
}

.infoWrapper {
  /* display: grid;
  grid-template-rows: 1fr auto; */
  /* grid-template-columns: auto auto 1fr; */
  /* width: 100%; */
  display: flex;
  width: 90%;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  margin-right: auto;
  /* margin-left: auto; */
  max-width: 460px; /* max width - 20 */
  min-width: 335px;
}

/* .empty {
  width: 5%;
} */

.nameWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  padding-bottom: 20px;
}

.candidateSwitch{
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 8px;
}
.switchButton{
  color: #7458D3;
  text-align: left;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  -webkit-appearance: none ;
  padding: 0px;
  appearance: none;
  background-color: transparent;
  border: none;
}
.switchButton:enabled{
  cursor: pointer;
}

 .right {
   text-align: right;
   margin-right: 30px;
 }
.switchButton:not(:disabled):hover{
  text-decoration: underline;
}
.switchButton:disabled{
  color: rgba(116, 88, 211,0.3);
}

.nameText {
  font-family: 'Jost-400';
  font-size: 24px;
  line-height: 34.68px;
  color: #585858;
  padding-right: 10px;
}

.surnameText {
  font-family: 'Jost-400';
  font-size: 30px;
  line-height: 43px;
  color: #585858;
}
.linkIcon {
  align-self: center;
  margin-left: auto;
  margin-right: 30px;
}

.infoText {
  font-family: 'Jost-300';
  font-size: 18px;
  line-height: 26.01px;
  color: rgba(88, 88, 88, 0.8);
  height: fit-content;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}
.icon {
  margin-right: 20px;
  display: flex;
}

.cvWrapper {
  width: 90%;
  max-width: 460px;
  min-width: 335px;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  height: 60px;
  align-items: center;
  display: grid;
  margin-top: 3px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  grid-template-columns: min-content auto min-content;
}

.cvText {
  margin-left: 5px;
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 18px;
  color: #585858;
  margin-top: 4px;
}

.contentBody {
  /* background-color: #fff; */
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  height: calc(100vh - 80px); /* changed with header */
  /* margin-bottom: 40px; */
}

.pageWrapper {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  height: 100%;
  overflow-y: auto;
}

.filterColumn {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-basis: 20%;
  height: calc(100vh - 80px); /* it isn't being used */
  background-color: #fff;
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 319px;
  min-width: 319px;
}

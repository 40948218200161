.switch {
  width: 56px;
  height: 26px;
  border-radius: 100px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.switch.responsive {
  width: 5.6rem;
  height: 2.6rem;
}

.switch.mobile {
  width: 42px;
  height: 22px;
}

.switch.on {
  background-color: #22cc88;
}

.switch.off {
  background: rgba(88, 88, 88, 0.2);
  justify-content: flex-start;
}

.switch div {
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  border-radius: 50px;
  position: relative;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
  transition: all 0.6s;
}

.switch.mobile div {
  width: 24px;
  height: 24px;
}

.switch.responsive div {
  width: 2.8rem;
  height: 2.8rem;
}

.switch.on div {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px);
  border: 2px solid #4ac389;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}

.switch.on.responsive div {
  -webkit-transform: translateX(2.8rem);
  -ms-transform: translateX(2.8rem);
  transform: translateX(2.8rem);
  filter: drop-shadow(0px 0.4rem 0.8rem rgba(0, 0, 0, 0.1));
}

.switch.on.mobile div {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
}

.switch.off div {
  border: 2px solid rgba(88, 88, 88, 0.2);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}

.switch.off.responsive div {
  filter: drop-shadow(0px 0.4rem 0.8rem rgba(0, 0, 0, 0.1));
}

.switch.off.mobile div {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
}
.Panel {
  width: 50%;
}

.Left {
  border-right: 1px solid rgba(57, 57, 57, 0.15);
  height: 100%;
  padding-right: 3.2rem;
  display: flex;
  flex-direction: column;
}

.Right {
  padding: 0rem 0.8rem 0rem 4rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.logoWrapper {
  height: fit-content;
}

.companyLogo {
  display: block;
  max-width: 13rem;
  max-height: 2rem;
  margin-bottom: 5rem;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.oopsImg {
  max-width: 100%;
  max-height: 100%;
}

.Header {
  font-family: 'Jost-500';
  font-size: 2.4rem;
  line-height: 3.5rem;
  color: #393939;
  margin-bottom: 5.6rem;
}

.Subtext {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  margin-bottom: 2.4rem;
}

@media screen and (max-width: 576px) {
  .Panel {
    width: 100%;
  }
  .Left {
    border-right: none;
    border-bottom: 1px solid rgba(57, 57, 57, 0.15);
    padding-right: 0rem;
    padding-bottom: 3.2rem;
  }

  .Right {
    padding: 32px 0px 16px 0px;
  }

  .companyLogo {
    max-width: 120px;
    max-height: 36px;
    margin-bottom: 5rem;
  }

  .Header {
    font-size: 22px;
    line-height: 3.5rem;
    color: #393939;
    margin-bottom: 32px;
  }

  .Subtext {
    font-size: 14.4px;
    line-height: 2.3rem;
    color: #393939;
    margin-bottom: 24px;
  }
}

.wrapper {
  padding: 25px 25px 20px 25px;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  display: flex;
  gap: 25px;
  justify-content: space-between;
}

.footerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 18px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.candidatePP {
  background: #7458d3;
  border-radius: 11.2px;
  height: 84px;
  min-height: 84px;
  max-height: 84px;

  width: 84px;
  min-width: 84px;
  max-width: 84px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  font-family: 'Inter-500';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-right: 25px;
}

.ppImg {
  max-width: 100%;
  max-height: 100%;
  border-radius: 11.2px;
}

.candidateName {
  font-family: 'Jost-500';
  font-size: 16px;
  line-height: 20px;
  color: #393939;
}

.candidateJob {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  color: #393939;
}

.infoBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.infoText {
  font-family: 'Jost-400';
  font-size: 15px;
  line-height: 22px;
  /* display: flex; */
  align-items: center;
  color: #393939;
  margin-left: 12px;
  margin-right: 12px;
}
.footerInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerInfoWrapperBlurred {
  display: inline;
  position: relative;
  bottom: 0;
  width: 100%;
  flex-wrap: nowrap;
  color: #393939;
}

.lockedInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.lockedText {
  font-family: 'Jost-500';
  font-size: 16px;
  line-height: 20px;
  color: #393939;
  white-space: nowrap;
  /* width: min-content; */
}

.inPoolDiv {
  /* height: 24px; */
  left: 304px;
  top: 27px;
  padding: 4px 8px;
  background: rgba(116, 88, 211, 0.15);
  border-radius: 4px;
  text-transform: uppercase;
  color: #7458d3;

  font-family: 'Jost-500';
  font-size: 11px;
  line-height: 16px;
  width: fit-content;
}

.candidateMobileText {
  font-family: 'Jost-500';
  font-size: 16px;
  line-height: 20px;
  color: #393939;
}

@media screen and (min-width: 960px) and (max-width: 1100px) {
  .lockedText {
    font-family: 'Jost-500';
    font-size: 16px;
    line-height: 20px;
    color: #393939;
    /* width: min-content; */
    /* white-space: break-spaces; */
    /* margin-right: 8px; */
  }
  .contentWrapper {
    gap: 12px;
  }
}

@media screen and (min-width: 576px) and (max-width: 650px) {
  .contentWrapper {
    gap: 18px;
  }
  .lockedText {
    /* width: min-content; */
    /* white-space: break-spaces; */
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    height: fit-content;
  }
  .footerWrapperBlurredMobile {
    flex-direction: column;
    gap: 14px;
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
  .candidatePP {
    height: 64px;
    min-height: 64px;
    max-height: 64px;

    width: 64px;
    min-width: 64px;
    max-width: 64px;
  }
  .footerWrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-top: 18px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
}

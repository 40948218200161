.SearchBarSmall {
  margin-right: 10px;
  outline: none;
  /* border: 1px solid rgba(88, 88, 88, 0.2); */
  width: 300px;
  height: 38px;
  box-sizing: border-box;
  border-radius: 10px;
  /* font-size: 18px; */
  padding: 5px 5px 5px 5px;
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
}

.SearchBarLarge {
  margin-right: 10px;
  outline: none;
  /* border: 1px solid rgba(88, 88, 88, 0.2); */
  width: 600px;
  height: 38px;
  box-sizing: border-box;
  border-radius: 10px;
  /* font-size: 18px; */
  padding: 5px 5px 5px 5px;
  display: grid;
  grid-template-columns: 1fr 14fr 1fr;
  align-content: center;
}

.SearchBarLine {
  margin-right: 10px;
  outline: none;
  /* border: 1px solid rgba(88, 88, 88, 0.2); */
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  border-radius: 10px;
  /* font-size: 18px; */
  padding: 5px 5px 5px 5px;
  display: grid;
  grid-template-columns: 2fr 25fr 1fr;
  align-content: center;
}

.SearchBarxSmall {
  margin-right: 10px;
  outline: none;
  width: 225px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 10px;
  /* font-size: 16px; */
  padding: 5px 5px 5px 5px;
  display: grid;
  grid-template-columns: 1fr 25fr 1fr;
  align-content: center;
}

.SearchBarInputField :global(input:-webkit-autofill:focus) {
  padding: 0 !important;
}

.SearchBarInputField :global(input:-webkit-autofill) {
  padding: 0 !important;
}

.SearchBarWhite {
  background-color: #ffffff;
}

.SearchBarGrey {
  background-color: rgba(244, 244, 244, 0.6);
}

.SearchIconButton {
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  background-position: center;
  /* height: 100%;
    width: 100%;; */
}

.SearchBarInputField {
  background-color: transparent;
  outline: none;
  border: none;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  width: 100% !important;
  color: #585858;
  font-family: Jost-300;
  font-size: 16px;
}

.SearchBarWhite .SearchBarInputField::placeholder {
  color: rgba(88, 88, 88, 0.2);
}

.SearchBarGrey .SearchBarInputField::placeholder {
  color: rgba(88, 88, 88, 0.4);
}

.closeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.SearchBarxSmall .SearchBarInputField {
  padding: 0 0 0 5px !important;
  /* font-size: 16px !important; */
}
.SearchBarxSmall .SearchBarInputField:hover {
  padding: 0 0 0 5px !important;
  /* font-size: 16px !important; */
}

.SearchBarxSmall .SearchBarInputField::placeholder {
  padding: 0 0 0 5px !important;
  /* font-size: 16px !important; */
}

.pageWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.left {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  flex-grow: 1;
  height: calc(100vh - 80px); /* changed with header */
  background-color: #f4f4f4;
}

.right {
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  height: calc(100vh - 80px); /* changed with header */
  overflow: hidden;
  background-color: #fff;
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 450px;
  min-width: 450px;
}

body {
    /* margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left; */
    /* background-color: #fff !important; */
}

.QuestionWrapperPane {
  /* background-color: #f4f4f4; */
  background-color: white;
  height: calc(100vh - 100px);
  /* max-height: calc(100vh - 100px); */
  /* 160 = 50 from padding + 80 from navbar + 30 from footer */
  border-radius: 24px 24px 0 0;
  display:flex;
  flex-direction: column;
  margin: 0px 30px;
}
.QuestionHeaderRow {
  display: flex;
  flex-direction: row;
  height: 35px;
}
.QuestionHeader {
  text-align: left;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: black;
  }
  .QuestionHeaderCloseButton {
    height: 30px;
    width: 30px;
  }
  .closeButton {
    color: #fff;
    background-color: #999;
    /* position: absolute; */
    /* top: -8px;
    right: -8px; */
      font-size: 15px;
      border-radius: 50%;
      border: 2px solid #333;
  }
  
  .closeButton:hover,
  .closeButton:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
      outline: none;
  }
  .QuestionPane {
    position: relative;
    grid-row-start: 2;
    /* background-color: rgb(20, 62, 83) !important; */
    background-color: #eee !important;
    border: 1px solid grey;
    display: grid;
    /* margin: 0px 30px; */
    /* padding-top: 20px; */
    /* height: calc(100vh - 210px); */
    display: flex;
  flex-direction: row;
  height: calc(100vh - 185px);
    overflow: hidden;
  }
  .LeftPane {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 185px);
    /* margin: 5px; */
    /* margin-bottom: 10px; */
    padding: 5px 5px 10px 5px;
  width: 50%;
}
  

.height30px{
    height: 30px;
    min-height: 30px;
    font-family: Jost-400;
    font-size: 1.6rem;
  }
  
  .TextEditorQuestionText {
    position: relative !important;
    height: calc(100vh - 215px);
    /* width: 90% !important; */
    /* max-height: inherit !important; */
    /* padding-bottom: 100px; */
  }
  
  .TextEditorQuestionText > .TextEditor > .wrapper > .toolbar {
    border: 2px solid #ccc;
    /* height: min-content; */
    background-color: rgba(116, 88, 211, 0.4) !important;
    border-radius: 8px !important;
    margin: 0 !important;
  }
  
  .TextEditorQuestionText > .TextEditor > .wrapper {
    height: 100% !important;
    /* background-color: red; */
  }
  
  .TextEditorQuestionText > .TextEditor > .wrapper > .editor {
    position: absolute;
    width: 100%;
    height: 90% !important;
    background-color: white;
    padding: 1rem;
    border: 1px solid rgba(116, 88, 211, 0.6);
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .toolbarBorder {
    border-right: 2px solid rgba(190, 190, 190, 0.4);
    border-left: 2px solid rgba(190, 190, 190, 0.4);
    height: 100%;
}

.nav-question {
  background-color: rgba(114, 71, 170, 0.884);
  height: 50px;
  display: grid;
  grid-template-columns: 10fr 1fr 1fr 10fr;
  }

  .questionNavButton{
    width: 50px;
    height: 30px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }

  .TextEditorQuestionText :global(.rdw-option-wrapper) {
    border: none !important;
    background: none !important;
}


/* functionSignature.js */

.RightPane {
    position: relative;
    margin: 5px;
    margin-bottom: 10px;
    height: 100%;
    /* max-height: 100%; */
    font-family: Jost-300;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .FunctionSignature {
    position: relative;
    overflow-y: scroll;
    background-color: white;
    height: 60%;
    width: 100%;
    margin-bottom: 5px;
    padding: 5px;
  }



.FormInput {
    background: rgba(244, 244, 244, 0.6);
    border: 1px solid rgba(116, 88, 211, 0.6);
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 44px;
    padding: 10px 12px;
    /* height: 100%; */
    color: #585858;
}

.FormInput:focus {
    background: white;
    border: 1px solid rgba(116, 88, 211, 0.6);
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 44px;
    padding: 10px 12px;
}

.FormInput:not(:placeholder-shown) {
    border-color: rgba(88, 88, 88, 0.2) !important;
    background-color: #fff !important;
}
.FormInput:-internal-autofill-selected,
.FormInput::autofill {
    border-color: rgba(88, 88, 88, 0.2) !important;
    background-color: #fff !important;
}

.FormInput:-webkit-autofill,
.FormInput:-webkit-autofill:hover,
.FormInput:-webkit-autofill:focus,
.FormInput:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.CodingQuestionOutputDropdown{
    width: 100%;
    /* display: grid; */
  }

  .FunctionSignatureCompilerWrapper{
    position: relative;
    height: 35%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 5fr;
  }

  .FunctionSignatureCompilerDropdownRow{
    position: relative;
    width: 100%;
    grid-row-start: 1;
    display: grid;
    grid-template-columns: 3fr 5fr;
    vertical-align: middle;
    width: 100% !important;
    align-items: center;
  }

  .FunctionSignatureCompilerDropdown{
    width: calc(100% - 160px);
    margin-left: 20px;
    /* max-width: 100%; */
  }
  .FunctionSignatureInitialCode {
    grid-row-start: 2;
    margin-right: 5px;  
  }

.error {
  color: red;
}

.paramError {
  color: red;
}


/* testCases.js */

.TestCasesWrapper {
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    font-family: Jost-300;
    font-size: 15px;
    overflow-y: auto;
    justify-content: space-between;
    margin: 5px;
}
.TestCases {
    background-color: white;
    overflow-y: scroll;
    border-radius: 10px;
    margin: 10px 0px;
    padding: 5px;
    width: 100%;
    height: 50%;
    border: 1px solid #999;
  }
  .ValidationCases {
    max-height: 100%;
    background-color: white;
    overflow-y: scroll;
    height: 50%;
    margin: 10px 0px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #999;
  }


  /* testCaseElement.js */

  .testCaseHeader {
    /* display: grid;
    grid-template-columns: 30% 50% 10% 10%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 5px 0px;
    width: 100%;
    /* font-family: Jost-300; */
    font-size: 14px;
  }

  .TestCaseCountWrapper {
    height: 100% !important;
    min-width: 40px;
  }

  .TestCaseSignature {
    width: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
  }

  .WeightWrapper {
    margin-right: 10px;
  }

  .ButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .TestCaseBody {
    /* margin: 10px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 5px;
    padding-left: 5px;
  }

  .IndividiualRow {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .TestCaseBodyInfoArea {
    width: 40%;
    min-width: 220px;
  }

  .TestCaseInputField {
    margin: 2px 10px;
    font-size: 13px;
    /* width: fit-content; */
    width: 30%;
    min-width: 200px;
  }


  /* verificationCode.js */

  .RightPaneVerificationCode {
    grid-column: 3;
    position: relative;
    margin: 5px;
    margin-bottom: 10px;
    /* display: grid;
    grid-template-rows: 1fr 8fr 2fr; */
    display: flex;
    flex-direction: column;
    font-family: Jost-300;
    font-size: 14px;
    height: 100%;
    overflow: hidden;
    width: 50%;
  }

  .VerificationCodeInfoPanel{
    border-bottom: 1px solid black;
    height: 7%;
  }
  .VerificationCodeCompilerWrapper{
    position:relative;
    width: 100%;
    height: 75%;
    /* display: grid;
    grid-template-rows: 1fr 8fr; */
    display: flex;
    flex-direction: column;
  }
  
  .VerificationCodeCompilerWrapperDropdownRow{
    position: relative;
    width: 100%;
    height: 10%;
    display: grid;
    grid-template-columns: 5fr 2fr;
    /* vertical-align: middle; */
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: center;
  }

  .VerificationCodeCompiler {
    height: 90%;
  }
  
.VerificationCodeCompilerDropdown{
    /* width: calc(100% - 160px); */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .VerificationCodeTestOutput{
    height: 15%;
    /* display: grid;
    grid-template-rows: 1fr 3fr; */
    display: flex;
    flex-direction: column;
  }
  
  .VerificationCodeTestOutputHeader{
    height: 25%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    background-color: rgb(20, 62, 83);
    align-items: center;
  }
  
  .VerificationCodeTestOutputResult{
    height: 75%;
    background-color: rgb(14, 44, 59);
    overflow-y: scroll;

  }

.wrapper {
  display: flex;
  flex-direction: row;
  width: 85%;
  max-width: 1000px;
  min-width: 700px;
  /* margin-left: 3%; */
  /* margin-right: 3%; */
  margin-bottom: 24px;
  transition: 0.7s;
  margin-top: 28px;
}
.editCommentIcon,
.commentIcon {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 32px;
  width: 32px;
}
.imgWrapper {
  height: 60px;
  flex: 0 0 54px;
  width: 54px;
  position: relative;
}
.box {
  border: 1px solid rgba(88, 88, 88, 0.2);
  border-radius: 18px;
  resize: none;
  height: 60px;
  transition: 0.7s;
  width: 100%;
  /* margin-right: 54px; */
  /* width: calc((100% - 54px) * 1); */
  padding-top: 12px;
  padding-left: 18px;
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 1);
}

.box::placeholder {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: rgba(88, 88, 88, 0.2);
  padding-top: 4px;
}

.editBox {
  border: 1px solid #585858;
  border-radius: 18px;
  height: 60px;
  width: 100%;
  padding-top: 14px;
  transition: 0.7s;
  padding-left: 18px;
  padding-bottom: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  resize: none;
  display: grid;
  grid-template-rows: auto min-content;
  grid-row-gap: calc((100% - 36px)*0.09);
}
.height-small{
  height: 60px;
  transition: 0.7s;
}
.height-big{
  height: 156px;
  transition: 0.7s;
}
.opacity-low{
  opacity: 0;
  transition: 0.7s;
}
.opacity-high{
  opacity: 1;
  transition: 0.7s;
}
.editTextArea{
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding-right: 2%;
  font-family: 'Jost-300';
  font-size: 18px;
  line-height: 30px;
  color: #585858;
}

.editTextArea::-webkit-scrollbar {
  width: 8px !important;
}

.cancel{
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: rgba(88, 88, 88, 1);
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 12px;
  width: 72px;
  cursor: pointer;
}
.cancel:hover{
  background: #F4F4F4;
  border-radius: 10px;
}
.cancel:active{
  background: #F4F4F4;
  color: rgba(88, 88, 88, 0.6);
}

.send{
  background: #7458D3;
  border-radius: 12px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  height: 36px;
  width: 72px;
  border: none;
}
.send:hover{
  background: rgba(116, 88, 211, 0.9);
}
.send:active{
  background: rgba(116, 88, 211, 0.9);
  color: rgba(255, 255, 255, 0.6);
}
.send:disabled{
  background: rgba(88, 88, 88, 0.3);
  color: rgba(255, 255, 255, 0.6);
}
.buttonWrapper{
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  gap: 20px;
  max-width: 1054px;
}
.container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.header {
  font-family: 'Jost-400';
  font-size: 24px;
  line-height: 35px;
  color: #393939;
  width: fit-content;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-basis: 663px;
  max-width: 663px;
  flex-grow: 0;
  flex-shrink: 2;
  min-width: 500px;
}
.rightColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 371px;
  max-width: 371px;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 280px;
}
/* Plan Container */
.planContainer {
  background: #ffffff;
  padding: 22px 32px 18px 28px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buttonRowHeader {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.upgradeButton {
  font-family: 'Jost-400';
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: linear-gradient(90deg, rgba(116, 88, 211, 0.67) 0%, #7458d3 104.32%);
  border-radius: 10px;
  height: 42px;
  width: 151px;
  text-align: center;
  outline: none;
  align-self: center;
  cursor: pointer;
  border: none;
  justify-content: center;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 26px;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 15px;
}
.tableColumn {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.unsubscribedRow {
  display: flex;
  flex-direction: column;
}
.unsubscribedInfo {
  display: flex;
  justify-content: space-between;
}
.boldText {
  font-family: 'Jost-400';
  font-size: 16px;
  color: #393939;
}

.lightText {
  font-family: 'Jost-300';
  font-size: 16px;
  color: #393939;
}

.validInfoText {
  font-family: 'Jost-300';
  font-size: 14px;
  color: rgba(57, 57, 57, 0.6);
}

.userIcon {
  border-radius: 50%;
  background: rgba(116, 88, 211, 0.2);
  width: 24px;
  height: 24px;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  padding-top: 3px;
  color: #7458d3;
  font-size: 10px;
  justify-content: center;
  display: flex;
}

.userIconText {
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}

.renewText {
  font-family: 'Jost-300';
  font-size: 16px;
  text-align: right;
  color: #7458d3;
}

.renewalRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Add-On Container */
.addOnContainer {
  background: #ffffff;
  padding: 16px 24px 14px 24px;
}

/* Current Usage Container */
.usageContainer {
  background: #ffffff;
  padding: 18px 26px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.usageGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.usageRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.usageSubheader {
  font-family: 'Jost-400';
  font-size: 14px;
  line-height: 20px;
  color: rgba(57, 57, 57, 0.5);
  display: list-item;
  margin-left: 16px;
}
.barRow {
  display: flex;
  flex-direction: row;
  gap: 9px;
  justify-content: center;
}

.circleContainer {
  height: 15px;
  width: 15px;
  align-self: center;
  display: flex;
  margin-top: -20px;
}
.tooltipIcon {
  display: flex;
  margin-top: 3px;
  background-image: url('../../../Images/info.svg');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-size: 16px;
}

.tooltipIcon:hover {
  display: flex;
  margin-top: 3px;
  cursor: pointer;
  background-image: url('../../../Images/infoHover.svg');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-size: 16px;
}

.icon {
  cursor: pointer;
  background-image: url('../../../Images/info.svg');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-size: 16px;
}

.icon:hover {
  cursor: pointer;
  background-image: url('../../../Images/infoHover.svg');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-size: 16px;
}

.subheader {
  font-size: 12px;
  line-height: 17px;
  color: rgba(57, 57, 57, 0.5);
  text-transform: uppercase;
}

.boldHeader {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.listHeader {
  font-family: 'Jost-400';
  font-size: 14px;
  line-height: 20px;
  color: rgba(57, 57, 57, 0.5);
}
.text {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: rgba(57, 57, 57, 0.84);
}

.planName {
  font-family: 'Jost-400';
  font-size: 24px;
  line-height: 35px;
  color: rgba(57, 57, 57, 0.84);
}

/* Media Queries */
@media screen and (min-width: 1920px) {
}
@media screen and (max-width: 1920px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1040px) {
  .container {
    flex-direction: column;
  }
  .wrapper {
    max-width: 663px;
  }
  .leftColumn {
    flex-basis: auto;
  }
}
@media screen and (max-width: 720px) {
  .leftColumn {
    min-width: 370px;
  }
  .tableRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-row-gap: 24px;
    justify-content: left;
  }
  .tableColumn {
    gap: 4px;
  }
  .buttonRow {
    flex-direction: column;
    gap: 20px;
  }
  .upgradeButton {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .leftColumn {
    min-width: fit-content;
  }
  .buttonRow {
    flex-direction: column;
    gap: 20px;
  }
  .upgradeButton {
    width: 100%;
  }
  .planContainer {
    padding: 22px 24px;
  }
  .usageContainer {
    padding: 22px 24px;
  }
  .header{
    margin-left: 12px;
  }
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  width: 90%;
  margin-left: auto;
  margin-bottom: 12px;
  margin-top: 30px;
  margin-right: auto;
  line-height: 35px;
  color: #393939;
}

.container {
  height: 100%;
  flex-direction: column;
  width: 100%;
  display: grid;
  grid-template-rows: auto 42px;
}

.switchContainer {
  width: 42px;
  height: 22.5px;
  margin-right: 12px;
  position: relative;
  display: flex;
  background: rgba(88, 88, 88, 0.2);
  border-radius: 11.25px;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 89%;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 42px;
  margin-left: auto;
}

.atsRow {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 19px;
  width: 90%;
  flex-direction: row;
  border-bottom: 1px solid rgba(57, 57, 57, 0.2);
  align-items: center;
}

.active {
  background: #4ac389;
}

.listWrapper {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  flex-wrap: wrap;
  margin-right: auto;
  width: 90%;
  max-width: 90%;
  margin-top: 21px;
}

.switchButton {
  width: 19.5px;
  height: 19.5px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.2s linear;
}

.subtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  color: #393939;
  margin-bottom: 26px;
}

.switchText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #393939;
}

@media screen and (max-width: 700px) {
  .subtitle {
    font-size: 16px;
    color: #393939;
    margin: 18px 0px;
  }
  .switchText {
    font-size: 16px;
  }
  .atsRow {
    margin: 0px;
    width: 100%;
    border-bottom: 1px solid rgba(88, 88, 88, 0.1);
  }
  .listWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin: 18px 0px 0px 0px;
    max-width: 100%;
    margin-bottom: 60px;
  }
  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100vw;
    height: fit-content;
    gap: 10px;
    left: 0px;
    bottom: 0px;
    padding: 20px;
    box-shadow: 0px -1px 12px rgba(134, 134, 134, 0.15);
    background-color: #fff;
  }
}

.text {
  font-size: 20px;
}

.modal-dialog {
  width: 572px;
  /* min-width: 0px; */
  max-width: 100vw;
  /* height: 328px; */
  /* min-height: 0px; */
  /* max-height: 100vh; */
}

.modal-content {
  border-radius: 24px !important;
  padding: 2rem !important;
  height: 300px !important;
  /* width: 572px; */
}

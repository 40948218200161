body {
  /* margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left; */
  /* background-color: #fff !important; */
}

.QuestionWrapperPane {
  /* background-color: #f4f4f4; */
  background-color: white;
  height: calc(100vh - 100px);
  border-radius: 24px 24px 0 0;
  /* display: grid;
  grid-template-rows: 1fr 20fr 3fr; */
  display:flex;
  flex-direction: column;
  margin: 0px 30px;
}
.QuestionHeaderRow {
  display: flex;
  flex-direction: row;
  /* height: 4%; */
  height: 35px;
}
.QuestionHeader {
  text-align: left;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.QuestionHeaderCloseButton {
  height: 30px;
  width: 30px;
}

.closeButton {
  color: #fff;
  background-color: #999;
  /* position: absolute; */
  /* top: -8px;
      right: -8px; */
  font-size: 15px;
  border-radius: 50%;
  border: 2px solid #333;
}

.closeButton:hover,
.closeButton:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.QuestionPane {
  position: relative;
  /* background-color: rgb(20, 62, 83) !important; */
  background-color: #eee !important;
  border: 1px solid grey;
  display: flex;
  flex-direction: row;
  /* height: 88%; */
  height: calc(100vh - 185px);
}

.LeftPane {
  /* grid-column: 1; */
  /* display: grid;
  grid-template-rows: 1fr 20fr;
  height: 100%; */
  display: flex;
  flex-direction: column;
  height: calc(100vh - 185px);
  /* margin: 5px; */
  /* margin-bottom: 10px; */
  padding: 5px 5px 10px 5px;
  width: 50%;
}

.height30px{
    height: 30px;
    /* grid-row-start: 1; */
    min-height: 30px;
    font-family: Jost-400;
    font-size: 1.6rem;
  }

  .TextEditorQuestionText {
    position: relative !important;
    height: calc(100vh - 215px);
    /* width: 90% !important; */
    /* max-height: inherit !important; */
    grid-row-start: 2 !important;
    grid-row-end: 3 !important;
    margin-bottom: 100px;
  }
  
  .TextEditorQuestionText > .TextEditor > .wrapper > .toolbar {
    border: 2px solid #ccc;
    /* height: min-content; */
    background-color: rgba(116, 88, 211, 0.4) !important;
    border-radius: 8px !important;
    margin: 0 !important;
  }
  
  .TextEditorQuestionText > .TextEditor > .wrapper {
    height: 100% !important;
    /* background-color: red; */
  }
  
  .TextEditorQuestionText > .TextEditor > .wrapper > .editor {
    position: absolute;
    width: 100%;
    height: 90% !important;
    background-color: white;
    padding: 1rem;
    border: 1px solid rgba(116, 88, 211, 0.6);
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .toolbarBorder {
    border-right: 2px solid rgba(190, 190, 190, 0.4);
    border-left: 2px solid rgba(190, 190, 190, 0.4);
    height: 100%;
}
.nav-question {
    background-color: rgba(114, 71, 170, 0.884);
    height: 50px;
    display: grid;
    grid-template-columns: 10fr 1fr 1fr 10fr;
  }

  .questionNavButton{
    width: 50px;
    height: 30px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }

  .TextEditorQuestionText :global(.rdw-option-wrapper) {
    border: none !important;
    background: none !important;
}

  /*  */

  .MultiSelectRightPane{
    background-color: #f4f4f4;
    padding: 5px;
    border-radius: 24px 24px 0 0;
    width: 50%;
    font-family: Jost-300;
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    margin: 0px 5px;
    margin-bottom: 10px;
  }

  .MultiSelectHeader {
    height: 6%;
  }

  .AnswerBlock {
    height: 90%;
  }

  .FormInput-custom {
    background: rgba(244, 244, 244, 0.6);
    /* border: 1px solid rgba(116, 88, 211, 0.6); */
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 44px;
    padding: 12px;
    /* height: 50%; */
    height: fit-content;
    /* min-height: 200px; */
    /* max-height: 400px; */
    display: flex;
    flex-direction: column;
}

.TopRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10%;
}






.planWrapper {
  width: 100%;
  background: #ffffff;
  padding: 18px 16px 14px 22px;
  filter: drop-shadow(0px 1px 24px rgba(131, 131, 131, 0.1));
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.addOnContainer {
  min-height: 150px;
  height: fit-content;
  display: grid;
  grid-template-rows: auto auto 1.5fr;
}
.headerRow {
  margin-bottom: 8px;
}
.open {
  min-height: 230px;
}

.userIcon {
  border-radius: 50%;
  background: rgb(226, 223, 244);
  width: 24px;
  height: 24px;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  padding-top: 3px;
  color: #7458d3;
  font-size: 10px;
}

.userIconText {
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}

.header {
  font-family: 'Jost-400';
  font-size: 12px;
  line-height: 17px;
  color: rgba(57, 57, 57, 0.49);
  text-transform: uppercase;
}

.addOnTitle {
  font-family: 'Jost-400';
  font-size: 18px;
  color: rgba(116, 88, 211, 0.8);
}

.unsubscribeRow {
  border-top: 1px solid #d9d9d9;
  padding-top: 12px;
  font-family: 'Jost-400';
  font-size: 16px;
  color: #393939;
  cursor: pointer;
}

.unsubscribedRow {
  border-top: 1px solid #d9d9d9;
  padding-top: 7px;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.usagePercent {
  font-family: 'Jost-400';
  font-size: 18px;
  color: #393939;
  display: flex;
  flex-direction: row;
  margin-right: 0 !important;
  margin-left: auto;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 21px;
  align-items: center;
}
.outerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  height: 35px;
}
.outerContainer {
  display: flex;
  flex-direction: column;
}
.addOnTitleOpen {
  color: rgba(57, 57, 57, 0.8) !important;
  margin-left: 20px;
}

.icon {
  cursor: pointer;
  background-image: url('../../../Images/info.svg');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-size: 16px;
}

.icon:hover {
  cursor: pointer;
  background-image: url('../../../Images/infoHover.svg');
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  background-size: 16px;
}

.unsubscribedInfo {
  display: flex;
  justify-content: space-between;
}

.boldText {
  font-family: 'Jost-400';
  font-size: 16px;
  color: #393939;
}

.lightText {
  font-family: 'Jost-300';
  font-size: 16px;
  color: #393939;
}

.validInfoText {
  font-family: 'Jost-300';
  font-size: 14px;
  color: rgba(57, 57, 57, 0.6);
}

.arrowIcon {
  cursor: pointer;
  width: 11px;
  align-self: center;
  justify-self: center;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.closeIcon {
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .planWrapper {
    padding: 22px 24px;
  }
}

.container {
    max-width: fit-content !important;
    max-height: fit-content !important;
  }
  
  .modal-content {
    max-width: 100vw;
    min-width: 572px;
    height: fit-content !important;
    align-items: center;
    display: flex;
  }
  /* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .modal-content {
    min-width: fit-content;
  }
}
/* Temporary mobile solution */

  .textWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
  }
  
  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .title {
    font-family: 'Jost-400';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #585858;
    margin-bottom: 12px;
  }
  .subtitle {
    font-family: 'Jost-300';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    color: #585858;
    margin-bottom: 24px;
    text-align: center;
    white-space: pre-line;
  }
  
  .subtitleBold {
    font-family: 'Jost-400';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    color: #585858;
    margin-bottom: 24px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 45px;
  
    justify-content: flex-end;
  }
  .modalContent {
    border: none;
    padding: 48px 32px 32px 32px !important ;
    max-width: 572px;
  }
  .rowWrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
  .successIcon {
    /* margin-top: 26px; */
    margin-bottom: 20px;
  }
  
.ExtendableCard {
  background-color: white;
  margin-top: 2.4rem;
  box-shadow: 0px 1px 24px rgba(208, 208, 208, 0.15);
  border-radius: 12px;
  width: 100%;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  position: relative;
  padding-left: 3.2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  overflow: hidden;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 3.2rem;
  overflow: hidden;
}

.ClosedHeaderText {
    font-family: 'Jost-500';
    font-size: 1.6rem;
line-height: 2.3rem;
color: #393939;
display: flex;
align-items: center;
}

.baseInputBody {
    display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.5s linear;
}

.closedInputBody {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    /* transition: all 2s linear; */
  }
  
  .openInputBody {
    max-height: 1000px;
    opacity: 1;
    visibility: visible;
  margin-top: -22px;
  /* transition: all 2s linear; */
  }

.CompanyListWrapper {
    display: flex;
    flex-wrap: wrap;
}

.ButtonWrapper {
    display: flex;
    justify-content: flex-end;
}


.SelectAll{
    margin-left: 2rem;
    align-items: center;
    display: flex;
    font-family: 'Jost-400';
font-size: 1.6rem;
line-height: 2.3rem;
color: #7458D3;
cursor: pointer;
}

.BodyHead {
display: flex;
padding-bottom: 16px;
margin-bottom: 6px;
border-bottom: 1px solid rgba(57, 57, 57, 0.1);
}












@media screen and (max-width: 756px) {
  .ExtendableCard {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

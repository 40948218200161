.row {
  display: grid;
  width: 100%;
  padding-bottom: 12px;
  align-items: center;
  grid-template-columns: 1.5fr 3fr 2fr 1fr;
  padding-top: 19px;
  /* border-bottom: 1px solid rgba(57, 57, 57, 0.2); */
}

.billingDate {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}
.downloadButton {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
  cursor: pointer;
  display: flex;
}

@media screen and (max-width: 700px) and (min-width: 576px) {
  .billingDate {
    font-size: 14px;
  }
  .downloadButton {
    font-size: 14px;
  }
}

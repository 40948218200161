.FilterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.8rem;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.FilterRowBackdropWrapper {
  display: flex;
  height: 4.8rem;
  width: 100%;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  /* z-index: 1001; */
  border-radius: 12px;
  padding: 0.7rem 2.8rem 0.6rem 2rem;
}

.FilterRowLeft {
  display: flex;
  flex-direction: row;
  gap: 1.7rem;
  align-items: center;
}

.selectAll {
  font-family: 'Jost-300';
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  color: #585858;
}

.moveTo {
  margin-left: 0.3rem;
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(88, 88, 88, 0.2);
}

.FilterRowRight {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.FilterIconWrapper {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FilterIcon {
  width: 3.2rem;
  height: 3.2rem;
}

.FilterIconBackground {
  width: 3.2rem;
  height: 3.2rem;
}

.sortByWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-left: 0.4rem;
  height: fit-content;
  border-radius: 12px;
  padding: 0.4rem 0.4rem;
  cursor: pointer;
}

.SortIcon {
  margin-left: 0.6rem;
  cursor: pointer;
}

.polygon {
  width: 1rem;
  height: 0.8rem;
  margin-left: 0.5rem;
  background-color: #585858;
  clip-path: polygon(0rem 0rem, 0.5rem 0.8rem, 1rem 0rem);
}

@media screen and (max-width: 576px) {
  .FilterIconBackground {
    margin-left: 20px;
  }
  .FilterIcon {
    width: 30px;
    height: 30px;
  }
  .selectAll {
    font-size: 16px;
    line-height: 23px;
  }
}

@media screen and (hover: hover) {
  .moveToHover:hover {
    text-decoration: underline;
  }
  .FilterIconBackground:hover {
    background-color: rgba(88, 88, 88, 0.1);
    border-radius: 20px;
  }
  .sortByWrapper:hover {
    background-color: rgba(88, 88, 88, 0.1);
  }
}

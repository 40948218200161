.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  margin-top: 29px;
  color: #393939;
  width: 89%;
  margin-left: auto;
  margin-right: auto;
}

.nameInputWrapper {
  display: flex;
  width: 89%;
  margin-left: auto;
  margin-right: auto;
}

.buttonWrapper {
  width: 89%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  justify-content: space-between;
}

.subtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  width: 89%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  margin-top: 14px;
  line-height: 26px;
  color: rgba(57, 57, 57, 0.8);
  margin-bottom: 32px;
}

.container {
  display: grid;
  flex-direction: row;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 42px;
}

@media screen and (max-width: 700px) {
  .container {
    /* width: fit-content;
    height: fit-content; */
  }
  .subtitle {
    font-family: 'Jost-300';
    font-size: 14px;
    line-height: 20px;
    color: rgba(57, 57, 57, 0.8);
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 0;
  }
  .nameInputWrapper {
    display: flex;
    width: 100%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .MobileButtonWrapper {
    width: 100vw;
    left: 0px;
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    box-shadow: 0px -1px 12px rgba(134, 134, 134, 0.15);
  }
}


.companyName {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: rgba(57, 57, 57, 0.5);
}

.assessmentTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  margin-bottom: 2.4rem;
  color: #393939;
}

.infoText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: max(1.6rem, 14px);
  line-height: 2.3rem;
  color: #393939;
  cursor: default;
}

.desc {
  margin-bottom: 2.4rem;
}

.questionWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(57, 57, 57, 0.15);;
}

.skillList {
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
}


.codingElement {
  display: flex;
}

.codingElement::before {
  margin-right: 1rem;
  height: 20px;
  content: url("../../../images/coding.svg");
}

.multiElement {
  display: flex;
}

.multiElement::before {
  margin-right: 1rem;
  height: 20px; /*FIXME: daha iyi bir yol var mı*/
  content: url("../../../images/multiple.svg");
}

.FreeTextElement {
  display: flex;
}

.FreeTextElement::before {
  margin-right: 1rem;
  height: 20px;
  content: url("../../../images/freetext.svg");
}


.infoIcon {
  margin-left: 0.8rem;
  cursor: pointer;
}

.questionPart {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.infoPart {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.questionNumberWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  flex-direction: row;
}

.questionNumber {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: right;
  margin-left: 1rem;
  color: #393939;
}

.bullet {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.questionTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: max(1.3rem, 12px);
  text-transform: uppercase;
  line-height: 1.9rem;
  color: rgba(57, 57, 57, 0.4);
}

.infoList {
  list-style-position: inside;
  list-style: none;
}

.checkBoxGroup label {
  margin-bottom: 0;
}

.checkBoxItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bullet::before {
  content: "•";
  position: relative;
  margin-right: 0.8rem;
}

.infoTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1.6rem;
  font-size: max(1.6rem, 14px);
  line-height: 2.3rem;
  color: #393939;
  cursor: default;
}

/* Mobile Version */
@media screen and (max-width: 576px) {

  .infoPart {
    width: 60%;
  }

}

body div[role='dialog']:has(.package-modal-dialog) {
  padding-right: 0 !important;
}

.blurredContent {
  filter: blur(6px);
}

.package-modal-dialog {
}
.planFirstRow {
  border-bottom: 1px solid rgba(88, 88, 88, 0.2);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

.enterpriseContainer {
  display: flex;
  flex-direction: column;
}

.modal-dialog {
  overflow-y: initial !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  max-width: none !important;
  margin: 0 !important;
}

.backdropClass {
  overflow-y: hidden !important;
  padding-left: 0 !important;
}

:global(.modal-open) {
  overflow-y: hidden !important;
  padding-left: 0 !important;
}

.modal-dialog {
  overflow-y: initial !important;
}

.modal-content {
  left: 0px;
  top: 0px;
  margin-top: 3.5rem !important;
  border-radius: 20px 20px 0px 0px !important;
  background-color: #fafafa;
  padding: 0 !important;
}

.modalBody {
  z-index: 1060;
  width: calc(100vw + 12px);
  overflow-y: scroll;
  /* padding-left: 12px; */
}
.newContainer {
  padding: 24px 12px 24px 30px !important;
  z-index: 1070;
}

.innerBody {
  /* margin-right: 18px; */
  margin-top: -221px;
}

.closeButton {
  position: absolute;
  top: -30px;
  right: 6px;
}
.closeButton img {
  content: url('../../Auth/Images/seePlansClose.svg') !important;
}
.planContainerGrid {
  border: 1px solid rgba(57, 57, 57, 0.1);
}
.innerContainer {
  /* display: flex; */
  margin-bottom: 50px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  /* flex-direction: row; */
  justify-content: space-between;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 20px;
}

.container {
  display: flex;
  padding-top: 0 !important;
  overflow: hidden;
  margin-top: 50px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  flex-direction: column;
  border-radius: 24px 24px;
}

.row {
  display: flex;
  margin-bottom: 48px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  /* justify-content: center; */
  height: 42px;
  border: 1px solid #7458d3;
  border-radius: 10px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-right: 24px;
  padding-left: 14px;
  background-color: #fafafa;
}

.tableItem {
  display: grid;
}
.packageColContainer {
  display: flex;
  flex-direction: column;
  width: 150px;
}
.package {
  justify-self: center;
  margin-bottom: 50px;
  /* overflow: hidden; */
}
.bundleColumn {
  text-align: center;
  justify-content: center;
  width: 280px;
}
.infoItemContainer {
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
}
.cell {
  height: 50px;
  text-align: center;
  justify-content: center;

  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  color: #393939;
}
.stickyHeader {
  background: linear-gradient(180deg, #f9f9f9 -4.59%, #f2f2f2 94.64%);
  align-items: center;
  text-align: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: 20px;
  position: sticky !important;
  top: 0px !important;
  border-radius: 16px;
  width: 750px;
  height: 75px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid rgba(57, 57, 57, 0.1);
  z-index: 1000000;
}

.stickyText {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  line-height: 60px;
  flex-direction: row;
  color: #393939;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  justify-content: center;
}

.stickyTextBorder {
  border-right: 1px solid rgba(39, 39, 39, 0.15);
}

.stickySelected {
  background: linear-gradient(270deg, #7458d3 0%, rgba(116, 88, 211, 0.58) 95.51%);
  border-radius: 16px;
  color: #fff !important;
}

.infoContainer {
  display: flex;
  border-right: 1px solid rgba(57, 57, 57, 0.15);
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  align-content: center;
  place-items: center;
  min-height: 50px;
}

.planValue {
  width: 200px;
}
.featureContainer {
  display: grid;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 12px;
  margin-bottom: 120px;
  height: fit-content;
}
.rightCol {
  display: flex;
  grid-column-start: 2;
  flex-direction: row;
  /* grid-template-columns: auto auto auto auto; */
}

.guruTick {
  grid-row: 5;
  grid-column: 4;
}

.tick {
  margin-left: auto;
  margin-right: auto;
}

.rowItem {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  width: 250px;
  line-height: 35px;

  /* identical to box height */

  text-align: center;

  color: #393939;
}

.infoItem {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 35px;
  color: #393939;
  justify-self: self-start;
}

.category {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 50px;
  color: #393939;
  margin-bottom: 5px;
  height: 65px !important;
  display: flex;
  align-items: flex-end;
}

.categoryName {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 50px;
  text-align: center;
  color: #7458d3;
  min-height: 70px;
  /* margin-bottom: 20px; */
}
.presentCategoryName {
  background: linear-gradient(180deg, rgba(244, 244, 244, 0.3) -4.59%, #f2f2f2 94.64%);
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
  position: sticky;
  top: 0 !important;
  /* position: -webkit-sticky;  */
}

.categoryNameItem {
  width: 250px;
}
.selectedBundle {
  border: 1px solid whitesmoke;
  border-radius: 12px;
}

.packageList {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 26px;

  color: #393939;
}

.packageElement {
  padding: 4px;
  padding-left: 0 !important;
  /* margin-left: 20px; */
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 4px;

  font-family: 'Jost-400';
  font-size: 1.8rem;
  color: rgba(57, 57, 57, 0.8);
}
.packageElementEnt {
  padding: 4px;
  padding-left: 0 !important;
  margin-bottom: 8px;
  margin-right: 4px;

  font-family: 'Jost-400';
  font-size: 1.8rem;
  color: rgba(57, 57, 57, 0.8);
}
.recommended {
  background: linear-gradient(270deg, #7458d3 0%, rgba(116, 88, 211, 0.58) 95.51%);
  border-radius: 12px 12px 0px 0px;
  font-family: 'Jost-400';
  width: fit-content;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  line-height: 29px;
  /* identical to box height */
  margin-left: auto;
  margin-right: auto;
  margin-top: -39px;
  padding-top: 8px;
  padding-bottom: 2px;
  padding-right: 24px;
  padding-left: 24px;
  text-align: center;

  color: #ffffff;
}

.compare {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 35px;
  text-align: center;
  color: #7458d3;
  cursor: pointer;
  /* margin-top: -5px; */
}
.row path {
  stroke: #7458d3;
}
.packageElement::before {
  /* content: ''; */
  display: inline-block;
  width: 2px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 8px;
  height: 2px;
  border-radius: 50%;
  background: #585858;
}

.packageElement[big='true']::before {
  margin-bottom: 25px;
}

.titleRow {
  display: flex;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 15px;
  /* flex-direction: row; */
  /* position: static;
  top:0; */
  flex-direction: column;
}

.modalContent {
  border: none;
  /* width: 95vw !important; */
  height: fit-content;
  height: calc(100vh - 3.5rem) !important;
}

.modalContentClosed {
  border: none;
  height: fit-content;
}
.title {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 400;
  margin-bottom: 18px;
  margin-top: 6px;

  font-size: 2.4rem;
  line-height: 35px;
  /* text-align: center; */
  color: #393939;
}

.description {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  margin-bottom: 24px;
  line-height: 26px;
  color: rgba(57, 57, 57, 0.8);
}

.value {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 3.2rem;
  white-space: nowrap;
  line-height: 46px;
  line-height: 46px;
  color: #393939;
}
.value2 {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  white-space: nowrap;
  line-height: 52px;
  color: #393939;
  line-height: 46px;
}

.divider {
  height: 0.1px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  background-color: rgba(88, 88, 88, 0.2);
}

.modalTitle {
  font-family: 'Jost-500';
  font-style: normal;
  /* margin-bottom: 16px; */
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 35px;
  color: #fff;
  text-align: center;
  width: 100%;
  text-align: center;
}

.points {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  margin-bottom: 16px;
  line-height: 26px;
  color: #393939;
}

.footnote {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 23px;
  margin-bottom: 24px;
  color: rgba(116, 88, 211, 0.5);
}

.categoryContainer {
  height: 100px;
}

.recommendedCol {
  /* background: linear-gradient(
    180deg,
    rgba(116, 88, 211, 0.0375) -3.75%,
    rgba(116, 88, 211, 0.3) 100%
  ) !important;
  border: 1px solid rgba(116, 88, 211, 0.25); */
}
.headerWrapper {
  display: grid;
  grid-template-columns: repeat(1, 450px auto);
  column-gap: 50px;
}

.letsTalk {
  font-family: 'Jost-400';
  font-size: 2.4rem;
  line-height: 35px;
  text-transform: uppercase;
  color: #393939;
  margin-bottom: calc(40px);
}

.highlightedHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -160px;
}
.headerContainer {
  display: flex;
  flex-direction: column;
}

.categoryHeaderContainerGray {
  height: 58px;
  background-color: #f4f4f4;
  width: 100%;
  /* padding-top: 16px; */
  padding-left: 27px;
  padding-right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.categoryHeaderContainer {
  height: 58px;
  background-color: #fff;
  width: 100%;
  /* padding-top: 16px; */
  padding-right: 8px;
  padding-left: 27px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.categoryText {
  font-family: 'Jost-500';
  font-size: 2.4rem;
  line-height: 35px;
  text-transform: capitalize;
  color: #393939;
}
.bundleItemText {
  font-family: 'Jost-400';
  font-size: 1.8rem;
  line-height: 26px;
  color: #393939;
}
.categoryGridWrapper {
  display: grid;
  grid-template-columns: 35% 65%;
}
.planValueContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-family: 'Jost-300';
  font-size: 1.8rem;
  line-height: 26px;
  text-align: center;
  color: #393939;
}

.gradientHeader {
  background: linear-gradient(270deg, #32cbe0 6.2%, #7458d3 87.72%);
  height: 52px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: -8px;
  border-radius: 10px;
  text-align: center;
  font-family: 'Jost-500';
  font-size: 1.8rem;
  text-transform: capitalize;
  color: #ffffff;
  padding-top: 11px;
}
.myStickyHeader {
  position: absolute;
  top: 10px;
}
/* MEDIA QUERIES */
@media screen and (min-width: 1600px) {
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .innerContainer {
    margin-bottom: 50px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }
  .titleRow {
    display: flex;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 45px;
    flex-direction: row;
    min-width: 100%;
    text-align: center;
    justify-content: center;
  }
  .planContainerGrid {
    min-width: 390px !important;
    margin-bottom: 40px;
  }
  .package {
    grid-template-columns: repeat(1, 390px) !important;
  }
  .bundleColumn {
    width: 220px !important;
  }
  .tableColumn {
    grid-template-columns: repeat(3, 220px) !important;
  }
  .stickyHeader {
    width: 660px !important;
  }
  .infoContainer {
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .innerContainer {
    /* display: flex; */
    margin-bottom: 50px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    /* grid-column-gap: 50px; */
  }
  .titleRow {
    display: flex;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 45px;
    flex-direction: row;
    min-width: 100%;
    text-align: center;
    justify-content: center;
  }
  .planContainerGrid {
    min-width: 420px !important;
    margin-bottom: 40px;
  }
  .row {
    display: none;
  }
  .featureContainer {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .innerContainer {
    /* display: flex; */
    margin-bottom: 50px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    /* grid-column-gap: 50px; */
  }
  .titleRow {
    display: flex;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    flex-direction: row;
    min-width: 100%;
    text-align: center;
    justify-content: center;
  }
  .planContainerGrid {
    min-width: 320px;
    margin-bottom: 40px;
  }
  .row {
    display: none;
  }
  .featureContainer {
    display: none;
  }
}

/* NEW */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .innerContainer {
    /* display: flex; */
    margin-bottom: 50px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    /* grid-column-gap: 50px; */
  }
  .titleRow {
    display: flex;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    flex-direction: row;
    min-width: 100%;
    text-align: center;
    justify-content: center;
  }
  .planContainerGrid {
    min-width: 280px;
    margin-bottom: 40px;
  }
  .row {
    display: none;
  }
  .featureContainer {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .newContainer {
    padding: 24px 12px 24px 12px !important;
   
  }
  .innerContainer {
    /* display: flex; */
    margin-bottom: 50px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
  }
  .titleRow {
    display: flex;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    flex-direction: row;
    min-width: 100%;
    text-align: center;
    justify-content: center;
  }
  .planContainerGrid {
    min-width: 350px;
    margin-bottom: 40px;
  }
  .row {
    display: none;
  }
  .featureContainer {
    display: none;
  }
}

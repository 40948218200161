.ContentBody {
  background-color: #fff;
  padding-bottom: 20px;
  border-radius: 24px 24px 0 0;
}
.ContentBody.no-radius {
  border-radius: 0;
}
.AssessmentContent {
  margin-left: 38px;
  margin-right: 38px;
}
/* Mobile Design */
@media screen and (max-width: 710px) {
  .AssessmentContent {
    margin-left: 20px;
    margin-right: 20px;
  }
}
/* Mobile Design */

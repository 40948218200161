.ButtonLayout {
  box-sizing: border-box;
  border-radius: 10px;
  /* letter-spacing: -0.02em; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;

  font-family: 'Jost-400';
  font-style: normal;
  text-align: center;

  width: fit-content;
  padding: 0px 24px;
}

.ButtonLayout.responsive {
  border-radius: 1rem;
  padding: 0rem 2.4rem;
}

.ButtonLayoutWithIcon {
  box-sizing: border-box;
  border-radius: 10px;
  /* letter-spacing: -0.02em; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;

  font-family: 'Jost-400';
  font-style: normal;
  text-align: center;

  width: fit-content;
  padding: 0px 24px 0px 14px;
}

.ButtonLayoutWithIcon.responsive {
  border-radius: 1rem;
  padding: 0rem 2.4rem 0rem 1.4rem;
}

.medium {
  font-size: 18px;
  line-height: 26px;
  height: 42px;
}

.medium.responsive {
  font-size: 1.8rem;
  line-height: 2.6rem;
  height: 4.2rem;
}

.small {
  font-size: 16px;
  line-height: 23px;
  height: 36px;
}

.small.responsive {
  font-size: 1.6rem;
  line-height: 2.3rem;
  height: 3.6rem;
}
.mobile {
  font-size: 1.6rem;
  width: 100%;
  height: 4.2rem;
}

/* ------------------------------------------------- */

.Type1Button {
  background: #7458d3;
  color: #ffffff;
}

.Type1Button > .IconWrapper > .IconColor {
  /* fill: #ffffff; */
}

@media screen and (hover: hover) {
  .Type1Button:hover {
    background: rgba(116, 88, 211, 0.9) !important;
  }
}

.Type1Button:active {
  background: rgba(116, 88, 211, 0.9) !important;
  color: rgba(255, 255, 255, 0.6);
}

.Type1Button:active > .IconWrapper > .IconColor {
  /* fill: rgba(255, 255, 255, 0.6); */
}

.Type1Button:disabled {
  background: rgba(88, 88, 88, 0.3) !important;
  color: rgba(255, 255, 255, 0.6);
  cursor: default;
}

.Type1Button:disabled > .IconWrapper > .IconColor {
  fill: rgba(255, 255, 255, 0.6);
}

/* ------------------------------------------------- */

.Type2Button {
  background: #ffffff;
  color: #585858;
}

.Type2Button > .IconWrapper > .IconColor {
  fill: #585858;
}

@media screen and (hover: hover) {
  .Type2Button:hover {
    background: #f4f4f4 !important;
  }
  
}

.Type2Button:active {
  background: #f4f4f4 !important;
  color: rgba(88, 88, 88, 0.6);
}

.Type2Button:active > .IconWrapper > .IconColor {
  fill: rgba(88, 88, 88, 0.6);
}

.Type2Button:disabled {
  background: #ffffff !important;
  color: rgba(88, 88, 88, 0.2);
  cursor: default;
}

.Type2Button:disabled > .IconWrapper > .IconColor {
  fill: rgba(88, 88, 88, 0.2);
}

/* ------------------------------------------------- */

.Type3Button {
  background: #f1eefb;
  color: #7458d3;
}

.Type3Button > .IconWrapper > .IconColor {
  fill: #7458d3;
}

@media screen and (hover: hover) {
  .Type3Button:hover {
    background: rgba(116, 88, 211, 0.2) !important;
    /* background: #7458d333 !important; */
  }
}

.Type3Button:active {
  background: rgba(116, 88, 211, 0.2) !important;
  color: rgba(116, 88, 211, 0.6);
}

.Type3Button:active > .IconWrapper > .IconColor {
  fill: rgba(116, 88, 211, 0.6);
}

.Type3Button:disabled {
  background: rgba(88, 88, 88, 0.1) !important;
  color: rgba(88, 88, 88, 0.2);
  cursor: default;
}

.Type3Button:disabled > .IconWrapper > .IconColor {
  fill: rgba(88, 88, 88, 0.2);
}

/* ------------------------------------------------- */

.Type4Button {
  background: #ffffff;
  color: #7458d3;
}

.Type4Button > .IconWrapper > .IconColor {
  /* fill: #7458d3; */
}

@media screen and (hover: hover) {
  .Type4Button:hover {
    text-decoration: underline;
  }
}

.Type4Button:active {
  text-decoration: underline;
  color: rgba(116, 88, 211, 0.6);
}

.Type4Button:active > .IconWrapper > .IconColor {
  /* fill: rgba(116, 88, 211, 0.6); */
}

.Type4Button:disabled {
  text-decoration: none;
  color: rgba(88, 88, 88, 0.2);
  cursor: default;
}

.Type4Button:disabled > .IconWrapper > .IconColor {
  /* fill: rgba(88, 88, 88, 0.2); */
}

/* ------------------------------------------------- */

.Type5Button {
  background: #ffffff;
  color: #7458d3;
  border: 1px solid #7458d3;
}

.Type5Button > .IconWrapper > .IconColor {
  /* fill: #7458d3; */
}

@media screen and (hover: hover) {
  .Type5Button:hover {
    color: rgba(116, 88, 211, 0.9);
    border: 1px solid rgba(116, 88, 211, 0.9);
  }

  .Type5Button:hover > .IconWrapper > .IconColor {
    /* fill: rgba(116, 88, 211, 0.9); */
  }
}


.Type5Button:active {
  color: rgba(116, 88, 211, 0.6);
  border: 1px solid rgba(116, 88, 211, 0.6);
}

.Type5Button:active > .IconWrapper > .IconColor {
  fill: rgba(116, 88, 211, 0.6);
}

.Type5Button:disabled {
  color: rgba(88, 88, 88, 0.2);
  border: 1px solid rgba(88, 88, 88, 0.2);
  cursor: default;
}

.Type5Button:disabled > .IconWrapper > .IconColor {
  fill: rgba(88, 88, 88, 0.2);
}

/* ------------------------------------------------- */

.Type6Button {
  background: rgba(222, 71, 71, 0.9);
  color: white;
}

.Type6Button > .IconWrapper > .IconColor {
  fill: white;
}

@media screen and (hover: hover) {
  .Type6Button:hover {
    background: rgba(222, 71, 71, 0.8);
  }

.Type6Button:hover > .IconWrapper > .IconColor {
  fill: white;
}
}


.Type6Button:active {
  color: rgba(255, 255, 255, 0.6);
  background: rgba(222, 71, 71, 0.8);
}

.Type6Button:active > .IconWrapper > .IconColor {
  fill: rgba(255, 255, 255, 0.6);
}

.Type6Button:disabled {
  color: rgba(255, 255, 255, 0.6);
  background: rgba(88, 88, 88, 0.3);
  cursor: default;
}

.Type6Button:disabled > .IconWrapper > .IconColor {
  fill: rgba(255, 255, 255, 0.6);
}

/* ------------------------------------------------- */

.Type7Button {
  background: #ffffff;
  color: #de4747;
}

.Type7Button > .IconWrapper > .IconColor {
  fill: #de4747;
}

@media screen and (hover: hover) {
  .Type7Button:hover {
    text-decoration: underline;
  }
}

.Type7Button:active {
  text-decoration: underline;
  color: #de4747;
}

.Type7Button:active > .IconWrapper > .IconColor {
  fill: #de4747;
}

.Type7Button:disabled {
  text-decoration: none;
  color: rgba(221, 58, 58, 0.3);
  cursor: default;
}

.Type4Button:disabled > .IconWrapper > .IconColor {
  fill: rgba(88, 88, 88, 0.2);
}

/* ------------------------------------------------- */

.Type8Button {
  background: #ffffff;
  color: #585858;
  border: 1px solid #585858;
}

.Type8Button:disabled {
  cursor: default;
  opacity: 0.3;
}

/* ------------------------------------------------- */

.Type9Button {
  background: #ffffff;
  color: #de4747;
  border: 1px solid #de4747;
}

.Type9Button:disabled {
  cursor: default;
  opacity: 0.3;
}

/* ----------------- Loading animation CSS ------------------------------- */

.animationSpan {
  animation: buttonAnimation 1s ease-out infinite;
}

@keyframes buttonAnimation {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
  90% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
}

/* ------------------------------------------------- */

.IconWrapper {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.IconWrapper.responsive {
  margin-right: 1rem;
}

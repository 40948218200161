.TimerHeader {
    display: flex;
    flex-direction: column;
    color: #393939;
    background-color: #fafafa;
}

.UpperTimer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
}

.DigitalTimeWrapper {
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.DigitalTime {
    font-family: Jost-400;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin-left: 1.2rem;
}

.QuestionCount {
    font-family: Jost-400;
    font-size: max(1.6rem, 14px);
    line-height: 2.3rem;
}

.QuestionTimerBar {
  background-color: rgba(88, 88, 88, 0.15);
  overflow: visible;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 9px;
}

.innerBar {
  /* width: 100%; */
  height: 100%;
  /* transition: width 15s linear; */
  background:  linear-gradient(270deg, #7458D3 0%, rgba(116, 88, 211, 0.6) 100%);
}

.wrapper input[type='checkbox'] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  cursor: pointer !important;
}
.wrapper{
  cursor:pointer;
}
.checkbox {
  display: inline-block;
  height: 18px;
  width: 18px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}

.disabled:hover {
  border: 1.5px solid rgba(84, 84, 84, 0) !important;
}

.disabled {
  background-color: rgba(84, 84, 84, 0.4) !important;
  border: 1.5px solid rgba(84, 84, 84, 0) !important;
}
.allowHover:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #7458d3 !important;
  border: 1.5px solid rgba(84, 84, 84, 0) !important;
}
.allowEmptyHover:hover {
  border: 1.5px solid #7458d3 !important;
}
.selected {
  border: 1.5px solid #7458d3 !important;
}

.indeterminate {
  background-image: url('../../images/checkboxLine.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: 43% !important;
  background-size: 65% !important;
}
.indeterminate:hover:not(.disabled) {
  background-image: url('../../images/checkboxLine.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: #57429e !important;
  background-position-x: 43% !important;
  background-size: 65% !important;

}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.current {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-transform: uppercase;
  color: rgba(57, 57, 57, 0.49);
}

.plans {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: right;
  cursor: pointer;
  color: #7458d3;
}

.usageBlock {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.6rem;
}
.usageRow {
  display: flex;
  height: fit-content;
  justify-content: space-between;
  position: relative;
  flex-direction: row;
  gap: 1.6rem;
}
.currentUsageItemWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: rgba(57, 57, 57, 0.5);
}

.currentUsageItemWrapper ul {
  padding-inline-start: 1.6rem;
  margin-bottom: 0rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.currentUsageItemWrapper ul li::marker {
  font-size: 10px;
}
.planNames {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}
.contentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 1rem;
}

.content {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
.available {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: rgba(57, 57, 57, 0.5);
  margin-right: 0.7rem;
}

.circleContainer {
  /* height: 2rem;
    width: 2rem; */
  align-self: center;
  display: flex;
}

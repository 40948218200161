.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 14px 20px;
  background: #ffffff;
  border-bottom: 1px solid rgba(88, 88, 88, 0.05);
}

.assessmentNameWrapper {
  display: flex;
  align-items: center;
}

.assessmentName {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.9rem;
  letter-spacing: -0.02em;
  color: #585858;
  padding-left: 5px;
  padding-right: 16px;
  /* max-width: 200px; */
}

.back {
  width: 10px;
  height: 16px;
  align-items: center;
  justify-content: center;
}
.backWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (hover: hover) {
  .backWrapper:hover {
    cursor: pointer;
    background-color: rgba(88, 88, 88, 0.1);
    border-radius: 50%;
  }
}

.settings {
  width: 2rem;
  height: 2rem;
}
.imgWrapper {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (hover: hover) {
  .imgWrapper:hover {
    cursor: pointer;
    background-color: rgba(88, 88, 88, 0.1);
    border-radius: 50%;
  }
}

.link {
  width: 2rem;
  height: 2rem;
}

.inviteCandidatesButton {
  padding: 6.5px 14px 6.5px 14px;
  width: 18rem;
  height: 3.6rem;
  background: #ffffff;
  border: 1px solid #7458d3;
  border-top-right-radius: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (hover: hover) {
  .inviteCandidatesButton:hover {
    cursor: pointer;
    background: rgba(116, 88, 211, 0.2);
  }
}

.smallInviteButton {
  padding: 1rem 1rem 1rem 1rem !important;
  width: 4rem;
  height: 3.2rem;
}

.inviteCandText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  text-align: center;
  color: #7458d3;
  margin-left: 10px;
}

@media screen and (hover: hover) {
  .inviteCandText:hover {
    cursor: pointer;
  }
}


@media screen and (max-width: 800px) {
  .inviteCandidatesButton {
    padding: 6.5px;
  }
}

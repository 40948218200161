/* .rightColumn {
  overflow: auto;
} */

body:has(.questionNumber) :global(#hubspot-messages-iframe-container) {
  display: none !important;
}
.listContainer {
  overflow: hidden;
  height: calc(100vh - 100px);
  padding-bottom: 200px;
  padding-right: 12px;
}

.listContainer:hover {
  overflow: auto;
  padding-right: 0px !important;
}

.detailsColumn {
  display: flex;
  flex-direction: row;
}

.questionNumber {
  font-family: 'Jost-500';
  font-style: normal;
  margin-left: 3px;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.questionInfo {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}

.detailsBar {
  display: flex;
  padding: 9px 27px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 47px;
  margin-left: auto;
  margin-right: auto;
  background: #f4f4f4;
  border-radius: 12px;
  margin-bottom: 24px;
  /* margin-right: 27px; */
}

.rightColumn p {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-top: 4px;
  width: 90%;
  margin-left: auto;
  margin-bottom: 12px;
  margin-top: 0;
  margin-right: auto;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(88, 88, 88, 0.6);
}

.rightColumn p[type='last'] {
  padding-bottom: 4px;
}

.noQuestion {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  font-family: 'Inter-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #7458d3;
}

.noQuestionText {
  margin-left: 6px;
}

.noQText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #393939;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 11px;
}

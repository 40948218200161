.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 6px;
}


.ReportWrapper {
  background: #ffffff;
  box-shadow: 0px 2px 24px rgba(134, 134, 134, 0.15);
  border-radius: 10px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.QuestionWithHeader {
  filter: drop-shadow(0px 2px 24px rgba(134, 134, 134, 0.15));
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.blurredWrapper {
  filter: blur(12.5px);
}

.lockPosition {
  position: absolute;
  top: 218px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

.LockIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 28px;
}

.QuestionListWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ReportText {
  font-family: 'Jost-500';
  font-size: 18px;
  line-height: 26px;
  color: #393939;
  margin-bottom: 6px;
}

.NotScoredText {
  font-family: 'Jost-400';
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */
  color: #393939;
}

.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.modal-content {
  max-width: 100vw;
  width: 572px;
  height: 500px !important;
  display: flex;
  align-items: center;
}

/* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .modal-content {
    width: fit-content !important;
    max-width: fit-content !important;
  }
  .cardWrapper {
    width: 98% !important;
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .errorMessage {
    width: fit-content !important;
  }
}
/* Temporary mobile solution */

.redCircle {
  height: 42px;
  width: 42px;
  display: flex;
  align-self: center;
  margin-top: 15px;
  margin-bottom: 22px;
}
.modalContent {
  padding: 20px 20px 20px 20px !important ;
  max-width: 572px;
  display: flex;
  align-content: center;
}
.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 12px;
  line-height: 35px;
  color: #585858;
}
.subtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #585858;
  text-align: center;
  margin-bottom: 10px;
  margin-right: 12px;
  margin-left: 12px;
}
.downArrow {
  margin-bottom: 7px;
}
.cardWrapper {
  background: rgba(244, 244, 244, 0.6);
  border-radius: 10px;
  padding: 14px 30px 17px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 508px;
  height: 83px;
  margin-bottom: 8px;
}
.cardTitle {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}
.cardInfo {
  font-family: 'Jost-300';
  font-size: 14px;
  line-height: 20px;
  color: #393939;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mastercard {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.errorMessage {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;
  color: #de4747;
  display: flex;
  flex-direction: row;
  align-self: flex-start !important;
  margin-bottom: 12px;
  height: 23px;
  width: 508px;
  margin-right: auto;
  margin-left: auto;
}

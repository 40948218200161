.successContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-content {
  max-width: 100vw;
  min-width: 572px !important;
  height: fit-content;
  padding: 0 !important;
  border: 1px solid rgba(88, 88, 88, 0.2);
}

.successFootNote {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(88, 88, 88, 0.6);
  margin-top: 20px;
}

.successTitle {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  /* margin-bottom: 8px; */
  text-align: center;
  color: #585858;
}
.successIcon {
  /* margin-top: 26px; */
  margin-bottom: 37px;
}

.successSubtitle {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #585858;
}

.descText {
  font-family: 'Jost-300';
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #585858;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modalContent {
  padding: 20px !important;
}

/* Temporary mobile solution */
@media screen and (max-width: 576px) {
  .modal-content {
    min-width: fit-content !important;
  }
}
/* Temporary mobile solution */

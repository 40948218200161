.categoryWrapper {
  margin-bottom: 24px;
  background: #fafafa;
  border-radius: 12px;
  overflow-y: hidden;
  height: 376px;
  padding-top: 19px;
  padding-left: 14px;
  padding-bottom: 24px;
  justify-content: space-between;
}
.categoryInnerWrapper {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  scroll-behavior: smooth;
  gap: 20px;
  grid-gap: 20px;
  margin-top: 6px;
}
.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}
.scrollWrapper {
  margin-right: 16px;
}
.scrollIcon {
}
.scrollIcon path {
  display: none;
}

.CustomCategoryWrapper {
  margin-bottom: 24px;
  background: #fafafa;
  border-radius: 12px;
  padding: 19px 24px 24px;
  justify-content: space-between;
}

.CustomWrapper {
  display: flex;
  flex-wrap: wrap;
}

.CustomCardWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

@media screen and (min-width: 1005px) {
  .CustomCardWrapper {
    max-width: 50%;
  }
}

@media screen and (min-width: 1440px) {
  .CustomCardWrapper {
    max-width: 33.33%;
  }
}

@media screen and (min-width: 1876px) {
  .CustomCardWrapper {
    max-width: 25%;
  }
}

@media screen and (min-width: 2311px) {
  .CustomCardWrapper {
    max-width: 20%;
  }
}

@media screen and (min-width: 2746px) {
  .CustomCardWrapper {
    max-width: 16.66%;
  }
}

@media screen and (min-width: 3181px) {
  .CustomCardWrapper {
    max-width: 14.286%;
  }
}

@media screen and (min-width: 3618px) {
  .CustomCardWrapper {
    max-width: 12.5%;
  }
}

.first-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
  padding: 1.2rem 0px;
  margin-left: -34px;
  margin-right: 0 !important;
}
.back-button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 44px;
  padding-left: 8px;
  margin-right: 8px;
}
.career-group {
  margin-top: 5rem;
  margin-left: 1rem;
}

.back-button:hover {
  background: rgba(88, 88, 88, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 100px;
  line-height: 44px;
  padding-left: 8px;
}

.back-button:hover svg {
  filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(294deg) brightness(107%) contrast(107%);
}
.candidate-page-result h1.title {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 59px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-top: 50px;
}

.candidate-page-result h2.title {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 53px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-top: 280px;
}
.public-assessment-page .title {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 59px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-bottom: 80px;
}
@media only screen and (max-width: 600px) {
  .public-assessment-page .title {
    font-family: 'News Cycle';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 46px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #585858;
  }
  .floating-form .prepend {
    top: 11px;
  }
}
.who {
  text-decoration: underline;
}
.btn {
  color: #676767;
}
.dropdown-toggle.btn {
  background-color: transparent !important;
  width: 100%;
  height: 100%;
  text-align: start;
  font-size: larger;
  padding: 0;
  align-self: center !important;
}

.dropdown-toggle.btn::after {
  /* padding-top: 30%; */
  align-self: center;
  margin-top: 3%;
}

.dropdown-toggle.btn {
  margin-bottom: 5%;
}
.assessment-header-container .btn {
  background: rgba(116, 88, 211, 0.8);
  border: 1px solid rgba(116, 88, 211, 0.2);
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 16px;
  min-width: 128px;
}
.assessment-header-container .btn {
  background: rgba(116, 88, 211, 0.8);
  border: 1px solid rgba(116, 88, 211, 0.2);
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 16px;
  min-width: 128px;
}
.assessment-header-container .btn .btn-inner .txt-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.assessment-header-container .btn .btn-inner .txt-container span.number {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #585858;
}
.btn:hover span:first-child {
  color: #fff;
}
.btn:focus span:first-child {
  color: #fff;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}
.btn-primary {
  background: rgba(116, 88, 211, 0.5) !important;
  border: 1px solid rgba(116, 88, 211, 0.5) !important;
  color: #585858 !important;
  box-sizing: border-box !important;
  border-radius: 32px !important;
  padding: 12px !important;
  color: #fff !important;
  min-width: 160px;
  font-size: 20px !important;
  line-height: 33px !important;
  letter-spacing: -0.02em !important;
  outline: none !important;
}

.btn-primary:disabled {
  opacity: 0.2;
  border: 1px solid #585858 !important;
  color: #585858 !important;
  background: transparent !important;
}
.SearchAssessment {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 18px;
  background-color: white;
  /* background-position: 3px 3px;  */
  background-repeat: no-repeat;
  padding: 5px 2rem 5px 5px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  background-color: #e5e5e5;
  padding-left: 3rem;
  margin-bottom: 15px;
}
.CustomRow {
  margin-left: 0;
  margin-right: 0;
}
.mainCategory {
  /* padding-top: 19px; */
  font-family: 'Jost-500';
  font-style: normal;
  /* font-size: 1.4rem; */
  font-size: 14px;
  line-height: 20px;
  color: #393939;
  text-transform: uppercase;
  margin-left: 10px;
  height: 20px;
  /* margin-left: 2rem; */
}
.assessment-info .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: #ffffff !important;
  border-radius: 8px;
  border: none !important;
}
.CustomCategoryCard {
  margin-top: 0.5rem;
  max-height: 220px;
  border-radius: 15px !important;
  margin-bottom: 1rem;
  cursor: pointer;
}

.CustomCategoryCardBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.CustomCategoryCardBody:hover .category-right-div {
  opacity: 1;
}
.CustomCategoryCardBody:hover .category-left-div {
  background-color: #9079dc;
}

.category-left-div {
  height: 70px;
  min-width: 40px;
  border-radius: 15px 0 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-left-div.selected {
  background-color: #9079dc;
}

.category-right-div {
  opacity: 0;
  min-width: 50px;
  margin-right: 10px;
  display: flex;
  align-items: end;
  justify-content: end;
}
.category-center-div {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* .CategoryName {
  font-size: 20px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
} */
.category-right-div {
  opacity: 0;
  min-width: 50px;
  margin-right: 10px;
  display: flex;
  align-items: end;
  justify-content: end;
}

.CustomCategoryCardBody:hover .category-right-div {
  opacity: 1;
}
.CategoryQuestions {
  cursor: pointer;
  float: right;
  height: 28px;
  width: 28px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../images/addAssessment/list_default.svg');
}

.CategoryQuestions:hover {
  background-image: url('../../../images/addAssessment/list_hover.svg');
}
.notFoundButton {
  border-radius: 25px;
  border: 1px solid rgba(88, 88, 88, 0.2);
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
}
.secondAddAssessmentPage {
  padding-top: 1rem;
  display: none;
}

.CustomCreate.CreateButton {
  padding: 12px !important;
  background: rgba(116, 88, 211, 0.4) !important;
  border: 1px solid rgba(116, 88, 211, 0.4) !important;
  box-sizing: border-box !important;
  border-radius: 32px !important;
  box-sizing: border-box;
  color: #7458d3 !important;
  min-width: 160px;
  cursor: pointer;
  font-size: 20px !important;
  line-height: 33px !important;
  letter-spacing: -0.02em !important;
}

.CustomCreate.CreateButton:hover:enabled {
  padding: 12px !important;
  background: rgba(116, 88, 2111) !important;
  border: 1px solid rgba(116, 88, 2111) !important;
  box-sizing: border-box !important;
  border-radius: 32px !important;
  box-sizing: border-box;
  color: #fff !important;
}
.CustomCreate.CreateButton:disabled {
  background: transparent !important;
  border: 1px solid rgba(116, 88, 211, 0.2) !important;
}
.CreateButton {
  background: transparent !important;
  border: none !important;
  color: #676767 !important;
  padding: 10px !important;
  border-radius: 50px !important;
  align-self: start !important;
  font-size: 24px !important;
}
.nextButton {
  color: rgba(116, 88, 211, 0.8);
  border: 2px rgba(116, 88, 211, 0.8);
}

.nextButton:hover {
  background-color: rgba(116, 88, 211, 0.8);
  border: none;
  color: white;
}
.userCount {
  font-size: 36px;
}
.rightBigBorder {
  font-size: 50px;
  border-right: 1px solid rgba(88, 88, 88, 0.2);
  height: 60px;
  margin-left: 20px;
  margin-right: 20px;
}
.userWrapper {
  background-color: #fff;
  padding: 6px 12px;
  border-radius: 25px;
  float: left;
  margin-right: 10px;
}
.user-profile-img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid #f4f4f4;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  display: inline-block;
  /* color: #ff8a75;
  background: #f9d7d1; */
  color: #7458d3;
  background: #c3b6ef;
}

.user-profile-img:nth-child(3) {
  background: #c3b6ef;
  color: #7458d3;
}

.user-profile-img:nth-child(5) {
  background: #c3b6ef;
  color: #7458d3;
}
.user-profile-img:nth-child(7) {
  background: #c3b6ef;
  color: #7458d3;
}
.settings {
  vertical-align: sub;
}
.relatedUderName {
  vertical-align: text-bottom;
}
.FormInput.has-prepend,
.floating-form span.has-prepend {
  padding-left: 62px !important;
}

.FormInput.has-prepend-linkedin,
.floating-form span.has-prepend-linkedin {
  padding-left: 231px !important;
}
.FormInput:focus {
  background: white;
  border: 1px solid rgba(116, 88, 211, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 44px;
  padding: 10px 12px;
}

.FormInput:not(:placeholder-shown) {
  border-color: rgba(88, 88, 88, 0.2) !important;
  background-color: #fff !important;
}

.FormInput:-internal-autofill-selected,
.FormInput::autofill {
  border-color: rgba(88, 88, 88, 0.2) !important;
  background-color: #fff !important;
}

.FormInput:-webkit-autofill,
.FormInput:-webkit-autofill:hover,
.FormInput:-webkit-autofill:focus,
.FormInput:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.block-input,
.block-input .FormInput {
  width: 100%;
}
.FormInput {
  background: hsla(0, 0%, 95.7%, 0.6);
  border: 1px solid rgba(116, 88, 211, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 44px;
  padding: 10px 12px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #585858;
}
.user-list .user-list-item {
  height: 48px;
  left: 0;
  top: 0;
  box-sizing: border-box;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0;
  background: hsla(0, 0%, 100%, 0.6);
  border: 1px solid hsla(0, 0%, 100%, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}

.block-input.xl-input .FormInput {
  padding: 18px;
}
.SearchForm {
  background-repeat: no-repeat !important;
  padding: 10px !important;
  padding-left: 40px !important;
}

.user-list .user-list-item.selected {
  /*border: 1px solid rgba(116, 88, 211, 0.8) !important;*/
}
.user-list .user-list-item.statusWrapper {
  cursor: pointer;
}
.user-checkbox {
  min-width: 40px;
  border-radius: 16px 0 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  padding: 24px 7px;
}
.user-checkbox {
  list-style-type: circle;
}
.user-list-item .right-side {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.user-list-item .right-side-body {
  margin-left: auto;
  display: flex;
}
.user-list .user-list-item:hover .drag-icon {
  opacity: 1;
  pointer-events: none;
}
.user-checkbox.selected,
.user-list .user-list-item:hover .user-checkbox {
  /*background: rgba(116, 88, 211, 0.6);*/
}

.user-list .user-list-item:hover {
}

.user-list .user-list-item .statusListUser svg {
  visibility: hidden;
}

.user-list .user-list-item:hover .statusListUser svg {
  visibility: visible;
}

.user-list .delete-user,
.user-list .edit-user {
  visibility: hidden;
  cursor: pointer;
}

.user-list .user-list-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.user-list .user-list-item .shield {
  box-shadow: none !important;
}

.user-list .user-list-item:hover .delete-user,
.user-list .user-list-item:hover .edit-user {
  visibility: initial;
}

.user-list .new-user {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(116, 88, 211, 0.2);
  border: 1px solid rgba(116, 88, 211, 0.2);
  cursor: pointer;
  color: #585858;
}

.user-list .new-user:hover {
  background: rgba(116, 88, 211, 0.8);
  border: 1px solid rgba(116, 88, 211, 0.4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.user-list .user-list-item .left-side {
  width: 50%;
  padding-left: 12px;
  display: flex;
  align-items: center;
  align-content: center;
}
.user-list .fullname {
  font-size: 20px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #585858;
}
.user-list-item .right-side {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.user-list-item .right-side-body {
  margin-left: auto;
  display: flex;
}
.candidate-user-list .user-list-item {
  border: 1px solid rgba(255, 255, 255, 0.8) !important;
  background: rgba(255, 255, 255, 0.8) !important;
  box-sizing: border-box !important;
  border-radius: 16px !important;
  cursor: pointer;
}
.user-list .user-list-item:hover .drag-icon {
  opacity: 1;
  pointer-events: none;
}
.user-list-item:hover .user-role-text {
  display: block !important;
}
.user-role-text {
  font-family: 'News Cycle', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #585858;
  display: none !important;
}
.candidate-user-list .create-left-side {
  display: flex;
  align-items: center;
}
.candidate-user-list .create-left-side-user-list {
  display: flex;
  align-items: center;
}
.create-left-side {
  padding-left: 36px !important;
}
.create-left-side-user-list {
  padding-left: 0px !important;
}
.candidate-user-list .fullname {
  font-family: 'News Cycle', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 33px !important;
  text-align: center !important;
  letter-spacing: -0.02em !important;
  color: #585858 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 330px;
  display: inline-flex;
}

.thirdAddAssessmentPage {
  padding-top: 1rem;
  display: none;
}
.btn-success {
  background: rgba(74, 195, 181, 0.2) !important;
  border: 1px solid rgba(74, 195, 181, 0.3) !important;
  box-sizing: border-box !important;
  border-radius: 32px !important;
  padding: 12px !important;
  color: #4ac4b5 !important;
  min-width: 150px;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: -0.02em !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  outline: none !important;
}

.btn-success:disabled {
  opacity: 0.2;
  background: transparent !important;
  border: 1px solid #58585840 !important;
  color: #58585850 !important;
}

.btn-success:hover:enabled {
  background: rgba(74, 195, 181, 0.8) !important;
  color: #fff !important;
  outline: none !important;
}

.btn-success:hover:enabled img,
.btn-success:hover:enabled svg {
  filter: invert(100%) sepia(0%) saturate(1954%) hue-rotate(183deg) brightness(100%) contrast(106%);
}
.btn-success:focus,
.btn-success:active {
  outline: none !important;
  box-shadow: none !important;
  background: rgba(74, 195, 181) !important;
  color: #fff !important;
}
.floating-form span.has-prepend {
  padding-left: 62px !important;
}

.FormInput.has-prepend-linkedin,
.floating-form span.has-prepend-linkedin {
  padding-left: 231px !important;
}

.floating-form input:not(:placeholder-shown) + span.has-prepend,
.floating-form label:focus-within > span.has-prepend,
.floating-form input:not(:placeholder-shown) + span.has-prepend-linkedin,
.floating-form label:focus-within > span.has-prepend-linkedin {
  padding: 2px 4px !important;
}
.floating-form .prepend {
  position: absolute;
  top: 13px;
  left: 12px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #787878;
  border-right: 1px solid rgba(116, 88, 211, 0.6);
  padding-right: 12px;
}

.floating-form .prepend.filled,
.floating-form .prepend.clicked {
  border-color: rgba(88, 88, 88, 0.2) !important;
  padding: 0px !important;
  padding-right: 12px !important;
}
.floating-form label {
  position: relative;
}

.floating-form input::placeholder {
  opacity: 0;
}

.floating-form span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(14px);
  font-size: 0.9em;
  transition-duration: 300ms;
  margin-left: 12px;
  pointer-events: none;
}
.floating-form span strong {
  top: -6px;
  position: relative;
}

*:focus {
  outline: none !important;
}

.floating-form .select-label span {
  transform: translateY(-10px);
  padding: 2px;
  color: #585858;
  font-size: 13px;
  background: #f4f4f4;
  border-radius: 4px;
  padding: 2px;
}
.floating-form input:-webkit-autofill ~ span {
  transform: translateY(-10px);
  background: #fff;
  padding: 2px;
  color: #585858;
  font-size: 13px;
  border-radius: 4px;
}

.floating-form input:not(:placeholder-shown) + span,
.floating-form label:focus-within > span {
  transform: translateY(-10px);
  background: #fff;
  padding: 2px;
  color: #585858;
  font-size: 13px;
  border-radius: 4px;
  background: #f4f4f4;
  border-radius: 4px;
  padding: 2px;
}
.ResumeCard {
  background-color: #fff;
  height: 60px;
  border-radius: 20px;
  cursor: pointer;
}
.outATSCheckLeft {
  border: 2px solid white;
  width: calc(5%);
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.outATSCheckLeft:hover,
.radio-selected {
  background-color: rgba(116, 88, 211, 0.2);
}

.user-form-select {
  height: 140px;
}

.user-form-select:hover .outATSCheckLeft {
  background-color: rgba(116, 88, 211, 0.2);
}

.outATSCheckLeft.radio-selected {
  border: 1px solid rgba(116, 88, 211, 1);
  border-right: none;
}
.collectLeft.selected {
  background: rgba(116, 88, 211, 0.8);
}
.AtsCheckbox {
  list-style-type: circle;
  margin-left: 16px;
}
.outATSCheckRight {
  width: 95%;
  display: inline;
  background-color: #fff;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 1.4rem;
}
.outATSCheckRight.radio-selected {
  border: 1px solid rgba(116, 88, 211, 1);
  border-left: none;
  background: #fff !important;
}
.outATSCheckRight.selected {
  border: 1px solid rgba(116, 88, 211);
  border-left: none;
}

.manage-candidate .manage-candidate-red,
.manage-candidate h1 {
  font-family: 'News Cycle', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  letter-spacing: -0.02em;
}
.manage-candidate h1 {
  line-height: 59px;
  color: #585858;
  display: flex;
  align-items: center;
}
.ATSHeight {
  height: 250px;
  display: flex;
  cursor: pointer;
}

.manage-candidate .manage-candidate-red {
  font-family: 'News Cycle', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #d35858;
  margin-left: 10px;
}

.manage-candidate .manage-candidate-green {
  font-family: 'News Cycle', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #4ac4b5;
  margin-left: 10px;
}

.manage-candidate .manange-candidate-desc {
  font-size: 24px;
  line-height: 39px;
  color: rgba(88, 88, 88, 0.8);
}
.manage-candidate .manage-candidate-green,
.manage-candidate .manange-candidate-desc {
  font-family: 'News Cycle', sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
}

.career-group-breezy {
  margin-top: 2.6rem;
  margin-left: 0.7rem;
}

.career-group-breezy .customDropdown {
  width: 98%;
}
.career-group-breezy .customDropdown {
  width: 98%;
}

.customDropdown {
  width: 100%;
  text-align: initial;
  background-color: #fff;
  color: #585858;
  font-size: 20px;
}

.customDropdownMenu {
  width: 100%;
  text-align: initial;
  background-color: #fff;
  border-radius: 10px;
  color: #585858;
  font-size: 20px;
}
.customDropdownMenu {
  width: 100%;
  text-align: initial;
  background-color: #fff;
  border-radius: 10px;
  color: #585858;
  font-size: 20px;
}

.row > * {
  width: auto;
}

.highres,
.mediumres,
.lowres {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* min-height: 1px; */
  /* padding-right: 15px;
    padding-left: 15px; */
}

.dropdownContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2.5rem;
  margin-bottom: 2.4rem;
  margin-top: 0.6rem;
}

@media screen and (min-width: 1060px) {
  .lowres {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media screen and (max-width: 1060px) {
  .lowres {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 760px) {
  .lowres {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1396px) {
  .mediumres {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 1726px) {
  .highres {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media screen and (max-width: 900px) {
  .dropdownContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 2rem;
    grid-column-gap: 2.5rem;
    margin-bottom: 1.8rem;
    margin-top: 1.8rem;
  }
}

.filterButton {
  background: #ffffff;
  border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  justify-content: space-between;
  cursor: pointer;
}

.filterButton.active {
  border: 1px solid #7458d3;
  color: #7458d3;
}

/* Mobile Design */

@media screen and (min-width: 576px) and (max-width: 710px) {
  .first-header {
    margin-left: -15px;
  }
}

@media screen and (max-width: 576px) {
  .dropdownContainer {
    display: grid;
    grid-template-columns: auto 110px;
    grid-column-gap: 18px;
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
  }
  .first-header {
    margin-left: -20px;
    padding-left: 20px;
    width: 100vw;
    padding-right: 20px;
    top: 45px;
  }

  .categoryWrapper {
    padding: 16px 20px;
    width: 100vw;
    margin-left: -20px;
    border-radius: 0;
    height: 297px;
  }

  .mainCategory {
    font-size: 12px;
  }

  .scrollIcon svg {
    height: 24px;
    width: 24px;
  }
  .headerWrapper {
    position: sticky;
    left: 0;
  }
  .categoryInnerWrapper {
    overflow-x: auto;
    padding-bottom: 14px;
    gap: 20px;
  }
  .CustomCategoryWrapper {
    width: 100vw;
    margin-left: -20px;
    padding: 16px 20px;
  }
}
/* Mobile Design */

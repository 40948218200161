.PageWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.OuterCard {
  max-width: 1000px;
  min-width: 576px;
  background: #ffffff;
  display: flex;
  width: 60%;
}

.Card {
  /* max-width: 1000px;
  min-width: 576px; */
  background: #ffffff;
  display: flex;
  width: 100%;
  padding: 3.2rem 5rem 3.2rem 5rem;
  flex-direction: column;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
  border-radius: 1.6rem;
}
.circleWrapper {
  display: flex;
  margin-bottom: 3.2rem;
}

.currentCircle {
  margin-right: 0.5rem;
  width: 4rem;
  height: 1.2rem;
  background: rgba(116, 88, 211, 0.6);
  border-radius: 7px;
}

.emptyCircle {
  margin-right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  background: rgba(116, 88, 211, 0.2);
  border-radius: 50%;
}

.filledCircle {
  margin-right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
  background: rgba(116, 88, 211, 0.6);
  border-radius: 50%;
}

@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }
  .circleWrapper {
    margin-bottom: 0;
  }

  .CardWrapper {
    justify-content: unset;
  }
  .OuterCard {
    max-width: 1000px;
    min-width: unset;
    background: #ffffff;
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 576px;
    padding: 0px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 16px;
    align-items: center;
  }
  .Card {
    width: 90%;
    min-width: unset;
    /* min-height: 415px; */
    /* max-width: 576px; */
    justify-content: flex-start;
    border-radius: 16px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .currentCircle {
    width: 32px;
    height: 8px;
  }
  .emptyCircle {
    width: 8px;
    height: 8px;
  }
  .filledCircle {
    width: 8px;
    height: 8px;
  }

  .logo {
    width: 36px;
    height: 36px;
    margin-left: 25px;
  }

  .navigationBar {
    width: 100%;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 45px;
    display: flex;
    align-items: center;
  }
}

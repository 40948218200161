.text {
  top: 5px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #585858;
  border: none;
  background-color: transparent;
  margin-left: 30px;
  -webkit-backface-visibility: hidden;
}

.selectedText {
  top: 5px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #ffffff;
  border: none;
  margin-left: 30px;
  background: #7458d3;
  border-radius: 8px;
  -webkit-backface-visibility: hidden;
}

.new {
  height: 36px;
  /* margin-bottom: 3px; */
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-left: 30px;
  margin-left: 10px;
  padding-left: 20px !important;
  border-radius: 8px;
  width: 175px;
  border: none;
  /* background-color: transparent; */
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 0;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.new:hover {
  background: #f4f4f4;
  border-radius: 8px;
  color: #32cbe0;
  height: 36px;
  /* margin-bottom: 3px; */
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.newFocus {
  background: #32cbe0;
  border-radius: 8px;
  color: #ffffff;
  height: 36px;
  /* margin-bottom: 3px; */
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  padding: 0;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.newFocus:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #32cbe0;
  border-radius: 8px;
  color: #ffffff;
  height: 36px;
  /* margin-bottom: 3px; */
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
  -webkit-backface-visibility: hidden;
}

.inReview {
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-left: 30px;
  border-radius: 8px;
  margin-left: 10px;
  padding-left: 20px !important;
  border: none;
  /* background-color: transparent; */
  background-color: white;
  display: flex;
  padding: 0;
  align-items: center;
  /* background: #f4f4f4; */
  width: 175px;
  border-radius: 8px;
  -webkit-backface-visibility: hidden;
}

.backdropDiv[hover='hover'] {
  background: #f4f4f4 !important;
  cursor: copy !important;
}
.inReview[iconHover='hover'] {
  color: rgba(116, 88, 211, 1)  !important;
  cursor: copy !important;

}
.inReview[hover='hover'] {
  color: #7458d3;
  cursor: copy !important;
}
.stageWrapperPurple[myselected='selected'] span{
  color: white !important;
}
.new[hover='hover'] {
  color: #32cbe0;
  cursor: copy !important;
}
.hired[hover='hover'] {
  color: #4ac389;
  cursor: copy !important;
}
.rejected[hover='hover'] {
  color: #de4747;
  cursor: copy !important;
}
.inReview:hover {
  color: #7458d3;
  background: #f4f4f4;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.inReviewFocus {
  background: #7458d3;
  color: #ffffff;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  padding: 0;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.inReviewFocus:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #7458d3;
  color: #ffffff;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
  -webkit-backface-visibility: hidden;
}

.hired {
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-left: 30px;
  border-radius: 8px;
  border: none;
  /* background-color: transparent; */
  background-color: white;
  display: flex;
  padding: 0;
  margin-left: 10px;
  padding-left: 20px !important;
  width: 175px;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.hired:hover {
  color: #4ac389;
  background: #f4f4f4;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.hiredFocus {
  background: #4ac389;
  color: #ffffff;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  padding: 0;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.hiredFocus:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #4ac389;
  color: #ffffff;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.rejected {
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-left: 30px;
  margin-left: 10px;
  border-radius: 8px;
  padding-left: 20px !important;
  width: 175px;
  border: none;
  /* background-color: transparent; */
  background-color: white;
  display: flex;
  padding: 0;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.rejected:hover {
  color: #de4747;
  background: #f4f4f4;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.rejectedFocus {
  color: #ffffff;
  background: #de4747;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  padding: 0;
  align-items: center;
  -webkit-backface-visibility: hidden;
}

.rejectedFocus:hover {
  color: #ffffff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #de4747;
  border-radius: 8px;
  height: 36px;
  margin-bottom: 3px;
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin-left: 30px;
  border: none;
  display: flex;
  width: 175px;
  margin-left: 10px;
  padding-left: 20px !important;
  text-align: left;
  align-items: center;
  -webkit-backface-visibility: hidden;
}
.stageWrapper button {
  z-index: 1;
  position: relative;
  background: white;
  -webkit-backface-visibility: hidden;
}

.backdropDiv {
  z-index: 1;
  position: relative;
  /* background: white; */
  -webkit-backface-visibility: hidden;
  cursor: pointer;
  -webkit-appearance: none !important;
}

.backdropDiv[mytype='bottom'] {
  z-index: 1;
  position: relative;
  /* background: white; */
  -webkit-backface-visibility: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  cursor: pointer;
}
.backdropDiv[mytype='top'] {
  z-index: 1;
  position: relative;
  /* background: white; */
  -webkit-backface-visibility: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  cursor: pointer;
}

#stageName {
  font-family: Jost-400;
  font-size: 16px;
  line-height: 23px;
}

.hideButton {
  z-index: 0 !important;
  cursor: pointer;
}

.stageWrapper {
  max-width: 100%;
  border-left: 1px solid rgba(88, 88, 88, 0.2);

  /* margin-bottom: 3px; */

  background-color: white;
  /* border-radius: 8px; */
  border-left: 1px solid rgba(88, 88, 88, 0.2);
  display: flex;
  width: fit-content;
  flex-wrap: nowrap;
  -webkit-backface-visibility: hidden;

  position: relative;
}

.stageWrapperPurple {
  max-width: 100%;
  border-left: 3px solid #7458d3;
  margin-left: -1px;
  display: flex;
  -webkit-backface-visibility: hidden;
  position: relative;
  z-index: 0;
}
.stageWrapperBlue {
  max-width: 100%;
  border-left: 3px solid #32cbe0;
  margin-left: -1px;
  display: flex;
  -webkit-backface-visibility: hidden;
  position: relative;
  z-index: 0;
}
.stageWrapperGreen {
  max-width: 100%;
  border-left: 3px solid #4ac389;
  margin-left: -1px;
  display: flex;
  -webkit-backface-visibility: hidden;
}
.stageWrapperBlack {
  max-width: 100%;
  border-left: 3px solid #585858;
  margin-left: -1px;
  display: flex;
  -webkit-backface-visibility: hidden;
}
.stageWrapperRed {
  max-width: 100%;
  border-left: 3px solid #de4747;
  margin-left: -1px;
  display: flex;
  -webkit-backface-visibility: hidden;
}

.dot {
  font-size: 4px;
  margin-right: 5px;
  margin-left: 5px;
  height: 32px;
  margin-top: 8px;
}

.editIcon {
  /* display: flex; */
  /* margin-top: 1px; */
  margin-left: 1px;
  width: 36px;
  height: 36px;
  background-image: url('../../images/purpleEditIcon.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: 30px;
  -webkit-appearance: none !important;

}
.editIcon:hover {
  /* display: flex; */
  margin-top: 0px;
  margin-left: 0px;
  width: 36px;
  height: 36px;
  background-image: url('../../images/purpleEditHover.svg');
  background-repeat: no-repeat;
  background-position-y: bottom !important;
  background-position-x: center;
  background-size: 36px;
  cursor: pointer;
  -webkit-appearance: none !important;
}
.infoIcon {
  align-items: center;
  width: 36px;
  height: 36px;
  background-image: url('../../images/blueInfoIcon2.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: 28px;
  -webkit-appearance: none !important;
}

.infoIcon:hover {
  align-items: center;
  width: 36px;
  height: 36px;
  background-image: url('../../images/blueInfoHover.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: 28px;
  cursor: pointer;
  -webkit-appearance: none !important;
}

.imgWrapper {
  margin-left: 0px;
  display: flex;
  align-items: center;
  -webkit-appearance: none !important;
  -webkit-transform: translate3d(0,0,0) !important;
  transform: unset !important;
}

#button-tooltip-2 :global(.tooltip-inner) {
  background-color: white !important;
}

#button-tooltip-2 :global(.tooltip-arrow::before) {
  border-top-color: white !important;
}

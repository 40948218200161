.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #ffffff;
}

.CardWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #ffffff;
}

.OuterCard {
  background: #ffffff;
  display: flex;
  padding: 20px;
  width: 100%;
  height: 50%;
  flex-direction: column;
}
.InnerCard {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  align-items: center;
  justify-content: space-between;
}
.Logo {
  height: 45px;
  width: 119px;
}

.CardTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #393939;
  margin-top: 32px;
}
.CardText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}
.NoAccountWrapper {
  display: inline-block;
  margin-top: 28px;
}
.NoAccount {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
}
.Signup {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #7458d3;
  margin-left: 5px;
  cursor: pointer;
}

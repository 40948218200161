.powered {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.9rem;
  display: flex;
  margin-right: 0.8rem;
  align-items: center;
  color: #585858;
}

.Panel {
  width: 50%;
}

.Left {
  border-right: 1px solid rgba(57, 57, 57, 0.15);
  height: 100%;
  padding-right: 3.2rem;
  display: flex;
  flex-direction: column;
}

.Right {
  padding: 0rem 0.8rem 100px 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Header {
  font-family: 'Jost-500';
  font-size: 2.4rem;
  line-height: 3.5rem;
  color: #393939;
}

.Subtext {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
}

.logoWrapper {
    height: fit-content;
}

.companyLogo {
  display: block;
  max-width: 13rem;
  max-height: 4rem;
  margin-bottom: 5rem;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.oopsImg {
    max-width: 100%;
    max-height: 100%;
}

@media screen and (max-width: 576px) {
    .Panel {
        width: 100%;
    }
   .Left {
    border-right: none;
    border-bottom: 1px solid rgba(57, 57, 57, 0.15);
    padding-right: 0rem;
    padding-bottom: 3.2rem;
   }

   .Right {
    padding: 2.8rem 1rem 2rem 0rem;
   }

   .Header {
    font-size: 22px;
    line-height: 3.5rem;
    color: #393939;
    margin-bottom: 2rem;
  }
  
  .Subtext {
    font-size: 14.4px;
    line-height: 2.3rem;
    color: #393939;
    margin-bottom: 2rem;
  }

  .companyLogo {
    max-width: 120px;
    max-height: 36px;
    margin-bottom: 5rem;
  }
  }
.MainWrapper {
  transition: all 1s;
  background-color: white;
  outline: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0px 5px;
}

.SearchIcon {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-position: center;
  border-radius: 20px;
}

.searchHover:hover {
  background-color: rgba(88, 88, 88, 0.1);
}

.MainWrapper button {
  padding: 0px;
}

.SearchIcon img {
  max-height: 100%;
  max-width: 100%;
}

.SearchInput {
  background-color: transparent;
  outline: none;
  border: none;
  color: #585858;
  font-family: Jost-300;
  width: 100%;
  font-size: min(max(1.4rem, 12px), 16px);
  line-height: min(max(2.8rem, 24px), 28px);
  padding: 5px 0px;
}

.SearchInput::placeholder {
  color: rgba(88, 88, 88, 0.4);
}

.CloseWrapper {
  margin-left: 5px;
}
@media screen and (max-width: 576px) {
  .extendedIcon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.wrapper {
  display: flex;
  height: 80px;
  border-bottom: 1px solid rgba(88, 88, 88, 0.1);
  margin-bottom: 17px;
  flex-direction: column;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.questionNo {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  color: rgba(88, 88, 88, 0.6);
  margin-right: 3px;
  width: 20px;
}

.questionDifficulty {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 8px;
}

.questionDifficulty::before {
  content: '-';
  margin-right: 8px;
}
.languageLabel {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.questionLanguage {
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(88, 88, 88, 0.5);
  margin-left: 4px;
}
.worldIcon {
  width: 15px;
  height: 15px;
}

.title {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #585858;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: flex-end;
  margin-bottom: 15px;
  margin-left: 23px;
}

.durationWrapper {
  display: flex;
  flex-direction: row;
  padding: 2.5px 12px 2.5px 8px;
  gap: 6px;
  height: 22px;
  background: #f4f4f4;
  border: 0.766197px solid #f4f4f4;
  border-radius: 7px;
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
}

.skillWrapper {
  background: #ffffff;
  border: 0.766197px solid #585858;
  border-radius: 7px;
  padding: 2px 6px 2px 6px;
  font-family: 'Jost-400';
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.duration {
  margin-right: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
}
.statusIcon {
  margin-bottom: 4px;
  margin-left: 11px;
}
.purchasedIcon {
  margin-bottom: 3px;
  margin-left: 11px;
}
.imgWrapper1 {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.imgWrapper2 {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 15px;
}

.imgWrapper1:hover {
  background: rgba(88, 88, 88, 0.1);
  border-radius: 6px;
}

.imgWrapper2:hover {
  background: rgba(116, 88, 211, 0.1);
  border-radius: 6px;
}

.imgWrapper1:active {
  background: rgba(88, 88, 88, 0.2);
  border-radius: 6px;
}

.imgWrapper2:active {
  background: rgba(116, 88, 211, 0.2);
  border-radius: 6px;
}

.img1 {
  /* background-image: url('../Images/outSource.svg');
background-repeat: none; */
  /* width: 100%;
height: 100%; */
}

.img2 {
  /* background-image: url('../Images/deleteMinus.svg');
background-repeat: none; */
  /* width: 100%;
height: 100%; */
  /* height: 24px;
  width: 24px; */
}

.imgWrapper2 {
  height: 24px;
  width: 24px;
}

.container {
  max-width: fit-content !important;
  max-height: fit-content !important;
  padding: 0px !important;
}

.modal-content {
  width: 694px;
  max-width: 100vw;
  height: 500px !important;
}

.categoryArrowWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;
}

.modalContent {
  border: none;
  padding: 24px 32px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 694px !important;
  max-width: 694px;
  height: 500px !important;
}

.Header {
  font-family: Jost-400;
  font-size: 24px;
  line-height: 35px;
  margin-left: 23px;
  display: flex;
  align-items: center;
  color: #393939;
  justify-content: space-between;
  margin-bottom: 8px;
}

.Description {
  font-family: Jost-300;
  font-size: 16px;
  line-height: 23px;
  color: #393939;
  margin-left: 23px;
  margin-bottom: 21px;
}

.left {
  transform: rotate(180deg);
}

.icon {
  flex-shrink: 0;
  cursor: pointer;
}
.icon path {
  stroke: rgba(88, 88, 88, 0.6);
}
.icon:hover path {
  stroke: rgba(88, 88, 88, 1);
}
.CategoryWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 630px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  white-space: nowrap;
}

.Category {
  margin-right: 20px;
  font-family: Inter-400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(57, 57, 57, 0.8);
  cursor: pointer;
  align-items: center;
  height: 23px;
  display: flex !important;
}

.Category:hover {
  color: rgba(116, 88, 211, 0.6);
  border-bottom: 1px solid rgba(116, 88, 211, 0.4);
}

.SelectedCategory {
  color: #7458d3;
  border-bottom: 1px solid #7458d3;
}

.LabelWrapper {
  width: 630px;
  height: 190px; /* 125px + 8px (top margin of Labels) + 1px (outline) */
  margin-top: -16px;
  overflow-y: auto;
  padding: 0px 1px;
  margin-left: 23px;
  margin-right: 23px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-content: flex-start;
}

.Label {
  /* display: flex;
  flex-direction: row; */
  height: 31px;
  width: fit-content;
  background: rgba(244, 244, 244, 0.6);
  border-radius: 7px;
  margin: 8px 15px 8px 0px;
  font-family: Jost-300;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #393939;

  cursor: pointer;
  border: 1px solid transparent;
}

.selectedLabel {
  border: 1px solid rgba(57, 57, 57, 0.6);
}

.LabelText {
  padding: 4px 12px;
}

.LabelIcon {
  padding: 3.5px 7.5px 4.5px 7.5px;
  border-left: 1px solid rgba(57, 57, 57, 0.2);
}

.TickIcon {
  background: rgba(57, 57, 57, 0.8);
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 26.5px;
  height: 30.5px;
}

.ButtonWrapper {
  display: flex;
  flex-direction: row;
  margin: auto 13px 0px auto;
}

.difficultyWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 22px;
  margin-left: 23px;
  margin-right: 13px;
  margin-bottom: 22px;
}

.notAvailable {
  font-family: Jost-300;
  font-size: 1.6rem;
  text-align: center;
  width: 630px;
  margin-top: 75px;
  color: #393939;
  opacity: 0.5;
}
.blurIconRight {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  width: 60px;
  height: 23px;
  content: '';
  display: block;
  position: absolute;
  right: 24px;
}
.blurIconLeft {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 50px;
  height: 23px;
  content: '';
  display: block;
  position: absolute;
  left: 24px;
}

.listWrapper {
  margin-top: 20px;
  overflow: hidden;
  width: 100%;
  /* height: 100vh; */
  height: calc(100vh - 200px);
  padding-bottom: 30px;
  padding-right: 12px;
}

.listWrapper:hover {
  overflow: auto;
  padding-right: 0px !important;
}

.mycompanyLib {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #585858;
}

.loading {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.scrollWrapper {
  height: calc(100vh - 200px);
}

.firstRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  margin-top: 22px;
}

.secondRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 22px;
  height: 32px;
  width: calc(100% - 12px);
}

.coensioLib {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #7458d3;
  margin-right: 18px;
  border-bottom: 1px solid #7458d3;
  cursor: pointer;
}

.companyLib {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(88, 88, 88, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  height: 32px;
  padding: 0 15px;
}

.companyLib:hover {
  background-color: rgba(255, 255, 255, 0.8);
  /* opacity: 0.8; */
  border-radius: 10px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
}

.questionsText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #585858;
}

.searchBar {
  margin-right: 28px;
  margin-left: auto;
}

.createQWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 32px;
  margin-left: auto;
}

.createQ {
  font-family: 'Jost-400';
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #7458d3 !important;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .createQ {
    display: none;
  }
}

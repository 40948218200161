
.tooltip-c {
  font-family: 'Jost-300';
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #585858;
  margin-left: 25px;
}

.tooltip-c :global(.tooltip-arrow) {
  /* background-color: red; */
}

.tooltip-c :global(.tooltip-inner) {
  background: #ffffff;
  pointer-events: none;
  border: 1px solid rgba(88, 88, 88, 0.2);
  box-shadow: 0px 1px 18px rgba(88, 88, 88, 0.2);
  opacity: 1 !important;
  padding: 10px !important;
  max-width: none !important;
}


#candidateCvTooltip :global(.tooltip-arrow::before) {
  left: 0px !important;
}

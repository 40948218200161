.container {
    max-width: fit-content !important;
    max-height: fit-content !important;
    padding: 0px !important;
  }
  
  .modal-content {
    max-width: 100vw;
    width: 540px;
    height: 200px;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: 0;
    justify-content: flex-end;
  }
  .modalContent {
    border: none;
    padding: 20px 24px 24px 30px !important ;
    max-width: 540px;
  }
  .title {
    font-family: 'Jost-400';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 35px;
    color: #585858;
  }
  .subtitle {
    font-family: 'Jost-300';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    color: #585858;
  }
  .container button {
    font-family: 'Jost-400';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    border-radius: 10px;
    width: 92px;
    cursor: pointer;
    height: 37px;
  }
  .cancel:enabled:hover {
    text-decoration: underline;
  }
  .cancel:active {
    text-decoration: underline;
    color: rgba(88, 88, 88, 0.6);
  }
  
  .delete:enabled:hover {
    background: rgba(222, 71, 71, 0.6);
  }
  .delete:active {
    color: rgba(255, 255, 255, 0.6);
  }
  .cancel {
    -webkit-appearance: none;
    background-color: white;
    border: none;
    color: #585858;
  }
  .delete {
    -webkit-appearance: none;
    border: none;
    color: white;
    background: rgba(222, 71, 71, 0.8);
  }
  
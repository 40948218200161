.PageWrapper {
  height: calc(100dvh);
  overflow-x: hidden;
}

.ButtonRow {
  padding: 1.2rem 2.4rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  justify-content: space-between;
  max-width: calc(1295px + 1.8rem + 4.8rem);
}

.GoBackWrapper {
  width: fit-content;
  cursor: default;

  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;

  color: #393939;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.GoBackButton {
  cursor: pointer;
  border-radius: 15px;
  padding: 0px 5.5px;
}

@media screen and (hover:hover) {
  .GoBackButton:hover {
    background-color: rgba(57, 57, 57, 0.1);
  }
}

.NextPrevWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.NextPrevButton {
  border-radius: 10px;
}

@media screen and (hover: hover) {
  .NextPrevButton:hover {
  border-radius: 20px;
  background: rgba(57, 57, 57, 0.1);
}
}

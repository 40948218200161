.ListWrapper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 325px); /* - 323px */
  overflow: hidden;
  margin-bottom: 10px;
  padding-right: 6px;
}

.innerWrapper {
  width: 100%;
  margin-left: 16px;
  /* padding-right:10px; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerWrapper:global(::-webkit-scrollbar-thumb) {
  visibility: hidden;
}

.innerWrapper:global(::-webkit-scrollbar) {
  visibility: hidden;
}

.innerWrapper:hover:global(::-webkit-scrollbar-thumb) {
  visibility: visible;
}

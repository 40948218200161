.mainWrapper {
  margin-top: 46px;
  width:100%;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  /* top: -20px; */
  height: 50px;
  background-color: white;
  margin-bottom: 8px;
  z-index: 1;
}

.wrapper {
  width: 100%;
  height: 50px;
  background: #585858;
  border-radius: 14px;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  font-family: 'Jost-400';
  letter-spacing: -0.02em;
  padding-left: 20px;
  padding-right: 20px;
}

.right{
  /* display: flex; */
  /* width: 20%; */
  /* margin-right: 46px; */
  /* justify-content: space-between;
  flex-direction: row; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  color: white;
  /* margin-right: 20px; */
}
.left{
  /* margin-left: 20px; */
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

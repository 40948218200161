.CompanyItemWrapper {
  min-width: 50%;
  width: 50%;
  padding: 12px 0px;
  display: flex;
  margin-bottom: 2.4rem;
}

.CompanyInfoWrapper {
  margin: 0rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.CompanyLogoWrapper {
  margin-bottom: 15px;
}

.CompanyInfoWrapper img {
  max-width: 75px;
  max-height: 20px;
}

.CompanyNameWrapper {
  font-family: 'Jost-400';
  font-size: 1.6rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;

  color: #393939;
}

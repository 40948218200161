.wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 24px;
  gap: 10px;
  height: 45%;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #585858;
  white-space: nowrap;
  border-radius: 10px;
}
.text{
  font-weight: 400;
  font-family: Jost-400;
  font-size: 16px;
  line-height: 23px;
  text-transform: capitalize;
  color: #585858;
}
.wrapper[name="Reject"]:hover{
  border: 1px solid rgba(222, 71, 71, 0.8);
  color: rgba(222, 71, 71, 0.8);
}
.wrapper[name="Re-Activate"]:hover{
  border: 1px solid #7458D3;
  color: #7458D3;
}
.wrapper[name="Re-Activate"]:hover .text {
  color: #7458D3;
}
.wrapper[name="Reject"]:hover .text{
  color: rgba(222, 71, 71, 0.8);
}
.wrapper[name="Reject"]:active{
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(222, 71, 71, 0.6);
}
.wrapper[name="Reject"]:active .text{
  color: rgba(222, 71, 71, 0.6);
}

.wrapper[name="Re-Activate"]:active .text{
  color:rgba(116, 88, 211, 0.6);
}

.wrapper[name="Re-Activate"]:active {
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(116, 88, 211, 0.6);
  border-radius: 10px;
}

.MainWrapper {
  border-radius: 10px;
  height: 90%;
  /* max-height: 90%; */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.Toolbar {
  background-color: white !important;
  border-radius: 8px !important;
  margin: 0 !important;
}

.Wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Editor {
  background-color: white;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid rgba(116, 88, 211, 0.4) !important;
  overflow-y: auto;
}

.Editor > :global(.DraftEditor-root) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Editor > :global(.DraftEditor-root) > :global(.DraftEditor-editorContainer) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.MainWrapper :global(.rdw-option-wrapper) {
  border: none !important;
  background: rgb(248, 244, 244) !important;
}

.Toolbar :global(.rdw-image-modal-upload-option-label) > img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

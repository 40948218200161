.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.headerText {
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;

  color: #000000;
}

.funnelWrapper {
  max-height: 276px;
  overflow-y: auto;
  overflow-x: hidden;
}

.individualFunnelWrapper {
  width: 310px;
  margin-bottom: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(57, 57, 57, 0.1);
}

.individualFunnelWrapper:last-child {
  border-bottom: unset;
}

.individualFunnel {
  height: 36px;
  border-radius: 8px;
  font-family: 'Jost-400';
  font-size: 16px;
  line-height: 23px;

  padding: 6.5px 20px;
  color: #393939;
  cursor: pointer;
}

@media screen and (hover: hover) {
  .individualFunnel:hover {
    background: #f4f4f4;
  }
}

@media screen and (max-width: 576px) {
    .individualFunnel {
        height: 42px;
        padding: 9.5px 20px;
    }
}
.PageWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  background: #fafafa;
}

.CardWrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.outerCard {
  max-width: 1000px;
  min-width: 576px;
  min-height: 400px;
  background: #ffffff;
  display: flex;
  padding: 6.4rem 12.8rem;
  justify-content: center;
  width: 47%;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);
}
.innerCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo {
  margin-bottom: 3rem;
}

.cardTitle {
  font-family: 'Jost-500';
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.9rem;
  margin-left: 5px;
  color: #393939;
}
.cardText {
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #393939;
  margin-bottom: 1.6rem;
}
.backWrapper {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.4rem;
}

@media screen and (max-width: 576px) {
  .PageWrapper {
    background-color: white;
    justify-content: flex-start;
  }

  .outerCard {
    min-width: 375px;
    box-shadow: none;
    padding: 3rem;
  }
  .innerCard {
    width: 100%;
    height: 100%;
  }
  .cardTitle {
    font-size: 20px;
    line-height: 29px;
    /* margin-bottom: 32px; */
  }
  .cardText {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 2.4rem;
  }
}
